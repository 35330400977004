import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const ScoreGroup = withConfig(theme => styled('div')`
    display: flex;
    justify-content: center;
    padding: 0 0 8px 0;
    margin: -22px 0 0 0;
    @media ${theme.star.mediaQuery.desktop} {
        flex-flow: column nowrap;
        flex: 0 0 35px;
        margin: 0;
        padding: 0;
    }
`);

const setScoreWrapper = (theme: EmotionTheme): SerializedStyles => css`
    background-color: ${theme.star.eventRow.bgColorTertiary};
    color: ${theme.star.eventRow.txtColorTertiary};
    font-size: ${theme.star.fontSize.small};
    height: 16px;
    line-height: 1.3334;
    padding: 0 4px;
    position: relative;
    @media ${theme.star.mediaQuery.desktop} {
        align-items: center;
        display: flex;
        height: 50%;
        justify-content: center;
        padding: 0;
        position: static;
    }
`;

export const ScoreHome = withConfig(theme => styled('span')`
    ${setScoreWrapper(theme)};
    right: 54px;
    text-align: right;
`);

export const ScoreAway = withConfig(theme => styled('span')`
    ${setScoreWrapper(theme)};
    left: 54px;
    text-align: left;
`);
