import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface SocialTwitterIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const SocialTwitterIcon = observer(
    'SocialTwitterIcon',
    ({ className, onClick }: SocialTwitterIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='#072d4f'
                height='24'
                onClick={onClick}
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <mask
                    id='mask-x'
                    style={{ maskType: 'alpha' }}
                    maskUnits='userSpaceOnUse'
                    x='2'
                    y='2'
                    width='20'
                    height='19'
                >
                    <path
                        d='M17.7512 2.65472H20.818L14.1179 10.3124L22 20.7329H15.8284L10.9946 14.4129L5.46359 20.7329H2.39494L9.5613 12.5421L2 2.65472H8.32828L12.6976 8.43139L17.7512 2.65472ZM16.6748 18.8973H18.3742L7.4049 4.39394H5.58133L16.6748 18.8973Z'
                        fill='black'
                    />
                </mask>
                <g mask='url(#mask-x)'>
                    <rect width='24' height='24' fill='fill' />
                </g>
            </SvgElement>
        );
    }
);
