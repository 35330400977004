import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface IconPropsType {
    className?: string;
    onClick?: () => void;
}

export const UkFlagIcon = observer(
    'UkFlagIcon',
    ({ className, onClick }: IconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='none'
                onClick={onClick}
                width='24' height='24' viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <g clipPath='url(#UKFlagIconIdA)'>
                    <rect width='24' height='24' fill='#1A47B8' rx='12'/>
                    <path fill='#fff' fillRule='evenodd' d='M-1.226 0H-4.8v4l30.006 20H28.8v-4L-1.226 0Z' clipRule='evenodd'/>
                    <path fill='#F93939' d='M-3.608 0 28.8 21.657V24h-1.164L-4.8 2.32V0h1.192Z'/>
                    <path fill='#fff' fillRule='evenodd' d='M25.6 0h3.2v4S8.016 17.325-1.6 24h-3.2v-4L25.6 0Z' clipRule='evenodd'/>
                    <path fill='#F93939' d='M28.8 0h-1.085L-4.8 21.675V24h1.192L28.8 2.338V0Z'/>
                    <path fill='#fff' fillRule='evenodd' d='M7.42 0h9.189v7.403H28.8v9.19H16.609V24h-9.19v-7.408H-4.8v-9.19H7.42V0Z' clipRule='evenodd'/>
                    <path fill='#F93939' fillRule='evenodd' d='M9.347 0h5.306v9.23H28.8v5.54H14.653V24H9.347v-9.23H-4.8V9.23H9.347V0Z' clipRule='evenodd'/>
                </g>
                <defs>
                    <clipPath id='UKFlagIconIdA'>
                        <rect width='24' height='24' fill='#fff' rx='12'/>
                    </clipPath>
                </defs>
            </SvgElement>
        );
    }
);
