import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled'; 
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { withConfig } from 'src/withConfig';
import { SerializedStyles, css } from '@emotion/react';

const buildInfoMessageWrapper = (theme: EmotionTheme, backgroundColor: string, hasIcon?: boolean, hasMargin?: boolean): SerializedStyles | undefined => {
    if (backgroundColor === 'default') {
        if (hasIcon === false) {
            return css`
                background-color: ${theme.star.statuses.info.bgColor};
                color: ${theme.star.statuses.info.txtColor};
                margin: 0;
            `;
        }
        if (hasMargin === false) {
            return css`
                background-color: ${theme.star.statuses.info.bgColor};
                color: ${theme.star.statuses.info.txtColor};
                margin: 0;
            `;
        }
        return css`
            background-color: ${theme.star.statuses.info.bgColor};
            color: ${theme.star.statuses.info.txtColor};
            margin: 10px 0 0;
            
        `;
    }

    if (backgroundColor === 'transparent') {
        return css`
            background-color: transparent;
            color: ${theme.star.statuses.text.info.txtColor};
            margin: 8px 0 0;
        `;
    }
};

interface InfoMessageStyledType {
    backgroundColor: 'default' | 'transparent';
    hasIcon?: boolean;
    hasMargin?: boolean;
}

export const InfoMessageWrapper = withConfig(theme => styled('div')<InfoMessageStyledType>`
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
    padding: 4px 8px;
    text-align: left;
    ${({ backgroundColor, hasIcon, hasMargin }): SerializedStyles | undefined => buildInfoMessageWrapper(theme, backgroundColor, hasIcon, hasMargin)};
`);

export const ErrorMessageWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.statuses.error.bgColor};
    color: ${theme.star.statuses.error.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
    margin: 0 0 4px 0;
    padding: 4px 8px;
    text-align: left;
`);

export const InfoIconWrapper = styled(InfoIcon)`
    fill: currentcolor;
    flex: 0 0 16px;
    height: 16px;
    margin-right: 4px;
`;

export const WarningIconWrapper = styled(WarningIcon)`
    fill: currentcolor;
    flex: 0 0 16px;
    margin-right: 4px;
    width: 16px;
`;

export const SuccessMessageStyled = withConfig(theme => styled('div')`
    background-color: ${theme.star.statuses.success.bgColor};
    color: ${theme.star.statuses.success.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
    margin: 10px 0 20px 0;
    padding: 4px;
    position: relative;
`);

export const SuccessMessageContent = styled('div')`
    align-items: center;
    display: flex;
`;
