import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const NavigationSportsInner = withConfig(theme => styled('div')`
    background: ${theme.star.lhsSidebar.bgColor};
    width: 100%;
    max-width: 375px;
    padding: 0 0 50px 0;
    bottom: auto;
    left: auto;
    top: auto;
    position: relative;
    transform: none;
    transition: none;
    z-index: ${theme.star.zIndexGlobal.base};
    @media ${theme.star.mediaQuery.largeDesktop} {
        background: ${theme.star.lhsSidebar.bgColorSecondary};
        max-width: none;
        padding: 10px;
    }
`);

export const NavigationSidebarContent = withConfig(theme => styled('div')`
    overflow-y: auto;
    @media ${theme.star.mediaQuery.largeDesktop} {
        overflow: visible;
    }
`);

export const SectionWrapper = styled('div')`
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 0%;
`;
