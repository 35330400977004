import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const MarketGroupFilters = withConfig(theme => styled('nav')`
    background-color: ${theme.star.marketGroupGoalscorer.bgColorSecondary};
    margin-top: -1px;
    padding: 8px;
`);

export const SelectionsGroupWrap = withConfig(theme => styled('div')`
    background-color: ${theme.star.selection.bgColor};
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: -1px;
`);


export const SelectionsInRow = styled('ul')`
    display: flex;
`;

export const MarketGroupCaption = withConfig(theme => styled('div')`
    align-items: center;
    border-bottom: 1px solid ${theme.star.marketGroupGoalscorer.borderColor};
    border-top: 1px solid ${theme.star.marketGroupGoalscorer.borderColor};
    display: flex;
    justify-content: space-between;
    line-height: 1.4;
    margin-bottom: -1px;
    position: relative;
    width: 100%;
`);

export const CaptionGroup = styled('div')`
    display: flex;
`;

interface CaptionPropsType {
    isFirst?: boolean;
}

export const Caption = withConfig(theme => styled('span') <CaptionPropsType>`
    border-left: 1px solid ${theme.star.marketGroupGoalscorer.borderColor};
    border-right: 1px solid ${theme.star.marketGroupGoalscorer.borderColor};
    color: ${theme.star.marketGroupGoalscorer.txtColorSecondary};
    font-size: ${theme.star.fontSize.xSmall};
    margin-left: -1px;
    padding-left: ${({ isFirst }): string => isFirst === true ? '8px' : '0'};
    text-align: ${({ isFirst }): string => isFirst === true ? 'left' : 'center'};
    width: ${({ isFirst }): string => isFirst === true ? '100%' : '50px'};
    @media ${theme.star.mediaQuery.tablet} {
        width: ${({ isFirst }): string => isFirst === true ? '100%' : '68px'};
    }
`);

export const SelectionsGroup = styled('section')`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const Goalscorers = withConfig(theme => styled('ul')`
    border-top: 1px solid ${theme.star.marketGroupGoalscorer.borderColor};
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
`);

export const GoalscorersElement = withConfig(theme => styled('li')`
    border-right: 0;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 0%;
    justify-content: space-between;
    width: 100%;
    min-height: 40px;
    &.market-group__goalscorers--NG .selection {
        background: ${theme.star.marketGroupGoalscorer.bgColorTertiary};
    }
`);

interface GoalscorerNamePropsType {
    templateId: string;
}

export const GoalscorerName = withConfig(theme => styled('span')<GoalscorerNamePropsType>`
    background: ${({ templateId }): string => templateId === 'NG' ? theme.star.marketGroupGoalscorer.bgColorTertiary : 'transparent'};
    border-bottom: 1px solid ${theme.star.marketGroupGoalscorer.borderColor};
    color: ${theme.star.marketGroupGoalscorer.txtColor};
    display: block;
    line-height: 1.3334;
    overflow: hidden;
    padding: 13px 8px 11px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`);
