import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { LazyLoadingImg } from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg';
import { GameWrapperType } from 'src/domains/casino/state/AppCasino.state';
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';

const buildGameWrapperStyles = (theme: EmotionTheme, wrapperType: GameWrapperType): SerializedStyles => {
    if (wrapperType === 'rectangle') {
        return css`
            padding-top: 75%;
            width: 100%;
        `;
    }
    else if (wrapperType === 'square') {
        return css`
            padding-top: 100%;
            width: 100%;
        `;
    }
    else if (wrapperType === 'slider-grid') {
        return css`
            padding-top: 75%;

            :nth-of-type(1) {
                grid-column: span 2;
                grid-row: span 2;
                padding-top: 100%;
            }

            @media ${theme.star.mediaQuery.xxMobile} {
                max-height: 125px;
                width: 167px;

                :nth-of-type(1) {
                    max-height: 258px;
                    width: 258px;
                }
            }
            @media ${theme.star.mediaQuery.tablet} {
                height: 100%;
                max-height: 180px;
                width: 240px;

                :nth-of-type(1) {
                    max-height: 368px;
                    width: 368px;
                }
            }
        `;
    }
    else if (wrapperType === 'slider') {
        return css`
            max-height: 125px;
            padding-top: 75%;
            width: 167px;

            @media ${theme.star.mediaQuery.tablet} {
                max-height: 180px;
                width: 240px;
            }
        `;
    }

    return css`
        height: 100%;
        width: 100%;
    `;
};

interface GameWrapperPropsType {
    wrapperType: GameWrapperType;
}

export const GameWrapper = withConfig(theme => styled('div') <GameWrapperPropsType>`
    position: relative;
    width: 100%;
    overflow-y: hidden;
    ${({ wrapperType }): SerializedStyles => buildGameWrapperStyles(theme, wrapperType)};
    &:last-of-type {
        margin-right: 0;
    }
    &:hover > div {
        visibility: visible;
    }
`);

export const GameImgWrapperLazy = styled(LazyLoadingImg)`
    ${positionElementFull};
`;
