import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';


export const MainContent = withConfig(theme => styled('div')`
    padding-left: 16px;
    @media ${theme.star.mediaQuery.desktop} {
        padding-left: 24px;
    }
`);

export const ContentWrapper = withConfig(theme => styled('div')`
    display: flex;
    flex: 1 1 0%;
    flex-flow: column nowrap;
    width: 100%;
    
    @media ${theme.star.mediaQuery.largeDesktop} {
        flex-direction: row;
    }
`);

interface ContentWrapperChildrenPropsType {
    streamRmgWrapperIsVisible: boolean | undefined;
    isStreamOpen: boolean | undefined;
    isAccountOpen: boolean | undefined;
}

const setStreamVisibleWhenIsFloating = (theme: EmotionTheme, streamRmgWrapperIsVisible: boolean | undefined, isStreamOpen: boolean | undefined, isAccountOpen: boolean | undefined): SerializedStyles => {
    if (isAccountOpen === true) {
        return css`
            z-index: ${theme.star.zIndexGlobal.base};
        `;
    };

    if (streamRmgWrapperIsVisible === false && isStreamOpen === true && isAccountOpen === false) {
        return css`
            overflow: initial;
            z-index: ${theme.star.zIndexGlobal.floatingStream};
        `;
    };

    return css``;
};

export const ContentWrapperChildren = withConfig(theme => styled('div')<ContentWrapperChildrenPropsType>`
    overflow: hidden;
    flex: 1 1 0%;
    display: flex;
    flex-flow: column nowrap;
    @media ${theme.star.mediaQuery.largeDesktop} {
        flex: 1 1 auto;
        width: calc(100% - 200px);
        ${({ streamRmgWrapperIsVisible, isStreamOpen, isAccountOpen }): SerializedStyles => setStreamVisibleWhenIsFloating(theme, streamRmgWrapperIsVisible, isStreamOpen, isAccountOpen)};
    }
`);
