import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

interface RadioButtonTypes {
    color: 'light' | 'dark';
    isDisabled: boolean;
    isActive: boolean;
    gap?: string;
}



const setButtonColor = ( color: 'light' | 'dark', isActive: boolean, theme: EmotionTheme): SerializedStyles => {
    if (color === 'light') {
        if (isActive) {
            return css`
                &::before {
                    border-color: ${theme.star.radioButton.light.borderColorSecondary};
                }
                &::after {
                    background-color: ${theme.star.radioButton.light.bgColor};
                    opacity: 1;
                }
            `;
        }
        return css`
            &::before {
                border-color: ${theme.star.radioButton.light.borderColor};
            }
        `;
    }

    if (isActive) {
        return css`
            &::before {
                border-color: ${theme.star.radioButton.dark.borderColorSecondary};
            }
            &::after {
                background-color: ${theme.star.radioButton.dark.bgColor};
                opacity: 1;
            }
        `;
    }

    return css`
        &::before {
            border-color: ${theme.star.radioButton.dark.borderColor};
        }
    `;
};

const styleDisabledRadio = (isDisabled?: boolean): string | null => {
    if (isDisabled !== undefined && isDisabled === true) {
        return `
            cursor: not-allowed;
            opacity: .3;
            user-select: none;
        `;
    }
    return null;
};

export const RadioButton = withConfig(theme => styled('label')<RadioButtonTypes>`
    display: flex;
    cursor: pointer;
    position: relative;
    &:not(:last-of-type){
        margin-right: ${({ gap }): string => gap === undefined ? '0' : `${gap}px`};
    }

    &::before {
        background-color: transparent;
        border-style: solid;
        border-radius: 50%;
        bottom: 0;
        content: '';
        margin: auto 0;
        position: absolute;
        top: 0;
        transition: box-shadow 0.2s ease;
        border-width: 2px;
        height: 24px;
        width: 24px;
    }
    &::after {
        border-radius: 50%;
        bottom: 0;
        content: '';
        margin: auto 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.2s ease;
        width: 14px;
        height: 14px;
        left: 5px;
    }

    ${({ isDisabled }): string | null => styleDisabledRadio(isDisabled)};
    ${({ color, isActive }): SerializedStyles => setButtonColor(color, isActive, theme)};
`);

export const RadioButtonInput = styled('input')`
    visibility: hidden;
    width: 24px;
    margin: 0 8px 0 0;
`;

interface RadioLabelType {
    size: 'small' | 'large';
}

export const RadioLabel = withConfig(theme => styled('div')<RadioLabelType>`
    display: flex;
    font-size: ${({ size }): string => size === 'large' ? theme.star.fontSize.xRegular: theme.star.fontSize.small};
    align-items: center;
`);
