import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import styled from '@emotion/styled'; 
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';

export const RaceHeaderWrapper = styled('div')`
    padding: 0 8px;
    position: relative;
`;

export const RaceHeaderDetails = withConfig(theme => styled('header')`
    color: ${theme.star.quickPick.txtColor};
    padding: 6px 0 8px;
`);

export const RaceHeaderDetailsName = withConfig(theme => styled('h4')`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 0;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.xMedium};
        line-height: 1.4;
    }
`);

export const RaceHeaderDetailsDistance = styled('div')`
    display: flex;
    flex-flow: row wrap;
    margin-top: 3px;
`;

export const RaceHeaderListMeta = withConfig(theme => styled('ul')`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
    margin-right: 8px;
`);

export const RaceHeaderListItemMeta = styled('li')`
    display: inline-block;
    margin: 0 5px 0 0;
    &:last-of-type {
        margin-right: 0;
    }
`;

export const RaceHeaderListItemSeparatorMeta = styled(RaceHeaderListItemMeta)`
    position: relative;
    width: 1px;
    &:last-of-type {
        display: none;
    }
    &::after {
        background-color: currentcolor;
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
    }
`;

export const RaceHeaderListEwTerms = withConfig(theme => styled('ul')`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
`);

export const RaceHeaderListItem = withConfig(theme => styled('li')`
    color: #9b9b9b;
    display: inline-block;
    font-weight: ${theme.star.fontWeight.medium};
    margin-right: 5px;
`);

export const QuickPickStyle = withConfig(theme => styled('section')`
    color: ${theme.star.quickPick.txtColorQuaternary};
`);

export const QuickPickInfo = withConfig(theme => styled('div')`
    color: ${theme.star.quickPick.txtColorQuinary};
    font-size: ${theme.star.fontSize.xRegular};
    padding: 10px 5px 15px;
    position: relative;
    text-align: center;
`);

export const QuickPickColumnTitle = withConfig(theme => styled('span')<{isSelected: boolean}>`
    border-bottom: 1px solid ${theme.star.quickPick.borderColor};
    border-right: 1px solid ${theme.star.quickPick.borderColor};
    color: ${theme.star.quickPick.txtColor};
    height: 50px;
    line-height: 1.4;
    margin-left: -1px;
    overflow: hidden;
    padding-top: 8px;
    position: relative;
    text-overflow: ellipsis;
    transition: 300ms all ease-in;
    white-space: nowrap;
    ${({ isSelected }): SerializedStyles => {
        if (isSelected === true) {
            return css`
                color: ${theme.star.quickPick.txtColorTertiary};
                background-color: ${theme.star.quickPick.bgColorSecondary};
                z-index: ${theme.star.zIndexGlobal.above};
            `;
        }
        return css``;
    }}
`);

export const QuickPickColumnStyle = withConfig(theme => styled('div')<{isSelected: boolean}>`
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 69px;
    justify-content: flex-start;
    position: relative;
    text-align: center;
    width: 69px;
    .quick-pick__selection {
        border-color: ${theme.star.quickPick.borderColor};
        border-style: solid;
        border-width: 0 1px 1px 1px;
        display: flex;
        flex-direction: row;
        font-size: ${theme.star.fontSize.xRegular};
        font-weight: ${theme.star.fontWeight.bold};
        height: 50px;
        justify-content: center;
        margin-left: -1px;
        min-height: 50px;
        opacity: ${({ isSelected }): string => isSelected ? '1' : '0.4'};
    }

    .quick-pick__selection:hover {
        z-index: ${theme.star.zIndexGlobal.above};
    }

    .quick-pick__selection:hover::after {
        background-color: ${theme.star.quickPick.bgColorTertiary};
    }
`);

export const QuickPickContent = withConfig(theme => styled('div')`
    background-color: ${theme.star.quickPick.bgColor};
    border: 1px solid ${theme.star.quickPick.borderColor};
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 16px;
`);

export const QuickPickNameStyle = withConfig(theme => styled('ul')`
    flex: 0 0 220px;
    max-width: 220px;
    li {
        align-items: stretch;
        box-shadow: inset 0 -1px 0 0 ${theme.star.quickPick.borderColor};
        display: flex;
        flex-flow: row nowrap;
        font-size: ${theme.star.fontSize.xRegular};
        height: 50px;
        justify-content: space-between;
    }

    .hovered {
        background-color: ${theme.star.quickPick.bgColorQuinary};
        color: ${theme.star.quickPick.txtColorSenary};
    }
    .quick-pick__name--nr > * {
        opacity: 0.3;
    }
`);

export const QuickPickNameTitle = withConfig(theme => styled('li')`
    background-color: ${theme.star.quickPick.bgColor};
    border-bottom: 0;
    color: ${theme.star.quickPick.txtColor};
    line-height: 1.5;
    position: relative;
`);

export const QuickPickNamePlace = withConfig(theme => styled('span')`
    align-items: center;
    border-right: 1px solid ${theme.star.quickPick.borderColor};
    color: ${theme.star.quickPick.txtColor};
    display: flex;
    flex: 0 0 30px;
    flex: column nowrap;
    font-size: ${theme.star.fontSize.xSmall};
    justify-content: center;
    line-height: 1.1;
    padding: 8px 0;
    text-align: center;
`);

export const QuickPickNameSilk = withConfig(theme => styled('span')`
    border-right: 1px solid ${theme.star.quickPick.borderColor};
    display: flex;
    flex-flow: column nowrap;
    font-size: ${theme.star.fontSize.xSmall};
    justify-content: center;
    line-height: 1.25;
    min-width: 40px;
    padding: 8px 0;
    text-align: center;
    img {
        display: block;
        height: auto;
        margin: auto;
        max-width: 25px;
    }
`);

export const QuickPickNameRunner = withConfig(theme => styled('span')`
    border-right: 1px solid ${theme.star.quickPick.borderColor};
    color: ${theme.star.quickPick.txtColor};
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    justify-content: center;
    line-height: 1.28;
    min-width: 29px;
    overflow: hidden;
    padding: 8px 10px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
`);

export const QuickPickNameRunnerTitle = withConfig(theme => styled(QuickPickNameRunner)`
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    padding: 8px;
`);

export const LoaderWrapperQuickPick = styled(Loader)`
    justify-content: center;
`;

export const QuickPickTableWrapper = withConfig(theme => styled('div')`
    align-content: space-between;
    align-items: stretch;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    font-size: ${theme.star.fontSize.xRegular};
    overflow: auto;
`);

export const QuickPickHeader = withConfig(theme => styled('div')`
    border-top-width: 0;
    border-bottom-width: 0;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.5;
    ul li:empty {
        display: none;
    }
`);
