import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';

interface FakeCheckboxWrapperPropsType {
    isSelected: boolean;
    mode: 'dark' | 'light';
}

const fakeCheckboxStyled = (theme: EmotionTheme, isSelected: boolean, mode: 'dark' | 'light'): SerializedStyles | undefined => {
    if (mode === 'light') {
        if (isSelected) {
            return css`
                background-color: ${theme.star.checkbox.light.bgColorSecondary};
                border-color: ${theme.star.checkbox.light.borderColorSecondary};
            `;
        }
        return css`
            background-color: ${theme.star.checkbox.light.bgColor};
            border-color: ${theme.star.checkbox.light.borderColor};
        `;
    }

    if (mode === 'dark') {
        if (isSelected) {
            return css`
                background-color: ${theme.star.checkbox.dark.bgColorSecondary};
                border-color: ${theme.star.checkbox.dark.borderColorSecondary};
            `;
        }
        return css`
            background-color: ${theme.star.checkbox.dark.bgColor};
            border-color: ${theme.star.checkbox.dark.borderColor};
        `;
    }
};

export const FakeCheckboxWrapper = withConfig(theme => styled('div')<FakeCheckboxWrapperPropsType>`
    ${({ isSelected, mode }): SerializedStyles | undefined => fakeCheckboxStyled(theme, isSelected, mode)}
    border-radius: 2px;
    border-style: solid;
    border-width: 2px;
    height: 20px;
    margin-right: 5px;
    width: 20px;
`);

export const FakeCheckboxCheckIcon = withConfig(theme => styled(CheckSingleIcon)`
    fill: ${theme.star.checkbox.bgColor};
    width: 12px;
`);
