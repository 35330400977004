import styled from '@emotion/styled';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { TrashIcon } from 'src/domains/layouts/shared/assets/icons/TrashIcon';
import { withConfig } from 'src/withConfig';

export const TabsContainer = styled('div')`
    margin-bottom: 0;
`;

export const BetSlipHeaderWrapper = withConfig(theme => styled('header')`
    align-items: center;
    color: ${theme.star.betslipGeneral.txtColor};
    display: flex;
    height: 48px;
    justify-content: space-between;
    margin: 0 -8px;
    padding: 0 8px;
    width: calc(100% + 16px);
`);

export const BetSlipHeader = withConfig(theme => styled('h2')`
    align-items: center;
    display: flex;
    font-size: ${theme.star.fontSize.xMedium};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4;
    margin: 0;
    text-transform: uppercase;
`);

export const BetsCounter = withConfig(theme => styled('span')`
    background: ${theme.star.counter.bets.bgColor};
    border-radius: 50%;
    color: ${theme.star.counter.bets.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    height: 20px;
    justify-content: center;
    letter-spacing: -0.5px;
    line-height: 1;
    margin-left: 4px;
    padding-top: 4px;
    position: relative;
    text-align: center;
    top: -1px;
    width: 20px;
`);

export const CloseIconBetSlip = styled(CloseIcon)`
    cursor: pointer;
    fill: currentcolor;
    height: 48px;
    padding: 14px;
    width: 48px;
`;

export const RemoveAllWrapper = withConfig(theme => styled('button')`
    align-self: flex-end;
    background-color: transparent;
    border: none;
    color: ${theme.star.betslipGeneral.txtColorQuaternary};
    cursor: pointer;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3333;
    padding: 0;
    text-align: right;
    white-space: nowrap;
    @media ${theme.star.mediaQuery.desktop} {
        align-self: center;
    }
`);

export const RemoveAllTrashIcon = styled(TrashIcon)`
    fill: currentcolor;
    margin-left: 4px;
    position: relative;
    top: 2px;
    width: 12px;
`;

export const BettingTabContainer = withConfig(theme => styled('div')`
    background-color: ${theme.star.betslipGeneral.bgColor};
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: 0 8px;
    @media ${theme.star.mediaQuery.desktop} {
        min-height: initial;
    }
`);
