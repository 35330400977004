import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface PanelContainerPropsType {
    isAzMobile?: boolean;
}

export const PanelContainer = withConfig(theme => styled('section')<PanelContainerPropsType>`
    padding: ${({ isAzMobile }): string => isAzMobile === true ? '0 16px 20px 16px' : '0'};
    @media ${theme.star.mediaQuery.largeDesktop} {
        margin: 16px 0 0;
        padding: 0;
    }
`);

export const HeaderStyle = withConfig(theme => styled('header')`
    color: ${theme.star.lhsSidebar.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4286;
    margin: 8px 0 7px;
    @media ${theme.star.mediaQuery.largeDesktop} {
        margin: 0 0 8px;
        color: ${theme.star.lhsSidebar.txtColorSecondary};
    }
`);

export const HeaderSpan = withConfig(theme => styled('span')`
    border-bottom: 1px solid transparent;
    border-image: ${theme.star.lhsSidebar.borderColor} 1;
    display: inline-block;
    line-height: 1.43;
    padding-bottom: 4px;
`);
