import styled from '@emotion/styled';
import { SortIcon } from 'src/domains/layouts/shared/assets/icons/brand/SortIcon';
import { withConfig } from 'src/withConfig';

export const StyledContainer = styled('div')`
    position: relative;
    height: 32px;
`;

export const SortIconWrapper = withConfig(theme => styled(SortIcon)`
    width: 32px;
    height: 32px;
    padding: 6px;
    @media ${theme.star.mediaQuery.desktop} {
        padding: 0;
    }
`);

export const StyledSelectedContainer = withConfig(theme => styled('div')<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 12px 9px;
    background: ${({ selected }): string => selected ? theme.star.raceDetailsDropdownColors.bgColorSecondary : theme.star.raceDetailsDropdownColors.bgColorPrimary};
    cursor: pointer;

    &:hover {
        background: ${theme.star.raceDetailsDropdownColors.bgColorSecondary};
    }
`);

export const StyledDropdownMenu = withConfig(theme => styled('div')`
    position: absolute;
    z-index: ${theme.star.zIndexGlobal.above};

    list-style-type: none;
    right: 0;
    padding: 0;

    width: 120px;
`);

export const StyledDropdownMenuItem = withConfig(theme => styled('div')`
    width: 100%;
    height: 100%;
    text-align: left;

    color: ${theme.star.raceDetailsDropdownColors.txtColor};
    font-weight: ${theme.star.fontWeight.regular};
    font-size: ${theme.star.fontSize.xSmall};
`);
