import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import { HeartFilledIcon } from 'src/domains/layouts/shared/assets/icons/HeartFilledIcon';
import { HeartOutlinedIcon } from 'src/domains/layouts/shared/assets/icons/HeartOutlineIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { LazyLoadingImg } from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg';
import { withConfig } from 'src/withConfig';

export const GameInfoWrapper = withConfig(theme => styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${theme.star.casino.gameInfo.bgColorQuaternary};
`);

export const GameImageWrapper = styled('div')`
    position: relative;
    overflow: hidden;
    height: 340px;
    width: 100%;
`;

export const GameImgWrapperLazy = withConfig(theme => styled(LazyLoadingImg)`
    height: 100%;
    width: 100%;
    &::before {
        background: ${theme.star.casino.gameInfo.bgColorSecondary};
    }
`);

export const FavouriteBar = withConfig(theme => styled('div')`
    padding: 12px 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    color: ${theme.star.casino.txtColor};
`);

export const FavouriteBarOverlay = withConfig(theme => styled('div')`
    ${positionElementFull}
    position: absolute;
    opacity: 0.8;
    background-color: ${theme.star.casino.bgColorQuaternary};
`);

const favouritiesBarIcon = (theme: EmotionTheme): SerializedStyles => css`
    fill: ${theme.star.casino.txtColor};
    width: 22px;
    margin-right: 12px;
`;

export const HeartFilledIconWrapper = withConfig(theme => styled(HeartFilledIcon)`
    ${favouritiesBarIcon(theme)};
`);

export const HeartOutlinedIconWrapper = withConfig(theme => styled(HeartOutlinedIcon)`
    ${favouritiesBarIcon(theme)};
`);

export const CheckIconWrapper = withConfig(theme => styled(CheckSingleIcon)`
    ${favouritiesBarIcon(theme)};
    fill: ${theme.star.casino.gameInfo.txtColorTertiary};
`);

export const LikeBtn = styled('div')`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const LikeMessage = withConfig(theme => styled('div')`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.star.casino.gameInfo.txtColorTertiary};
    animation: fade-in .5s;
    @keyframes fade-in {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`);

export const GameInfoBtnWrapper = styled('div')`
    display: flex;
    flex-flow: row nowrap;
`;

const GameInfoBtn = (): SerializedStyles => css`
    flex: 1 1 50%;
    line-height: 1.5;
    padding: 12px 0;
`;

export const PlayBtn = withConfig(theme => styled(Button)`
    ${GameInfoBtn}
    color: ${theme.star.casino.gameInfo.txtColorQuaternary};
`);

export const PlayDemoBtn = withConfig(theme => styled(Button)`
    ${GameInfoBtn}
    color: ${theme.star.casino.gameInfo.txtColor};
    background-color: ${theme.star.casino.gameInfo.bgColor};
    &:hover {
        opacity: .8;
    }
`);

export const GameInfoDescription = withConfig(theme => styled('p')`
    color: ${theme.star.casino.gameInfo.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    margin: 16px 16px 0 16px;
    padding: 0 0 16px 0;
    border-bottom: 1px dashed ${theme.star.casino.gameInfo.borderColor};
`);

export const GameInfoStatisticsWrapper = styled('div')`
    display: flex;
    flex-flow: row wrap;
    padding: 16px;
`;
