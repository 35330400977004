import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const PopupWrapper = styled('div')`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
`;

export const PopupElement = withConfig(theme => styled('div')`
    background-color: ${theme.star.popup.bgColor};
    color: ${theme.star.popup.txtColor};
    flex: 0 0 288px;
    padding: 24px 16px;
    text-align: center;
    z-index: ${theme.star.zIndexPopups.deleteCardPopup};
`);

export const PopupHeader = withConfig(theme => styled('h2')`
    font-size: ${theme.star.fontSize.regular} !important;
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0;
    padding: 0 0 8px 0 !important;
    text-transform: uppercase;
`);

export const PopupParagraph = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.small};
    letter-spacing: -0.1px;
    line-height: 1.3334;
    margin: 0 0 20px 0;
`);

export const GotItButtonWrapper = withConfig(theme => styled(Button)`
    font-size: ${theme.star.fontSize.xRegular};
    margin: 4px 0 0 0 !important;
    padding: 14px 32px 12px;
    width: 100%;
`);

export const CancelButton = withConfig(theme => styled('button')`
    background-color: ${theme.star.popup.bgColorQuaternary};
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 4px 0 0 0 !important;
    padding: 10px 0;
    text-decoration: none;
    width: 100%;
`);
