import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { withConfig } from 'src/withConfig';

export const DepositLimitPopupBtn = styled(Button)`
    width: 100%;
    margin-bottom: 5px;
`;

export const DepositLimitPopupBtnWrapper = styled('div')`
    margin-top: 20px;
    text-align: center;
`;

export const DepositLimitPopupPreviousSpan = withConfig(theme => styled('span')`
    color: ${theme.star.statuses.success.bgColor};
    font-size: ${theme.star.fontSize.small};
`);

interface DepositLimitPopupInputType {
    isNewLimit: boolean;
}

export const DepositLimitPopupInput = withConfig(theme => styled(Input)<DepositLimitPopupInputType>`
    margin-bottom: 0;
    svg {
        fill: ${({ isNewLimit }):string => isNewLimit ? theme.star.form.dark.txtColor : theme.star.form.bgColorTertiary};
    }
    input {
        background-color: ${({ isNewLimit }):string => isNewLimit ? theme.star.statuses.success.bgColor : theme.star.form.bgColorSecondary};
        color: ${({ isNewLimit }):string=> isNewLimit ? theme.star.form.dark.txtColor : theme.star.form.txtColor};
        &:disabled{
            opacity: 1;
            &::placeholder{
                color: ${({ isNewLimit }):string=> isNewLimit ? theme.star.form.dark.txtColor : theme.star.form.txtColor};
            }
        }
    }
`);

export const DepositLimitPopupHeader = withConfig(theme => styled('h4')`
    font-size: ${theme.star.fontSize.xRegular};
    text-align: center;
    margin: 0;
`);

export const DepositLimitPopupDescription = withConfig(theme => styled('p')`
    text-align: center;
    margin: 0;
    font-size: ${theme.star.fontSize.small};
`);
