import { FreeBetIcon } from 'src/domains/layouts/shared/assets/icons/FreeBetIcon';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { AccountContent } from 'src/domains/players/webview/components/Account/accountParts/Account.style';


export const FreeBetIconWrapper = styled(FreeBetIcon)`
    width: 12px;
    fill: currentcolor;
    margin: 0 5px 0 0;
`;

export const StaticPageWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
export const ButtonWrapper = styled(Button)`
    margin: 16px 0 0;
    width: 100%;
`;

export const StaticPageInnerWrapper = withConfig(theme => styled(AccountContent)`
    padding: 10px 16px 16px;
    overflow-x: hidden;
    line-height: 1.6;
    height: 100%;

    h6,
    h5,
    h4,
    h3,
    h2,
    h1 {
        margin: 0 0 5px 0;
        line-height: 1.3;
        padding: 0;
        color: ${theme.star.staticPage.txtColor};
        font-weight: 500;
    }

    p {
        margin-top: 0;
        line-height: 1.6;
    }
    ul {
        padding: 5px 0;
    }
    ul > li {
        padding-left: 20px;
        position: relative;
        margin-bottom: 10px;
    }
    ul > li:before {
        content: "";
        position: absolute;
        border-radius: 50px;
        border: 1px solid ${theme.star.staticPage.borderColor};
        width: 10px;
        height: 10px;
        left: 0;
        top: 5px;
    }
    a {
        color: ${theme.star.staticPage.linkColor};
    }
`);

export const StaticPageInfoWrapper = withConfig(theme => styled('div')`
    position: relative;
    padding: 10px 5px 15px;
    text-align: center;
    background: ${theme.star.staticPage.bgColor};
    font-size: ${theme.star.fontSize.regular};
`);

export const StaticPageLoaderWrapper = styled(Loader)`
    color: currentcolor;
    justify-content: center;
    margin-top: 50px;
`;

export const StaticFreeBetLoaderWrapper = styled(Loader)`
    color: currentcolor;
    display: inline-block;
`;

export const StaticAccountSummaryWrapper = styled('dl')`
    margin: 20px 0 10px 0;
    overflow: hidden;
`;

export const AccountSummaryDefinitionTag = styled('dt')`
    clear: left;
    width: 60%;
    float: left;
    line-height: 1.2;
    margin: 0;
    padding: 0 0 10px 0;
    a {
        color: currentcolor;
    }
`;

export const AccountSummaryDefinitionDescribe = withConfig(theme => styled('dd')`
    float: left;
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1.2;
    margin: 0;
    padding: 0 0 10px 0;
    text-align: right;
    width: 40%;
`);
