import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')<{isSearchBarDesktopOpened?: boolean}>`
    height: auto;
`;

interface SendIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const SendIcon = observer('SendIcon', ({ className, onClick }: SendIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='m14.963 22-.068-.002a1.11 1.11 0 0 1-1.021-.888l-1.709-8.407a1.11 1.11 0 0 0-.868-.868l-8.407-1.71a1.109 1.109 0 0 1-.13-2.141l17.777-5.926a1.112 1.112 0 0 1 1.405 1.404L16.016 21.24c-.151.457-.578.76-1.053.76Z'
                fill='fill'
                fillRule='evenodd'
            />
        </SvgElement>
    );
});
