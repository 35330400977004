import * as React from 'react';
import { EventRowTitle } from 'src/domains/sportsbook/webview/components/events/row/Participant.style';
import {
    EventRowItem,
    EventRowItemLogo,
    EventRowParticipantName,
} from 'src/domains/sportsbook/webview/components/eventsList/eventRow6Pack/Participant6Pack.style';
import { EventRow6PackState } from 'src/domains/sportsbook/webview/components/eventsList/eventRow6Pack/EventRow6Pack.state';
import { observer } from 'src/utils/mobx-react';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';

interface Participant6PackPropsType {
    state: EventRow6PackState;
    smallEvent: EventListGroupEventItemType;
}

export const Participant6Pack = observer('Participant6Pack', (props: Participant6PackPropsType) => {
    const { smallEvent, state } = props;
    const eventModel = smallEvent.id2.getEventModel();

    if (eventModel === null) {
        return <EventRowTitle>{smallEvent.name}</EventRowTitle>;
    }

    if (eventModel.homeParticipant === null && eventModel.awayParticipant === null) {
        return <EventRowTitle>{smallEvent.name}</EventRowTitle>;
    }

    return (
        <>
            <EventRowItem>
                <EventRowItemLogo
                    decoding='async'
                    src={state.getParticipantLogo(eventModel.homeParticipant)}
                    alt={eventModel.homeParticipant ?? ''}
                />
                <EventRowParticipantName isHomeParticipant={true}>
                    {eventModel.homeParticipant}
                </EventRowParticipantName>
            </EventRowItem>



            <EventRowItem>
                <EventRowItemLogo
                    decoding='async'
                    src={state.getParticipantLogo(eventModel.awayParticipant)}
                    alt={eventModel.awayParticipant ?? ''}
                />
                <EventRowParticipantName isHomeParticipant={false}>
                    {eventModel.awayParticipant}
                </EventRowParticipantName>
            </EventRowItem>
        </>
    );
});
