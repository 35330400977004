import { computed, observable, action, makeObservable } from 'mobx';
import {
    LegType,
    CombinationsType,
    decodeReferralType,
    ReferralTypeModel,
    ReferralCombinationType,
    ReferralLegType,
} from './BetSlipTypes';
import { postRejectOffer } from 'src/domains/sportsbook/betting/betting/postRejectOffer';
import { postAcceptOffer } from 'src/domains/sportsbook/betting/betting/postAcceptOffer';
import { mapOfferBets } from 'src/domains/sportsbook/betting/betting/parse';
import { CombinationsRequestType } from 'src/domains/sportsbook/betting/betting/getPossibleBetsTypes';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import {
    ParsedReferredBetsType,
    ReferralParsedLegsType,
    decodeResponseGetReferredBets,
} from 'src/domains/sportsbook/betting/betting/getReferredTypes';
import { SuccessPlaceBetResponseType } from 'src/domains/sportsbook/betting/betting/postPlaceBetTypes';
import { ReferralModelType } from 'src/domains/sportsbook/betting/betting/getReferredBetSlipTypes';
import { ForTotalStakeTypes, BetSlipUserDataType } from './BetSlipSheredTypes';
import { PossibleBetsRequestState } from './possibleBetsState/PossibleBetsState';
// TODO: there is SBK-2264 ticket in backlog created to fix it - use session.call mechanism
// eslint-disable-next-line no-restricted-imports
import { apiCommon } from 'src/api/ApiCommon';
import { ReferralBetsExtended } from 'src/api/config/betting/getReferredBetSlipDecode';
import { BasicBetSlipState } from './BasicBetSlipState';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { ChannelType } from 'src/domains/sportsbook/betting/betting/types';
import { DateTime } from 'src_common/utils/time/time';
import { BetslipSingleId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { BetslipIdsFactory } from 'src/domains/sportsbook/betting/models/BetslipIdsFactory';
import { WebsocketId } from 'src_common/common/websocket2/id/WebsocketId';
import { BetslipData } from './BetslipData';
import { iterateByLegsRefferal } from './iterateByLegsRefferal';

export interface ResponseSuccessType {
    status: 'success';
    data: ParsedReferredBetsType;
}

interface ResponseErrorType {
    status: 'error';
    data: {};
}

export type ReferralResponseType = ResponseSuccessType | ResponseErrorType | null;

//@ts-expect-error
//eslint-disable-next-line
const parseLeg = (leg) => {
    let id;

    //eslint-disable-next-line
    if (!leg.selectionId && (!leg.selection || !leg.selection.id)) {
        leg.empty = true;
        leg.selectionName = leg.selection.name;
        leg.eventName = leg.event.name;
        delete leg.selection;
        delete leg.event;
    } else {
        //eslint-disable-next-line
        id = leg.selectionId || leg.selection.id;

        //eslint-disable-next-line
        leg.eventId = leg.eventId || leg.event.id;
        //eslint-disable-next-line
        leg.marketId = leg.marketId || leg.market.id;
        //eslint-disable-next-line
        leg.selectionId = leg.selectionId || leg.selection.id;
        delete leg.event;
        delete leg.market;
        delete leg.selection;
    }

    leg.id = id;
    leg.eventId = parseInt(leg.eventId, 10);
    //eslint-disable-next-line
    leg.marketId = '' + leg.marketId;
    //eslint-disable-next-line
    leg.selectionId = '' + leg.selectionId;

    return leg;
};

//evil thing from modules/betting
//@ts-expect-error
//eslint-disable-next-line
const mapOfferBetsOld = (bets) => {
    const combinations = {};
    const legs = {};
    let channel;
    let emptyBetId = 0;

    for (const bet of bets) {
        if (bet.type === 'SGL' && bet.legs.length === 1) {
            //eslint-disable-next-line
            const leg = parseLeg(bet.legs[0]);
            let id = leg.id;

            //eslint-disable-next-line
            if (leg.empty) {
                emptyBetId++;

                //eslint-disable-next-line
                id = 'empty' + emptyBetId;

                //@ts-expect-error
                //eslint-disable-next-line
            } else if (legs[id]) {
                //eslint-disable-next-line
                id = id + Object.keys(legs).filter((x) => x.split('-')[0] === id).length;
            }

            //@ts-expect-error
            //eslint-disable-next-line
            legs[id] = leg;

            leg.index = id;
            leg.stakePerLine = bet.stakePerLine;
            leg.eachWay = bet.eachWay;
            leg.potentialReturns = bet.potentialReturns;
        } else {
            // @TODO: Think about multiple combinations of same type
            // @TODO: Think about combinations of part legs

            //@ts-expect-error
            //eslint-disable-next-line
            combinations[bet.type] = bet;

            for (let leg of bet.legs) {
                leg = parseLeg(leg);
                let id = leg.id;

                //eslint-disable-next-line
                if (leg.empty) {
                    emptyBetId++;
                    //eslint-disable-next-line
                    id = 'empty' + emptyBetId;
                    //@ts-expect-error
                    //eslint-disable-next-line
                } else if (legs[id]) {
                    continue;
                }

                //@ts-expect-error
                //eslint-disable-next-line
                legs[id] = leg;

                leg.index = id;
                leg.stakePerLine = '0';
                leg.eachWay = false;
            }

            delete bet.legs;
        }
        channel = bet.channel;
    }

    return { combinations, legs, channel };
};

export class ReferralState {
    @observable public isFromFreeBet: boolean = false; //TODO: to remove as it is temporary solution
    @observable.ref public referralDataInner: ReferralTypeModel | null;
    @observable public isloading: boolean = false;
    private getReferredBetSlipResource: Resource<ReferralResponseType> | null;

    public constructor(
        private websocketId: WebsocketId,
        private ids: BetslipIdsFactory,
        private readonly configComponents: ConfigComponents,
        private readonly possibleBetsRequestState: PossibleBetsRequestState,
        private readonly basicBetSlipState: BasicBetSlipState,
        private readonly onPlaceBetSuccess: (bets: Array<SuccessPlaceBetResponseType>) => void,
        private readonly getAccountData: () => BetSlipUserDataType,
        private readonly onCleanAll: () => void,
        private readonly onRedirectToBetslip: () => void,
        private readonly getChannel: () => ChannelType,
        public betslipData: BetslipData
    ) {
        makeObservable(this);
        this.referralDataInner = null;

        this.getReferredBetSlipResource = new Resource(async (): Promise<ReferralResponseType | null> => {
            const response = await apiCommon.getReferredBetSlip.run({});
            if (response.responseType === 'success') {
                const bets: Array<ReferralBetsExtended> = response.json.bets.map((elem) => {
                    return {
                        ...elem,
                        legs: elem.legs.map((item) => {
                            if (item.type === 'empty') {
                                return {
                                    ...item,
                                    selectionName: item.selection.name,
                                    eventName: item.event.name,
                                    eventId: undefined,
                                    selectionId: undefined,
                                    marketId: undefined,
                                };
                            } else {
                                return {
                                    ...item,
                                    selectionId: item.selection.id,
                                    eventId: item.event.id,
                                    marketId: item.market.id,
                                    eventName: undefined,
                                    selectionName: undefined,
                                };
                            }
                        }),
                    };
                });

                const parsedResponse = mapOfferBets(bets, response.json.expiresAt, response.json.type);
                const decodedResponse = decodeResponseGetReferredBets(parsedResponse);

                if (decodedResponse instanceof Error) {
                    console.error(decodedResponse);
                    return null;
                }

                if (response.json.expiresAt !== null) {
                    const isActive =
                        DateTime.from(response.json.expiresAt)?.diffInMilliseconds(DateTime.current()) ?? -1;

                    if (isActive < 0) {
                        return {
                            status: 'error',
                            data: {},
                        };
                    }
                }

                this.setReferralLegs(decodedResponse.legs);
                return {
                    status: 'success',
                    data: decodedResponse,
                };
            }

            return null;
        });
    }

    @action public onCancelOffer = (): void => {
        setTimeout(() => {
            this.onCleanAll();
        }, 3000);
    };

    private convertPriceType(leg: ReferralParsedLegsType): 'fp' | 'sp' {
        if (leg.priceType === 'fp' || leg.priceType === 'sp') {
            return leg.priceType;
        }

        console.error('PriceType was expected to be "sp" or "fp"', leg);
        return 'fp';
    }

    @action private setReferralLegs = (legs: Record<string, ReferralParsedLegsType>): void => {
        for (const [leg, index] of iterateByLegsRefferal(Object.values(legs))) {
            if (leg.eventId !== null && leg.marketId !== null) {
                const selectionId = this.websocketId
                    .getEventId(leg.eventId)
                    .getMarketId(leg.marketId)
                    .getSelectionId(leg.selectionId);

                const betId = this.ids.getSingleId(selectionId, index);

                this.betslipData.updateOrCreateLegFromBetRefferal(
                    betId,
                    this.convertPriceType(leg),
                    new Amount(leg.stakePerLine),
                    leg.eachWay,
                    leg.price ?? null
                );
            } else {
                console.error('null in setReferralLegs', leg);
            }
        }

        // for (const leg of Object.values(legs)) {
        //     if (leg.id !== null) {
        //         const legNum = typeof leg.id === 'number' ? leg.id : parseInt(leg.id, 10);

        //         if (
        //             leg.eventId !== null &&
        //             leg.marketId !== null &&
        //             leg.selectionId !== null &&
        //             leg.selectionName !== undefined &&
        //             leg.marketName !== null &&
        //             leg.betEventId !== null
        //         ) {
        //             const selectionId = this.websocketId
        //                 .getEventId(leg.eventId)
        //                 .getMarketId(leg.marketId)
        //                 .getSelectionId(leg.selectionId);

        //             const legModel: BetslipStateLegsType = {
        //                 selectionId,
        //                 // eventId: leg.eventId,
        //                 // marketId: leg.marketId,
        //                 // marketName: leg.marketName,
        //                 // selectionId: leg.selectionId,
        //                 // selectionName: leg.selectionName,
        //                 // betEventId: leg.betEventId,
        //                 // betEventName: leg.betEventName,
        //                 // id: leg.selectionId,
        //                 priceType: this.convertPriceType(leg),
        //                 // timestamp: 0,
        //                 stakePerLine: leg.stakePerLine, // new Amount(leg.stakePerLine),
        //                 // potentialReturns: null,
        //                 // related: false,
        //                 errors: [],
        //                 eachWay: leg.eachWay,
        //                 // freebetRemarks: [],
        //                 // freebetCredits: [],
        //                 // maxStake: null,
        //                 // potentialReturnsAt: '0',
        //                 // oldPrice: null,
        //                 // name: leg.eventName ?? '',
        //                 // potentialReturnsEw: '0',
        //                 // type: leg.type,
        //                 index: null,
        //                 // price: null,
        //                 selectionPrice: leg.price ?? null,
        //             };
        //             this.betslipData.setMapCorePrepareLegs(legNum, legModel).catch((error) => {
        //                 console.error('setMapCorePrepareLegs', error);
        //             });
        //         }
        //     }
        // }
    };

    @action public handleBetReferralData = (referralRawData: ReferralModelType): void => {
        const headerType = referralRawData.header.type;

        switch (headerType) {
            case 'request':
                this.referralDataInner = this.calcReferralResponse(referralRawData);
                break;

            case 'offered':
                this.referralDataInner = this.calcReferralResponse(referralRawData);
                break;

            case 'reject':
                this.onRejectByTrader(referralRawData);
                break;

            case 'accept':
                this.onPlaceBetSuccess(referralRawData.body.bets);
                break;

            default:
                break;
        }

        if (this.getReferredBetSlipResource !== null && headerType !== 'assigned') {
            this.getReferredBetSlipResource = null;
        }
        if (this.basicBetSlipState.isShowQuickBet === true) {
            this.onRedirectToBetslip();
        }
        this.updateBetSlipModels().catch((error) => {
            console.error('updateBetSlipModels', error);
        });
    };

    @action private updateBetSlipModels = async (): Promise<void> => {
        if (this.parsedLegs !== null) {
            for (const [leg, index] of iterateByLegsRefferal(Object.values(this.parsedLegs))) {
                const id = await this.betslipData.convertOldSelectionIdToNewId(leg.selectionId, index);
                this.betslipData.betReferralUpdateLeg(id, /*leg.selectionId, index,*/ leg);
            }
        }

        if (this.parsedCombinations !== null) {
            for (const combination of Object.values(this.parsedCombinations)) {
                if (combination.stakePerLine !== null) {
                    this.betslipData.betReferralUpdateCombination(combination.type, combination);
                }
            }
        }
    };

    @action public onRejectOffer = async (): Promise<void> => {
        await postRejectOffer();
        setTimeout(() => {
            this.onCleanAll();
        }, 5000);
    };

    @action public onAcceptOffer = async (): Promise<void> => {
        this.isloading = true;
        if (this.referralData !== null) {
            const response = await postAcceptOffer();

            if (response?.status === 'success') {
                this.onPlaceBetSuccess(response.data);
            }
        }
        this.isloading = false;
    };

    public getLegById = (legIdNew: BetslipSingleId): LegType | null => {
        const [legId, index] = legIdNew.getOldIds();

        for (const [leg, legIndex] of iterateByLegsRefferal(Object.values(this.parsedLegs ?? {}))) {
            if (leg.selectionId === legId && legIndex === index) {
                return leg;
            }
        }

        return null;
    };

    public getCombinationById = (combinationType: string): CombinationsType | null => {
        if (this.referralData !== null && this.parsedCombinations !== null) {
            return this.parsedCombinations[combinationType] ?? null;
        }
        return null;
    };

    private calcReferralResponse = (referralRawData: ReferralModelType): ReferralTypeModel | null => {
        const offer = referralRawData.body.referredBetslip;
        const dataInner = mapOfferBetsOld(offer.bets);
        const decodeData = decodeReferralType(dataInner);

        if (decodeData instanceof Error) {
            console.error(decodeData);
            return null;
        }

        const expiresAtDate = offer.expiresAt === null ? null : DateTime.from(offer.expiresAt);
        const referralDate = DateTime.from(referralRawData.header.when);
        if (referralDate === null) {
            return null;
        }
        const expiresAt =
            expiresAtDate === null
                ? null
                : DateTime.current().addSeconds(expiresAtDate.diffInSeconds(referralDate)).toISOString();

        return {
            combinations: decodeData.combinations,
            legs: decodeData.legs,
            expiresAt: expiresAt,
            status: referralRawData.header.type,
            user: referralRawData.header.who.type,
        };
    };

    private onRejectByTrader = (referralRawData: ReferralModelType): void => {
        this.referralDataInner = this.calcReferralResponse(referralRawData);
        setTimeout(() => {
            this.onCleanAll();
        }, 5000);
    };

    @computed public get isReferred(): boolean {
        return this.referralData !== null;
    }

    @computed private get getReferredBetSlip(): ResponseSuccessType | null {
        const accountData = this.getAccountData();

        if (
            /*accountData !== undefined &&*/ accountData.accountAuthenticated &&
            this.getReferredBetSlipResource !== null
        ) {
            const referralResponseResult = this.getReferredBetSlipResource.get();
            if (referralResponseResult.type === 'ready' && referralResponseResult.value !== null) {
                if (referralResponseResult.value.status === 'success') {
                    return referralResponseResult.value;
                }
            }
        }

        return null;
    }

    @computed private get referralDataResponse(): ParsedReferredBetsType | null {
        if (this.getReferredBetSlip !== null) {
            return this.getReferredBetSlip.data;
        }
        return null;
    }

    @computed private get referralDataModel(): ReferralTypeModel | null {
        if (this.referralDataResponse !== null) {
            const combinations: Record<string, ReferralCombinationType> = {};
            for (const combination of Object.values(this.referralDataResponse.combinations)) {
                combinations[combination.type] = {
                    type: combination.type,
                    correlationID: '',
                    stakePerLine: this.configComponents.precision.newFromAnything(combination.stakePerLine).value,
                    eachWay: combination.eachWay,
                    comment: null,
                    country: '',
                    name: '',
                    channel: this.getChannel(),
                    potentialReturns:
                        combination.potentialReturns === null
                            ? null
                            : this.configComponents.precision.newFromAnything(combination.potentialReturns).value,
                    freebet: false,
                    legs: [],
                };
            }

            const legs: Record<string, ReferralLegType> = {};
            for (const leg of Object.values(this.referralDataResponse.legs)) {
                if (leg.price !== null && leg.eventId !== null && leg.marketId !== null && leg.selectionId !== null) {
                    legs[leg.index] = {
                        type: leg.type,
                        sport: null,
                        eachWayTerms: null,
                        termsWithBet: null,
                        eventCountry: [],
                        price: leg.price,
                        priceType: leg.priceType,
                        eventId: leg.eventId,
                        marketId: leg.marketId.toString(),
                        selectionId: leg.selectionId.toString(),
                        id: leg.selectionId.toString(),
                        index: leg.index.toString(),
                        stakePerLine: leg.stakePerLine,
                        eachWay: leg.eachWay,
                        potentialReturns: null,
                    };
                }
            }

            return {
                combinations: combinations,
                legs: legs,
                expiresAt: this.referralDataResponse.expiresAt,
                status: this.referralDataResponse.type,
                user: '',
            };
        }
        return null;
    }

    @computed public get referralData(): ReferralTypeModel | null {
        if (this.referralDataInner !== null) {
            return this.referralDataInner;
        }

        if (this.referralDataModel !== null) {
            return this.referralDataModel;
        }

        return null;
    }

    @computed private get parsedLegs(): Record<number, LegType> | null {
        if (this.referralData !== null) {
            const tempLegs: Record<number, LegType> = {};
            const { legsPossibleBetsResponseMap } = this.possibleBetsRequestState;
            for (const leg of Object.values(this.referralData.legs)) {
                const selectionId = parseInt(leg.selectionId, 10);
                const index = parseInt(leg.index, 10);
                const marketId = parseInt(leg.marketId, 10);

                if (!isNaN(index) && !isNaN(selectionId)) {
                    const oldPossibleLegs = legsPossibleBetsResponseMap.get(selectionId) ?? null;
                    tempLegs[index] = {
                        eachWay: leg.eachWay,
                        errors: oldPossibleLegs === null ? [] : oldPossibleLegs.errors,
                        eventId: leg.eventId,
                        freeBets: [],
                        marketId: isNaN(marketId) === false ? marketId : null,
                        maxStake: oldPossibleLegs === null ? null : oldPossibleLegs.maxStake,
                        potentialReturns:
                            leg.potentialReturns !== null && leg.potentialReturns !== undefined
                                ? leg.potentialReturns
                                : oldPossibleLegs === null
                                  ? null
                                  : oldPossibleLegs.potentialReturns,
                        potentialReturnsAt: oldPossibleLegs === null ? null : oldPossibleLegs.potentialReturnsAt,
                        potentialReturnsEw: oldPossibleLegs === null ? null : oldPossibleLegs.potentialReturnsEw,
                        price: leg.price,
                        priceType: leg.priceType,
                        related: oldPossibleLegs === null ? null : oldPossibleLegs.related,
                        selectionId: selectionId,
                        index: index,
                        stakePerLine: leg.stakePerLine,
                        totalStake: oldPossibleLegs === null ? null : oldPossibleLegs.totalStake,
                        timestamp: oldPossibleLegs === null ? null : oldPossibleLegs.timestamp,
                        numLines: oldPossibleLegs === null ? null : oldPossibleLegs.numLines,
                        uuid: oldPossibleLegs?.uuid ?? '',
                    };
                }
            }

            return tempLegs;
        }
        return null;
    }

    @computed private get parsedCombinations(): Record<string, CombinationsType> | null {
        if (this.referralData !== null) {
            const tempCombinations: Record<string, CombinationsType> = {};
            for (const combination of Object.values(this.referralData.combinations)) {
                const oldPossibleCombination = Array.from(
                    this.possibleBetsRequestState.combinationsForViewMap.values()
                ).find((comb) => comb.type === combination.type);
                if (/*oldPossibleCombination !== null &&*/ oldPossibleCombination !== undefined) {
                    tempCombinations[combination.type] = {
                        errors: oldPossibleCombination.errors ?? null,
                        ewOffered: false,
                        eachWay: combination.eachWay,
                        freeBets: oldPossibleCombination.freeBets,
                        legs: oldPossibleCombination.legs,
                        maxStake: oldPossibleCombination.maxStake,
                        name: oldPossibleCombination.name,
                        numLines: oldPossibleCombination.numLines,
                        potentialReturns: combination.potentialReturns,
                        potentialReturnsAt: null,
                        potentialReturnsEw: oldPossibleCombination.potentialReturnsEw ?? null,
                        price: oldPossibleCombination.price,
                        stakePerLine: combination.stakePerLine,
                        totalStake: oldPossibleCombination.totalStake ?? null,
                        type: combination.type,
                    };
                }
            }

            return tempCombinations;
        }
        return null;
    }

    @computed public get legsIds2(): Array<BetslipSingleId> {
        if (this.parsedLegs === null) {
            return [];
        }

        const out: Array<BetslipSingleId> = [];

        for (const [leg, legIndex] of iterateByLegsRefferal(Object.values(this.parsedLegs))) {
            const oldEventId = leg.eventId;
            const oldMarketId = leg.marketId;
            const oldSelectionId = leg.selectionId;

            if (oldEventId === null || oldMarketId === null) {
                console.error('bet referral - expected id which is not null', leg); //TODO - maybe me can send the event to sentry?
                continue;
            }

            const selectionId = this.websocketId.getSelectionId(oldEventId, oldMarketId, oldSelectionId);
            const singleId = this.ids.getSingleId(selectionId, legIndex);

            out.push(singleId);
        }

        return out;
    }

    @computed public get legsIds(): number[] {
        if (this.parsedLegs !== null) {
            return Object.keys(this.parsedLegs).map((legId) => {
                return parseInt(legId, 10);
            });
        }
        return [];
    }

    @computed public get combinationsTypes(): string[] {
        if (this.parsedCombinations !== null) {
            return Object.keys(this.parsedCombinations);
        }
        return [];
    }

    @computed public get expiresAt(): string | null {
        return this.referralData?.expiresAt ?? null;
    }

    @computed public get status(): string | null {
        return this.referralData === null ? null : this.referralData.status;
    }

    @computed private get user(): string | null {
        if (this.referralData !== null /*&& typeof this.referralData.user === 'string'*/) {
            return this.referralData.user;
        }
        return null;
    }

    @computed public get totalStake(): Array<ForTotalStakeTypes> {
        const prepareForTotalStake: Array<ForTotalStakeTypes> = [];

        if (this.parsedCombinations !== null) {
            for (const combination of Object.values(this.parsedCombinations)) {
                //if (combination !== null) {
                prepareForTotalStake.push({
                    eachWay: combination.eachWay,
                    numLines: combination.numLines,
                    stakePerLine: combination.stakePerLine,
                    potentialReturns: combination.potentialReturns,
                    priceType: null,
                });
                //}
            }
        }
        if (this.parsedLegs !== null) {
            for (const leg of Object.values(this.parsedLegs)) {
                //if (leg !== null) {
                prepareForTotalStake.push({
                    eachWay: leg.eachWay,
                    numLines: /*leg.numLines !== undefined ? leg.numLines : null,*/ leg.numLines,
                    stakePerLine: leg.stakePerLine === undefined ? null : leg.stakePerLine,
                    potentialReturns: leg.potentialReturns,
                    priceType: leg.priceType,
                });
                //}
            }
        }
        return prepareForTotalStake;
    }

    @computed public get totalPotentialReturn(): Amount {
        let totalPotentialReturn: Amount = new Amount('0');
        if (this.parsedCombinations !== null) {
            for (const combination of Object.values(this.parsedCombinations)) {
                if (
                    combination.potentialReturns !== null &&
                    combination.stakePerLine !== null &&
                    new Amount(combination.stakePerLine).isGreaterThanZero()
                ) {
                    totalPotentialReturn = totalPotentialReturn.add(new Amount(combination.potentialReturns));
                }
            }
        }
        if (this.parsedLegs !== null) {
            for (const leg of Object.values(this.parsedLegs)) {
                if (
                    leg.potentialReturns !== null &&
                    leg.stakePerLine !== undefined &&
                    leg.stakePerLine !== null &&
                    this.configComponents.precision.newFromAnything(leg.stakePerLine).isGreaterThanZero()
                ) {
                    totalPotentialReturn = totalPotentialReturn.add(new Amount(leg.potentialReturns));
                }
            }
        }
        return totalPotentialReturn;
    }

    @computed public get coreCombinationsPossibleBetsRequest(): Record<string, CombinationsRequestType> {
        const tempObj: Record<string, CombinationsRequestType> = {};
        if (this.referralData !== null) {
            for (const combination of Object.values(this.referralData.combinations)) {
                tempObj[combination.type] = {
                    legs: [],
                    potentialReturns: combination.potentialReturns ?? '0',
                    price: null,
                    ewOffered: false,
                    name: '',
                    maxStake: null,
                    stakePerLine: combination.stakePerLine,
                    potentialReturnsEw: '0',
                    potentialReturnsAt: '0',
                    numLines: 1,
                    type: combination.type,
                    eachWay: combination.eachWay,
                    freeBets: [],
                };
            }
        }
        return tempObj;
    }

    @computed public get isReferredBetMessage(): boolean {
        return this.referralData !== null && (this.status === 'request' || this.status === 'assigned');
    }
    @computed public get isRejectedByTrader(): boolean {
        return this.referralData !== null && this.status === 'reject' && this.user === 'staff';
    }
    @computed public get isRejectedByCustomer(): boolean {
        return this.referralData !== null && this.status === 'reject' && this.user === 'customer';
    }
    @computed public get isBetSlipOffer(): boolean {
        return this.referralData !== null && (this.status === 'offered' || this.status === 'offer');
    }

    @computed public get isRejectedBet(): boolean {
        return this.referralData === null ? false : this.status === 'reject';
    }
}
