import { BoostIcon } from 'src/domains/layouts/shared/assets/icons/BoostIcon';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';

interface ABTestButtonTwoWrapper {
    isBoosted?: boolean;
}

const renderBoostedWrapper = (isBoosted: boolean | undefined, theme: EmotionTheme): SerializedStyles => {
    if (isBoosted === true) {
        return css`
            color: ${theme.star.lifeSpan.txtColorTertiary};
            font-weight: ${theme.star.fontWeight.bold};
            background-color: ${theme.star.lifeSpan.bgColorQuaternary};
            pointer-events: none;
            height: 10px;
            width: 10px;
        `;
    }

    return css`
        font-weight: ${theme.star.fontWeight.regular};
        background-color: ${theme.star.lifeSpan.bgColorQuinary};
        color: ${theme.star.lifeSpan.txtColorQuaternary};
    `;
};

export const ABTestButtonTwo = withConfig(theme => styled(Button)<ABTestButtonTwoWrapper>`
    ${({ isBoosted }): SerializedStyles => renderBoostedWrapper(isBoosted, theme)}
    align-items: center;
    color: ${theme.star.lifeSpanBoostButton.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    height: 34px;
    justify-content: center;
    line-height: 1.4285;
    width: 58px;
`);

export const ABTestButtonTwoWrapper = withConfig(theme => styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    margin-top: 16px;
    border-top: 1px solid ${theme.star.lifeSpan.borderColor};
    height: 50px;
`);

export const BoostWrapperIconABTwo = withConfig(theme => styled(BoostIcon)`
    margin-right: 8px;
    display: flex;
    align-items: center;
    fill: ${theme.star.lifeSpan.bgColorQuinary};
    margin-left: 12px;
`);

export const ApplyBoostWrapperTwo = withConfig(theme => styled('div')`
    display: flex;
    flex-direction: row;
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.xRegular};
    justify-content: flex-start;
    align-items: center;
    height: 24px;
    margin-top: 5px;
`);

export const PotentialReturnWrapperTwo = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.xSmall};
    position: relative;
    top: -5px;
`);

export const BoostAvailableWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const PotentialReturnElement = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: -8px;
`;

export const TriangleUp = withConfig(theme => styled('div')`
    position: relative;
    &::after {
        background: ${theme.star.betslipGeneral.bgColor};
        border-bottom: 1px solid ${theme.star.lifeSpan.borderColor};
        border-radius: 1px;
        border-right: 1px solid ${theme.star.lifeSpan.borderColor};
        content: '';
        display: block;
        height: 12px;
        left: 45px;
        position: absolute;
        top: 10px;
        transform: rotate(225deg);
        width: 12px;
        z-index: ${theme.star.zIndexGlobal.above};
    }
`);
