import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/utils';
import { withConfig } from 'src/withConfig';

interface BetSlipContentPropsType {
    isVisibleKeyboard: boolean;
    isMiniCasino: boolean;
    computedHeight: number;
}


const setBetSlipContent = (isVisibleKeyboard: boolean, isMiniCasino: boolean, computedHeight: number ): SerializedStyles => {
    if (isVisibleKeyboard) {
        return css`
            padding-bottom: calc(${computedHeight}px + 181px);
        `;
    }

    if (isMiniCasino) {
        return css`
            padding-bottom: 0;
        `;
    }

    return css`
        padding-bottom: 80px;
    `;
};

export const BetSlipContent = styled('div')<BetSlipContentPropsType>`
    ${({ isVisibleKeyboard, isMiniCasino, computedHeight }): SerializedStyles => setBetSlipContent(isVisibleKeyboard, isMiniCasino, computedHeight)};
`;

export const SelectionsList = withConfig(theme => styled('ul')`
    color: ${theme.star.betslipSelectionList.txtColor};
    margin: 16px 0;
`);

export const CombinationsList = styled('ul')`
    margin-top: 16px;
`;

interface BetslipButtonsAndMessageWrapper {
    computedHeight: number;
    isVisibleKeyboard: boolean;
}

export const BetslipButtonsAndMessageWrapper = withConfig(theme => styled('div')<BetslipButtonsAndMessageWrapper>`
    background: ${theme.star.betslipGeneral.bgColor};
    bottom: ${({ isVisibleKeyboard }): string => isVisibleKeyboard ? '201px' : '0'};
    height: ${({ computedHeight }): string => `${computedHeight}px`};
    left: 8px;
    position: ${({ isVisibleKeyboard }): string => isVisibleKeyboard ? 'fixed' : 'sticky'};
    right: 8px;
    z-index: 1;
    @media ${theme.star.mediaQuery.largeDesktop} {
        height: initial;
    }
`);
