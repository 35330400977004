import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Iframe } from 'src/domains/layouts/webview/components/Iframe';
import { withConfig } from 'src/withConfig';


export const GameContentWrapper = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const IframeContainer = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
`;
    
interface IframeType {
    isFullscreen: boolean;
    isMini: boolean;
}

export const GameIframe = withConfig(theme => styled(Iframe)<IframeType>`
    width: 100%;
    height: 100%;
    border: 0;
    
    @media ${theme.star.mediaQuery.desktop} {
        ${({ isMini, isFullscreen }): SerializedStyles | false => isMini && isFullscreen && css`
            max-width: 832px;
            max-height: 468px;
            position: absolute;
            left: 50%;
            top: 50%;
            overflow: hidden;
            transform: translate(-50%, -50%);
        `}
    }
`);
