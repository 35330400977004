import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { ChatWritingArea } from 'src/domains/players/webview/components/Account/traderChat/components/ChatInput.style';
import { observer } from 'src/utils/mobx-react';

export const AutoHeightInput = observer('AutoHeightInput', () => {
    const { appLayoutsState, appPlayersState } = useAppStateContext();
    const { languagesState } = appLayoutsState;
    const { traderChatState } = appPlayersState;
    const { setRef, changeMessage, sendMessageOnEnter } = traderChatState;

    return (
        <ChatWritingArea
            contentEditable={true}
            data-placeholder={languagesState.getTranslation('account.trader-chat.input.placeholder', 'Type your message here...')}
            onBlur={changeMessage}
            onInput={changeMessage}
            onKeyDown={sendMessageOnEnter}
            ref={setRef}
            data-test='message-input'
        />
    );
});
