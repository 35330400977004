import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { slideAnimationDuration } from 'src/domains/layouts/state/AccountDrawerNew.state';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

interface RhsMenuWrapperType {
    isVisible: boolean;
    topOffset: number;

}

export const RhsMenuWrapper = withConfig(theme => styled('aside')<RhsMenuWrapperType>`
    bottom: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.above};
    ${({ isVisible }): string => isVisible ? '' : 'pointer-events: none' };
    @media ${theme.star.mediaQuery.xMobile} {
        width: 375px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        right: 280px;
        top: ${({ topOffset }): string => `${topOffset}px`};
    }
`);

const sliderMoves = (tabForView: 'left' | 'right' | null): SerializedStyles => {
    if (tabForView === 'left') {
        return css`
            transform: translate3d(0, 0, 0);
        `;
    }

    if (tabForView === 'right') {
        return css`
            transform: translate3d(-50%, 0, 0);
        `;
    }

    return css`
        transform: translate3d(50%, 0, 0);
    `;
};

interface RhsMenuSliderType {
    tabForView: 'left' | 'right' | null;
}

export const RhsMenuSlider = withConfig(theme => styled('div')<RhsMenuSliderType>`
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    width: 200%;
    ${({ tabForView }): SerializedStyles => sliderMoves(tabForView)}
    transition: transform ${slideAnimationDuration}ms ease;
    @media ${theme.star.mediaQuery.xMobile} {
        width: 750px;
    }
`);

export const RhsMenuSlide = withConfig(theme => styled('div')`
    background: ${theme.star.accountDrawer.bgColor};
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 50%;
    width: 50%;
    &:empty {
        background: transparent;
    }
`);


interface RhsMenuOverlayPropsType {
    isVisible: boolean;
}

export const RhsMenuOverlay = withConfig(theme => styled(Link)<RhsMenuOverlayPropsType>`
    background-color: ${theme.star.sidebar.bgColorSecondary};
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: ${({ isVisible }): string => isVisible ? '1' : '0'};
    position: fixed;
    right: 0;
    top: 0;
    transition: .2s ease opacity, .2s ease visibility;
    visibility: ${({ isVisible }): string => isVisible ? 'visible' : 'hidden'};
`);

