import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const Wrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.heroEvents.bgColorSenary};
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 0%;
`);
