import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface TitleTypes {
    isActive: boolean;
}

export const TitleChooseBoxWrapper = withConfig(theme => styled('div')`
    align-items: center;
    background-color: ${theme.star.form.dark.bgColor};
    display: flex;
    flex: 1 0 90px;
    height: 40px;
    justify-content: space-between;
    padding: 10px 12px;
    cursor: pointer;
    &:not(:last-of-type) {
        margin-right: 6px;
    }
`);

export const TitleChooseBoxTitle = withConfig(theme => styled('span')<TitleTypes>`
    font-size: ${theme.star.fontSize.xRegular};
    color: ${({ isActive }): string =>
        isActive ? theme.star.form.txtColor : theme.star.form.txtColorSecondary};
    text-transform: capitalize;
`);
