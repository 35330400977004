import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface MenuAndSearchIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const MenuAndSearchIcon = observer(
    'MenuAndSearchIcon',
    ({ className, onClick }: MenuAndSearchIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#fff'
                fillRule='evenodd'
                height='25'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 25'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M0 5.614h16v2.5H0v-2.5zm0 7.75v-2.5h12v2.5H0zm0 5.25v-2.5h12v2.5H0z'
                    fill='fill'
                />
                <path
                    d='M21.339 16.069a4.034 4.034 0 00.786-2.392 4.068 4.068 0 00-4.063-4.063A4.067 4.067 0 0014 13.677a4.066 4.066 0 004.062 4.062c.895 0 1.721-.294 2.393-.786l2.661 2.661.884-.884-2.662-2.661zm-3.276.42a2.815 2.815 0 01-2.812-2.812 2.816 2.816 0 012.812-2.813 2.816 2.816 0 012.813 2.813 2.815 2.815 0 01-2.813 2.812z'
                    fill='fill'
                    fillRule='nonzero'
                />
            </SvgElement>
        );
    }
);
