import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

interface PropsType {
    className?: string
}

const SvgElement = styled('svg')`
    height: auto;
`;

// Temp icon - Please remove after betslip visual refactor.
export const StopWatchIcon = observer('StopWatchIcon', ({ className }: PropsType) => (
    <SvgElement
        className={className}
        fill='#fff'
        height='32px'
        viewBox='0 0 8 9'
        width='32px'
        xmlns='http://www.w3.org/2000/svg'
        fillRule='evenodd'
        clipRule='evenodd'
        strokeLinecap='round'
        strokeMiterlimit='1.5'
    >
        <path d='M2.88 0h2.16v.72H2.88z'/>
        <path d='M3.6.715h.72v.48H3.6z'/>
        <circle
            cx='3.886'
            cy='4.846'
            r='2.926'
            stroke='#fff'
            strokeWidth='1.6'
            fill='none'
        />
        <path d='M3.293 4.388l.733-.429.877-.497c.038-.024 0 0 0 0L4.215 5.27c-.191.373-.761.411-1.039.131-.246-.313-.212-.797.117-1.013z'/>
    </SvgElement>
));
