import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { ExpandIcon } from 'src/domains/layouts/shared/assets/icons/ExpandIcon';
import { withConfig } from 'src/withConfig';

export const GameViewDesktopContainer = withConfig(theme => styled('div')`
    background-color: ${theme.star.overlay.bgColorSecondary};
    margin: 0 10px 8px 10px;
`);

export const GameViewMobileContainer = withConfig(theme => styled('div')`
    background-color: ${theme.star.casino.casinoMini.bgColor};
    left: 0;
    margin: 0 0 0 auto;
    position: fixed;
    right: 0;
    top: calc(100% - 43px);
    width: 100%;
    z-index: ${theme.star.zIndexCasino.miniGamesGameContainer};
    animation: slidePreviewIn .5s 1 forwards;

    @media ${theme.star.mediaQuery.xxMobile} {
        max-width: 375px;
    }

    @keyframes slidePreviewIn {
        from { transform: translateY(0); }
        to { transform: translateY(-290px); }
    }
`);

export const PreviewHeader = withConfig(theme => styled('div')`
    align-items: center;
    background-color: ${theme.star.casino.bgColor};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: space-between;
    padding: 8px;
    position: relative;
    width: 100%;
    @media ${theme.star.mediaQuery.desktop} {
        border-radius: 4px 4px 0 0;
        width: auto;
    }
`);

export const GamePreviewTitle = withConfig(theme => styled('span')`
    color: ${theme.star.casino.txtColor};
`);

const iconStyled = css`
    fill: currentcolor;
    cursor: pointer;
    position: absolute;
    width: 14px;
`;

export const ClosePreviewIcon = withConfig(theme => styled(CloseIcon)`
    ${iconStyled};
    color: ${theme.star.casino.txtColor};
    right: 8px;
`);

export const ExpandIconWrapper = withConfig(theme => styled(ExpandIcon)`
    ${iconStyled};
    color: ${theme.star.casino.txtColor};
    right: 36px;
`);

export const LaunchMiniGameBody = styled('div')`
    margin: 0 auto;
    width: 260px;
    height: 158px;
`;

export const LaunchMiniGameBodyMobile = styled('div')`
    height: 214px;
    margin: auto;
    text-align: center;
    width: 100%;
`;

export const LaunchMiniGameBodyIframe = styled('iframe')`
    border: none;
    height: 100%;
    width: 100%;
`;

