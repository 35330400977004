import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';

interface LifeSpanBoostButtonWrapper {
    isBoosted?: boolean;
}

const renderBoostedWrapper = (isBoosted: boolean | undefined, theme: EmotionTheme): SerializedStyles => {
    if (isBoosted === true) {
        return css`
            color: ${theme.star.lifeSpan.txtColorTertiary};
            font-weight: ${theme.star.fontWeight.bold};
            background-color: ${theme.star.lifeSpan.bgColorQuaternary};
            pointer-events: none;
        `;
    }

    return css`
        font-weight: ${theme.star.fontWeight.regular};
        background-color: ${theme.star.lifeSpan.bgColorQuinary};
        color: ${theme.star.lifeSpan.txtColorQuaternary};
    `;
};

export const LifeSpanBoostButtonWrapper = withConfig(theme => styled(Button)<LifeSpanBoostButtonWrapper>`
    ${({ isBoosted }): SerializedStyles => renderBoostedWrapper(isBoosted, theme)}
    align-items: center;
    display: flex;
    font-size: ${theme.star.fontSize.xRegular};
    height: 48px;
    justify-content: center;
    line-height: 1.4285;
    margin-top: 8px;
    width: 100%;
    color: ${theme.star.lifeSpanBoostButton.txtColor};
`);
