import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const BonusItemsWrapper = styled.div``;

export const BonusItem = styled.div`
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #fff;
`;

export const BonusItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;
`;

export const BonusItemHeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
`;

export const BonusItemHeading = withConfig(
    (theme) => styled.h3`
        font-weight: ${theme.star.fontWeight.bold};
        font-size: ${theme.star.fontSize.xMedium};
        margin: 0;
    `
);

export const Label = withConfig(
    (theme) => styled.span`
        font-size: ${theme.star.fontSize.small};
        margin: 0;
    `
);

export const BonusItemBtn = styled(Button)`
    width: 100%;
`;

export const BonusItemContent = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px 5px;
    margin-bottom: 15px;
`;

export const BonusItemWidget = withConfig(
    (theme) => styled.span`
        background-color: rgba(28, 27, 31, 0.1);
        width: fit-content;
        border-radius: 18px;
        padding: 0px 5px;
        font-size: ${theme.star.fontSize.xSmall};
    `
);
