import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';
interface RadioWrapperType {
    disabled: boolean;
}

const styleDisabledRadio = (disabled?: boolean): string => {
    if (disabled === true) {
        return `
            cursor: not-allowed;
            opacity: .48;
            user-select: none;
        `;
    }
    return '';
};

export const RadioButtonWrapper = styled('div')<RadioWrapperType>`
    ${({ disabled }): string => styleDisabledRadio(disabled)};
`;

const setRadioBtnSize = (size: 'large' | 'small'): SerializedStyles => {
    if (size === 'small') {
        return css`
            height: 16px;
            margin-left: 30px;
            margin-right: 0;
            width: 16px;
        `;
    }
    return css`
        height: 24px;
        margin-left: 0;
        margin-right: 8px;
        width: 24px;
    `;
};


const setRadioBtnColor = (version: 'dark' | 'light', isActive: boolean, theme: EmotionTheme): SerializedStyles => {
    if (version === 'dark') {
        if (isActive) {
            return css`
                border: 2px solid ${theme.star.radioButton.dark.borderColorSecondary};
                &::before {
                    background-color: ${theme.star.radioButton.dark.bgColor};
                }
            `;
        }
        return css`
            border: 2px solid ${theme.star.radioButton.dark.borderColor};
        `;
    }

    if (isActive) {
        return css`
            border: 2px solid ${theme.star.radioButton.light.borderColorSecondary};
            &::before {
                background-color: ${theme.star.radioButton.light.bgColor};
            }
        `;
    }
    return css`
        border: 2px solid ${theme.star.radioButton.light.borderColor};
    `;
};

interface PropsTypes {
    isActive: boolean;
    size: 'large' | 'small';
    version: 'dark' | 'light';
}

export const RadioButton = withConfig(theme => styled('label')<PropsTypes>`
    cursor: pointer;
    display: flex;
    border-radius: 50%;
    position: relative;
    ${({ size }): SerializedStyles => setRadioBtnSize(size)};
    ${({ version, isActive }): SerializedStyles => setRadioBtnColor(version, isActive, theme)};
    &::before {
        border-radius: 50%;
        bottom: 0;
        content: '';
        display: inline-block;
        height: calc(100% - 4px);
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% - 4px);
    }
`);

export const RadioButtonInput = styled('input')`
    display: none;
`;

interface LabelPropsTypes {
    size: 'small' | 'large';
}

const styleRadioLabel = (size: 'small' | 'large', theme: EmotionTheme): SerializedStyles => {
    if (size === 'small') {
        return css`
            font-size: ${theme.star.fontSize.xRegular};
            line-height: 1.43;
        `;
    }
    return css`
        font-size: ${theme.star.fontSize.small};
        line-height: 1.42;
    `;
};

export const RadioLabel = withConfig(theme => styled('span')<LabelPropsTypes>`
    ${({ size }): SerializedStyles => styleRadioLabel(size, theme)};
    color: ${theme.star.form.txtColorSecondary};
`);
