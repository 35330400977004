import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface KeyboardIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const KeyboardIcon = observer('KeyboardIcon', ({ className, onClick }: KeyboardIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            clipRule='evenodd'
            fill='#2b2b2b'
            fillRule='evenodd'
            height='16'
            onClick={onClick}
            strokeLinejoin='round'
            strokeMiterlimit='2'
            viewBox='0 0 15 16'
            width='15'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M7.488 15.012L4.5 11.989h5.977l-2.989 3.023zm5.238-11.25V2.25h-1.477v1.512h1.477zm0 2.25V4.5h-1.477v1.512h1.477zm-2.25-2.25V2.25H8.999v1.512h1.477zm0 2.25V4.5H8.999v1.512h1.477zm0 2.988V7.488H4.499V9h5.977zm-6.75-5.238V2.25H2.249v1.512h1.477zm0 2.25V4.5H2.249v1.512h1.477zM4.5 4.5v1.512h1.477V4.5H4.5zm0-2.25v1.512h1.477V2.25H4.5zM6.75 4.5v1.512h1.477V4.5H6.75zm0-2.25v1.512h1.477V2.25H6.75zM13.5 0c.398 0 .744.152 1.037.457.293.304.44.656.44 1.054v7.488c0 .399-.147.75-.44 1.055-.293.304-.639.457-1.037.457L1.477 10.51c-.399 0-.745-.152-1.038-.456A1.477 1.477 0 010 8.999V1.511C0 1.113.146.761.439.457A1.392 1.392 0 011.477 0H13.5z'
                fillRule='nonzero'
            />
        </SvgElement>
    );
});
