import styled from '@emotion/styled';
import * as React from 'react';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface LoadingSpinnerIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const HorseRacingIcon = observer(
    'HorseRacingIcon',
    ({ className, onClick }: LoadingSpinnerIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='#072d4f'
                height='24'
                onClick={onClick}
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fill='fill'
                    fillRule='evenodd'
                    d='M19.2 14.9c-.82.3-2.05-.38-3.93-2.64-.72.26-1.28.84-1.52 2.1-.46 2.38.27 3.9 1.3 6l.56 1.16H1.06l.04-.04c-1-7.02 5.73-10.47.82-8.3 2.1-5.1 8.6-6.77 4.02-6.24 2.36-2.16 5.33-3.35 9.56-2.6.9-1.3 3.05-4.1 2.4-.75l1.07-.97.06 3.39c1.28.97 1.98 4.23 2.97 6.35.85.84 1.18 1.84.9 3.4-1.31 1.71-3.4 1.29-3.7-.86Z'
                    clipRule='evenodd'
                />
            </SvgElement>
        );
    }
);
