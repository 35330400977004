import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import { EyeClosedIcon } from 'src/domains/layouts/shared/assets/icons/EyeClosedIcon';
import { EyeOpenIcon } from 'src/domains/layouts/shared/assets/icons/EyeOpenIcon';
import { XTSIcon } from 'src/domains/layouts/shared/assets/icons/XTSIcon';
import { DollarIcon } from 'src/domains/layouts/shared/assets/icons/DollarIcon';
import { GBPIcon } from 'src/domains/layouts/shared/assets/icons/GBPIcon';
import { EuroIcon } from 'src/domains/layouts/shared/assets/icons/EuroIcon';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';
import { InputTypeType } from 'src/domains/players/webview/components/form/input/Input';
import { BRLIcon } from 'src/domains/layouts/shared/assets/icons/BRLIcon';

const currencyIconStyled = (theme: EmotionTheme): SerializedStyles => css`
    bottom: 0;
    fill: ${theme.star.form.bgColorTertiary};
    margin: auto 0;
    position: absolute;
    top: 0;
`;

const currencyTextStyled = (theme: EmotionTheme): SerializedStyles => css`
    bottom: 0;
    fill: ${theme.star.form.bgColorTertiary};
    margin: auto 0;
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    font-weight: ${theme.star.fontWeight.bold};
`;

export const CurrencyDollar = withConfig(theme => styled(DollarIcon)`
    ${currencyIconStyled(theme)};
    width: 16px;
    left: 6px;
`);

export const CurrencyEuro = withConfig(theme => styled(EuroIcon)`
    ${currencyIconStyled(theme)};
    width: 14px;
    left: 8px;
`);

export const CurrencyGBP = withConfig(theme => styled(GBPIcon)`
    ${currencyIconStyled(theme)};
    width: 13px;
    left: 8px;
`);

export const CurrencyXTS = withConfig(theme => styled(XTSIcon)`
    ${currencyIconStyled(theme)};
    width: 15px;
    left: 8px;
`);

export const CurrencyBRL = withConfig(theme => styled(BRLIcon)`
    ${currencyIconStyled(theme)};
    width: 16px;
    left: 12px;
`);

export const CurrencyRON = withConfig(theme => styled('span')`
    ${currencyTextStyled(theme)};
    width: 16px;
    left: 12px;
`);

const styleTextAlignInput = (textAlign?: 'inherit' | 'left' | 'right' | 'center'): string | undefined => {
    if (textAlign === undefined) {
        return;
    };

    return `
        text-align: ${textAlign};
    `;
};

const styleBackgroundInput = (theme: EmotionTheme, hasBackground: boolean, colorTheme: 'dark' | 'light'): string | undefined => {

    if (colorTheme === 'light') {
        return `
            background-color: ${theme.star.form.light.bgColor};
        `;
    }

    if (hasBackground) {
        return;
    }

    return `
        background-color: ${theme.star.form.dark.bgColor};
    `;
};

const styleDisabledInput = (disabled?: boolean): string | undefined => {
    if (disabled === undefined) {
        return;
    }

    if (disabled) {
        return `
            cursor: not-allowed;
            opacity: .4;
            user-select: none;
        `;
    }
};


const styleReadOnlyInput = (readOnly?: boolean): string | undefined => {
    if (readOnly === undefined) {
        return;
    }

    if (readOnly) {
        return `
            cursor: text;
            user-select: none;
        `;
    }
};

const stylePasswordInput = (type: InputTypeType, theme: EmotionTheme): string | null => {
    if (type === 'password') {
        return `
            font-family: inherit;
            letter-spacing: 2px;
            padding-right: 75px;

            &[value='']{
                font-size: ${theme.star.fontSize.xRegular};
            }

            ::placeholder {
                letter-spacing: 0px;
                font-weight: ${theme.star.fontWeight.regular};
                font-size: ${theme.star.fontSize.xRegular};
            }
        `;
    }

    return null;
};

export const InputWrapper = styled('div')`
    position: relative;
    margin: 16px 0 0 0;
`;

interface LabelTextWrapperType {
    isEmpty: boolean;
    isFocus: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    colorTheme: 'dark' | 'light';
}

export const LabelTextWrapper = withConfig(theme => styled('span')<LabelTextWrapperType>`
    display: block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    color: ${({ colorTheme }): string => colorTheme === 'light' ? theme.star.form.light.txtColor : theme.star.form.dark.txtColor};
    ${({ readOnly }): string | undefined => styleReadOnlyInput(readOnly)};
    margin-bottom: 4px;
    line-height: 1.3334;
`);

interface InputElementType {
    disabled?: boolean;
    readOnly?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
    value?: string;
    hasLabel?: boolean;
    type: InputTypeType;
    textAlign?: 'inherit' | 'left' | 'right' | 'center';
    hasBackground: boolean;
    placeholderSize?: string;
    currency: string | undefined;
    colorTheme: 'dark' | 'light';
};

const renderPadding = (isError: boolean | undefined, isSuccess: boolean | undefined, type: string | undefined, currency: string | undefined): string => {
    if (currency !== undefined && isError === true) {
        return '10px 33px 10px 20px';
    }

    if (currency !== undefined) {
        return '12px 12px 12px 20px';
    }

    if ((isError === true || isSuccess === true || type === 'password') && type !== 'dropdown') {
        return '10px 47px 10px 12px';
    }

    return '10px 12px';
};

const renderCurrencyStyle = (): string => {
    return (
        `text-align: right;
        ::placeholder {
            text-align: right;
        }`
    );
};

export const InputElement = withConfig(theme => styled('input')<InputElementType>`
    border: 1px solid ${({ isError, colorTheme }): string => {
        if (isError === true) {
            return theme.star.statuses.error.bgColor;
        }
        if (colorTheme === 'light') {
            return theme.star.form.light.borderColor;
        }
        return theme.star.form.dark.borderColor;

    }};
    border-radius: 0;
    color: ${theme.star.form.txtColor};
    line-height: 1;
    font-size: ${({ type }): string => type === 'password' ? theme.star.fontSize.xMedium : theme.star.fontSize.xRegular};
    font-weight: ${({ type }): string => type === 'password' ? theme.star.fontWeight.bold : theme.star.fontWeight.regular};
    padding: ${({ isError, isSuccess, type, currency }): string => renderPadding(isError, isSuccess, type, currency)};
    width: 100%;
    height: 40px;

    ${({ currency }): string => currency === undefined ? '' : renderCurrencyStyle()};

    &:focus {
        border: 1px solid ${({ isError }): string => isError === true ? theme.star.statuses.error.bgColor : theme.star.form.borderColor};
    }
    ${({ type }): string | null => stylePasswordInput(type, theme)}
    ${({ disabled }): string | undefined => styleDisabledInput(disabled)}
    ${({ readOnly }): string | undefined => styleReadOnlyInput(readOnly)}
    ${({ textAlign }): string | undefined => styleTextAlignInput(textAlign)}
    ${({ hasBackground, colorTheme }): string | undefined => styleBackgroundInput(theme, hasBackground, colorTheme)}

    &:-webkit-autofill {
        -webkit-text-fill-color: ${theme.star.form.txtColor};
        box-shadow: 0 0 0 100px ${({ colorTheme }): string => colorTheme === 'light' ? theme.star.form.light.borderColor : theme.star.form.dark.borderColor} inset;
    }
    &:autofill {
        -webkit-text-fill-color: ${theme.star.form.txtColor};
        filter: none;
    }
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-text-fill-color: ${theme.star.form.txtColor};
        box-shadow: 0 0 0 100px ${({ colorTheme }): string => colorTheme === 'light' ? theme.star.form.light.borderColor : theme.star.form.dark.borderColor} inset;
    }
    &:autofill:hover,
    &:autofill:focus,
    &:autofill:active {
        -webkit-text-fill-color: ${theme.star.form.txtColor};
        filter: none;
    }

    ::placeholder {
        color: ${theme.star.form.txtColorSecondary};
        ${({ placeholderSize }): string => (placeholderSize === 'small') ? `font-size: ${theme.star.fontSize.small};` : ''}
    }
    &[type='number'] {
        appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }
    }
    &[type='password'] {
        letter-spacing: 10px;
        &::-ms-reveal,
        &::-ms-clear {
            display: none;
        }
    }

    &[type='dropdown'] {
        cursor: pointer;
    }

    &[type='single-option-dropdown'] {
        cursor: default;
    }
`);

export const InnerElementsWrapper = withConfig(theme => styled('div')`
    position: relative;

    &:focus-within {
        &::before {
            border: 1px solid ${theme.star.form.borderColor};
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: ${theme.star.zIndexGlobal.below};
        }
    }
`);

export const CheckInputIconWrapper = withConfig(theme => styled(CheckSingleIcon)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    margin: auto 0;
    width: 16px;
    fill: ${theme.star.statuses.success.bgColor};
`);

interface EyePasswordIconPropsType {
    isErrorIconVisible: boolean;
    isSuccessIconVisible: boolean;
}


export const EyePasswordIconWrapper = withConfig(theme => styled(EyeClosedIcon)<EyePasswordIconPropsType>`
    bottom: 0;
    cursor: pointer;
    fill: ${theme.star.form.bgColor};
    margin: auto 0;
    position: absolute;
    right: ${({ isSuccessIconVisible, isErrorIconVisible }): string => (isSuccessIconVisible || isErrorIconVisible) ? '52px' : '16px'};
    top: 0;
    width: 20px;
`);

export const OpenEyePasswordIconWrapper = withConfig(theme => styled(EyeOpenIcon)<EyePasswordIconPropsType>`
    bottom: 0;
    cursor: pointer;
    fill: ${theme.star.form.bgColor};
    margin: auto 0;
    position: absolute;
    right: ${({ isSuccessIconVisible, isErrorIconVisible }): string => (isSuccessIconVisible || isErrorIconVisible) ? '48px' : '16px'};
    top: 0;
    width: 20px;
`);

export const InputWarningIconWrapper = withConfig(theme => styled(WarningIcon)`
    bottom: 0;
    fill: ${theme.star.statuses.error.bgColor};
    margin: auto 0;
    position: absolute;
    right: 16px;
    top: 0;
    width: 16px;
`);
