import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const CenteredVerifyHeadline = withConfig(theme => styled('h3')`
    margin: 0 0 8px 0 !important;
    font-size: ${theme.star.fontSize.regular} !important;
    font-weight: ${theme.star.fontWeight.bold} !important;
    line-height: 1.5;
`);

export const VerifyInfo = withConfig(theme => styled('p')`
    margin: 0 0 12px 0;
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.43 !important;
`);

export const VerifyInfoSuspendedCentered = styled(VerifyInfo)`
    margin: 10px auto 0;
`;

export const VerifyInfoGamStop = styled(VerifyInfo)`
    margin: 0 4px 12px;
`;

const YellowButton = styled(Button)`
    width: 100%;
    text-transform: capitalize;
`;

export const HomeButton = styled(YellowButton)`
    margin: 24px 0 0 0 !important;
`;

export const HomeButtonWrapper = styled(YellowButton)`
    margin: 56px 0 0 0;
`;
