import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')<{isSearchBarDesktopOpened?: boolean}>`
    height: auto;
`;

interface CloseIconPropsType {
    className?: string;
    onClick?: () => void;
    isSearchBarDesktopOpened?: boolean;
    onMouseDown?: () => void;
}

export const CloseIcon = observer('CloseIcon', ({ className, onClick, isSearchBarDesktopOpened, onMouseDown }: CloseIconPropsType): JSX.Element => {
    return (
        <SvgElement
            onMouseDown={onMouseDown}
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
            isSearchBarDesktopOpened={isSearchBarDesktopOpened}
            data-test='close-icon'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M22.5 3.6l-2.1-2.1L12 9.9 3.6 1.5 1.5 3.6 9.9 12l-8.4 8.4 2.1 2.1 8.4-8.4 8.4 8.4 2.1-2.1-8.4-8.4 8.4-8.4z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});

