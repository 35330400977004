import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const setArrowPosition = (position: 'up' | 'right' | 'down' | 'left'): string => {
    if (position === 'right') {
        return 'rotate(90deg)';
    }

    if (position === 'down') {
        return 'rotate(180deg)';
    }

    if (position === 'left') {
        return 'rotate(-90deg)';
    }

    return 'rotate(0)';
};

interface SvgElementPropsType {
    position: 'up' | 'right' | 'down' | 'left';
}

const SvgElement = styled('svg')<SvgElementPropsType>`
    height: auto;
    transform: ${({ position } ): string => setArrowPosition(position)};
`;

interface ArrowPropsType {
    className?: string;
    onClick?: () => void;
    position: 'up' | 'right' | 'down' | 'left';
}

export const ArrowIcon = observer('ArrowIcon', ({ className, onClick, position }: ArrowPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            position={position}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M1 12L12 1l11 11-1.994 1.93-7.655-7.655V23h-2.702V6.275L2.93 13.93 1 12z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
