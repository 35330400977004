import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface SocialInstagramIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const SocialInstagramIcon = observer('SocialInstagramIcon', ({ className, onClick }: SocialInstagramIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M22.94 7.468c-.052-1.169-.24-1.973-.512-2.669a5.364 5.364 0 00-1.272-1.951 5.407 5.407 0 00-1.947-1.268c-.7-.27-1.5-.46-2.668-.511C15.363 1.013 14.989 1 12.002 1c-2.987 0-3.36.013-4.534.064-1.169.052-1.973.241-2.669.512-.74.28-1.4.709-1.951 1.272A5.407 5.407 0 001.58 4.795c-.27.7-.46 1.5-.511 2.669C1.013 8.64 1 9.015 1 12.002s.013 3.361.064 4.534c.052 1.17.241 1.973.512 2.67.28.738.713 1.4 1.272 1.95.55.559 1.216.993 1.947 1.268.7.271 1.5.46 2.669.512 1.173.051 1.547.064 4.534.064s3.36-.013 4.534-.064c1.169-.052 1.973-.241 2.669-.512a5.629 5.629 0 003.219-3.219c.27-.7.46-1.5.511-2.669.052-1.173.065-1.547.065-4.534s-.005-3.36-.056-4.534zm-1.981 8.982c-.048 1.075-.228 1.655-.378 2.042a3.646 3.646 0 01-2.09 2.088c-.386.15-.97.331-2.04.379-1.161.051-1.51.064-4.444.064-2.936 0-3.288-.013-4.444-.064-1.075-.048-1.655-.228-2.042-.378a3.4 3.4 0 01-1.263-.821 3.412 3.412 0 01-.821-1.264c-.15-.387-.331-.971-.378-2.041-.052-1.16-.065-1.509-.065-4.444 0-2.936.013-3.288.065-4.444.047-1.074.227-1.655.378-2.041.176-.478.455-.912.825-1.264a3.402 3.402 0 011.264-.82c.386-.151.97-.332 2.041-.38 1.16-.05 1.509-.064 4.444-.064 2.94 0 3.288.013 4.444.065 1.074.047 1.654.228 2.041.378.477.176.911.456 1.264.82.365.358.644.787.82 1.265.15.386.331.97.379 2.041.051 1.16.064 1.508.064 4.444 0 2.935-.013 3.279-.064 4.44z'
            />
            <path
                d='M12.002 6.35a5.653 5.653 0 000 11.304c3.12 0 5.652-2.532 5.652-5.652s-2.532-5.651-5.652-5.651zm0 9.318a3.666 3.666 0 110-7.332 3.666 3.666 0 010 7.332zm7.195-9.541a1.32 1.32 0 11-2.64 0 1.32 1.32 0 012.64 0z'
            />
        </SvgElement>
    );
});
