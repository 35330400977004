import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface HelpIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const HelpIcon = observer(
    'HelpIcon',
    ({ className, onClick }: HelpIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11zm-1.467 0A9.533 9.533 0 0112 21.533 9.533 9.533 0 012.467 12 9.533 9.533 0 0112 2.467 9.533 9.533 0 0121.533 12zM9.351 7.134c.667-.667 1.586-1 2.756-1 1.105 0 1.974.25 2.609.754.634.503.951 1.209.951 2.117 0 .58-.13 1.06-.393 1.443s-.646.793-1.149 1.231c-.437.394-.76.736-.968 1.025-.208.29-.312.638-.312 1.042h-2.182c0-.437.063-.82.189-1.148.126-.328.279-.6.46-.812.18-.214.412-.457.697-.73.317-.296.552-.556.705-.78.153-.224.23-.49.23-.796 0-.667-.372-1-1.116-1-.82 0-1.236.486-1.247 1.46l-2.248-.017c.011-1.192.35-2.122 1.018-2.789zm3.363 8.237c.262.262.394.601.394 1.017 0 .416-.132.757-.394 1.025-.263.268-.602.402-1.017.402-.405 0-.739-.134-1.001-.402-.263-.268-.394-.61-.394-1.025 0-.416.129-.755.386-1.017.257-.263.593-.394 1.009-.394.415 0 .755.131 1.017.394z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
