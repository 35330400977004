import { EmotionTheme } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { buildBtnSize } from 'src/domains/layouts/webview/components/common/button/utils';
import { withConfig } from 'src/withConfig';

interface ButtonUniversalType {
    size?: 'large' | 'medium' | 'small' | 'xs';
    className?: string;
    isButton?: boolean;
}

const linkAsButton = (theme: EmotionTheme, size: 'large' | 'medium' | 'small' | 'xs', isButton?: boolean,): SerializedStyles => {
    if (isButton === undefined) {
        return css``;
    }

    return css`
        ${buildBtnSize(theme, size)}
        background-color: ${theme.star.buttons.primary.bgColor};
        border: 0;
        color: ${theme.star.buttons.primary.txtColor};
        cursor: pointer;
        display: inline-block;
        font-weight: ${theme.star.fontWeight.bold};
        line-height: 1;
        margin: 0;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: .2s ease opacity;
        &:active,
        &:focus {
            outline: none;
        }
        &::before {
            background-color: ${theme.star.buttons.primary.bgColorSecondary};
            bottom: 0;
            content: '';
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: .2s ease opacity;
            z-index: ${theme.star.zIndexGlobal.below};
        }
        &:hover {
            &::before {
                opacity: 1;
            }
        }
    `;
};

export const NewLinkWrapper = withConfig(theme => styled('a')<ButtonUniversalType>`
    ${({ size = 'xs', isButton }): SerializedStyles => linkAsButton(theme, size, isButton)};
`);
