import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { AccountContent } from 'src/domains/players/webview/components/Account/accountParts/Account.style';

export const PersonalDetailsTabWrapper = styled(AccountContent)`
    padding: 8px 16px 24px;
`;

export const UpdateButton = styled(Button)`
    width: 100%;
    margin-top: 16px;
`;

export const FormWrapper = styled('form')`
     padding-top: 8px;
`;
