import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface AccordionTypes {
    isSeparateLine?: boolean;
    isSelected?: boolean;
    isActiveDetails?: boolean;
    isActive?: boolean;
    teamColor?: string | null;
}

export const AccordionWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.heroSingleMarket.bgColor};
    padding: 8px 0;
    color: ${theme.star.heroEvents.txtColor};
`);

export const Header = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.regular};
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    padding: 0 8px;

    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.medium};
    }
`);

export const HeaderWrapper = styled('div')`
    display: flex;
    cursor: pointer;
`;

export const HeaderContent = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const EachWayStyle = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.xRegular};
`);

export const MarketName = withConfig(theme => styled('div')`
    font-weight: ${theme.star.fontWeight.bold};
`);

export const EventName = withConfig(theme => styled('div')`
    font-weight: ${theme.star.fontWeight.regular};
    font-size: ${theme.star.fontSize.small};
    margin-left: 30px;
`);

export const ParticipantColor = withConfig(theme => styled('div')<AccordionTypes>`
    height: 20px;
    width: 3px;
    margin-right: 15px;
    margin-left: 5px;
    background-color: ${({ teamColor }):string => teamColor ?? theme.star.heroEvents.bgColorSecondary};
`);

export const SilkWrapper = withConfig(theme => styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0 5px;
    width: 20px;
    @media ${theme.star.mediaQuery.tablet} {
        margin: 0 16px 0 8px;
    };
`);

export const ParticipantWrapper = withConfig(theme => styled('div')`
    display: flex;
    flex: 3 1 100%;
    align-items: center;
    background-color: ${theme.star.heroSingleMarket.bgColorQuaternary};
`);

interface SelectionGroupType {
    displayTemplate?: string;
    isSelected?: boolean;
    hasDetails?: boolean;
}

export const SelectionName = withConfig(theme => styled('div')<SelectionGroupType>`
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    color: ${theme.star.specialEventsColors.txtColor};
    height: 100%;
    background-color: ${theme.star.specialEventsColors.bgColor};
    ${({ hasDetails }): string => hasDetails === true ? 'cursor: pointer;' :''};
`);

export const ChevronIconStyle = styled(ChevronIcon)`
    fill: currentcolor;
    flex: 0 0 12px;
    margin: 0 8px;
    width: 12px;
`;

export const SmallDropdownChevronWrapper = withConfig(theme => styled('div')`
    margin-right: 10px;
    background-color: ${theme.star.heroSingleMarket.bgColorQuaternary};
`);

export const SelectionInfoWrapper = withConfig(theme => styled('div')`
    border-right: 1px solid ${theme.star.specialEventsColors.borderColor};
    display: flex;
    height: 100%;
    width: 100%;
`);

export const SelectionWrapper = withConfig(theme => styled('button')<AccordionTypes>`
    align-items: center;
    background-color: ${({ isSelected }):string => isSelected === true ? theme.star.heroEvents.bgColorTertiary: theme.star.heroSelection.bgColor};
    border-color: ${theme.star.heroSingleMarket.bgColor};
    border-style: solid;
    border-width: 0 3px 0 0;
    color: ${({ isSelected }):string => isSelected === true ? theme.star. heroSelection.txtColorSecondary : theme.star.heroSelection.txtColor};
    cursor: pointer;
    display: flex;
    font-weight: ${theme.star.fontWeight.bold};
    height: 100%;
    justify-content: center;
    margin: 0;
    min-width: 70px;
    position: relative;
    &:hover{
        background-color: ${theme.star.heroEvents.bgColorTertiary};
        color: ${theme.star.heroSelection.txtColorSecondary};
        transition: all 0.3s ease;
    }
    &:disabled {
        opacity: .5;
        background-color: ${({ isSelected }):string => isSelected === true ? theme.star.heroEvents.bgColorTertiary : theme.star.heroEvents.bgColorSecondary};
        color: ${({ isSelected }):string => isSelected === true ? theme.star. heroSelection.txtColorSecondary : theme.star.heroSelection.txtColor};
        cursor: not-allowed;
    };
    :last-of-type {
        border: none;
    }
`);

export const RowWrapper = styled('li')`
    flex: 1 1 0%;
`;

export const SelectionList = styled('ul')<SelectionGroupType>`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    &:last-of-type {
        border-right: 0;
    }
    &:first-of-type {
        border-left: 0;
    }
    ${({ displayTemplate }): string => displayTemplate === 'two-column' ? `
    flex: 0 0 50%
    `: displayTemplate === 'three-column' ? `
    ` : 'flex: 0 0 33.333333%'}
`;

export const SelectionRowLarge = withConfig(theme => styled('div')<SelectionGroupType>`
    ${({ hasDetails }): string => hasDetails === true ? 'cursor: pointer;' :''};
    align-items: center;
    background-color: ${theme.star.specialEventsColors.bgColor} !important;
    border-bottom: 1px solid ${theme.star.specialEventsColors.borderColor};
    border-left: 1px solid ${theme.star.specialEventsColors.borderColor};
    display: flex;
    height: 70px;
    justify-content: space-between;
    padding-left: 8px;
`);

export const SelectionRowWrapper = styled('div')<SelectionGroupType>`
    ${({ isSelected }): string => isSelected === false ? 'height: 70px' : ''};
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    width: 100%;
`;

export const SelectionTemplateRowCotainer = styled('div')`
    width: 100%;
`;

export const ParticipantColorWrapper = styled('div')`
    display: flex;
`;

export const GoToEventButton = withConfig(theme => styled('span')`
    color: ${theme.star.heroEvents.txtColorTertiary};
    font-weight: ${theme.star.fontWeight.bold};
    cursor: pointer;
`);
