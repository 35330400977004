import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface StarOutlineIconPropsType {
    className?: string;
    onClick?: (event: React.SyntheticEvent) => void;
}

export const StarOutlineIcon = observer('StarOutlineIcon', ({ className, onClick }: StarOutlineIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M23.996 9.496l-8.628-.74L11.998.8l-3.37 7.955L0 9.496l6.545 5.674-1.962 8.438 7.415-4.473 7.414 4.473-1.961-8.438 6.545-5.674zM12.98 17.507l-.982-.592-.982.592-3.563 2.15.943-4.057.26-1.116-.866-.75-3.148-2.729 4.148-.356 1.141-.098.447-1.055 1.62-3.823 1.62 3.823.446 1.055 1.141.098 4.149.356-3.148 2.729-.866.75.26 1.116.943 4.057-3.563-2.15z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
