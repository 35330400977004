import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface CasinoTableGamesIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const CasinoTableGamesIcon = observer('CasinoTableGamesIcon', ({ className, onClick }: CasinoTableGamesIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            clipRule='evenodd'
            fill='#072d4f'
            fillRule='evenodd'
            height='24'
            onClick={onClick}
            strokeLinejoin='round'
            strokeMiterlimit='2'
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M22.155 7.655L15.63 5.366a2.7 2.7 0 00-2.578.44l-1-3.808A2.746 2.746 0 008.715.09L1.998 1.859A2.746 2.746 0 00.09 5.196L3.088 16.49a2.749 2.749 0 003.338 1.939l1.928-.51v.14a2.74 2.74 0 001.64 3.488l6.515 2.288a2.758 2.758 0 003.478-1.679l3.857-10.993a2.749 2.749 0 00-1.689-3.507v-.001zM16.99 15.2v2.188l-1.25-1.798-1.499.45a2.708 2.708 0 00.82-2.719l-.07-.21.78.28 1.329-1.749-.05 2.199 2.078.72-2.138.639zM6.246 17.758a2 2 0 01-2.499-1.449L.75 4.996a2.049 2.049 0 011.46-2.468L8.883.75a2.058 2.058 0 012.498 1.46l1.11 4.187 1.718 6.445.17.65v.23a2.067 2.067 0 01-1.459 2.268l-4.297 1.14-2.378.63.001-.002zm-4.22-13.95c.089-.132.185-.275.282-.44.161.093.312.168.451.238.455.227.78.39.918.91a.638.638 0 01-.45.78.408.408 0 01-.449-.14.398.398 0 01-.31.34.63.63 0 01-.79-.45c-.137-.518.064-.816.348-1.238zm11.425 9.663a.65.65 0 00-.79-.46.422.422 0 00-.31.34.439.439 0 00-.449-.14.65.65 0 00-.45.79c.137.507.457.67.904.898.143.073.299.152.465.25.096-.163.192-.305.28-.436.286-.428.488-.73.35-1.242zM6.895 7.345a1 1 0 01.72-.78 1.49 1.49 0 011.819 1.05c.313 1.192-.151 1.894-.803 2.878-.2.302-.418.631-.636 1.01-.379-.222-.732-.4-1.056-.563-1.053-.53-1.799-.906-2.112-2.106a1.476 1.476 0 011.069-1.809c.367-.09.753.033 1 .32h-.001z'
                fill='fill'
            />
        </SvgElement>
    );
});
