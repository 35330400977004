import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { withConfig } from 'src/withConfig';

interface WrapperType {
    computedHeight: number;
}

export const progress = keyframes`
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(150%);
    }
`;

type sizeType = 'small' | 'header' | 'scoreboard' | 'sportsFilters' | undefined;

interface LoaderType {
    size?: sizeType;
}

const setPrimaryHederHeight = (type: sizeType): string => {
    switch (type){
        case 'small':
            return '80px';
        case 'header':
            return '40px';
        case 'scoreboard':
            return '130px';
        case 'sportsFilters':
            return '44px';
        default:
            return '88px';
    }
};

export const PrimaryLoaderContainer = withConfig(theme => styled('div')`
    position: relative;
    overflow: hidden;
    &::after {
        animation: ${progress} 1s linear infinite;
        background-image: ${`linear-gradient(to right,
            ${theme.star.skeletonLoader.gradient.bgColorOctonary} 0%,
            ${theme.star.skeletonLoader.gradient.bgColorQuaternary} 15%,
            ${theme.star.skeletonLoader.gradient.bgColor} 50%,
            ${theme.star.skeletonLoader.gradient.bgColorQuaternary} 95%,
            ${theme.star.skeletonLoader.gradient.bgColorOctonary} 100%)
            `};
        content: '';
        height: 100%;
        left: -100%;
        position: absolute;
        top: 0;
        width: 100%;
        background-size: 300%;
    }
`);

const SilkLoaderContainer = withConfig(theme => styled('div')`
    position: relative;
    overflow: hidden;
    &::after {
        animation: ${progress} 1s linear infinite;
        background-image: ${`linear-gradient(to right,
            ${theme.star.skeletonLoader.gradient.bgColorOctonary} 0%,
            ${theme.star.skeletonLoader.gradient.bgColorQuaternary} 15%,
            ${theme.star.skeletonLoader.gradient.bgColor} 50%,
            ${theme.star.skeletonLoader.gradient.bgColorQuaternary} 95%,
            ${theme.star.skeletonLoader.gradient.bgColorOctonary} 100%)
        `};
        content: '';
        height: 100%;
        left: -100%;
        position: absolute;
        top: 0;
        width: 100%;
        background-size: 250%;
        border-radius: 50%;
    }
`);

const CasinoLoaderContainer = withConfig(theme => styled('div')`
    position: relative;
    overflow: hidden;
    &::after {
        animation: ${progress} 1.2s linear infinite;
        background-image: ${`linear-gradient(to right,
            ${theme.star.skeletonLoader.gradient.bgColorUndenary} 0%,
            ${theme.star.skeletonLoader.gradient.bgColorSenary} 30%,
            ${theme.star.skeletonLoader.gradient.bgColorSeptenary} 50%,
            ${theme.star.skeletonLoader.gradient.bgColorSenary} 70%,
            ${theme.star.skeletonLoader.gradient.bgColorUndenary} 100%)`};
        content: '';
        height: 100%;
        left: -100%;
        position: absolute;
        top: 0;
        width: 100%;
        background-size: 500%;
    }
`);

export const Wrapper = withConfig(theme => styled('div')`
    display: flex;
    justify-content: space-between;
    background-color: ${theme.star.skeletonLoader.placeholder.bgColor};
`);

export const PlacehodlerWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColor};
`);

export const PrimaryHeaderLoader = withConfig(theme => styled('div')<LoaderType>`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorQuaternary};
    height: ${({ size }): string => setPrimaryHederHeight(size)};
    width: 100%;
`);

export const SecondaryHeaderLoader = withConfig(theme => styled('div')<LoaderType>`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorQuinary};
    height: ${({ size }): string => size === 'small' ? '24px' : '40px'};
    width: 100%;
`);

export const QuaternaryHeaderLoader = withConfig(theme => styled('div')`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorSecondary};
    height: 24px;
    width: 100%;
`);

export const EventsLoader = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 0 8px;
`;

export const EventRowLoader = withConfig(theme => styled('div')`
  height: 56px;
  padding: 14px 0;
  width: 100%;
  @media ${theme.star.mediaQuery.tablet} {
        height: 48px;
    }
`);

const EventLoader = withConfig(theme => styled(PrimaryLoaderContainer)`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorNonary};
    height: 8px;
`);

export const FirstLineLoader = withConfig(theme => styled(EventLoader)`
    margin-bottom: 4px;
    width: 101px;
    @media ${theme.star.mediaQuery.tablet} {
        width: 288px;
    }
`);

export const SecondLineLoader = withConfig(theme => styled(EventLoader)`
    width: 163px;
    @media ${theme.star.mediaQuery.tablet} {
        width: 80px;
    }
`);

export const ThirdLineLoader = withConfig(theme => styled(EventLoader)`
    margin-top: 4px;
    width: 38px;
    @media ${theme.star.mediaQuery.tablet} {
        display: none;
    }
`);

export const FirstLineRacingLoader = withConfig(theme => styled(EventLoader)`
    margin-bottom: 4px;
    width: 120px;
    @media ${theme.star.mediaQuery.tablet} {
        width: 144px;
    }
`);

export const FirstLineRacecardLoader = withConfig(theme => styled(EventLoader)`
    margin-bottom: 4px;
    width: 120px;
    @media ${theme.star.mediaQuery.tablet} {
        width: 173px;
    }
`);

export const SecondLineRacecardLoader = withConfig(theme => styled(EventLoader)`
    width: 80px;
    @media ${theme.star.mediaQuery.tablet} {
        width: 115px;
    }
`);

export const RacecardRowLoader = styled('div')`
    padding: 12px 8px;
    display: flex;
    flex-direction: column;
`;

export const FirstLineRaceInfo = withConfig(theme => styled(EventLoader)`
    height: 14px;
    width: 120px;
    @media ${theme.star.mediaQuery.tablet}{
        height: 20px;
    }
`);

interface SecondLineType {
    additional?: boolean;
}

export const SecondLineRaceInfo = withConfig(theme => styled(EventLoader)<SecondLineType>`
    height: 12px;
    margin-top: 5px;
    width: 164px;
    @media ${theme.star.mediaQuery.tablet}{
        ${({ additional }): string => additional === true ? 'display: none' : ''};
    }
`);

export const SecondLineRacingLoader = withConfig(theme => styled(EventLoader)`
    width: 80px;
    @media ${theme.star.mediaQuery.tablet} {
        width: 104px;
    }
`);

export const SelectionLoader = withConfig(theme => styled(PrimaryLoaderContainer)`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorNonary};
    height: 16px;
    margin-left: 24px;
    width: 40px;
`);

export const SelectionSPLoader = withConfig(theme => styled(SelectionLoader)`
    display: none;
    @media ${theme.star.mediaQuery.tablet} {
        display: block;
    }
`);

export const WeightLoader = withConfig(theme => styled(SelectionLoader)`
    display: none;
    @media ${theme.star.mediaQuery.tablet} {
        display: block;
    }
`);

export const SelectionsWrapper = styled('div')`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 13px;
`;

export const ViewRaceCardLoader = withConfig(theme => styled(PrimaryLoaderContainer)`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorNonary};
    height: 14px;
    margin-left: 8px;
    position: relative;
    width: 72px;
`);

export const SilkLoader = withConfig(theme => styled(SilkLoaderContainer)`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorNonary};
    border-radius: 50%;
    height: 24px;
    width: 24px;
`);

export const CasinoGamesHomepage = withConfig(theme => styled('div' )`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorNonary};
    height: 112px;
    width: 100%;
`);

export const LoadingWrapper = withConfig(theme => styled('div')<WrapperType>`
    ${({ computedHeight }): string => `max-height: ${computedHeight}px`};
    overflow: hidden;
    transition: max-height .2s ease;
    will-change: max-height;
    @media ${theme.star.mediaQuery.desktop}{
        transition: max-height .3s ease;
    }
`);

export const RacePositionLoader = withConfig(theme => styled('div')`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorNonary};
    height: 20px;
    margin-right: 8px;
    width: 8px;
`);

export const PriceHistory = withConfig(theme => styled(EventLoader)`
    height: 4px;
    margin-left: 24px;
    transform: rotate(-8deg);
    width: 44px;
    @media ${theme.star.mediaQuery.tablet} {
        width: 88px;
    }
`);

export const PriceHistoryWrapper = styled('div')`
    display: flex;
    align-items: center;
`;

export const RacecardSecondaryHeaderLoader = withConfig(theme => styled('div')`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorQuinary};
    height: 48px;
    width: 100%;
`);

export const RacecardHeaderLoader = withConfig(theme => styled('div')`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorSenary};
    height: 48px;
    width: 100%;
`);

export const HorseInfoWrapper = styled('div')`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const RaceboxWrapper = styled('div')`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px;
`;

export const RacecardSummaryHeaderLoader = withConfig(theme => styled('div')`
    height:16px;
    width: 100%;
    background-color: ${theme.star.skeletonLoader.placeholder.bgColor};
`);

export const FiltersLoader = withConfig(theme => styled('div')`
    width: 100%;
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorSeptenary};
    height: 205px;
`);

export const CasinoNavigationWrapper = withConfig(theme => styled('div')`
    display: flex;
    align-items: center;
    margin: 16px 0;
    @media ${theme.star.mediaQuery.desktop} {
        margin: 24px 0;
    }
`);

export const SearchbarLoader = withConfig(theme => styled(CasinoLoaderContainer)`
    height: 40px;
    width: 48px;
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorDenary};
    @media ${theme.star.mediaQuery.desktop} {
       padding-left: 24px;
    }
`);

export const CategoryLoader = withConfig(theme => styled(CasinoLoaderContainer)`
    height: 24px;
    min-width: 96px;
    margin-left: 16px;
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorDenary};
`);

export const GamesWrapper = withConfig(theme => styled('div')`
    padding: 24px 24px 9px 0;
    align-content: start;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fill,minmax(128px,auto));
    flex: 1 1 0%;
    @media ${theme.star.mediaQuery.xMobile} {
        grid-template-columns: repeat(auto-fill,minmax(170px,auto));
    }
    @media ${theme.star.mediaQuery.tablet} {
        grid-template-columns: repeat(auto-fill,minmax(228px,auto));
    }
`);

export const GameLoader = withConfig(theme => styled(CasinoLoaderContainer)`
    background-color: ${theme.star.skeletonLoader.placeholder.bgColorDenary};
    padding-bottom: 75%;
    position: relative;
    width: 100%;
    @media ${theme.star.mediaQuery.desktop} {
        height: 144px;
    }
`);
