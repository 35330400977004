import React from 'react';
import { PaginationContainer, PageButton, ArrowRight, ArrowLeft, PaginationWrapper } from './CreditsPagination.styled';
import { ButtonChevronIcon } from 'src/domains/layouts/shared/ScrollButtons.style';

type CreditsPaginationProps = {
    totalPages: number;
    currentPage: number;
    handlePageChange: (page: number) => void;
};

export const CreditsPagination = ({ totalPages, currentPage, handlePageChange }: CreditsPaginationProps) => {
    const renderPageButtons = () => {
        const visiblePages = Array.from({ length: totalPages }, (_, i) => i + 1).slice(
            Math.max(0, currentPage - 3),
            Math.min(totalPages, currentPage + 2)
        );

        return visiblePages.map((page) => (
            <PageButton
                key={page}
                isActive={page === currentPage}
                onClick={() => handlePageChange(page)}
            >
                {page}
            </PageButton>
        ));
    };

    return (
        <PaginationWrapper>
            <PaginationContainer>
                <ArrowLeft
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <ButtonChevronIcon position='left' />
                </ArrowLeft>
                {renderPageButtons()}
                <ArrowRight
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <ButtonChevronIcon position='right' />
                </ArrowRight>
            </PaginationContainer>
        </PaginationWrapper>
    );
};
