import * as React from 'react';
import styled from '@emotion/styled';

const SvgElement = styled('svg')`
    height: auto;
`;

interface SportsListIconType {
    className?: string;
}

export const SportsListIcon = ({ className }: SportsListIconType): React.ReactElement => (
    <SvgElement
        fill='#fff'
        className={className}
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        viewBox='0 0 24 24'
    >
        <path
            clipRule='evenodd'
            d='M5.143 18.536a2.48 2.48 0 0 1-.75 1.821c-.5.5-1.107.75-1.822.75a2.48 2.48 0 0 1-1.821-.75A2.48 2.48 0 0 1 0 18.536c0-.715.25-1.322.75-1.822s1.107-.75 1.821-.75c.715 0 1.322.25 1.822.75s.75 1.107.75 1.822Zm0-6.857a2.48 2.48 0 0 1-.75 1.821c-.5.5-1.107.75-1.822.75A2.48 2.48 0 0 1 .75 13.5 2.48 2.48 0 0 1 0 11.679c0-.715.25-1.322.75-1.822s1.107-.75 1.821-.75c.715 0 1.322.25 1.822.75s.75 1.107.75 1.822ZM24 17.25v2.571a.412.412 0 0 1-.127.302.412.412 0 0 1-.302.127H7.286a.412.412 0 0 1-.302-.127.412.412 0 0 1-.127-.302V17.25c0-.116.043-.216.127-.301a.412.412 0 0 1 .302-.128H23.57c.116 0 .217.043.302.128a.412.412 0 0 1 .127.301ZM5.143 4.821a2.48 2.48 0 0 1-.75 1.822c-.5.5-1.107.75-1.822.75a2.48 2.48 0 0 1-1.821-.75A2.48 2.48 0 0 1 0 4.82C0 4.107.25 3.5.75 3s1.107-.75 1.821-.75c.715 0 1.322.25 1.822.75s.75 1.107.75 1.821ZM24 10.393v2.571a.412.412 0 0 1-.127.302.412.412 0 0 1-.302.127H7.286a.412.412 0 0 1-.302-.127.412.412 0 0 1-.127-.302v-2.571c0-.116.043-.217.127-.302a.412.412 0 0 1 .302-.127H23.57c.116 0 .217.043.302.127a.412.412 0 0 1 .127.302Zm0-6.857v2.571a.412.412 0 0 1-.127.301.412.412 0 0 1-.302.128H7.286a.412.412 0 0 1-.302-.128.412.412 0 0 1-.127-.3V3.535c0-.116.043-.217.127-.302a.412.412 0 0 1 .302-.127H23.57c.116 0 .217.043.302.127a.412.412 0 0 1 .127.302Z'
            fill='fill'
            fillRule='evenodd'
        />
    </SvgElement>
);
