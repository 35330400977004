import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

export const LoginForm = styled('form')`
    margin: 0;
    padding: 5px 16px 16px;
    width: 100%;
    > div {
        margin: 8px 0;
    }
`;

export const BtnWrapper = styled(Button)`
    display: block;
    width: 100%;
`;
