import React, { useState } from 'react';
import { Count, BetTypePopup, Badge } from './BadgeList.styled';
import { BadgeListProps } from '../../types';

export const BadgeList = ({ items = [] }: BadgeListProps) => {
    const [showPopup, setShowPopup] = useState(false);

    const visible = items.slice(0, 3);
    const hidden = items.slice(3);

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            {visible.map((type, index) => (
                <Badge key={index}>{type}</Badge>
            ))}

            {hidden.length > 0 && (
                <Count
                    // TODO: Should use css not js for this
                    onClick={() => setShowPopup(!showPopup)}
                    onMouseEnter={() => setShowPopup(true)}
                    onMouseLeave={() => setShowPopup(false)}
                >
                    +{hidden.length}
                    {showPopup && (
                        <BetTypePopup>
                            {hidden.map((type, index) => (
                                <Badge key={index}>{type}</Badge>
                            ))}
                        </BetTypePopup>
                    )}
                </Count>
            )}
        </div>
    );
};
