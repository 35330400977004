import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface GBPPropsType {
    className?: string;
    onClick?: () => void;
}

export const GBPIcon = observer('GBPIcon', ({ className, onClick }: GBPPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M11.039 14.007h6.972v-3.52h-6.972v-.318c-.081-1.15.371-2.271 1.226-3.043 1.006-.75 2.245-1.12 3.497-1.045 1.443-.023 2.868.33 4.134 1.022l1.771-4.701c-1.954-.986-4.126-1.462-6.313-1.385-2.762-.15-5.484.714-7.654 2.43C5.81 5.192 4.794 7.69 4.93 10.26v.181H2v3.52h2.93v4.315H2V23h19.077v-4.678H11.039v-4.315z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
