import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount as AmountClass } from 'src_common/common/amount/Amount';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    LogInToPlaceBetBtnWrapper,
    PlaceButtonWrapper,
    AcceptChangesBtnWrapper,
} from 'src/domains/sportsbook/betting/ui/betSlip/betslip/Betslip.style';
import {
    BetNowWrapper,
    ButtonsWrapper,
    ButtonWrapper,
    EnterStakeButtonSpan,
    MoreInfo,
    PayoutRulesWrapper,
    SpanBottom,
    SpanTop,
} from './BetSlipButtons.style';

export const LogInToPlaceBet = observer('LogInToPlaceBet', () => {
    const { appLayoutsState } = useAppStateContext();
    const { starRouter } = appLayoutsState;

    return (
        <ButtonWrapper>
            <LogInToPlaceBetBtnWrapper
                isButton={true}
                size='large'
                routeNameForGtm='signup'
                key='Account:not-logged'
                onClickCallback={(): void => {
                    starRouter.redirectToLogin();
                }}
                to={{ account: 'login', promo: null }}
                dataTest='log-in-to-place-bet-button'
            >
                <I18n
                    langKey='betslip.login-button'
                    defaultText='Log in to place bet'
                />
            </LogInToPlaceBetBtnWrapper>
        </ButtonWrapper>
    );
});

export const AcceptChanges = observer('AcceptChanges', () => {
    const { appSportsBookState } = useAppStateContext();
    const { betSlipState } = appSportsBookState;

    return (
        <ButtonWrapper>
            <AcceptChangesBtnWrapper
                key='Accept:change'
                onClick={betSlipState.onAcceptChanges}
                size='large'
                version='secondary-dark'
            >
                <I18n
                    langKey='betslip.accept-changes-button'
                    defaultText='Accept Price Changes'
                />
            </AcceptChangesBtnWrapper>
        </ButtonWrapper>
    );
});

export const AcceptOfferBtn = observer('AcceptOfferBtn', () => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { betSlipState } = appSportsBookState;
    const { referralState, possibleBetsRequestState, betSlipSummaryState } = betSlipState;
    const { totalStake } = betSlipSummaryState;

    const { sdkCustomer } = appLayoutsState;

    return (
        <ButtonWrapper>
            <PlaceButtonWrapper
                disabled={possibleBetsRequestState.isLoading || referralState.isloading}
                isLoading={referralState.isloading}
                onClick={referralState.isloading === false ? referralState.onAcceptOffer : undefined}
                size='large'
            >
                <span>
                    <I18n
                        langKey='betslip.offer.accept-and-place-button'
                        defaultText='Accept & place bets'
                    />
                </span>
                &nbsp;
                <span>{sdkCustomer.money(totalStake)}</span>
            </PlaceButtonWrapper>
        </ButtonWrapper>
    );
});

const PlaceButton = observer('PlaceButton', () => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { betSlipState } = appSportsBookState;

    const { referralState, possibleBetsRequestState, betSlipSummaryState, basicBetSlipState } = betSlipState;
    const { isShowQuickBet } = basicBetSlipState;
    const { isLegsChangedWarning, isPlayableBalanceWarning, isSinglesOnlyWarning, isPlacingBet, totalStake } =
        betSlipSummaryState;

    const areSuspendedBets = betSlipState.rabState.suspendedBets.length > 0;
    const areBetsWithoutOffer = betSlipState.rabState.areBetsWithoutOffer;

    const hasErrors = possibleBetsRequestState.errors.length > 0;

    const onPlaceBets = async (): Promise<void> => {
        await betSlipSummaryState.onPlaceBet();
    };

    const isStake = totalStake.isGreaterThan(new AmountClass('0'));
    const isDisabled =
        possibleBetsRequestState.isLoading ||
        isLegsChangedWarning ||
        isPlayableBalanceWarning ||
        isSinglesOnlyWarning ||
        areSuspendedBets ||
        areBetsWithoutOffer ||
        referralState.isReferred ||
        hasErrors;

    const betNowButton = (
        <BetNowWrapper>
            <I18n
                langKey='betslip.summary.betNow'
                defaultText='bet now'
            />
        </BetNowWrapper>
    );

    const betButton = isStake ? (
        <>
            <SpanTop>
                <I18n
                    langKey='betslip.place-bet'
                    defaultText='Place Bet'
                />
            </SpanTop>
            <SpanBottom>{appLayoutsState.sdkCustomer.money(totalStake)}</SpanBottom>
        </>
    ) : (
        <EnterStakeButtonSpan>
            <I18n
                langKey='betslip.enter-stake'
                defaultText='Please Enter a Stake'
            />
        </EnterStakeButtonSpan>
    );

    return (
        <PlaceButtonWrapper
            disabled={isDisabled || !isStake}
            onClick={onPlaceBets}
            isLoading={(isPlacingBet || possibleBetsRequestState.isLoading) && !referralState.isBetSlipOffer}
            size='large'
            dataTest='place bets'
        >
            {isShowQuickBet ? betNowButton : betButton}
        </PlaceButtonWrapper>
    );
});

export const PlaceButtons = observer('PlaceButtons', () => {
    return (
        <ButtonWrapper>
            <ButtonsWrapper>
                <PlaceButton />
            </ButtonsWrapper>
        </ButtonWrapper>
    );
});

export const PayoutRules = observer('PayoutRules', () => {
    const { appLayoutsState, appSportsBookState } = useAppStateContext();
    const { betSlipState } = appSportsBookState;
    const { basicBetSlipState } = betSlipState;

    const { sdkCustomer, configComponents, starRouter } = appLayoutsState;
    const { isAuthorized: isUserAuthorized } = sdkCustomer.session;

    const handleRedirect = (): void => {
        starRouter.redirectToTermsAndConditions();
    };
    return configComponents.config.payoutRules && isUserAuthorized ? (
        <PayoutRulesWrapper isQuickBet={basicBetSlipState.isShowQuickBet}>
            <I18n
                langKey='betslip.payout-rules-info'
                defaultText='Payout limits apply to all betting transactions.'
            />
            {/* eslint-disable-next-line */}
            <MoreInfo onClick={handleRedirect}>
                &nbsp;
                <I18n
                    langKey='betslip.payout-rules-info.more'
                    defaultText='More Info'
                />{' '}
            </MoreInfo>
        </PayoutRulesWrapper>
    ) : null;
});
