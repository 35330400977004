import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const MarketGroupWrapper = withConfig(theme => styled('section')`
    border-left: 1px solid ${theme.star.eventRow.borderColor};
`);

export const MarketSeparator = withConfig(theme => styled('hr')`
    background-color: ${theme.star.headings.market.borderColor};
    border: 0;
    height: 1px;
    margin: 0;
    &:last-child {
        display: none;
    }
`);
