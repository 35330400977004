import styled from '@emotion/styled';
import { BabIcon } from 'src/domains/layouts/shared/assets/icons/BabIcon';
import { withConfig } from 'src/withConfig';

export const YouDecideHeaderWrapper = styled('div')`
    align-items: center;
    display: flex;
`;

export const YouDecideDescWrapper = styled('div')`
    margin-left: 8px;
`;

export const YouDecideLabel = withConfig(theme => styled('h2')`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 0 0 -4px 0;
`);

export const YouDecideDescriptionContainer = withConfig(theme => styled('div')`
    @media ${theme.star.mediaQuery.desktop} {
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
    }
`);

export const YouDecideDescription = withConfig(theme => styled('span')`
    display: block;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
`);

export const YouDecideCashOutText = withConfig(theme => styled('span')`
    display: block;
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin-top: -4px;
    opacity: .8;
    @media ${theme.star.mediaQuery.desktop} {
        margin-left: 2px;
    }
`);

export const RabBuildAbetIconWrapper = styled(BabIcon)`
    fill: currentcolor;
`;

