import styled from '@emotion/styled';
import { fadeIn, fadeOut } from 'src/domains/layouts/shared/Animation.style';
import { ScrollButton } from 'src/domains/layouts/shared/ScrollButtons.style';
import { withConfig } from 'src/withConfig';

interface LinksWrapperType {
    languageWidth: number;
}

export const LinksWrapper = withConfig(theme => styled('div')<LinksWrapperType>`
    @media ${theme.star.mediaQuery.largeDesktop} {
        max-width: ${({ languageWidth }): string => `calc(100% - 200px - ${languageWidth}px)`};
        position: relative;
        display: flex;
        align-items: center;
        margin: 0 auto 0 0;
    }
`);

export const LinksContentWrapper = withConfig(theme => styled('div')`
    @media ${theme.star.mediaQuery.largeDesktop} {
        width: max-content;
    }
`);

interface LinksScrollableWrapperType {
    isVisible: boolean;
    position: string;
}

export const ScrollButtonWrapper = withConfig(theme => styled('div')<LinksScrollableWrapperType>`
    ${({ position }): string => position === 'right' ? 'right: 0' : 'left: -1px'};
    animation: ${({ isVisible }): string => isVisible ? fadeIn : fadeOut} 500ms linear;
    background-color: ${theme.star.headerMain.bgColor};
    height: 56px;
    position: absolute;
    transition: visibility 500ms linear;
    visibility: ${({ isVisible }): string => isVisible ? 'visible': 'hidden'};
    width: 48px;
`);

export const ScrollLeftButton = withConfig(theme => styled(ScrollButton)`
    bottom: 5px;
    left: 8px;
    margin: auto 0;
    z-index: ${theme.star.zIndexGlobal.above};
`);

export const ScrollRightButton = withConfig(theme => styled(ScrollButton)`
    bottom: 5px;
    margin: auto 0;
    right: 8px;
    z-index: ${theme.star.zIndexGlobal.above};
`);
