import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;
interface PropsType {
  className?: string;
  onClick?: () => void;
}

export const HeadsetIcon = observer('HeadsetIcon', ({ className, onClick }: PropsType): JSX.Element => (
    <SvgElement
        className={className}
        height='24'
        onClick={onClick}
        viewBox='0 0 24 24'
        width='24'
        fill='#fff'
        xmlns='http://www.w3.org/2000/svg'
    >
        <mask id='a_headsetIcon' style={{ 'maskType': 'alpha' }} maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
            <path fill='#D9D9D9' d='M0 0h24v24H0z' />
        </mask>
        <g mask='url(#a_headsetIcon)'>
            <path
                d='M12 23v-2h7v-1h-4v-8h4v-1c0-1.933-.683-3.583-2.05-4.95C15.583 4.683 13.933 4 12 4c-1.933 0-3.583.683-4.95 2.05C5.683 7.417 5 9.067 5 11v1h4v8H5c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 18v-7c0-1.233.237-2.396.712-3.487A9.193 9.193 0 0 1 5.65 4.65a9.193 9.193 0 0 1 2.862-1.938A8.646 8.646 0 0 1 12 2c1.233 0 2.396.237 3.488.712A9.193 9.193 0 0 1 18.35 4.65a9.192 9.192 0 0 1 1.938 2.862A8.646 8.646 0 0 1 21 11v10c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 23h-7Z'
                fill='fill'
            />
        </g>
    </SvgElement>
));
