import React from 'react';
import styled from '@emotion/styled';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

const TomorrowLabelWrapper = withConfig(theme => styled('button')`
    align-items: center;
    background: transparent;
    border: 1px solid transparent;
    color: ${theme.star.raceCardColors.txtColorTomorrowLabel};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    height: 32px;
    justify-content: center;
    line-height: 1.3334;
    user-select: none;
`);

const TomorrowChevronWrapper = styled(ChevronIcon)`
    fill: currentcolor;
    flex: 0 0 14px;
    margin-top: 2px;
`;

interface TomorrowLabelPropsType {
    labelText?: 'default' | 'this-week';
}

export const TomorrowLabel = ({ labelText }: TomorrowLabelPropsType): JSX.Element => (
    <TomorrowLabelWrapper>
        {
            labelText === 'this-week' ?
                <I18n langKey='races-slider.this-week' defaultText='This week' /> :
                <I18n langKey='races-slider.tomorrow' defaultText='Tomorrow' />
        }
        <TomorrowChevronWrapper position='right' />
    </TomorrowLabelWrapper>
);
