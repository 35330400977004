import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface BoostIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const BoostIcon = observer('BoostIcon', ({ className, onClick }: BoostIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            clipRule='evenodd' 
            fill='#fff' 
            fillRule='evenodd' 
            height='24'
            onClick={onClick}
            strokeLinejoin='round' 
            strokeMiterlimit='2' 
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg' 
        >   
            <path 
                fill='fill' 
                d='m19.488 3.298 1.116 1.244-2.252 12.496-2.738-2.619-3.624 3.827-6.379-6.165 3.757-3.748-2.525-2.54 12.645-2.495ZM8.863 6.236 10.814 8.2l-3.625 3.642 4.009 3.87 3.629-3.781 2.058 1.849 1.744-9.496-9.766 1.952Z'
            />
            <path 
                d='M6.044 14.329a.598.598 0 0 0-.846 0l-2.237 2.238a.598.598 0 1 0 .846.846l2.237-2.238a.599.599 0 0 0 0-.846ZM9.774 18.04a.595.595 0 0 0-.84 0l-2.236 2.235a.595.595 0 0 0 .84.84l2.236-2.234a.595.595 0 0 0 0-.842ZM8.003 16.097a.594.594 0 0 0-.84 0L3.82 19.44a.594.594 0 1 0 .841.84l3.342-3.342a.595.595 0 0 0 0-.841Z'
            />
        </SvgElement>
    );
});
