import styled from '@emotion/styled';
import { ImageFitType } from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg';
import { withConfig } from 'src/withConfig';

interface ImagePropsType {
    allowShow: boolean;
    imgFit?: ImageFitType;
}

export const Image = styled('img')<ImagePropsType>`
    height: 100%;
    left: 0;
    object-fit: ${({ imgFit }): string => (imgFit === undefined ? 'cover' : imgFit)};
    opacity: ${({ allowShow }): string => (allowShow ? '1' : '0')};
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
`;

export const ImageRaw = styled('img')<ImagePropsType>`
    display: block;
    height: auto;
    object-fit: ${({ imgFit }): string => (imgFit === undefined ? 'fill' : imgFit)};
    opacity: ${({ allowShow }): string => (allowShow ? '1' : '0')};
    transition: opacity 0.3s ease-in-out;
    width: 100%;
`;

interface ImageWrapperPropsType {
    isLoaded: boolean;
}

export const ImageWrapper = withConfig(theme => styled('div')<ImageWrapperPropsType>`
    position: absolute;
    &::before {
        background: ${theme.star.skeletonLoader.placeholder.bgColorDenary};
        content: '';
        height: 100%;
        left: 0;
        opacity: ${({ isLoaded }): string => (isLoaded ? '0' : '1')};
        position: absolute;
        top: 0;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
        visibility: ${({ isLoaded }): string => (isLoaded ? 'hidden' : 'visible')};
        width: 100%;
    }
`);
