import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

export const ButtonsContainer = styled('div')`
    display: flex;
    margin-top: 8px;
`;

export const ButtonWrapper = styled(Button)`
    flex: 0 1 25%;
    &:not(:last-of-type) {
        margin-right: 4px !important;
    }
`;
