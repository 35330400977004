import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const FormWrapper = styled('form')`
    margin: 8px 0 0 0;
`;

export const HeaderWrapper = withConfig(theme => styled('div')`
    margin-bottom: 12px;
    line-height: 1.3334;
    font-weight: ${theme.star.fontWeight.bold};
`);
