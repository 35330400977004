import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const AccountHelperWrapper = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.small};
    text-align: center;
    padding: 24px 16px 40px 16px;
`);

interface AccountHelperLink {
    version: 'primary' | 'secondary';
}

const buildAccountHelperLink = (theme: EmotionTheme, version: 'primary' | 'secondary'): SerializedStyles | null => {

    if (version === 'primary'){
        return css`
            color: ${theme.star.accountHelperInfo.txtColor};
            &:hover {
                opacity: 0.8;
            }
        `;
    }

    if (version === 'secondary'){
        return css`
            color: ${theme.star.signUp.footer.txtColor};
            &:hover {
                color: ${theme.star.signUp.footer.txtColorSecondary};
            }
        `;
    }

    return null;
};

export const AccountHelperLink = withConfig(theme => styled(Link)<AccountHelperLink>`
    transition: 0.2s;
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;

    ${({ version }): SerializedStyles | null => buildAccountHelperLink(theme, version)};
`);

export const Separator = withConfig(theme => styled('span')<AccountHelperLink>`
    color: ${({ version }): string => version === 'primary' ? theme.star.accountHelperInfo.txtColor : theme.star.signUp.footer.txtColor};
    margin: 0 8px;
`);
