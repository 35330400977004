import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    AccountSummaryStatusWrapper,
    AccountSummaryWrapper,
    DepositButton,
    FreeBetsTextWithHelper,
    NameStyle,
    RowRecordStyle,
    UserNameStyle,
    UserRecordStyle,
    ValueStyle,
} from './AccountSummary.style';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import { LogoutButton } from 'src/domains/players/webview/components/Account/summaryTab/accountSummary/logoutButton/LogoutButton';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { AccountSummaryState } from './AccountSummary.state';
import { Amount } from 'src_common/common/amount/Amount';
import { Tooltip } from './ToolTip/Tooltip';

const AccountSummaryBalance = observer('AccountSummaryBalance', () => {
    const { appPlayersState } = useAppStateContext();
    const { usersState } = appPlayersState;

    const playableBalance =
        usersState.walletData.valueReady?.playableBalance === undefined
            ? undefined
            : new Amount(usersState.walletData.valueReady.playableBalance);
    const withdrawableBalance = new Amount(usersState.walletData.valueReady?.withdrawableBalance ?? '0');
    const backofficeWithdrawableBalance = new Amount(
        usersState.walletData.valueReady?.backofficeWithdrawableBalance ?? '0'
    );
    const failedWithdrawalsFunds = backofficeWithdrawableBalance.sub(withdrawableBalance);

    return (
        <>
            <RowRecordStyle>
                <NameStyle data-test='credit-balance-name'>
                    <I18n
                        langKey='account.summary.credit-balance'
                        defaultText='Credit balance'
                    />
                </NameStyle>
                <ValueStyle data-test='credit-balance-value'>{usersState.money(playableBalance)}</ValueStyle>
            </RowRecordStyle>

            {failedWithdrawalsFunds.isGreaterThanZero() ? (
                <RowRecordStyle>
                    <FreeBetsTextWithHelper>
                        <I18n
                            langKey='account.summary.failed-withdrawal-funds'
                            defaultText='Failed withdrawal funds '
                        />
                        <Tooltip
                            content={`We processed your withdrawal, but the bank returned the funds. Please provide a copy of your card showing your BACS information to enable us to pay your withdrawal. If you've already provided this, we will process your withdrawal shortly.`}
                        />
                    </FreeBetsTextWithHelper>
                    <ValueStyle>{usersState.money(failedWithdrawalsFunds)}</ValueStyle>
                </RowRecordStyle>
            ) : null}
        </>
    );
});

const AccountSummaryReady = observer('AccountSummaryReady', () => {
    const { appPlayersState, appLayoutsState } = useAppStateContext();
    const { configComponents, starRouter, googleTagManager } = appLayoutsState;
    const { config } = configComponents;
    const { usersState } = appPlayersState;

    const [accountSummaryState] = useState(
        () => new AccountSummaryState(usersState.session, starRouter, googleTagManager)
    );

    const id = usersState.basicData.valueReady?.id ?? -1;
    const name = usersState.basicData.valueReady?.name;
    const idPrefix = config.accountIdPrefix;

    return (
        <AccountSummaryWrapper>
            <UserRecordStyle>
                <UserNameStyle>{name}</UserNameStyle>
                <LogoutButton />
            </UserRecordStyle>
            <RowRecordStyle>
                <NameStyle data-test='account-number-name'>
                    <I18n
                        langKey='account.summary.account-number'
                        defaultText='Account number'
                    />
                </NameStyle>
                <ValueStyle data-test='account-number-value'>{`${idPrefix}${id}`}</ValueStyle>
            </RowRecordStyle>

            <AccountSummaryBalance />

            <RowRecordStyle>
                <NameStyle>
                    <I18n
                        langKey='account.summary.login-time'
                        defaultText='Logged in time'
                    />
                </NameStyle>
                <ValueStyle>{accountSummaryState.loggedCounter}</ValueStyle>
            </RowRecordStyle>

            <RowRecordStyle>
                <DepositButton
                    size='large'
                    onClick={accountSummaryState.redirectToTopUp}
                    dataTest='deposit-button'
                >
                    <I18n
                        langKey='account.summary.deposit'
                        defaultText='Deposit'
                    />
                </DepositButton>
            </RowRecordStyle>
        </AccountSummaryWrapper>
    );
});

export const AccountSummaryNew = observer('AccountSummaryNew', () => {
    const { appPlayersState } = useAppStateContext();
    const { account } = appPlayersState.accountState;

    if (account !== null) {
        const basicData = appPlayersState.usersState.basicData.get();
        const walletData = appPlayersState.usersState.walletData.get();

        if (basicData.type === 'error' || walletData.type === 'error') {
            return (
                <AccountSummaryStatusWrapper>
                    <div>Something went wrong...</div>
                    <div onClick={account.resetAll}>Try again</div>
                </AccountSummaryStatusWrapper>
            );
        }

        if (basicData.type === 'loading' || walletData.type === 'loading') {
            return (
                <AccountSummaryStatusWrapper>
                    <Loader version='dark' />
                </AccountSummaryStatusWrapper>
            );
        }

        return <AccountSummaryReady />;
    }
    return null;
});
