import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { OpenBetsBtnWrapper } from 'src/domains/sportsbook/betting/ui/betSlip/betslip/Betslip.style';
import { useAppStateContext } from 'src/appState/AppState';
import { Amount } from 'src_common/common/amount/Amount';
import {
    BetslipReceiptHeader,
    BetslipReceiptWrapper,
    Text,
    ListElement,
    ReceiptValue,
    BetslipReceiptIconWrapper,
    BetslipReceiptGiftIconWrapper,
    ListWrapper,
} from './BetslipReceipt.style';

export const BetslipReceipt = observer('BetslipReceipt', () => {
    const appState = useAppStateContext();
    const { appLayoutsState, appSportsBookState } = appState;

    const { betSlipState } = appSportsBookState;
    const { betReceipt } = betSlipState.betSlipSummaryState;

    const { lifeSpanState, sdkCustomer } = appLayoutsState;
    const lifeSpanPotentialReturn = lifeSpanState.lifeSpanSocketState.rewardValueForBetslipReceipt;

    if (betReceipt === null) return null;

    return (
        <BetslipReceiptWrapper
            className='betslip__content'
            key='Betslip:receipt'
        >
            <BetslipReceiptHeader>
                <I18n
                    langKey='betslip.bet-receipt.header'
                    defaultText='BET RECEIPT'
                />
            </BetslipReceiptHeader>

            <Text data-test='bet-receipt-paragraph'>
                <BetslipReceiptIconWrapper />
                <I18n
                    langKey='betslip.bet-receipt.description'
                    defaultText='Your bets have been successfully placed'
                />
            </Text>
            <ListWrapper>
                <ListElement>
                    <span data-test='label-bet-receipt-stake'>
                        <I18n
                            langKey='betslip.bet-receipt.stake.label'
                            defaultText='Total Stake'
                        />
                    </span>
                    <ReceiptValue data-test='value-bet-receipt-stake'>
                        {betReceipt.isFreeBet && <BetslipReceiptGiftIconWrapper />}

                        {sdkCustomer.money(betReceipt.totalStake)}
                    </ReceiptValue>
                </ListElement>
                <ListElement>
                    <span data-test='label-bet-receipt-total-potential-returns'>
                        <I18n
                            langKey='betslip.bet-receipt.potential-returns.label'
                            defaultText='Total Potential Returns'
                        />
                    </span>
                    <ReceiptValue data-test='value-bet-receipt-total-potential-returns'>
                        {betReceipt.totalPotentialReturns.isEqualWith(new Amount('0'))
                            ? 'n/a'
                            : sdkCustomer.money(betReceipt.totalPotentialReturns.add(lifeSpanPotentialReturn))}
                    </ReceiptValue>
                </ListElement>
            </ListWrapper>

            <OpenBetsBtnWrapper
                isButton={true}
                size='large'
                to={{ account: 'bet-history' }}
                dataTest='bet-receipt-open-bets-button'
            >
                <I18n
                    langKey='betslip.bet-receipt.open-bets-button.label'
                    defaultText='Open Bets'
                />
            </OpenBetsBtnWrapper>
        </BetslipReceiptWrapper>
    );
});
