import { action, computed, makeObservable, observable } from 'mobx';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { BasicBetSlipState } from 'src/domains/sportsbook/betting/betSlipState/BasicBetSlipState';
import { ReferralState } from 'src/domains/sportsbook/betting/betSlipState/ReferralState';
import { PossibleBetsRequestState } from 'src/domains/sportsbook/betting/betSlipState/possibleBetsState/PossibleBetsState';
import {
    InternalPlaceBetErrorsMessages,
    NewPlaceBetRequestType,
    ParsedPlaceBetType,
    PlaceBetBetsType,
    PlaceBetRabBetType,
    postPlaceBet,
    postPlaceBetNewTrading,
    preparePlaceBetRequestBody,
} from 'src/domains/sportsbook/betting/betting/postPlaceBet';
import {
    NewTradingPostBetData,
    SuccessPlaceBetResponseType,
} from 'src/domains/sportsbook/betting/betting/postPlaceBetTypes';
import { CombinationState } from 'src/domains/sportsbook/betting/state/betSlipState/CombinationsState';
import { LegsState } from 'src/domains/sportsbook/betting/state/betSlipState/LegsState';
import { RabItemState } from 'src/domains/sportsbook/betting/state/rabState/RabItemState';
import { RabState } from 'src/domains/sportsbook/betting/state/rabState/RabState';
import { EventEmmiter } from 'src_common/common/mobx-utils/EventEmmiter';

import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { CastBetType, LegType } from 'src/domains/sportsbook/betting/betSlipState/BetSlipTypes';
import { ChannelType } from 'src/domains/sportsbook/betting/betting/types';
import { Amount } from 'src_common/common/amount/Amount';

import { Logger } from 'src/domains/common/Logger';
import { EnvVariables } from 'src/domains/common/contextStore/EnvVariables';
import { LocalStorageState } from 'src/domains/layouts/state/localStorage/LocalStorageState';
import { BetslipSingleId } from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { FreeBetCreditsType, FreeBetType } from '../../betSlipState/BetSlipSheredTypes';
import { BetslipData } from '../../betSlipState/BetslipData';
import { ArrayBetsType, ErrorMsgType, parsePlaceBetErrors, parsePlaceBetLegs } from './BetSlipSummaryState.utils';

interface BetDetailsLegsType {
    selectionName: string;
    marketName: string;
    eventName: string;
    priceBet: string | null;
    betId: number;
    stake: string;
    potentialReturns: string | null;
}

interface BetReceiptType {
    totalStake: Amount;
    totalPotentialReturns: Amount;
    isFreeBet?: boolean;
    details?: {
        legs: Array<BetDetailsLegsType>;
    };
}

type PlaceBetStatusType = 'SUCCESS' | 'IN_PROGRESS' | 'ERROR';

export class BetSlipSummaryState {
    private readonly possibleBetsRequestState: PossibleBetsRequestState;
    private readonly legsState: LegsState;
    private readonly combinationState: CombinationState;
    private readonly rabState: RabState | null;
    private readonly referralState: ReferralState;
    private readonly basicBetSlipState: BasicBetSlipState;
    private readonly getLegsIds: () => Array<BetslipSingleId>;
    private readonly onCleanAll: () => void;
    private readonly lifeSpanState: LifeSpanState;

    public readonly onGoogleTagManagerBetPlacedTag: EventEmmiter<Array<ArrayBetsType>>;

    @observable private betReceiptSummary: BetReceiptType | null = null;
    // todo use Resource
    @observable public placeBetStatus: PlaceBetStatusType | null = null;
    @observable private responseErrorMsgInner: Array<ErrorMsgType> = [];

    @observable.ref public betTooHighValue: Amount | null = null;

    public constructor(
        private readonly configComponents: ConfigComponents,
        possibleBetsRequestState: PossibleBetsRequestState,
        legsState: LegsState,
        combinationState: CombinationState,
        rabState: RabState | null,
        referralState: ReferralState,
        basicBetSlipState: BasicBetSlipState,
        getLegsIds: () => Array<BetslipSingleId>,
        onCleanAll: () => void,
        private readonly sdk: SdkCustomer,
        private readonly onRedirectToBetslip: () => void,
        lifeSpanState: LifeSpanState,
        private readonly getChannel: () => ChannelType,
        private readonly envVariables: EnvVariables,
        private localStorageState: LocalStorageState,
        private betslipData: BetslipData
    ) {
        makeObservable(this);
        this.possibleBetsRequestState = possibleBetsRequestState;
        this.legsState = legsState;
        this.combinationState = combinationState;
        this.referralState = referralState;
        this.basicBetSlipState = basicBetSlipState;
        this.getLegsIds = getLegsIds;
        this.onCleanAll = onCleanAll;
        this.rabState = rabState;
        this.lifeSpanState = lifeSpanState;
        this.betslipData = betslipData;

        this.onGoogleTagManagerBetPlacedTag = new EventEmmiter();
    }

    private prepareRabBets(): Array<PlaceBetRabBetType> {
        const rabBets = this.rabState === null ? [] : this.rabState.getBets(this.payoutRab);

        // TODO: Adjust mapping when RAB will be adjusted to return new Amount value
        return rabBets.map(({ stakePerLine, ...el }) => ({
            ...el,
            stakePerLine: this.configComponents.precision.newFromOld(stakePerLine ?? 0).value,
        }));
    }

    private createFreeBetCredits(
        freeBets: FreeBetType[],
        stake: number
        // TODO: Move to type or use IO type
    ): FreeBetCreditsType[] {
        let remaining = Number(stake);

        /*
        stake 9
        0.9
        1.0
        1.0
        1.0

        */

        const freeBetCredits = freeBets.reduce<FreeBetCreditsType[]>((acc, freeBet, index, items) => {
            const isLastFreeBet = index === items.length - 1;
            const freeBetAmount = freeBet.currentAmount / 100;

            // Don't include other selected freebets if stake is less than all freebets selected
            if (remaining <= 0) {
                return acc;
            }

            if (isLastFreeBet) {
                return acc.concat({
                    id: index,
                    amount: Number(remaining.toFixed(2)),
                    newFreeBetId: freeBet.bonusId,
                });
            }

            const amount = Number(Math.min(freeBetAmount, remaining).toFixed(2));

            remaining -= freeBetAmount;

            return acc.concat({
                id: index,
                amount,
                newFreeBetId: freeBet.bonusId,
            });
        }, []);

        return freeBetCredits;
    }

    private async dataForPlaceBetNewTrading(): Promise<NewPlaceBetRequestType | null> {
        if (this.isDisabledBettingButton === false) {
            const { accountAuthenticated } = this.basicBetSlipState.userData;
            if (
                this.basicBetSlipState.placeBetStatus === 'IN_PROGRESS' ||
                this.isLegsChangedWarning ||
                accountAuthenticated === false ||
                (this.possibleBetsRequestState.castBets.length === 0 &&
                    this.possibleBetsRequestState.coreRabPossibleBetsResponse.length === 0) ||
                (this.referralState.referralData !== null && this.referralState.status === 'request')
            ) {
                let warnMsg = 'onPlaceBet error';
                if (this.basicBetSlipState.placeBetStatus === 'IN_PROGRESS') {
                    warnMsg = 'onPlaceBet error: placeBetStatus in progress';
                } else if (this.isLegsChangedWarning) {
                    warnMsg = 'onPlaceBet error: isLegsChangedWarning';
                } else if (accountAuthenticated === false) {
                    warnMsg = 'onPlaceBet error: accountAuthenticated failed';
                } else if (
                    this.possibleBetsRequestState.castBets.length === 0 &&
                    this.possibleBetsRequestState.coreRabPossibleBetsResponse.length === 0
                ) {
                    warnMsg = 'onPlaceBet error: castBets count is 0 and rabPossibleBets is 0';
                } else if (this.referralState.referralData !== null && this.referralState.status === 'request') {
                    warnMsg = 'onPlaceBet error: referralState status is request';
                }
                Logger.captureError('onPlaceBet error', 'Sportsbook', {
                    'Betslip info': {
                        basicBetSlipState: this.basicBetSlipState.placeBetStatus,
                        isLegsChangedWarning: this.isLegsChangedWarning,
                        possibleBetsRequestState: JSON.stringify(this.possibleBetsRequestState),
                        betSlipPlayableBalanceAmounts: JSON.stringify(
                            this.basicBetSlipState.betSlipPlayableBalanceAmounts
                        ),
                        referralData: JSON.stringify(this.referralState.referralData),
                        referralStateStatus: this.referralState.status,
                    },
                });
                console.error(warnMsg);
                return null;
            }

            //New version without possible bet in frontend api - trading refactor part

            const bets = this.convertSimpleToBets(
                this.legsState.forPlaceBet,
                this.combinationState.castCombinationsPossibleBetsResponse
            ).map((bet) => {
                // Take bet type for combination because for combination bets we don't have an id
                const id = bet.type === 'SGL' ? bet.id : bet.type;

                return {
                    ...bet,
                    freeBets: [],
                    ...(bet.totalStake && {
                        freebetCredits: this.createFreeBetCredits(
                            this.betslipData.getSelectedFreeBets(id),
                            bet.totalStake
                        ),
                    }),
                };
            });

            const data: NewPlaceBetRequestType = {
                channel: this.getChannel(),
                rabBets: this.prepareRabBets(),
                bets,
                possibleBetSuccess: this.possibleBetsRequestState.isSuccess,
            };
            return data;
        }
        return null;
    }

    @action public onPlaceBet = async (): Promise<void> => {
        const data = await this.dataForPlaceBetNewTrading();

        if (data !== null) {
            console.info('Place bet with new data');

            this.handlePlaceBetRequest(data).catch((error) => {
                Logger.captureError('handlePlaceBetRequest error', 'Sportsbook', {
                    'Betslip info': {
                        data: JSON.stringify(data),
                    },
                });
                console.error('onPlaceBet error', error);
            });
            this.basicBetSlipState.onHideKeyboard();
        }
    };

    @computed public get isPlacingBet(): boolean {
        return this.placeBetStatus === 'IN_PROGRESS';
    }

    @action private onPlaceBetRequest = async (data: NewPlaceBetRequestType, isRab?: boolean): Promise<void> => {
        this.placeBetStatus = 'IN_PROGRESS';

        const requestBody = preparePlaceBetRequestBody(data, this.configComponents.precision);
        if (requestBody === undefined || this.envVariables.gatewayApiHost === null) {
            return;
        }

        const response: ParsedPlaceBetType = await postPlaceBet(
            this.envVariables.gatewayApiHost,
            this.envVariables.universe,
            requestBody
        );

        if (response.status === 'internalError') {
            this.placeBetStatus = 'ERROR';
            this.setPlaceBetErrorMsg('INTERNAL_SERVER_ERROR_MESSAGE');
            console.error(InternalPlaceBetErrorsMessages[response.data]);
            return;
        }

        if (response.status === 'success') {
            this.placeBetStatus = 'SUCCESS';
            const arrayBets: Array<ArrayBetsType> = response.data.flatMap((singleBet) => {
                const { id, type, totalStake, legs } = singleBet;
                if (id === undefined || id === null) {
                    return [];
                }

                const selectionWithBoost = this.lifeSpanState.lifeSpanSocketState.selectionsWithAvailableBoosts(
                    legs[0]?.id
                );
                const arrayLegs: ArrayBetsType['legs'] = parsePlaceBetLegs(legs);

                return {
                    betId: id,
                    type,
                    totalStake,
                    totalStakeCurrency: this.sdk.currency,
                    legs: arrayLegs,
                    isBoostVisible: selectionWithBoost?.selectionInfo?.reward !== undefined,
                    isBoosted:
                        selectionWithBoost?.selectionInfo?.reward !== undefined &&
                        selectionWithBoost.selectionInfo.reward.active,
                };
            });
            this.onGoogleTagManagerBetPlacedTag.trigger(arrayBets);
            this.onPlaceBetSuccess(response.data, isRab);
        }

        if (response.status !== 'error') {
            return;
        }

        this.placeBetStatus = 'ERROR';
        const placeBetErrors = parsePlaceBetErrors(response, this.basicBetSlipState.isShowQuickBet);
        placeBetErrors.forEach((error) => {
            switch (error.type) {
                case 'set-bet-to-high-value':
                    this.betTooHighValue =
                        error.maxStakePerLine === null
                            ? null
                            : (this.betTooHighValue = this.configComponents.precision.newFromOld(
                                  error.maxStakePerLine
                              ));
                    this.setPlaceBetErrorMsg(error.messageType);
                    break;
                case 'redirect':
                    this.onRedirectToBetslip();
                    break;
                case 'message':
                    this.setPlaceBetErrorMsg(error.messageType);
                    break;
            }
        });
    };

    //this method is copy from old onPlaceBetRequest. After tests remove old onPlaceBetRequest
    @action private handlePlaceBetRequest = async (data: NewPlaceBetRequestType, isRab?: boolean): Promise<void> => {
        if (this.placeBetStatus === 'IN_PROGRESS') {
            return;
        }
        this.placeBetStatus = 'IN_PROGRESS';
        if (this.envVariables.isPlacebetNewVersion || this.localStorageState.newPlaceBet.getValue()) {
            await this.onPlaceBetRequest(data, isRab);
            return;
        }
        const response = await postPlaceBetNewTrading(data);
        if (response !== null && response.status === 'success' && response.data === null) {
            this.placeBetStatus = 'SUCCESS';
        }
        if (response !== null && response.status === 'success' && response.data !== null) {
            this.placeBetStatus = 'SUCCESS';
            const arrayBets: Array<ArrayBetsType> = response.data.map((singleBet) => {
                const { id, type, totalStake, legs } = singleBet;
                const selectionWithBoost = this.lifeSpanState.lifeSpanSocketState.selectionsWithAvailableBoosts(
                    legs[0]?.id
                );

                const arrayLegs = legs.map((singleLeg) => {
                    const { market, selection, event, price, sport } = singleLeg;
                    return {
                        marketId: market?.id,
                        marketName: market?.name,
                        selectionId: selection?.id,
                        selectionName: selection?.name,
                        betEventId: event.id,
                        betEventName: event.name,
                        price: price?.d,
                        sportId: sport?.id,
                        sportName: sport?.name,
                    };
                });

                return {
                    betId: id,
                    type: type,
                    totalStake: totalStake,
                    totalStakeCurrency: this.sdk.currency,
                    legs: arrayLegs,
                    isBoostVisible: selectionWithBoost?.selectionInfo?.reward === undefined ? false : true,
                    isBoosted:
                        selectionWithBoost?.selectionInfo?.reward === undefined
                            ? false
                            : selectionWithBoost.selectionInfo.reward.active,
                };
            });

            this.onGoogleTagManagerBetPlacedTag.trigger(arrayBets);
            this.onPlaceBetSuccess(response.data, isRab);
        }
        if (response !== null && response.status === 'error' && response.data !== null && response.data !== undefined) {
            this.placeBetStatus = 'ERROR';
            for (const bet of response.data.bets) {
                if (bet.errors !== undefined && bet.errors.length > 0) {
                    const idNum = parseInt(bet.id, 10);
                    if (!isNaN(idNum)) {
                        await this.legsState.betslipData.onUpdateError(idNum, bet.errors);
                    }
                }
            }
        }
        if (response !== null && response.status === 'error' && response.data === undefined) {
            this.placeBetStatus = 'ERROR';

            const debug = response.debug ?? null;
            if (typeof debug === 'string') {
                this.setPlaceBetErrorMsg('INTERNAL_SERVER_ERROR_MESSAGE');
            } else {
                const errors = debug === null ? [] : debug.errors;
                const firstError = errors[0] ?? null;
                const firstErrorCode = firstError === null ? null : firstError.code;
                for (const error of errors) {
                    if (firstErrorCode === 'bet-stake-too-high') {
                        if (this.basicBetSlipState.isShowQuickBet) {
                            this.onRedirectToBetslip();
                        }
                        if (
                            error.code === 'bet-stake-too-high' &&
                            error.details !== undefined &&
                            error.details?.maxStakePerLine !== undefined &&
                            error.details.maxStakePerLine !== null &&
                            error.details.maxStakePerLine > 0
                        ) {
                            this.betTooHighValue = this.configComponents.precision.newFromOld(
                                error.details.maxStakePerLine
                            );
                            this.setPlaceBetErrorMsg('BET-TOO-HIGH');
                        } else if (
                            error.code === 'bet-stake-too-high' &&
                            error.details !== undefined &&
                            error.details?.maxStakePerLine !== undefined &&
                            error.details.maxStakePerLine === 0
                        ) {
                            this.betTooHighValue = this.configComponents.precision.newFromOld(
                                error.details.maxStakePerLine
                            );
                            this.setPlaceBetErrorMsg('MAX-BET-EXCEEDED');
                        } else if (error.code === 'bet-stake-too-high') {
                            this.betTooHighValue = null;
                            this.setPlaceBetErrorMsg('BET-TOO-HIGH');
                        }
                        if (error.code === 'bet-exceeds-max-payout') {
                            this.setPlaceBetErrorMsg('PAYOUT-TOO-HIGH');
                        }
                    }
                    if (error.code === 'minimum') {
                        this.setPlaceBetErrorMsg('BET-TOO-LOW');
                    }
                }
                for (const errorKey in response.errors) {
                    if (errorKey === 'potentialPayout') {
                        this.setPlaceBetErrorMsg('PAYOUT-TOO-HIGH');
                    }
                }
            }
        }

        //TODO - If the code is placed in this branch (e.g. because of error 500) then the ui freezes with the loading on the button
    };

    private convertSimpleToBets = (
        legs: Array<LegType>,
        combinations: Array<CastBetType>
    ): Array<NewTradingPostBetData> => {
        const bets = [];
        const legCombinations = [];

        for (const leg of legs) {
            if (leg.selectionId === null) {
                continue;
            }
            bets.push(
                Object.assign({
                    id: `${leg.selectionId}`,
                    legs: [
                        {
                            type: 'standard',
                            selection: { id: leg.selectionId },
                            market: { id: leg.marketId },
                            event: { id: leg.eventId },
                            price: leg.price,
                            priceType: leg.priceType,
                            uuid: leg.uuid,
                        },
                    ],
                })
            );

            legCombinations.push({
                type: 'standard',
                selection: { id: leg.selectionId },
                market: { id: leg.marketId },
                event: { id: leg.eventId },
                price: leg.price,
                priceType: leg.priceType,
                uuid: leg.uuid,
            });
        }

        for (const combination of combinations) {
            bets.push({
                id: `all${combination.type}`,
                legs: legCombinations,
            });
        }
        const newBets = this.possibleBetsRequestState.corePossibleBetsResponse?.bets ?? [];
        const mergedBets: Array<NewTradingPostBetData> = [];

        for (const newBet of newBets) {
            for (const bet of bets) {
                if (bet.id === newBet.id) {
                    newBet.legs = bet.legs;
                    //overwrite legs from possibleBetsResponse, to place bet need add more details of legs
                    //@ts-expect-error
                    mergedBets.push(newBet);
                }
            }
        }

        return mergedBets;
    };

    @action public clearError = (): void => {
        this.betTooHighValue = null;
        this.responseErrorMsgInner = [];
    };
    @action private setPlaceBetErrorMsg = (errorMsg: ErrorMsgType): void => {
        this.responseErrorMsgInner.push(errorMsg);
    };

    @computed public get responseErrorMsg(): Array<ErrorMsgType> {
        const tempErrorMsg = new Set(this.responseErrorMsgInner);
        return Array.from(tempErrorMsg);
    }

    @action public onPlaceBetSuccess = (
        bets: PlaceBetBetsType | Array<SuccessPlaceBetResponseType>,
        isRab?: boolean
    ): void => {
        if (isRab === true) {
            this.rabState?.clearAllRabData();
            this.possibleBetsRequestState.prevCorePossibleBetsResponse = null;
        } else {
            this.onCleanAll();
            if (this.rabState !== null) {
                this.rabState.removeAllFromBetslip();
            }
        }

        if (bets.length > 0) {
            let totalStake: Amount = new Amount('0');
            let totalPotentialReturns: Amount | null = new Amount('0');
            const isSp = bets.some((bet) => bet.legs.some((leg) => leg.priceType === 'sp'));

            bets.forEach(
                (bet) => (totalStake = totalStake.add(this.configComponents.precision.newFromAnything(bet.totalStake)))
            );

            if (isSp) {
                totalPotentialReturns = null;
            } else {
                for (const bet of bets) {
                    if (bet.potentialReturns !== undefined && bet.potentialReturns !== null) {
                        totalPotentialReturns = totalPotentialReturns.add(new Amount(bet.potentialReturns));
                    }
                }
            }

            const betsDetails: Array<BetDetailsLegsType> = [];
            for (const bet of bets) {
                if (bet.type === 'SGL' && bet.id !== undefined && bet.id !== null) {
                    const firstLeg = bet.legs[0] ?? null;
                    if (firstLeg !== null) {
                        const marketName =
                            'market' in firstLeg &&
                            firstLeg.market !== undefined &&
                            firstLeg.market !== null &&
                            'name' in firstLeg.market
                                ? firstLeg.market.name
                                : 'Market name';
                        const selectionName =
                            'selection' in firstLeg && firstLeg.selection !== undefined
                                ? firstLeg.selection.name
                                : 'Selection name';
                        betsDetails.push({
                            potentialReturns: bet.potentialReturns ?? null,
                            stake: bet.stakePerLine,
                            betId: bet.id,
                            priceBet: firstLeg.price?.f ?? null,
                            eventName: firstLeg.event.name,
                            marketName,
                            selectionName,
                        });
                    }
                }
            }

            this.betReceiptSummary = {
                totalStake: totalStake,
                totalPotentialReturns: totalPotentialReturns ?? new Amount('0'),
                // @ts-expect-error eslint-disable-next-line
                isFreeBet: bets.some((bet) => bet.freebetCredits?.length > 0),
                details: {
                    legs: betsDetails,
                },
            };
        } else {
            this.betReceiptSummary = null;
        }

        setTimeout(() => {
            this.betReceiptSummary = null;
        }, 3000);
    };

    @action public onClearMessage = (): void => {
        this.betReceiptSummary = null;
    };

    @computed public get totalStakeRab(): Amount {
        let sum = new Amount('0');

        for (const item of this.possibleBetsRequestState.coreRabPossibleBetsResponse) {
            const stakePerLine = item.stakePerLine ?? 0;
            sum = sum.add(this.configComponents.precision.newFromAnything(stakePerLine));
        }

        return sum;
    }

    @computed public get payoutRab(): Amount {
        let sum = new Amount('0');

        for (const cur of this.possibleBetsRequestState.coreRabPossibleBetsResponse) {
            const payout = cur.payout ?? 0;
            //TODO: roundDown to remove after fix BE service. Currently, we receive values like 7.123(99)
            const payoutAmount = new Amount(payout.toString()).roundDown(2);

            sum = sum.add(payoutAmount);
        }

        return sum;
    }

    @computed public get totalStake(): Amount {
        return this.totalStakeClassic;
    }

    @computed private get totalStakeClassic(): Amount {
        let everyPossibleBet = [];
        everyPossibleBet =
            this.referralState.referralData === null
                ? this.possibleBetsRequestState.castBets
                : this.referralState.totalStake;
        let totalStake = new Amount('0');

        for (const bet of everyPossibleBet) {
            const stake =
                bet.stakePerLine === null
                    ? new Amount('0')
                    : this.configComponents.precision.newFromAnything(bet.stakePerLine);

            let multiplier = bet.eachWay !== null && bet.eachWay === true ? 2 : 1;
            multiplier *= bet.numLines === null ? 1 : bet.numLines;
            totalStake = totalStake.add(stake.multiply(multiplier));
        }

        return totalStake.add(this.totalStakeRab);
    }

    @computed public get potentialReturn(): Amount {
        if (this.referralState.referralData === null) {
            const potentialReturnCombinations = this.combinationState.forPotentialReturn;

            const potentialReturnLegs = this.legsState.forPotentialReturn;

            return potentialReturnCombinations.add(potentialReturnLegs.add(this.payoutRab));
        } else {
            return this.referralState.totalPotentialReturn;
        }
    }

    @computed public get requiredToBetAmount(): Amount | null {
        if (this.basicBetSlipState.betSlipPlayableBalanceAmounts !== null) {
            const { requiredAmount, currentAmount } = this.basicBetSlipState.betSlipPlayableBalanceAmounts;
            if (
                requiredAmount !== null &&
                requiredAmount !== undefined &&
                currentAmount !== null &&
                currentAmount !== undefined
            ) {
                return this.configComponents.precision.newFromAnything(requiredAmount - currentAmount);
            }
        }
        return null;
    }

    @computed public get isPlayableBalanceWarning(): boolean {
        return this.requiredToBetAmount !== null;
    }

    @computed public get isSinglesOnlyWarning(): boolean {
        return this.basicBetSlipState.betSlipSinglesOnly;
    }

    @computed private get rabValues(): RabItemState[] {
        return this.rabState === null ? [] : this.rabState.activeBets;
    }

    @computed private get legsIds(): Array<BetslipSingleId> {
        return this.getLegsIds();
    }

    @computed private get placeBetStatusIsNull(): boolean {
        return (
            this.basicBetSlipState.placeBetStatus === null || this.basicBetSlipState.placeBetStatus !== 'IN_PROGRESS'
        );
    }

    @computed public get isLegsChangedWarning(): boolean {
        if (this.referralState.isReferred) {
            return false;
        }
        let anyLegChanged = false;
        let anyRabLegChanged = false;

        this.rabValues.map((rabVal: RabItemState) => {
            if (rabVal.selectionChanged) {
                anyRabLegChanged = true;
            }
        });

        for (const legId of this.legsIds) {
            const leg = legId.getModel();
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (leg !== null) {
                if (anyLegChanged === false) {
                    anyLegChanged = leg.selectionChanged && this.placeBetStatusIsNull;
                }
            }
        }
        return anyLegChanged || anyRabLegChanged;
    }

    @computed private get isDisabledBettingButton(): boolean {
        return (
            this.isLegsChangedWarning ||
            this.referralState.isReferredBetMessage ||
            this.referralState.isRejectedByTrader ||
            this.referralState.isRejectedByCustomer ||
            this.referralState.isBetSlipOffer ||
            this.totalStake.isEqualWith(new Amount('0')) ||
            this.legsState.isError ||
            this.combinationState.isError
        );
    }

    @computed public get betReceipt(): BetReceiptType | null {
        return this.betReceiptSummary;
    }

    @computed public get balanceAfter(): Amount {
        const { balance } = this.basicBetSlipState.userData;

        const amount = balance === null ? new Amount('0') : balance;

        if (this.betslipData.hasSelectedFreeBets()) {
            return amount;
        }

        return amount.sub(this.totalStake);
    }

    @computed public get isAnyNA(): boolean {
        const referralWithSP = (this.referralState.referralData === null ? [] : this.referralState.totalStake).some(
            (elem) => elem.priceType === 'sp'
        );
        const castBetsWithoutPotentialReturns = (
            this.referralState.referralData === null ? this.possibleBetsRequestState.castBets : []
        ).some((elem) => elem.potentialReturns === null);

        return referralWithSP || castBetsWithoutPotentialReturns;
    }
}
