import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { DateInputState, DateInput } from 'src/domains/players/webview/components/form/DateInput';
import { DateInputWrapper, InputDescription } from './DateOfBirth.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SignupType } from 'src/domains/layouts/config/features/types';

interface PropsType {
    dateState: DateInputState;
    signupType: SignupType;
}

export const DateOfBirth = observer('DateOfBirth', (props: PropsType) => {

    const { dateState, signupType } = props;

    return (
        <DateInputWrapper>
            <DateInput
                label={<I18n langKey='signup.your-details.birth-date' defaultText='Date of birth' />}
                state={dateState}
                showSuccessIcon={signupType !== 'tertiary'}
                isReadOnly={signupType === 'tertiary'}
            />
            {signupType === 'tertiary' ?
                null :
                <InputDescription data-test='input-description'>
                    <I18n langKey='signup.age-restriction' defaultText='Age restriction: It is an offence to gamble if you are under 18' />
                </InputDescription>}
        </DateInputWrapper>
    );

});
