import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface CheckMarkPropsType {
    className?: string;
    onClick?: () => void;
}

export const CheckMark = observer('CheckMark', ({ className, onClick }: CheckMarkPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='none'
            height='12px'
            onClick={onClick}
            viewBox='0 0 12 10'
            width='10px'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.26628 9.21033C4.12735 9.3475 3.93054 9.46181 3.75687 9.46181C3.58321 9.46181 3.3864 9.34178 3.24168 9.20461L0 6.00397L1.03039 4.98662L3.76266 7.6843L10.987 0.5L12 1.53449L4.26628 9.21033Z'
                fill='white'
            />
        </SvgElement>
    );
});
