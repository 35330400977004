import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { CheckCircleOutlineIcon } from 'src/domains/layouts/shared/assets/icons/CheckCircleOutlineIcon';
import { withConfig } from 'src/withConfig';
import { FreeBetIcon } from 'src/domains/layouts/shared/assets/icons/FreeBetIcon';

const fadeInReceipt = keyframes`
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 400px;
        opacity: 1;
    }
`;

export const BetslipReceiptWrapper = withConfig(theme => styled('div')`
    animation: 0.4s ${fadeInReceipt} 1;
    background-color: ${theme.star.betSlipReceipt.bgColor};
    color: ${theme.star.betSlipReceipt.txtColor};
    height: 100%;
    padding: 20px 0;
    text-align: center;
    width: 100%;
`);

export const BetslipReceiptHeader = withConfig(theme => styled('header')`
    font-size: ${theme.star.fontSize.medium};
    font-weight: ${theme.star.fontWeight.bold};
    margin-bottom: 28px;
`);

export const BetslipReceiptIconWrapper = styled(CheckCircleOutlineIcon)`
    display: block;
    fill: currentcolor;
    margin: 4px auto;
    width: 36px;
`;

export const BetslipReceiptGiftIconWrapper = styled(FreeBetIcon)`
    fill: currentcolor;
    margin: 0 5px 0 0;
    position: relative;
    top: 2px;
    width: 14px;
`;

export const Text = withConfig(theme => styled('p')`
    color: ${theme.star.betSlipReceipt.txtColorQuaternary};
    line-height: 1.5;
    margin: 0 25px;
`);

export const ListWrapper = styled('ul')`
    margin: 25px 0;
    text-align: left;
`;

export const ListElement = withConfig(theme => styled('li')`
    color: ${theme.star.betSlipReceipt.txtColorTertiary};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.4;
    padding: 8px 10px;
`);

export const ReceiptValue = withConfig(theme => styled('span')`
    float: right;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    margin-top: -3px;
`);
