export const checkIsSmallLetter = (char: string): boolean => {
    return 'a' <= char && char <= 'z';
};

export const checkIsCapitalLetter = (char: string): boolean => {
    return 'A' <= char && char <= 'Z';
};

export const checkIsLetter = (char: string): boolean => {
    return checkIsSmallLetter(char) || checkIsCapitalLetter(char);
};

export  const checkIsNumericCharacter = (char: string): boolean => {
    return '0' <= char && char <= '9';
};

export const checkIsSpecialCharacter = (allowedSpecialChar: Array<string>, char: string): boolean => {
    return allowedSpecialChar.includes(char);
};
