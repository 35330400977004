import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const VerifyAccountWrapper = styled('div')`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
`;

export const VerifyAccountHeaderWrapper = withConfig(theme => styled('header')`
    width: 100%;
    &::after {
        background-color: ${theme.star.verifyAccountTab.bgColor};
        content: '';
        display: block;
        height: 1px;
        margin: 0 auto;
        width: 100px;
    }
`);

export const VerifyAccountHeader = styled('h1')`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3;
    margin: 16px 0 14px;
    text-align: center;
    text-transform: uppercase;
`;

export const VerifyAccountHeaderWelcome = styled('strong')`
    display: block;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 6px;
`;

interface VerifyAccountTextProps {
    left?: boolean;
    info?: boolean;
}

export const VerifyAccountText = styled('p')<VerifyAccountTextProps>`
    font-size: ${({ info }): string => info === undefined ? '14px' : '12px'};
    line-height: 1.3334;
    text-align: ${({ left }): string => left === undefined ? 'center' : 'left'};
    margin: 16px 0;
    padding: 0 10px;
    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const VerifyAccountInfoWrapper = withConfig(theme => styled('section')`
    background-color: ${theme.star.verifyAccountTab.bgColorTertiary};
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3334;
    margin: 35px 0 0 0;
    padding: 16px;
    text-align: left;
`);

export const VerifyAccountInfoHeader = withConfig(theme => styled('h2')`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.medium};
    text-align: center;
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
`);

export const VerifyInformationStyle = withConfig(theme => styled('div')`
    margin: 16px 0;
    background-color: ${theme.star.verifyAccountTab.bgColorSecondary};
    color: ${theme.star.verifyAccountTab.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.3334;
    text-align: center;
    padding: 16px;
`);

export const SendAgainStyle = withConfig(theme => styled('a')`
    color: ${theme.star.verifyAccountTab.txtColorSecondary};
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;
`);
