import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const LineChartSvg = styled('svg')`
    display: block;
    flex: 0 0 100%;
    height: auto;
    width: 100%;
`;

/* Chart background color */
export const PathArea = styled('path')`
    display: none; 
`;

export const PathLine = withConfig(theme => styled('path')`
    fill: transparent;
    stroke-width: 1px;
    stroke: ${theme.star.lineChart.bgColorSecondary};
`);


export const Circle = withConfig(theme => styled('circle')`
    fill: ${theme.star.lineChart.bgColor};
    stroke-width: 3px;
    stroke: ${theme.star.lineChart.borderColor};
`);

export const Text = withConfig(theme => styled('text')`
    fill: ${theme.star.lineChart.txtColor};
    font-size: ${theme.star.fontSize.small};
    text-anchor: middle;
`);

