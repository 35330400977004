import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';

export const LogoLabel = styled('span')`
    position: absolute;
    text-indent: -9999rem;
`;

export const LogoWrapper = withConfig(theme => styled('div')`
    display: flex;
    flex: 1 0 auto;
    @media ${theme.star.mediaQuery.largeDesktop} {
        padding-left: 16px;
        flex: 0 0 200px;
    }
`);

export const LogoLink = styled(Link)`
    cursor: pointer;
`;
