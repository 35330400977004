import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import styled from '@emotion/styled';
import { CheckCircleOutlineIcon } from 'src/domains/layouts/shared/assets/icons/CheckCircleOutlineIcon';
import { withConfig } from 'src/withConfig';

export const Wrapper = withConfig(theme => styled('div')`
    padding: 40px 16px;
    text-align: center;
    font-size: ${theme.star.fontSize.xRegular};
`);

export const CheckIcon = withConfig(theme => styled(CheckCircleOutlineIcon)`
    width: 48px;
    fill: ${theme.star.statuses.success.bgColor};
    margin-bottom: 8px;
`);

export const Title = withConfig(theme => styled('h2')`
    font-size: ${theme.star.fontSize.medium};
    line-height: 1.3334;
    margin: 0 0 8px 0;
`);

export const PrimaryDescription = styled('p')`
    line-height: 1.3334;
    margin: 0 0 40px 0;
`;

export const LinkButton = styled(Button)`
    width: 100%;
    margin-bottom: 8px;
`;

export const AccountHelperContainer = withConfig(theme => styled('div')`
    background-color: ${theme.star.accountDrawer.bgColor};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`);
