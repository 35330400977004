import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { StreamingState } from 'src/domains/sportsbook/state/streamingState/StreamingState';
import { RacingStreamGlobalPropsTypes } from './RacingStreamGlobal';

export class RacingStreamGlobalState {
    public constructor(
        public readonly props: RacingStreamGlobalPropsTypes,
        public readonly streamingState: StreamingState,
    ) {}

    public runObserve = (): void => {
        const { isStreamFloating } = this.streamingState;
        const innerWidth = getWindowInnerWidth();
        const isMobile = innerWidth !== null && innerWidth < 1024;
        const isRaceCard = this.props.isRaceCard;
        const allCasinos = this.props.isAnyCasinoPage;

        if (isMobile === false && isRaceCard === false && isStreamFloating === false) {
            this.streamingState.closeStream();
            return;
        }
    
        if (isMobile === false && isRaceCard === false && isStreamFloating && allCasinos) {
            this.streamingState.closeStream();
            return;
        }
    
        if (isMobile && isRaceCard === false && isStreamFloating === false && allCasinos) {
            this.streamingState.closeStream();
            return;
        }
    }
}
