/* eslint-disable import/no-relative-parent-imports */
import styled from '@emotion/styled';
import { CloseIcon } from '../../shared/assets/icons';

export const Wrapper = styled('div')`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Close = styled(CloseIcon)`
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
    fill: currentcolor;
    width: 40px;
`;

export const Container = styled('div')`
    max-width: 600px;
    width: 100%;

    & > div {
        background: transparent;
    }
`;
