import { InputWrapper } from 'src/domains/players/webview/components/form/input/Input.style';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface PropsType {
    isError?: boolean;
}

type DateInputsWrapperType = Pick<PropsType, 'isError'>;

export const DateInputsWrapper = withConfig(theme => styled('div')<DateInputsWrapperType>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 0;
    background-color: ${theme.star.form.dark.bgColor};
    border: 1px solid transparent;
    border-radius: 0;
    &:focus-within {
        border: 1px solid ${({ isError }): string => isError === true ? theme.star.statuses.error.bgColor : theme.star.form.borderColor};
    }
`);

export const DatePartInputWrapper = withConfig(theme => styled('div')`
    flex: 0 0 33.33%;
    border-right: solid 1px ${theme.star.form.borderColorSecondary};
    :last-of-type {
        border: none;
    }
`);

export const DatePart = withConfig(theme => styled('input')`
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
    appearance: textfield;
    width: 100%;
    border: none;
    text-align: left;
    color: ${theme.star.form.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.43;
    padding: 0 12px;
    &::placeholder {
        color: ${theme.star.form.txtColorSecondary};
    }
    &:invalid {
        color: ${theme.star.statuses.error.bgColor};
    }

    &:-webkit-autofill {
        -webkit-text-fill-color: ${theme.star.form.txtColor};
        box-shadow: 0 0 0 100px ${theme.star.form.dark.borderColor} inset;
    }
    &:autofill {
        -webkit-text-fill-color: ${theme.star.form.txtColor};
        filter: none;
    }
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-text-fill-color: ${theme.star.form.txtColor};
        box-shadow: 0 0 0 100px ${theme.star.form.dark.borderColor} inset;
    }
    &:autofill:hover,
    &:autofill:focus,
    &:autofill:active {
        -webkit-text-fill-color: ${theme.star.form.txtColor};
        filter: none;
    }
`);

export const InputLabel = withConfig(theme => styled('label')`
    color: ${theme.star.form.dark.txtColor};
    display: block;
    line-height: 1;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    margin-bottom: 6px;
`);

const styleDisabledInput = (disabled?: boolean): string | undefined => {
    if (disabled === true) {
        return `
            cursor: not-allowed;
            opacity: .4;
            user-select: none;
        `;
    }
    return;
};

interface DateInputPropsType {
    isDisabled?: boolean;
    isError?: boolean;
}

export const DateInputWrapper = styled(InputWrapper)<DateInputPropsType>`
    ${({ isDisabled }): string | undefined => styleDisabledInput(isDisabled)};
`;

export const CheckInputIconWrapper = withConfig(theme => styled(CheckSingleIcon)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    margin: auto 0;
    width: 16px;
    fill: ${theme.star.statuses.success.bgColor};
`);
