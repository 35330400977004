import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { css, SerializedStyles } from '@emotion/react';

const checkboxWrapper = (theme: EmotionTheme, signUpCheckboxTC: boolean): SerializedStyles => {
    if (signUpCheckboxTC === true) {
        return css`
            margin-top: 16px;
        `;
    }

    return css`
        border: 1px solid ${theme.star.signUp.borderColor};
        color: ${theme.star.signUp.txtColor};
        font-size: ${theme.star.fontSize.small};
        line-height: 1.3334;
        margin-top: 24px;
        padding: 16px;
    `;
};

interface InfoWrapperType {
    signUpCheckboxTC: boolean;
}
export const InfoWrapper = withConfig(theme => styled('div') <InfoWrapperType>`
    ${({ signUpCheckboxTC }): SerializedStyles => checkboxWrapper(theme, signUpCheckboxTC)};
`);

export const TermsButton = withConfig(theme => styled('button')`
    background-color: transparent;
    border: 0;
    color: ${theme.star.signUp.termsAndConditions.txtColor};
    cursor: pointer;
    font-weight: ${theme.star.fontWeight.bold};
    padding: 0;
    text-decoration: none;
`);


export const InfoContentWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.staticPage.bgColor};
    color: ${theme.star.layoutGlobal.txtColor};
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.above};
    height: 100%;
    overflow: auto;
`);

export const InfoContentTitle = withConfig(theme => styled('div')`
    align-items: center;
    background-color: ${theme.star.signUp.bgColor};
    color: ${theme.star.signUp.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    height: 48px;
    justify-content: space-between;
    padding: 16px;
    text-transform: uppercase;
`);

export const InfoContent = withConfig(theme => styled('div')`
    padding: 20px;
    color: ${theme.star.form.txtColor};
`);

export const CloseButton = styled('button')`
    background-color: transparent;
    border: 0;
    height: 20px;
    outline: none;
    padding: 0;
    position: relative;
    width: 20px;
`;

export const StyledCloseIcon = withConfig(theme => styled(CloseIcon)`
    cursor: pointer;
    fill: ${theme.star.signUp.termsAndConditions.bgColor};
    width: 20px;
`);
export const CheckboxWrapper = styled('div')`
    margin-bottom: 8px;
`;
