import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const From = styled('form')`
    margin-bottom: 20px;
`;

export const Description = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.43;
    margin: 8px 0 24px 0;
`);

export const QuestionsList = styled('ul')``;

export const Question = styled('li')``;

export const QuestionTitle = withConfig(theme => styled('h5')`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0 0 6px 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
`);

export const SaveButton = styled(Button)`
    width: 100%;
`;
