import styled from '@emotion/styled';
import { FreeBetIcon } from 'src/domains/layouts/shared/assets/icons';
import { withConfig } from 'src/withConfig';

export const Tabs = styled.div`
    display: flex;
    margin-bottom: 20px;
    padding: 8px;
`;

interface TabButtonProps {
    isActive: boolean;
}

export const TabButton = withConfig(
    (theme) => styled.button<TabButtonProps>`
        padding: 0 12px;
        margin: 0 4px 0 0;
        cursor: pointer;
        border: 1px solid #ffcc00;
        background-color: ${({ isActive }) => (isActive ? '#FFCC00' : '#ffffff')};
        color: ${({ isActive }) => (isActive ? '#111111' : '#FFCC00')};
        font-weight: ${theme.star.fontWeight.bold};
        font-size: ${theme.star.fontSize.small};
        transition: background-color 0.3s;
        height: 32px;
    `
);

export const TabContent = styled.div`
    margin-top: 20px;
    padding: 0 10px;
`;

export const TotalAmount = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

export const TotalAmountTitle = withConfig(
    (theme) => styled.div`
        font-size: ${theme.star.fontSize.small};
    `
);

export const TotalAmountSum = withConfig(
    (theme) => styled.span`
        font-weight: ${theme.star.fontWeight.bold};
        font-size: ${theme.star.fontSize.xRegular};
    `
);

export const FreeBetIconWrapped = styled(FreeBetIcon)`
    width: 16px;
    height: 16px;
`;

export const AmountWrapper = styled('span')`
    display: flex;
    align-items: center;
    gap: 6px;
`;
