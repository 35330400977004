import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface PropsType {
    betslipNew?: boolean;
    isQuickBet?: boolean;
    visibleKeyboard?: boolean;
}

export const ButtonsWrapper = styled('div')<PropsType>`
    display: flex;
    justify-content: space-between;
`;

type ButtonWrapperType = Pick<PropsType, 'betslipNew'>;

export const ButtonWrapper = styled('div')<ButtonWrapperType>`
    ${({ betslipNew }): string => betslipNew === true ? '' : 'padding: 0 0 8px 0'};
`;

type PayoutRulesWrapperType = Pick<PropsType, 'isQuickBet'>;

export const PayoutRulesWrapper = withConfig(theme => styled('div')<PayoutRulesWrapperType>`
    color: ${theme.star.betslipGeneral.txtColor};
    font-size: 9px;
    display: flex;
    padding: 0 8px;
    line-height: 1.8;
    white-space: nowrap;

    ${({ isQuickBet }): string => isQuickBet === true ? `
        padding: 2px;
        font-size: 9px;
        line-height: 1.3;
        margin: 5px 0;
    ` : ''}
`);

export const MoreInfo = withConfig(theme => styled('div')`
    color: ${theme.star.betSlipTooltip.bgColor};
    cursor: pointer;
`);

const renderButtons = (visibleKeyboard?: boolean): SerializedStyles => {
    if (visibleKeyboard === true) {
        return css`
            bottom: 201px;
        `;
    }

    return css `
        bottom: 0;
        position: relative;
    `;
};

type BetButtonsWrapperType = Pick<PropsType, 'visibleKeyboard'>;

export const BetButtonsWrapper = withConfig(theme => styled('div')<BetButtonsWrapperType>`
    ${({ visibleKeyboard }): SerializedStyles => renderButtons(visibleKeyboard)};
    background: ${theme.star.betslipGeneral.bgColor};
    width: 100%;
    left: 0;
    right: 0;
    padding: 8px 0;
    @media ${theme.star.mediaQuery.desktop} {
        position: relative;
        padding: 0 0 8px 0;
    }
`);

export const BetNowWrapper = styled('div')`
    text-transform: uppercase;
`;

export const SpanTop = withConfig(theme => styled('span')`
    display: block;
    font-size: ${theme.star.fontSize.small};
`);

export const SpanBottom = styled('span')`
    display: block;
`;
export const EnterStakeButtonSpan = withConfig(theme => styled('span')<PropsType>`
    display: block;
    font-size: ${(): string => theme.star.fontSize.regular};
    padding: 0 12px;
`);
