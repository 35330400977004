import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface RabSelectionType {
    isSelect: boolean;
    borderLeft: boolean | undefined;
    borderRight: boolean | undefined;
}

export const SelectionRabListItem = withConfig(theme => styled('li') <RabSelectionType>`
    background: ${({ isSelect }): string => isSelect ? theme.star.rabSelection.bgColorTertiary : theme.star.selectionsGroup.bgColor};
    border: 1px solid ${theme.star.selectionsGroup.borderColor};
    border-left: ${({ borderLeft }): string => borderLeft === true ? `1px solid ${theme.star.selectionsGroup.borderColor}` : 'none'};
    border-right: ${({ borderRight }): string => borderRight === false ? 'none' : `1px solid ${theme.star.selectionsGroup.borderColor}`};
    border-top: none;
    ${({ isSelect }): string => isSelect ? `color: ${theme.star.rabSelection.txtColor}` : ''};
    cursor: pointer;
    display: flex;
    flex: 1 1 0%;
    font-weight: ${({ isSelect }): string => isSelect ? theme.star.fontWeight.bold : theme.star.fontWeight.regular};
    justify-content: space-between;
    position: relative;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.base};
    &:first-of-type {
        left: ${({ borderLeft }): string => borderLeft === true ? '1px;' : '0;'};
    }
`);

interface SelectionRabListItemNameType {
    marketTemplateType: string | undefined;
}

const renderNamesInColumn = (marketTemplateType: string | undefined): SerializedStyles => {
    if (marketTemplateType === 'WinningMargin' || marketTemplateType === 'HalfWinningMargin') {
        return css`
            flex-direction: column;
            align-items: start;
        `;
    }
    return css``;
};

export const SelectionRabListItemName = styled('span')<SelectionRabListItemNameType>`
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    padding: 11px 10px;
    line-height: 1.5;
    ${({ marketTemplateType }): SerializedStyles => renderNamesInColumn(marketTemplateType)};
`;

interface SelectItemType {
    isSelect: boolean;
}

export const SelectItem = withConfig(theme => styled('div') <SelectItemType>`
    align-self: center;
    height: 16px;
    position: relative;
    width: 16px;
    &::before,
    &::after {
        background-color: ${theme.star.rabSelection.bgColor};
        bottom: 0;
        content: '';
        height: 2px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        transition: transform .2s ease;
        width: 16px;
    }
    ${({ isSelect }): SerializedStyles => isSelect ? css`
        width: 16px;
        &::before,
        &::after {
            background-color: ${theme.star.rabSelection.bgColorSecondary};
            width: 16px;
        }
        &::before {
            left: -12px;
            top: 6px;
            transform: rotate(-135deg);
            width: 8px;
        }
        &::after {
            left: 2px;
            transform: rotate(-45deg);
            width: 16px;
        }
        ` : css`
            &::after {
                transform: rotate(90deg);
            }
    `}
`);

export const Selection = withConfig(theme => styled('div')`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    width: 48px;
    &::before {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
    @media ${theme.star.mediaQuery.tablet} {
        width: 63px;
    }
`);

export const SelectionRabListItemCutName = styled('span')`
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 190px;
    margin-right: 5px;
    white-space: nowrap;
`;
