import styled from '@emotion/styled';
import { AccountNavigationLink } from 'src/domains/layouts/webview/components/accountNavigationLink/AccountNavigationLink';
import { withConfig } from 'src/withConfig';

export const FooterList = styled('nav')`
    display: flex;
    flex-flow: column nowrap;
    &:not(:last-of-type) {
        margin-bottom: 16px;
    }
`;

export const LinkWrapper = withConfig(theme => styled(AccountNavigationLink)`
    align-self: flex-start;
    color: ${theme.star.footerMain.txtColorSecondary};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3333;
    text-decoration: none;
    transition: color .2s ease;
    &:not(:last-of-type) {
        margin-bottom: 16px;
    }
    &:hover {
        color: ${theme.star.footerMain.txtColorQuinary};
    }
`);
