import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const DateInputWrapper = styled('div')``;

export const InputDescription = withConfig(theme => styled('span')`
    display: block;
    font-size: ${theme.star.fontSize.small};
    letter-spacing: -0.2px;
    line-height: 1.3334;
    opacity: .7;
`);
