import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface IconPropsType {
    className?: string;
    onClick?: () => void;
}

export const RomanianFlagIcon = observer(
    'RomanianFlagIcon',
    ({ className, onClick }: IconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='none'
                onClick={onClick}
                width='24' height='24' viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <g clipPath='url(#RomanianFlagIconIdA)'>
                    <rect width='24' height='24' fill='#FFDA2C' rx='12'/>
                    <path fill='#1A47B8' fillRule='evenodd' d='M-4.8 0H6.4v24H-4.8V0Z' clipRule='evenodd'/>
                    <path fill='#F93939' fillRule='evenodd' d='M17.6 0h11.2v24H17.6V0Z' clipRule='evenodd'/>
                </g>
                <defs>
                    <clipPath id='RomanianFlagIconIdA'>
                        <rect width='24' height='24' fill='#fff' rx='12'/>
                    </clipPath>
                </defs>
            </SvgElement>
        );
    }
);
