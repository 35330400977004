import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface HeartFilledIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const HeartFilledIcon = observer('HeartFilledIcon', ({ className, onClick }: HeartFilledIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M21.195 3.675c-2.136-2.226-5.605-2.226-7.74 0L12 5.18 10.546 3.67c-2.142-2.225-5.605-2.225-7.742 0C.4 6.171.4 10.232 2.804 12.733L12 22.309l9.195-9.57c2.407-2.502 2.407-6.563 0-9.064z'
                fill='fill'
                fillRule='evenodd'
            />
        </SvgElement>
    );
});
