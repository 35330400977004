import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const BetSummaryWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const AmountTitle = withConfig(theme => styled('span')`
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    color: ${theme.star.betslipSummary.txtColor};
    margin-right: 4px;
`);
