import styled from '@emotion/styled';
import { CasinoIcon } from 'src/domains/layouts/shared/assets/icons/CasinoIcon';
import { withConfig } from 'src/withConfig';

export const HeaderWrapper = withConfig(theme => styled('header')`
    align-items: center;
    background-color: ${theme.star.casino.bgColor};
    position: relative;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: space-between;
    padding: 8px 8px 8px 16px;
    width: 100%;
    @media ${theme.star.mediaQuery.desktop} {
        bottom: 0;
        margin: 0 10px;
        padding: 8px;
        position: relative;
        transform: none;
        transition: none;
        width: calc(100% - 20px);
    }
`);

export const HeaderTitle = withConfig(theme => styled('span')`
    color: ${theme.star.casino.txtColor};
    padding-left: 20px;
`);

export const CasinoIconWrapper = withConfig(theme => styled(CasinoIcon)`
    fill: ${theme.star.casino.casinoMini.bgColorTertiary};
    position: absolute;
    width: 15px;
`);
