import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

const setSwitchWrapper = (theme: EmotionTheme, version: 'primary' | 'secondary', switched: boolean): SerializedStyles => {
    if (version === 'primary') {
        if (switched) {
            return css`
                background: ${theme.star.switchButton.primary.bgColorTertiary};
            `;
        }
        return css`
            background: ${theme.star.switchButton.primary.bgColorSecondary};
        `;
    }

    if (switched) {
        return css`
            background: ${theme.star.switchButton.secondary.bgColorTertiary};
        `;
    }
    return css`
        background: ${theme.star.switchButton.secondary.bgColorSecondary};
    `;
};

interface SwitchWrapperPropsType {
    switched: boolean;
    version: 'primary' | 'secondary';
};

export const SwitchWrapper = withConfig(theme => styled('div')<SwitchWrapperPropsType>`
    ${({ version, switched }): SerializedStyles => setSwitchWrapper(theme, version, switched)};
    border-radius: 5px;
    cursor: pointer;
    flex: 0 0 32px;
    height: 8px;
    margin-right: 12px;
    position: relative;
    transition: background-color .2s ease;
    width: 32px;
`);

type SwitchCirclePropsType = Pick<SwitchWrapperPropsType, 'switched'>;

export const SwitchCircle = withConfig(theme => styled('div')<SwitchCirclePropsType>`
    background: ${theme.star.switchButton.bgColor};
    border-radius: 50%;
    bottom: 0;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.16));
    height: 24px;
    left: -12px;
    margin: auto 0;
    position: absolute;
    top: 0;
    transform: ${({ switched }): string => switched ? 'translateX(32px)' : 'translateX(0)'};
    transition: transform 0.2s ease;
    width: 24px;
`);
