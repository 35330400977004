import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { fadeIn } from 'src/domains/layouts/shared/Animation.style';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { EmotionTheme } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { withConfig } from 'src/withConfig';

const setTextMessageContainer = (type: 'error' | 'info', theme: EmotionTheme): SerializedStyles => {
    if (type === 'info') {
        return css`
            color: ${theme.star.statuses.text.info.txtColor};
        `;
    }

    return css`
        color: ${theme.star.statuses.text.error.txtColor};
    `;
};

interface TextMessagesPropsType {
    marginBottom?: string;
    marginTop?: string;
    type: 'error' | 'info';
}

export const TextMessageWrapper = withConfig(theme => styled('div')<TextMessagesPropsType>`
    animation: ${fadeIn} .2s ease forwards;
    display: flex;
    flex-flow: row nowrap;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
    margin-bottom: ${({ marginBottom }): string => marginBottom === undefined ? '0' : marginBottom};
    margin-top: ${({ marginTop }): string => marginTop === undefined ? '0' : marginTop};
    position: relative;
    text-align: left;
    ${({ type }): SerializedStyles => setTextMessageContainer(type, theme)};
`);

export const TextMessageContent = styled('div')`
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
`;

const setIcon = css`
    fill: currentcolor;
    flex: 0 0 16px;
    height: 16px;
    margin-right: 8px;
    width: 16px;
`;

export const InfoIconWrapper = styled(InfoIcon)`
    ${setIcon};
`;

export const WarningIconWrapper = styled(WarningIcon)`
    ${setIcon};
`;
