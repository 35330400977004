import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled'; 
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { withConfig } from 'src/withConfig';

interface PreMatchWrapperPropsType {
    bgImage?: string;
}

const renderPreMatchBgImage = (bgImage: string | undefined, theme: EmotionTheme): SerializedStyles => {
    if (bgImage === undefined ) {
        return css`
            &::before {
                background-image: ${theme.star.preMatch.bgColorSecondary};
            }
        `;
    }

    return css`
        &::before {
            background-image: url(${bgImage}), ${theme.star.preMatch.bgColorSecondary};
        }
    `;
};

/* Background container Pre match */
export const PreMatchWrapper = withConfig(theme => styled('div')<PreMatchWrapperPropsType>`
    background-color: ${theme.star.preMatch.bgColor};
    height: 72px;
    margin: auto;
    position: relative;
    text-align: center;
    width: 100%;
    padding: 12px 16px 16px;
    z-index: ${theme.star.zIndexGlobal.base};
    &::before {
        ${positionElementFull};
        background-position: top center;
        background-size: 100% auto;
        content: '';
        position: absolute;
        z-index: ${theme.star.zIndexGlobal.below};
    }
    ${({ bgImage }): SerializedStyles => renderPreMatchBgImage(bgImage, theme)};
    @media ${theme.star.mediaQuery.desktop} {
        padding: 16px 32px 32px;
        height: 128px;
    }
`);

export const PreMatchDate = withConfig(theme => styled('time')`
    color: ${theme.star.preMatch.txtColorSecondary};
    display: block;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1.2;
    margin-bottom: 6px;
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.regular};
        font-weight: ${theme.star.fontWeight.regular};
        line-height: 1.5;
        margin-bottom: 8px;
    }
`);

export const PreMatchTime = withConfig(theme => styled('span')`
    position: relative;
    display: inline-block;
    margin-left: 14px;
    &::before {
        background: currentcolor;
        bottom: 2px;
        content: '';
        left: -8px;
        position: absolute;
        top: 2px;
        width: 1px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        &::before {
            bottom: 5px;
            top: 6px;
        }
    }
`);

export const PreMatchParticipants = styled('div')`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const PreMatchParticipantName = withConfig(theme => styled('span')`
    color: ${theme.star.preMatch.txtColorTertiary};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1.3334;
    flex: 0 0 calc(100% - 22px);
    text-align: right;
    &:last-of-type {
        text-align: left;
    }
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.xMedium};
        line-height: 1.2;
        flex-basis: calc(50% - 40px);
    }
`);

export const Versus = withConfig(theme => styled('div')`
    align-items: center;
    background: ${theme.star.preMatch.bgColorTertiary};
    display: flex;
    flex: 0 0 24px;
    font-weight: ${theme.star.fontWeight.bold};
    height: 24px;
    justify-content: center;
    margin: 0 8px;
    &::before {
        color: ${theme.star.preMatch.txtColor};
        content: 'V';
        font-size: ${theme.star.fontSize.xRegular};
        line-height: 1.4286;
        margin-top: 2px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        flex-basis: 48px;
        height: 48px;
        margin: 0 16px;
        &::before {
            font-size: ${theme.star.fontSize.medium};
            line-height: 1.3334;
            margin-top: 4px;
        }
    }
`);
