import styled from '@emotion/styled';
import { AccountContent } from 'src/domains/players/webview/components/Account/accountParts/Account.style';

export const DateRangeFormWrapper = styled(AccountContent)`
    padding: 0 16px 16px;
`;

export const TransactionHistoryFilterWrapper = styled('div')`
    padding: 0 0 8px 8px;
`;

export const TransactionHistoryFilterWrapperMain = styled('div')`
    padding: 8px 0 8px 8px;
`;
