import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';
import * as React from 'react';
import { AppState } from 'src/appState/AppState';

const Wrap = styled('span')`
    display: inline-block;
    padding: 10px;
    color: blue;
    cursor: pointer;
    &:hover {
        color: red;
        background-color: #e0e0e0;
    }
`;

const Line = styled('div')`
    padding: 5px 0;
    border-top: 1px solid black;
    word-break: break-all;
`;

interface PropsTypes {
    appState: AppState;
}

export const MainViewMain = observer('MainViewMain', ({ appState }: PropsTypes) => {
    const { appLayoutsState, session } = appState;
    const { debugPanel } = appLayoutsState;

    const closePopup = (): void => {
        debugPanel.close();
    };

    const currentJwt = session.currentJwt;
    const userId = session.userId;
    const userCountry = session.userIpCountry;
    const decodedJwtData = session.decodedJwtData;

    return (
        <div>
            <Wrap onClick={closePopup}>
                Close this popup
            </Wrap>
            <Line>
                JWT = {currentJwt}
            </Line>
            <Line>
                userId = {userId}
            </Line>
            <Line>
                userCountry = {userCountry}
            </Line>
            <Line>
                decodedJwtData = {JSON.stringify(decodedJwtData)}
            </Line>
        </div>
    );
});
