import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface AccordionTypes {
    isSeparateLine?: boolean;
    isSelected?: boolean;
    isActiveDetails?: boolean;
}

export const ParticipantDetailsWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.heroSingleMarket.bgColorSecondary};
    padding: 16px;
    border-radius: 0 0 8px 8px;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 400ms;
    visibility: visible;
`);

export const DescriptionWrapper = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.small};
    margin-top: 10px;
    line-height: 1.2;

    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.xRegular};
        line-height: 1.4;
    }
`);

export const RenderDetailsWrapper = styled('div')`
    width: 100%;
`;

export const DetailsRowWrapper = withConfig(theme => styled('div')`
    display: flex;
    justify-content: flex-start;
    flex: 1 1 100%;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    margin-bottom: 5px;
    flex-flow: row wrap;
`);

export const DetailsColumnWrapper = withConfig(theme => styled('div')<AccordionTypes>`
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    ${({ isSeparateLine }): string => isSeparateLine === true ? `border-left: 1px solid ${theme.star.heroSingleMarket.bgColorTertiary}` : ''};
    padding-left: ${({ isSeparateLine }): string => isSeparateLine === true ? '10px' : '0'};
    flex: 1 1 33%;
    min-width: 120px;
    margin-bottom: 8px;
`);

export const DetailsTitle = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.5;
    color: ${theme.star.heroSingleMarket.txtColorSecondary};
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.small};
    }
`);

export const Detail = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.5;
    color: ${theme.star.heroEvents.txtColor};

    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.xRegular};
    }
`);
