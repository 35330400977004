import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { DateTime } from 'src_common/utils/time/time';

interface DateType {
    day: number;
    month: number;
    year: number;
}

export const validateAgeDateOfBirth = (value: DateType): Result<DateTime> => {
    const date = DateTime.strictFormattedYearMonthDay(value.year, value.month, value.day, 'YYYY-M-D');
    const dateLowest = DateTime.from('1900-01-01');
    const tooOld = dateLowest === null ? false : date.isBeforeInDay(dateLowest);

    if (date.isValid() === false || tooOld) {
        return Result.createError(getErrorByCode('ERROR_INVALID_DATE'));
    }
    if (date.unixMs() > DateTime.current().subtractYears(18).unixMs()) {
        //TODO - need to work out how to handle languages in forms
        try {
            const { languagesState } = $appState.appLayoutsState;
            return Result.createError(
                languagesState.getTranslation('errors.min-age:common', 'You must be over 18 to register an account.')
            );
        }
        catch (err) {
            return Result.createError('You must be over 18 to register an account.');
        }
    }

    return Result.createOk(date);
};
