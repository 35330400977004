import { action, computed, makeObservable } from 'mobx';
import { EnvironmentState } from './environmentState/EnvironmentState';
import { StarRouter } from './router/StarRouter';
import { HeaderMeta } from './headerMeta/HeaderMeta';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { FeatureState } from 'src/domains/layouts/config/features/featureState/FeatureState';
import { GoogleOptimizeState } from './googleState/GoogleOptimizeState';
import { GoogleTagManagerState } from './googleState/GoogleTagManagerState';
import { BannersBoxState } from './bannersBoxState/BannersBoxState';
import { HeroWidgetState } from './heroWidgetState/HeroWidgetState';
import { NetworkConnectionState } from './networkConnectionState/NetworkConnectionState';
import { DebugPanelState } from 'src/domains/layouts/webview/components/debugPanel/DebugPanelState';
import { LoginResponseType, Session } from 'src_common/sdk/session';
import { PopupState } from './popupState/PopupState';
import { BreakpointsState } from './breakpointsState/BreakpointsState';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { WebsocketV1 } from 'src/domains/layouts/state/websocketV1/WebsocketV1';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { AllSports } from 'src/domains/layouts/state/allSports/AllSports';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';
import { PopularSportState } from 'src/domains/layouts/state/popularSportState/PopularSportState';
import { NebetDefaultCompetitionState } from 'src/domains/layouts/state/nebetDefaultCompetitionState/NebetDefaultCompetitionState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { CmsState } from 'src/domains/layouts/state/cmsState/CmsState';
import { NotificationsState } from 'src/domains/layouts/state/notificationsState/NotificationsState';
import { ExternalApi } from 'src/domains/layouts/state/externalApi/ExternalApi';
import { SportsList } from 'src/domains/layouts/state/sportsList/SportsList';
import { NavigationFavourites } from 'src/domains/layouts/state/navigationFavourites/NavigationFavourites';
import { RecentlyVisitedLocalStorage } from 'src/domains/layouts/state/localStorage/RecentlyVisitedLocalStorage';
import { EventsCollectionList, StreamViewDetails } from 'src/domains/sportsbook/shared/Types';
import { SportModel } from 'src_common/common/websocket2/models/SportModel/SportModel';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { Overlay } from 'src/domains/layouts/state/overlay/Overlay';
import { IsStaticState } from 'src/domains/layouts/state/isStaticState/IsStaticState';
import { UserAttributionState } from './userAttributionState/userAttributionState';
import { CurrencyType } from 'src_common/common/amount/website-money/currency';
import { AccountBasicDataType, MarketingNotificationsState, ResourceCustomer } from 'src/domains/players/shared/Types';
import { ServerTimeState } from 'src_common/common/websocket2/ServerTimeState';
import { SearchState } from 'src/domains/layouts/webview/components/search/Search.state';
import { GameSharedModel } from 'src/domains/casino/shared/Types';
import { WalletDataTypeAmountType } from 'src/domains/players/state/UsersState';
import { EventsCollectionQueryFilterMarketType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { PromotedEventsState } from 'src/domains/layouts/webview/components/promotedSports/PromotedEventsState.state';
import { RealityCheckPopupState } from 'src/domains/layouts/state/popups/RealityCheckPopupState';
import { OpenapiProxyCustomerRealityCheckResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_reality_check';
import {
    OpenapiProxyCustomerSetRealityCheckParamsType,
    OpenapiProxyCustomerSetRealityCheckResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_set_reality_check';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';
import { GeolocalizationState } from './geolocalizationState/GeolocalizationState';
import { MarketingNotificationsPopup } from './popupState/popupTypes';
import { FormatType, ImageOptimizeOptions, imageOptimizedUrl } from 'src_common/common/imageOptimization';
import { AccountDrawerNew } from './AccountDrawerNew.state';
import { ActiveSpecialSportsType } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
import { LocalStorageState } from './localStorage/LocalStorageState';
import { Common } from 'src/domains/common/Common';

export interface LayoutsCallbacksType {
    getEvent: (eventId: number) => EventModel | null;
    getUserId: () => string | null;
    loginUser: (email: string, password: string, type: 'login' | 'registration') => Promise<LoginResponseType>;
    setVerificationEmail: (email: string) => void;
    getLoginErrorTranslation: (error: string, failedLoginLimit?: number | null) => string;
    getActiveSpecialSportsForView: () => Array<ActiveSpecialSportsType>;
    getSportsAndEventsListAllowed: () => Map<string, number>;
    listOfSport: (sportId: string) => EventsCollectionList;
    getGameById: (gameId: number) => GameSharedModel | null;
    isGameExists: (gameId: number) => boolean;
    getSport: (sportId: string) => SportModel | null;
    getSelection: (selectionId: number) => SelectionModel | null;
    getIsCasinoMiniMobileOverlayOpen: () => boolean;
    getSpecialEventsLandingPageShouldDisplay: () => boolean;
    getSearchStateIsShow: () => boolean;
    getIncomeaccess: () => string | null;
    getCurrency: () => CurrencyType;
    getOddsFormat: () => 'd' | 'f';
    getBasicData: () => ResourceCustomer<Omit<AccountBasicDataType, 'oddsFormat'>>;
    getWalletData: () => ResourceCustomer<WalletDataTypeAmountType>;
    getIsBrowser: () => boolean;
    listOfSearch: (query: string) => EventsCollectionList;
    getStreamViewDetails: () => StreamViewDetails;
    onRoutingAccountChange: (route: string) => void;
    getListOfSportAndCompetition: (sport: string, competition: CompetitionId) => EventsCollectionList;
    getListOfSport: (sport: string, filterMarket?: EventsCollectionQueryFilterMarketType) => EventsCollectionList;
    getAllSportsLength: () => number;
    getRealityCheckFrequency: () => OpenapiProxyCustomerRealityCheckResponse200Type | null;
    handleSetRealityCheckFrequency: (
        params: OpenapiProxyCustomerSetRealityCheckParamsType
    ) => Promise<OpenapiProxyCustomerSetRealityCheckResponse200Type>;
    realityCheckPopupForCasino: () => boolean;
    getFreeBetPopupShown: () => boolean;
    getQuickBetShown: () => boolean;
}

export class AppLayoutsState {
    /**
     * @deprecated - please use Common.session
     */
    public readonly session: Session;
    /**
     * @deprecated - please use StarRouter.get(common)
     */
    public readonly starRouter: StarRouter;
    /**
     * @deprecated - please use EnvironmentState.get(common)
     */
    public readonly env: EnvironmentState;
    public readonly headerMeta: HeaderMeta;
    /**
     * @deprecated - please use ConfigComponents.get(common)
     */
    public readonly configComponents: ConfigComponents;
    /**
     * @deprecated - please use FeatureState.get(common)
     */
    public readonly featureState: FeatureState;
    public readonly googleOptimize: GoogleOptimizeState;
    public readonly googleTagManager: GoogleTagManagerState;
    public readonly bannersBoxState: BannersBoxState;
    public readonly heroWidgetState: HeroWidgetState;
    public readonly networkConnectionState: NetworkConnectionState;
    public readonly breakpointsState: BreakpointsState;
    public readonly debugPanel: DebugPanelState; // move local ( refactor needed )
    public readonly popupState: PopupState;
    public readonly sdkCustomer: SdkCustomer;
    public readonly websocketV1: WebsocketV1;
    public readonly languagesState: LanguagesState;
    public readonly allSports: AllSports;
    public readonly translationsStore: TranslationsStore;
    public readonly popularSportState: PopularSportState;
    public readonly defaultCompetitionState: NebetDefaultCompetitionState;
    public readonly cmsState: CmsState;
    public readonly overlay: Overlay;
    public readonly isStatic: IsStaticState;
    public readonly notificationsState: NotificationsState;
    public readonly externalApi: ExternalApi;
    public readonly sportsList: SportsList;
    public readonly navigationFavourites: NavigationFavourites;
    public readonly recentlyVisitedLocalStorage: RecentlyVisitedLocalStorage;
    public readonly lifeSpanState: LifeSpanState;
    public readonly userAttributionState: UserAttributionState;
    public readonly callbacks: LayoutsCallbacksType;
    public readonly searchState: SearchState;
    public readonly serverTime: ServerTimeState;
    public readonly promotedEventsState: PromotedEventsState;
    public readonly getStreamViewDetails: () => StreamViewDetails;
    public readonly getListOfSportAndCompetition: (sport: string, competition: CompetitionId) => EventsCollectionList;
    public readonly getListOfSport: (
        sport: string,
        filterMarket?: EventsCollectionQueryFilterMarketType
    ) => EventsCollectionList;
    public readonly getAllSportsLength: () => number;
    public readonly realityCheckPopupState: RealityCheckPopupState;
    public readonly geolocalization: GeolocalizationState;
    public readonly accountDrawerNew: AccountDrawerNew;

    public constructor(
        common: Common,
        callbacks: LayoutsCallbacksType,
        marketingNotificationsState: () => MarketingNotificationsState
    ) {
        makeObservable(this);
        const localStorageState = LocalStorageState.get(common);
        const envVariables = common.envVariables;
        const isBrowser = common.isBrowser;
        this.callbacks = callbacks;
        this.session = common.session;
        this.userAttributionState = new UserAttributionState(isBrowser, () => callbacks.getIncomeaccess());
        this.env = EnvironmentState.get(common);

        this.configComponents = ConfigComponents.get(common);
        this.geolocalization = new GeolocalizationState(envVariables, this.configComponents);
        this.sdkCustomer = new SdkCustomer(
            this.session,
            {
                socketJoinSport: true,
                oddsFormatShortDefault: this.configComponents.config.oddsFormatShortDefault,
                currencyDefault: this.configComponents.config.currencyDefault,
            },
            callbacks
        );
        this.starRouter = StarRouter.get(common);
        this.headerMeta = new HeaderMeta(this.starRouter, this.env, this.configComponents, common.faviconUrl);
        this.featureState = FeatureState.get(common);
        this.googleOptimize = new GoogleOptimizeState();
        this.googleTagManager = new GoogleTagManagerState(
            this.configComponents,
            () => this.session.userId,
            this.userAttributionState,
            this.starRouter
        );
        this.bannersBoxState = new BannersBoxState();
        this.heroWidgetState = new HeroWidgetState(this.sdkCustomer.models);
        this.networkConnectionState = new NetworkConnectionState(this.configComponents);
        this.breakpointsState = new BreakpointsState();
        this.debugPanel = new DebugPanelState(localStorageState.debugPanel, localStorageState.debugPanelSize);

        this.realityCheckPopupState = new RealityCheckPopupState(
            this.sdkCustomer,
            this.configComponents,
            this.starRouter,
            localStorageState,
            this.callbacks
        );

        this.popupState = new PopupState(this.configComponents, () => {
            if (this.realityCheckPopupState.isPopupShouldAppear) {
                return {
                    type: 'popupRealityCheck',
                };
            }

            if (this.realityCheckPopupState.isPopupRealityCheckNewValueConfirmationShouldAppear) {
                return {
                    type: 'popupRealityCheckNewValueConfirmation',
                };
            }

            const { isModalAvailable } = marketingNotificationsState();
            if (isModalAvailable) {
                return new MarketingNotificationsPopup();
            }

            return null;
        });

        this.websocketV1 = new WebsocketV1(this.session, envVariables.websocket_host);
        this.languagesState = new LanguagesState(envVariables.allTranslations, envVariables.userLang);
        this.allSports = new AllSports(this.sdkCustomer);
        this.translationsStore = new TranslationsStore(this.languagesState, this.env, this.allSports);
        this.popularSportState = new PopularSportState(this.configComponents, this.translationsStore);
        this.defaultCompetitionState = new NebetDefaultCompetitionState();
        this.cmsState = new CmsState(
            this.sdkCustomer.models,
            this.languagesState,
            this.configComponents,
            common.trpcClient
        );
        this.notificationsState = new NotificationsState(
            localStorageState,
            this.starRouter,
            () => callbacks.getUserId(),
            this.sdkCustomer,
            callbacks.isGameExists
        );

        this.externalApi = new ExternalApi(
            this.starRouter,
            callbacks.loginUser,
            callbacks.setVerificationEmail,
            callbacks.getLoginErrorTranslation,
            this.configComponents,
            this.sdkCustomer,
            this.env,
            common.trpcClient
        );

        this.sportsList = new SportsList(this.sdkCustomer, this.configComponents, this.translationsStore, {
            getActiveSpecialSportsForView: callbacks.getActiveSpecialSportsForView,
            getSportsAndEventsListAllowed: callbacks.getSportsAndEventsListAllowed,
        });
        this.navigationFavourites = new NavigationFavourites(this.sportsList);
        this.recentlyVisitedLocalStorage = new RecentlyVisitedLocalStorage(
            localStorageState.cgRecentlyVisited,
            this.translationsStore,
            this.configComponents,
            this.sdkCustomer.models,
            {
                getGameById: callbacks.getGameById,
                getSport: callbacks.getSport,
                getSportsAndEventsListAllowed: callbacks.getSportsAndEventsListAllowed,
                listOfSport: callbacks.listOfSport,
            }
        );

        this.lifeSpanState = new LifeSpanState(
            this.session,
            this.configComponents,
            {
                getSelection: callbacks.getSelection,
                getCurrency: callbacks.getCurrency,
            },
            this.googleTagManager
        );

        this.overlay = new Overlay(this.starRouter, this.popupState, this.callbacks);

        this.isStatic = new IsStaticState(
            this.configComponents,
            this.starRouter,
            this.overlay,
            this.sdkCustomer.session,
            this.popupState,
            this.networkConnectionState,
            {
                getSpecialEventsLandingPageShouldDisplay: callbacks.getSpecialEventsLandingPageShouldDisplay,
                getSearchStateIsShow: callbacks.getSearchStateIsShow,
                getFreeBetPopupShown: callbacks.getFreeBetPopupShown,
                getQuickBetShown: callbacks.getQuickBetShown,
            }
        );

        this.serverTime = new ServerTimeState();

        this.searchState = new SearchState(this.configComponents, this.starRouter, {
            getIsBrowser: callbacks.getIsBrowser,
            listOfSearch: callbacks.listOfSearch,
        });

        this.promotedEventsState = new PromotedEventsState(this.sdkCustomer);

        this.getStreamViewDetails = callbacks.getStreamViewDetails;

        this.getListOfSportAndCompetition = callbacks.getListOfSportAndCompetition;
        this.getListOfSport = callbacks.getListOfSport;
        this.getAllSportsLength = callbacks.getAllSportsLength;

        this.starRouter.onChangeCurrentView((prevCurrentView, nextCurrentView) => {
            const prevView = prevCurrentView ?? null;
            const nextView = nextCurrentView ?? null;

            if (prevView !== null && nextView !== null && prevView !== nextView) {
                this.notificationsState.onHideAll();
            }
        });

        this.starRouter.onChangeCurrentView((prevCurrentView, nextCurrentView) => {
            if (prevCurrentView !== null && nextCurrentView !== null && prevCurrentView !== nextCurrentView) {
                this.recentlyVisitedLocalStorage.setItem(nextCurrentView);
            }
        });

        this.accountDrawerNew = new AccountDrawerNew(this.starRouter);
    }

    @action public walletResource = (): ResourceCustomer<WalletDataTypeAmountType> => {
        return this.callbacks.getWalletData();
    };

    @computed public get isStaticForView(): boolean {
        return this.isStatic.isStatic;
    }

    public optimizeImage = (
        imgSrc: string,
        format: FormatType,
        optimizeOptions?: ImageOptimizeOptions
    ): string | null => {
        const params = {
            imgSrc,
            format,
            ...optimizeOptions,
            quality: optimizeOptions?.quality ?? 85,
        };

        if (this.configComponents.config.imageOptimization === null) {
            return null;
        }

        if (this.configComponents.config.imageOptimization.type === 'baseUrl') {
            return imageOptimizedUrl(params, this.configComponents.config.imageOptimization.url);
        }
        return imageOptimizedUrl(params, null);
    };
}
