import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const PlayBrakeParagraph = withConfig(theme => styled('p')`
    line-height: 1.4285;
    margin: 0 0 8px;
    font-size: ${theme.star.fontSize.xRegular};
`);

export const PlayBrakeExpiryDate = styled('strong')`
    float: right;
`;

export const PlayBreakTitle = withConfig(theme => styled('h4')`
    margin: 0 0 8px;
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    text-transform: uppercase;
`);

export const PlayBreakProcedureButton = styled(Button)`
    width: 100%;
`;
