import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface ScrollBarTrackWrapper {
    position: 'horizontal' | 'vertical'
}

const setScrollPosition = (position: 'horizontal' | 'vertical'): string => {
    if (position === 'horizontal') {
        return `
            bottom: 0;
            height: 4px;
            left: 0;
            right: 0;
        `;
    }
    return `
        bottom: 5px;
        right: 2px;
        top: 5px;
        width: 4px;
    `;
};

export const ScrollBarTrackWrapper = withConfig(theme => styled('div')<ScrollBarTrackWrapper>`
    ${({ position }): string => setScrollPosition(position)};
    background-color: ${theme.star.customScrollBar.old.bgColor};
    border-radius: 5px;
    cursor: pointer;
    z-index: ${theme.star.zIndexGlobal.highLevel};
`);

export const ScrollBarThumbWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.customScrollBar.old.bgColorSecondary};
    cursor: pointer;
    border-radius: 5px;
`);
