import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface PromotionsIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const PromotionsIcon = observer(
    'PromotionsIcon',
    ({ className, onClick }: PromotionsIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M21.09 8.37c-1.935-4.505-5.05-7.932-6.625-7.293-2.676 1.089 1.593 6.312-11.548 11.652-1.135.464-1.423 2.308-.947 3.413.474 1.103 2.026 2.202 3.162 1.74.197-.08.918-.312.918-.312.81 1.09 1.659.444 1.96 1.134l1.416 3.252c.268.613.874 1.182 1.314 1.014l2.506-.952c.572-.218.708-.728.533-1.13-.188-.433-.96-.56-1.182-1.067-.22-.503-.94-2.124-1.147-2.635-.28-.694.317-1.26 1.186-1.35 5.982-.625 7.1 3.071 9.137 2.242 1.573-.641 1.253-5.205-.682-9.707l-.001-.001zm-.673 7.305c-.35.14-2.705-1.713-4.208-5.215-1.505-3.5-1.315-6.699-.966-6.842.35-.14 2.647 2.099 4.15 5.598 1.505 3.5 1.374 6.316 1.024 6.46v-.001z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
