import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const EventRowHeader = withConfig(theme => styled('div')`
    border-right: 1px solid  ${theme.star.eventRow.borderColor};
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 0%;
    min-width: 50px;
    transition: background-color .2s ease;
`);

export const EventRowMarkets = styled('div')`
    min-width: 100%;
`;

export const EventRowSelections = withConfig(theme => styled('ul')`
    display: flex;
    flex-flow: row nowrap;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: center;
`);

export const EventRowSelectionsElement = styled('li')`
    display: flex;
    height: 100%;
`;

export const EventRowMetaTime = withConfig(theme => styled('span')`
    display: flex;
    align-content: center;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.4;
`);
