import React, { useState } from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';
import { ImagePreloadState } from './ImagePreloadState';
import { SilkIconWrapper } from 'src/domains/sportsbook/webview/components/raceBoxSelection/RaceBoxSelection.style';

const LazyImageWrapper = styled('img')`
    width: 100%;
`;

interface AppStateComponentType {
    className?: string
    src?: string | null,
    placeholder: string,
    alt?: string | null,
    justSrc?: string | null,
}

export const LazyImage = observer('LazyImage', (props: AppStateComponentType) => {
    const [imagePreload] = useState(() => new ImagePreloadState());
    const { alt, className, justSrc, placeholder } = props;
    const img = justSrc ?? null;
    const src = props.src ?? null;

    if (img === null && src === null) {
        return (
            <SilkIconWrapper />
        );
    }

    return <LazyImageWrapper className={className} alt={alt ?? ''} src={img === null ? imagePreload.getSrc(src, placeholder) : img} />;

});
