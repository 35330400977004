import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { StarFillIcon } from 'src/domains/layouts/shared/assets/icons/StarFillIcon';
import { StarOutlineIcon } from 'src/domains/layouts/shared/assets/icons/StarOutlineIcon';
import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

interface NavigationListItemLinkType {
    isCapitalized: boolean;
}

export const NavigationListItemLink = withConfig(theme => styled(Link)<NavigationListItemLinkType>`
    color: ${theme.star.lhsNavigation.txtColor};
    display: block;
    overflow: hidden;
    padding: 12px 10px 12px 0;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${({ isCapitalized }): string => isCapitalized ? 'text-transform: capitalize'
        : `
        text-transform: lowercase; 
        &:first-letter {
            text-transform: uppercase; 
        }
    `};
    @media ${theme.star.mediaQuery.largeDesktop} {
        color: ${theme.star.lhsNavigation.txtColorSecondary};
        margin: 8px 0;
        padding: 0 20px 0 0;
        &:hover {
            color: ${theme.star.lhsNavigation.txtColorTertiary};
        }
    }
`);

export const NavigationChevronIconWrapper = withConfig(theme => styled(ChevronIcon)`
    bottom: 0;
    fill: currentcolor;
    margin: auto 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 12px;
    @media ${theme.star.mediaQuery.largeDesktop} {
        display: none;
    }
`);

export const NavigationStarFillIconWrapper = withConfig(theme => styled(StarFillIcon)`
    bottom: 0;
    fill: ${theme.star.lhsNavigation.bgColor};
    margin: auto 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
`);

export const NavigationStarOutlineIconWrapper = withConfig(theme => styled(StarOutlineIcon)`
    bottom: 0;
    fill: ${theme.star.lhsNavigation.bgColorSecondary};
    margin: auto 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
`);

export const NavigationListWrapper = withConfig(theme => styled('nav')`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.4;
`);
