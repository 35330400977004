import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

export const PendingTransactionWrapper = withConfig(theme => styled('div')`
    color: ${theme.star.pendingTransactions.txtColor};
    margin-top: 24px;
`);

export const PendingTitleWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.pendingTransactions.bgColorSecondary};
    margin-bottom: 8px;
    padding: 0 16px 4px;
`);

export const PendingTitle = withConfig(theme => styled('div')`
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.xRegular};
    color: ${theme.star.pendingTransactions.txtColorSecondary};
    display: flex;
    align-items: center;
    line-height: 1.4286;
`);

export const PendingSubTitle = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.xSmall};
    color: ${theme.star.pendingTransactions.txtColorTertiary};
    line-height: 1.4;
    margin-top: 2px;
`);

export const PendingWithdrawalsHeader = withConfig(theme => styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    background-color: ${theme.star.pendingTransactions.bgColor};
    color: ${theme.star.pendingTransactions.txtColor};
    padding-left: 16px;
`);

export const PendingWithdrawalsHeaderItem = withConfig(theme => styled('div')`
    display: flex;
    flex: 1 1 0%;
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1;
`);

export const PendingWithdrawalsList = withConfig(theme => styled('div')`
    background-color: ${theme.star.pendingTransactions.bgColorSecondary};
`);

export const PendingWithdrawalsRow = withConfig(theme => styled('div')`
    display: flex;
    justify-content: space-between;
    height: 48px;
    border-bottom: 1px solid ${theme.star.pendingTransactions.borderColor};
    padding-left: 16px;
    color: ${theme.star.pendingTransactions.txtColorSecondary};
`);

export const PendingWithdrawalsRowElem = withConfig(theme => styled('div')`
    margin: auto 0;
    flex: 1 1 0%;
    font-size: ${theme.star.fontSize.small};
`);

export const PendingLabel = withConfig(theme => styled('div')`
    line-height: 1;
    text-align: center;
    padding: 6px;
    width: 58px;
    height: 22px;
    font-size: ${theme.star.fontSize.xSmall};
    background-color: ${theme.star.pendingTransactions.bgColorTertiary};
`);

export const PendingButton = styled(Button)``;

export const PendingWithdrawalsDateWrapper = styled('div')`
    line-height: 1.2;
`;

export const AmountStyle = withConfig(theme => styled('div')`
    font-weight: ${theme.star.fontWeight.bold};
`);

export const PendingName = withConfig(theme => styled('div')`
    font-weight: ${theme.star.fontWeight.bold};
`);

export const PendingCount = withConfig(theme => styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    margin-left: 4px;
    border-radius: 100%;
    background-color: ${theme.star.pendingTransactions.bgColor};
    font-size: ${theme.star.fontSize.xSmall};
`);
