import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const setCasinoLayout = (theme: EmotionTheme): SerializedStyles => {
    return css`
        background: ${theme.star.casino.bgColor};
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 0%;
    `;
};

export const CasinoSection = withConfig(theme => styled('section')`
    ${setCasinoLayout(theme)};
`);
