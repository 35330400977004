import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface PropsType {
    className?: string;
    onClick?: () => void;
}

export const LogoIbas = observer('LogoIbas', ({ className, onClick }: PropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            height='146'
            onClick={onClick}
            width='356'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            fillRule='evenodd'
            clipRule='evenodd'
            strokeLinejoin='round'
            strokeMiterlimit='1.5'
            viewBox='0 0 356 146'
        >
            <path
                d='m34 103.125 29.466 34.86L117.171 70H356v76H34v-42.875Z'
                fill='#00abe5'
            />
            <path
                d='M849.667 524.628h7.113v28.2h-7.113z'
                fill='#fff'
                transform='matrix(1.384 0 0 1.385 -1039.154 -638.333)'
            />
            <path
                d='M893.279 559.407a3.68 3.68 0 0 1-3.638-3.6 3.633 3.633 0 0 1 3.556-3.6 3.616 3.616 0 0 1 3.5 3.599 3.615 3.615 0 0 1-3.418 3.595v.006Z'
                fill='#ff2915'
                transform='matrix(1.384 0 0 1.385 -1073.819 -662.273)'
            />
            <path
                d='M940.05 524.63c1.85-.126 3.709.056 5.5.537a7.032 7.032 0 0 1 5.375 6.492 6.41 6.41 0 0 1-3.8 5.664c4.012 1.24 5.954 3.6 5.954 7.318a7.744 7.744 0 0 1-5.54 7.567 15.689 15.689 0 0 1-5.292.662l-12.942-.042v-28.2l10.745.002Zm-3.6 11.2h2.65c3.433 0 4.756-.868 4.756-3.1 0-2.15-1.366-3.06-4.632-3.06h-2.77l-.004 6.16Zm0 11.866h3.473c4.424 0 5.912-.867 5.912-3.39 0-2.6-1.488-3.39-6.243-3.39h-3.142v6.78Z'
                fill='#fff'
                transform='matrix(1.384 0 0 1.385 -1108.213 -638.333)'
            />
            <path
                d='M1013.93 559.407c-1.98-.013-3.61-1.624-3.64-3.6a3.635 3.635 0 0 1 3.56-3.6 3.62 3.62 0 0 1 3.5 3.599 3.62 3.62 0 0 1-3.42 3.595v.006Z'
                fill='#ff2915'
                transform='matrix(1.384 0 0 1.385 -1178.446 -662.273)'
            />
            <path
                d='M1069.76 552.828h-7.57l-2.31-5.581h-12.61l-2.11 5.581h-7.44l11.37-28.2h8.15l12.52 28.2Zm-20.63-10.5h8.68l-4.55-10.915-4.13 10.915Z'
                fill='#fff'
                transform='matrix(1.384 0 0 1.385 -1202.233 -638.333)'
            />
            <path
                d='M1134.62 559.407c-1.97-.013-3.6-1.624-3.64-3.6.01-1.955 1.61-3.567 3.56-3.6a3.614 3.614 0 0 1 3.5 3.599 3.612 3.612 0 0 1-3.42 3.595v.006Z'
                fill='#ff2915'
                transform='matrix(1.384 0 0 1.385 -1283.113 -662.273)'
            />
            <path
                d='m1187.85 531.613-.5-.248a19.435 19.435 0 0 0-8.6-2.481c-2.57 0-4.22.91-4.22 2.274 0 1.2 1.45 2.233 4.59 3.35l2.23.827c5.42 1.943 7.77 4.631 7.77 8.889 0 5-4.42 8.353-11.04 8.353a20.132 20.132 0 0 1-7.44-1.53c-.89-.283-1.74-.687-2.52-1.2v-6.078c1.43.965 2.95 1.783 4.55 2.44a13.24 13.24 0 0 0 5.21 1.073c2.77 0 4.26-.867 4.26-2.563 0-1.406-.95-2.191-3.8-3.183l-1.53-.537a28.01 28.01 0 0 1-4.68-2.026 9.632 9.632 0 0 1-2.64-2.067 8.11 8.11 0 0 1-1.86-5.251c0-4.837 4.22-8.021 10.67-8.021 3.3.022 6.56.77 9.55 2.191v5.788Z'
                fill='#fff'
                transform='matrix(1.384 0 0 1.385 -1314.896 -637.467)'
            />
            <path
                d='M0 0h60.623v60.623H0z'
                fill='#00abe5'
                transform='matrix(.988 -.988 .988 .988 0 59.867)'
            />
            <path
                d='M90 50c-4.542 6.29-4.389 12.625 0 19'
                fill='none'
                stroke='#1d1d1b'
                strokeWidth='1px'
            />
            <path
                d='M90 50c-4.542 6.29-4.389 12.625 0 19'
                fill='none'
                stroke='#1d1d1b'
                strokeWidth='0.55px'
                transform='matrix(1.896 0 0 1.737 -87.61 -43.842)'
            />
            <path
                d='M90 50c-4.542 6.29-4.389 12.625 0 19'
                fill='none'
                stroke='#1d1d1b'
                strokeWidth='0.38px'
                transform='matrix(2.791 0 0 2.474 -175.22 -87.684)'
            />
            <path
                d='M90 50c-4.542 6.29-4.389 12.625 0 19'
                fill='none'
                stroke='#1d1d1b'
                strokeWidth='0.43px'
                transform='matrix(3.687 0 0 3.21 -262.83 -131.526)'
            />
            <path
                d='M90 50c-4.542 6.29-4.389 12.625 0 19'
                fill='none'
                stroke='#1d1d1b'
                strokeWidth='0.47px'
                transform='matrix(4.583 0 0 3.947 -350.439 -175.368)'
            />
            <path
                d='M893.279 559.407a3.68 3.68 0 0 1-3.638-3.6 3.633 3.633 0 0 1 3.556-3.6 3.616 3.616 0 0 1 3.5 3.599 3.615 3.615 0 0 1-3.418 3.595v.006Z'
                fill='#1d1d1b'
                transform='matrix(1.134 0 0 1.111 -912.995 -557.976)'
            />
            <path
                d='M90 50c-4.542 6.29-4.389 12.625 0 19'
                fill='none'
                stroke='#ff2915'
                strokeWidth='1px'
                transform='rotate(-180 58.667 59.5)'
            />
            <path
                d='M90 50c-4.542 6.29-4.389 12.625 0 19'
                fill='none'
                stroke='#ff2915'
                strokeWidth='0.55px'
                transform='matrix(-1.896 0 0 -1.737 204.943 162.842)'
            />
            <path
                d='M90 50c-4.542 6.29-4.389 12.625 0 19'
                fill='none'
                stroke='#ff2915'
                strokeWidth='0.38px'
                transform='matrix(-2.791 0 0 -2.474 292.553 206.684)'
            />
            <path
                d='M90 50c-4.542 6.29-4.389 12.625 0 19'
                fill='none'
                stroke='#ff2915'
                strokeWidth='0.43px'
                transform='matrix(-3.687 0 0 -3.21 380.163 250.526)'
            />
            <path
                d='M90 50c-4.542 6.29-4.389 12.625 0 19'
                fill='none'
                stroke='#ff2915'
                strokeWidth='0.47px'
                transform='matrix(-4.583 0 0 -3.947 467.772 294.368)'
            />
            <path
                d='M893.279 559.407a3.68 3.68 0 0 1-3.638-3.6 3.633 3.633 0 0 1 3.556-3.6 3.616 3.616 0 0 1 3.5 3.599 3.615 3.615 0 0 1-3.418 3.595v.006Z'
                fill='#ff2915'
                transform='matrix(-1.134 0 0 -1.111 1030.328 676.976)'
            />
        </SvgElement>
    );
});
