import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const Wrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.layoutGlobal.bgColor};
    height: 100%;
`);

export const Event = styled('div')`
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 0%;
    min-height: 100%;
`;
