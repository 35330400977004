import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import styled from '@emotion/styled';

export const RealexFrameWrapper = styled('div')`
    position: relative;
    overflow: auto;
    height: calc(100vh - 30px);
    -webkit-overflow-scrolling: touch;
    iframe {
        display: block;
        width: 100%;
        height: calc(100% - 30px);
        border: 0;
    }
`;

export const RealexLoaderWrapper = styled(Loader)`
    justify-content: center;
    left: 0;
    margin: -6px auto auto;
    position: absolute;
    right: 0;
    top: 50%;
`;
