import { action, makeObservable, observable } from 'mobx';
import { Amount } from 'src_common/common/amount/Amount';
import { normalizeAmount, tryStringToAmount } from './StakeInputState2Utils';

class StakeValue {
    @observable private text: string = '';
    @observable private stakeValue: Amount = new Amount('0');

    public constructor(private readonly handleOnChange: (value: Amount) => void) {
        makeObservable(this);
    }

    public getText(): string {
        return this.text;
    }

    public get(): Amount {
        return this.stakeValue;
    }

    @action public set(text: string, value: Amount, runCallback: boolean): void {
        this.text = text;
        this.stakeValue = value;
        if (runCallback) {
            this.handleOnChange(value);
        }
    }
}

// TODO: - in case of Single oraz Combination, there should be passed a reference to ReferalState
// there will be probably id needed here
export class StakeInputState2 {
    private readonly stakeValue: StakeValue;

    //TODO - change number to AmountPrecision
    public constructor(
        private readonly decimalLength: number,
        handleOnChange: (value: Amount) => void
    ) {
        makeObservable(this);

        this.stakeValue = new StakeValue(handleOnChange);
    }

    public static createForTest(decimalLength: number): [StakeInputState2, () => Amount | null] {
        const input = new StakeInputState2(decimalLength, () => {});
        return [input, (): Amount | null => input.stakeValue.get()];
    }

    //For input component
    public get valueForInput(): string {
        return this.stakeValue.getText();
    }

    public reset(): void {
        this.stakeValue.set('', new Amount('0'), false);
    }

    //For input component
    @action public changeFromInput = (value: string): void => {
        this.trySetNewText(value);
    };

    private trySetNewText(newValueInput: string): void {
        const newValue = normalizeAmount(newValueInput);

        const newAmount = tryStringToAmount(newValue, this.decimalLength);
        if (newAmount !== null) {
            this.stakeValue.set(newValue, newAmount, true);
        }
    }

    public get currentStakeValue(): Amount {
        return this.stakeValue.get();
    }

    // TODO: verify if functions calls come only from action
    @action public changeStake(value: Amount): void {
        this.stakeValue.set(value.value, value, true);
    }

    @action public onKeybordDelete = (): void => {
        const currentText = this.stakeValue.getText();
        if (currentText.length === 0) {
            return;
        }
        const newStrAmount = currentText.slice(0, -1);
        this.trySetNewText(newStrAmount);
    };

    @action public onKeybordDot = (): void => {
        const currentText = this.stakeValue.getText();
        const newStrAmount = `${currentText}.`;
        this.trySetNewText(newStrAmount);
    };

    @action public onKeybordNumberKey = (char: number): void => {
        if (0 <= char && char <= 9) {
            const currentText = this.stakeValue.getText();
            const newStrAmount = `${currentText}${char}`;
            this.trySetNewText(newStrAmount);
        } else {
            console.error(`Ignore onKeybordNumberKey ${char}`);
        }
    };
}
