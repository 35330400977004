import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const AddressesListWrapper = styled('ul')`
    margin: 4px 0;
    max-height: 200px;
    overflow-y: auto;
`;

export const AddressesListItem = styled('li')`
    margin: 5px 5px 5px 0;
`;

export const AddressesItemChoice = withConfig(theme => styled('button')`
    background: ${theme.star.addressesListColors.bgColor};
    border-radius: 3px;
    border: 1px solid ${theme.star.addressesListColors.borderColor};
    color: ${theme.star.addressesListColors.txtColor};
    cursor: pointer;
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1;
    overflow: hidden;
    padding: 8px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    &:hover {
        color: ${theme.star.addressesListColors.txtColorSecondary};
    }
`);
