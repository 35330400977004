import styled from '@emotion/styled';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import { withConfig } from 'src/withConfig';

export const StyledLoader = withConfig(theme => styled(Loader)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${theme.star.zIndexGlobal.highLevel};
`);
