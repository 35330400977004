import { streamButtonBarDesktop } from 'src/domains/sportsbook/shared/Components';
import TARTAN_BACKGROUND from 'src/domains/layouts/webview/modules/layoutSkeleton/Header/assets/tartan-pattern.webp';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { css, SerializedStyles } from '@emotion/react';
import { withConfig } from 'src/withConfig';

interface HeaderMainPropsType {
    isTartanClub?: boolean;
    bannersHeight: number;
    streamBoxHeight: number;
    isFloating: boolean;
}

const setHeaderPosition = (streamBoxHeight: number, bannersHeight: number, isFloating: boolean): SerializedStyles => {
    const fixedStreamHeight = streamBoxHeight - 1; // Remove 1px hap between stream and main header.
    const topPosition = fixedStreamHeight + streamButtonBarDesktop + bannersHeight;

    if (streamBoxHeight > 0) {
        if (isFloating) {
            return css`
                top: ${bannersHeight}px;
            `;
        }

        return css`
            top: ${topPosition}px;
        `;
    }

    return css`
        top: ${streamBoxHeight + bannersHeight}px;
    `;
};

const setBackground = (theme: EmotionTheme, isTartanClub?: boolean): SerializedStyles => {
    if (isTartanClub === true) {
        return css`
            background-color: ${theme.star.headerMain.bgColor};
            background-size: auto 100%;
            background-image: url(${TARTAN_BACKGROUND});
            background-repeat: repeat-x;
        `;
    }
    return css`
        background-color: ${theme.star.headerMain.bgColor};
    `;
};

export const HeaderMain = withConfig(theme => styled('header')<HeaderMainPropsType>`
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 48px;
    left: 0;
    position: fixed;
    right: 0;
    text-align: center;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.header};
    ${({ isTartanClub }): SerializedStyles => setBackground(theme, isTartanClub)}
    ${({ streamBoxHeight, bannersHeight, isFloating }): SerializedStyles =>
        setHeaderPosition(streamBoxHeight, bannersHeight, isFloating)};
    @media ${theme.star.mediaQuery.desktop} {
        flex: 0 0 56px;
        height: 56px;
        position: relative;
        top: 0;
    }
`);
