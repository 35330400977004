import styled from '@emotion/styled';
import { FreeBetIcon } from 'src/domains/layouts/shared/assets/icons';
import { withConfig } from 'src/withConfig';

export const BetslipGiftIconWrapper = styled(FreeBetIcon)`
    width: 18px;
`;

export const FreeBetButton = withConfig(
    (theme) => styled.button`
        border: none;
        background-color: ${theme.star.buttons.primary.bgColor};
        cursor: pointer;
        width: 42px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    `
);
