import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { BogIndicatorRace } from 'src/domains/sportsbook/webview/components/bog/BogIdicator.style';

export const BogIndicatorRaceWrapper = observer('BogIndicatorRaceWrapper', () => {
    return (
        <BogIndicatorRace key='star-bog-indicator' size='medium' data-test='bog-indicator-race'>
            <I18n langKey='events.bog' defaultText='BOG' />
        </BogIndicatorRace>
    );
});
