import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface TrashIconPropsType {
    className?: string;
    onClick?: () => void;
    dataTest?: string;
}

export const TrashIcon = observer(
    'TrashIcon',
    ({ className, onClick, dataTest }: TrashIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fillRule='evenodd'
                fill='#072d4f'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
                data-test={dataTest}
            >
                <path
                    fill='fill'
                    fillRule='evenodd'
                    d='M15.8445 4.02022h4.5024c.5768 0 1.0416.45482 1.0402 1.01582v1.65718H3.25V5.03604c0-.56243.46774-1.01582 1.04309-1.01582l4.51239-.13487V1.88239c0-.55956.7963-.88239 1.37022-.88239h4.4406c.574 0 1.2282.45483 1.2282 1.01726v2.00296Zm-4.6013-1.76479c-.6198 0-1.1235.363-1.1235.81066h-.0014v.95413h4.4909v-.95413c0-.44622-.5022-.81066-1.122-.81066h-2.244ZM6.88288 23c-.54091 0-.98139-.4233-.98139-.9484L4.43084 7.87241H20.2106L18.7399 22.0516c0 .5251-.4405.9484-.9814.9484H6.88288Z'
                    clipRule='evenodd'
                />
            </SvgElement>
        );
    }
);
