import styled from '@emotion/styled';

// Navigation
export const TabWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    gap: 5px;
    padding: 4px 0;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 0.9rem;
`;

interface TabLabelType {
    active?: boolean;
}

export const TabLabel = styled('div')<TabLabelType>`
    border: 1px solid black;
    padding: 6px;
    cursor: pointer;
    background-color: ${({ active }): string => active === true ? '#000' : '#fff'};
    color: ${({ active }): string => active === true ? '#fff' : '#000'};
`;

// Content wrappers
export const GroupWrapper = styled('div')`
    border: 1px solid #dadada;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f0f0f0;
`;

export const GroupHeader = styled('div')`
    font-weight: bold;
`;

export const GroupBody = styled('div')`
    border: 1px solid #afafaf;
    padding: 5px;
    margin: 5px 0;
    background-color: #ececec;
`;

export const JsonWrapper = styled('div')`
    word-break: break-word;
`;

// Debug table
export const TableWrapper = styled('div')`
    display: flex;
    flex-flow: column nowrap;
`;

interface TableRowType {
    isHeadline?: boolean;
}

export const TableRow = styled('div')<TableRowType>`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    border-bottom: 1px solid #dadada;
    background-color: ${({ isHeadline }): string => isHeadline === true ? '#ececec' : '#fff'};
    font-weight: ${({ isHeadline }): string => isHeadline === true ? '600' : '400'};

    &:hover {
        background-color: #f6f6f6;
    }
`;

export const TableCol = styled('div')`
    display: flex;
    flex: 1;
    padding: 8px;
    justify-content: left;
    align-items: center;
    word-break: break-word;
`;

// Buttons
export const Button = styled('button')`
    margin: 0 10px 0 0;
`;

// Labels
export const ValueBox = styled('div')`
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
`;

export const ValueWrapper = styled('div')`
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    border: 1px solid #dadada;
    border-radius: 4px;
    word-break: normal;
`;

export const Label = styled('div')`
    padding: 5px 6px;
`;

export const Value = styled('div')`
    background-color: #cf1010;
    color: #fff;
    padding: 5px 6px;
    border-radius: 4px;
`;
