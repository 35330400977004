import React, { useEffect, useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    AmountWrapper,
    FreeBetIconWrapped,
    TabButton,
    TabContent,
    Tabs,
    TotalAmount,
    TotalAmountSum,
    TotalAmountTitle,
} from './CreditsTab.styled';
import { BonusItems } from './components/BonusItems';
import { CreditsPagination } from './components/CreditTabPagination/CreditsPagination';
import { TabType, TabTypeLabels } from './types';
import { observer } from 'mobx-react-lite';
import { DarkLoadingSpinner } from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner';

export const CreditsTab = observer(() => {
    const {
        appPlayersState: { usersState, bonusState },
    } = useAppStateContext();

    const [activeTab, setActiveTab] = useState<TabType>(TabType.FreeBets);
    const [currentPage, setCurrentPage] = useState(1);

    const bonusItems = activeTab === TabType.FreeBets ? bonusState.bonuses : [];

    const itemsPerPage = 3;
    const totalPages = Math.ceil(bonusItems.length / itemsPerPage);

    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;

    const paginatedItems = bonusItems.slice(startIndex, startIndex + itemsPerPage);

    useEffect(() => {
        bonusState.refreshBalance();
        bonusState.refreshBonuses();
    }, [bonusState]);

    return (
        <>
            <Tabs>
                {Object.values(TabType)
                    .filter((item) => item !== TabType.BonusBalance)
                    .map((tab) => (
                        <TabButton
                            key={tab}
                            isActive={activeTab === tab}
                            onClick={() => setActiveTab(tab)}
                        >
                            {TabTypeLabels[tab]}
                        </TabButton>
                    ))}
            </Tabs>

            <TabContent>
                <TotalAmount>
                    <TotalAmountTitle>
                        {activeTab === TabType.FreeBets ? 'Total Free Bets:' : 'Total Bonus:'}
                    </TotalAmountTitle>

                    <AmountWrapper>
                        <FreeBetIconWrapped />

                        <TotalAmountSum>{usersState.money(bonusState.balance)}</TotalAmountSum>
                    </AmountWrapper>
                </TotalAmount>

                {bonusState.isLoadingBonuses ? (
                    <DarkLoadingSpinner />
                ) : (
                    <>
                        <BonusItems items={paginatedItems} />
                        {bonusItems.length > 0 && (
                            <CreditsPagination
                                handlePageChange={handlePageChange}
                                totalPages={totalPages}
                                currentPage={currentPage}
                            />
                        )}
                    </>
                )}
            </TabContent>
        </>
    );
});
