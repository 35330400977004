import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const FooterMobile = withConfig(theme => styled('footer')`
    background-color: ${theme.star.footerMobile.bgColor};
    top: calc(100dvh - 48px);
    @supports not (height: 100vh) {
        bottom: 0;
    }
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-flow: row nowrap;
    height: 48px;
    flex: 0 0 48px;
    position: fixed;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.footer};
`);

const setMobileFooterLinkColor = (theme: EmotionTheme, isActive: boolean): SerializedStyles => {
    if (isActive) {
        return css`
            color: ${theme.star.footerMobile.txtColorSecondary};
            &::before {
                background-color: ${theme.star.footerMobile.bgColorSecondary};
                opacity: 1;
                visibility: visible;
            }
        `;
    }

    return css`
        color: ${theme.star.footerMobile.txtColor};
        &::before {
            opacity: 0;
            visibility: hidden;
        }
        &:hover {
            color: ${theme.star.footerMobile.txtColorSecondary};
            &::before {
                opacity: 1;
                visibility: visible;
            }
        }
    `;
};

const linkStyle = (theme: EmotionTheme, isActive: boolean): SerializedStyles => css`
    ${setMobileFooterLinkColor(theme, isActive)};
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 20%;
    height: 100%;
    justify-content: flex-end;
    padding-bottom: 3px;
    position: relative;
    text-decoration: none;
    transition: color 0.2s ease;
    &::before {
        ${positionElementFull};
        background-color: ${theme.star.footerMobile.bgColorSecondary};
        content: '';
        position: absolute;
        transition: opacity 0.2s ease, visibility 0.2s ease;
        z-index: ${theme.star.zIndexGlobal.below};
    }
`;

interface LinkWrapperPropsType {
    isActive: boolean;
}

export const LinkWrapper = withConfig(theme => styled(Link)<LinkWrapperPropsType>`
    ${({ isActive }):SerializedStyles => linkStyle(theme, isActive)};
`);

export const CustomWrapper = withConfig(theme => styled('div')<LinkWrapperPropsType>`
    ${({ isActive }):SerializedStyles => linkStyle(theme, isActive)};
`);

export const Label = withConfig(theme => styled('span')`
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin-top: 4px;
    letter-spacing: -0.4px;
    white-space: nowrap;
    padding: 0 4px;
`);
