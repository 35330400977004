import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { LogoGameCare } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/LogoGameCare';
import { LogoGambleAware } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/LogoGambleAware';
import { LogoGamstop } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/LogoGamstop';
import { Logo18Plus } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/Logo18Plus';
import { withConfig } from 'src/withConfig';
import { LazyLoadingImg } from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg';
import { LogoGamblingCommission } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/LogoGamblingComission';
import { LogoIbas } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/LogoIbas';

interface LogoRowPropsType {
    isCentered?: boolean;
    isWrap?: boolean;
}

export const LogoRow = withConfig(theme => styled('div')<LogoRowPropsType>`
    display: flex;
    margin: 16px 0;
    align-items: ${({ isCentered }): string => isCentered === true ? 'center' : 'flex-end'};
    flex-flow: ${({ isWrap }): string => isWrap === true ? 'row wrap' : 'row nowrap'};

    @media ${theme.star.mediaQuery.mobile} {
        flex-flow: row nowrap;
    }
`);

export const SingleItem = styled('div')`
    display: flex;
    margin-right: 15px;
    &:last-of-type {
        margin-right: 0;
    }
`;

export const SingleItemAntillephone = styled('div')`
    width: 32px;
    height: 32px;
`;

export const SingleItemLink = withConfig(theme => styled(Link)`
    color: ${theme.star.footerMain.txtColor};
    font-size: ${theme.star.fontSize.small};
    text-decoration: none;
`);

export const ResponsibleInfo = withConfig(theme => styled('p')`
    color: ${theme.star.footerMain.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin: 16px 0;
`);

export const LinkWrapper = withConfig(theme => styled(Link)`
    color: ${theme.star.footerMain.txtColorSecondary};
    display: inline-block;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    text-decoration: none;
    transition: color 0.2s ease;
    margin-top: 2px;
    &:hover {
        color: ${theme.star.footerMain.txtColorQuinary};
    }
`);

export const LogoGamstopWrapper = styled(LogoGamstop)`
    display: block;
    max-width: 83px;
    width: 100%;
`;

export const LogoGamblingCommissionWrapper = withConfig(theme => styled(LogoGamblingCommission)`
    display: block;
    max-width: 119px;
    width: 100%;
    margin-top: 16px;

    @media ${theme.star.mediaQuery.mobile} {
        margin-top: 0;
    }
`);

export const LogoProblemGamblingWrapper = withConfig(theme => styled(LazyLoadingImg)`
    max-width: 56px;
    @media ${theme.star.mediaQuery.tablet} {
        max-width: 75px;
    }
`);

export const Logo18PlusWrapper = withConfig(theme => styled(Logo18Plus)`
    display: block;
    width: 24px;
    @media ${theme.star.mediaQuery.xxMobile} {
        width: 32px;
    }
`);

export const LogoGameCareWrapper = styled(LogoGameCare)`
    display: block;
    max-width: 37px;
    min-width: 30px;
    width: 100%;
`;

export const LogoGameCareSwiftyWrapper = styled(LogoGameCareWrapper)`
    max-width: 32px;
`;

export const LogoGambleAwareWrapper = styled(LogoGambleAware)`
    display: block;
    max-width: 165px;
    width: 100%;
`;

export const ValidationScriptLinkWrapper = styled(Link)`
    height: 30px;
`;

export const ValidationScriptImgWrapper = styled('img')`
    height: 30px;
    margin-left: 15px;
`;

export const LogoIbasWrapper = styled(LogoIbas)`
    max-width: 80px;
    min-width: 60px;
    vertical-align: bottom;
    width: 100%;
`;
