import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';

interface SwitcherType { 
    isSidebar?: boolean;
}

export const LanguageSwitcherWrapper = withConfig(theme => styled('div')<SwitcherType>`
    ${({ isSidebar }): string => isSidebar === true ? '' : 'width: 56px'};
    position: relative;
    margin-bottom: 8px;

    @media ${theme.star.mediaQuery.desktop} {
        width: auto;
        margin-bottom: 0;
        padding-right: 12px;
    }
`);

export const DropdownChevron = styled(ChevronIcon)<SwitcherType>`
    width: 11px;
    position: absolute;
    right: ${({ isSidebar }): string => isSidebar === true ? '8px' : '0'};
    fill: currentcolor;
`;


export const LanguagesList = withConfig(theme => styled('ul')<SwitcherType>`
    position: absolute;
    min-width: 160px;
    ${({ isSidebar }): string => isSidebar === true ? 'width: 100%': ''};
    ${({ isSidebar }): string => isSidebar === true ? '': 'top: 36px'};
    ${({ isSidebar }): string => isSidebar === true ? '': 'right: 0'};

    box-shadow: 0 4px 8px 0 ${theme.star.languageSwitcher.bgColorTertiary};
    @media ${theme.star.mediaQuery.desktop} {
        top: 38px;
        right: 12px;
    }
`);

interface LanguageListItemPropsType {
    isActive: boolean;
    isSidebar?: boolean;
}

export const ActiveLanguageWrapper = withConfig(theme => styled('div')<SwitcherType>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 8px;
    position: relative;
    padding-right: 20px;
    height: 100%;

    ${({ isSidebar }): string => isSidebar === true ? `background-color: ${theme.star.languageSwitcher.sidebar.bgColorSecondary}` : ''};
    ${({ isSidebar }): string => isSidebar === true ? 'padding: 12px 8px': ''};
    ${({ isSidebar }): string => isSidebar === true ? `color: ${theme.star.languageSwitcher.sidebar.txtColor}` : ''};
`);

export const LanguageListItem = withConfig(theme => styled('li')<LanguageListItemPropsType>`
    background-color: ${({ isActive, isSidebar }): string => isActive ? theme.star.languageSwitcher.bgColorSecondary : isSidebar === true ? theme.star.languageSwitcher.sidebar.bgColor : theme.star.languageSwitcher.bgColor};
    color: ${({ isActive, isSidebar }): string => isActive ? theme.star.languageSwitcher.txtColorSecondary : isSidebar === true ? theme.star.languageSwitcher.sidebar.txtColor : theme.star.languageSwitcher.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 8px;
    margin: auto;
    height: 100%;
    padding: 10px 16px;
    position: relative;
    text-transform: capitalize;

    &:hover {
        background-color: ${theme.star.languageSwitcher.bgColorSecondary};
        color: ${theme.star.languageSwitcher.txtColorSecondary};

        .language-dropdown-flag-wrapper {
            outline-color: ${theme.star.languageSwitcher.bgColorQuinary};
        }
    }

    @media ${theme.star.mediaQuery.desktop} {
        padding: 8px 12px;
    }
`);

export const CheckIconWrapper = styled(CheckSingleIcon)`
    width: 12px;
    position: absolute;
    right: 12px;
    fill: currentcolor;
`;
