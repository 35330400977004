import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { PhoneIcon } from 'src/domains/layouts/shared/assets/icons/PhoneIcon';
import { SocialFacebookIcon } from 'src/domains/layouts/shared/assets/icons/social/SocialFacebookIcon';
import { SocialInstagramIcon } from 'src/domains/layouts/shared/assets/icons/social/SocialInstagramIcon';
import { SocialTwitterIcon } from 'src/domains/layouts/shared/assets/icons/social/SocialTwitterIcon';
import { ChatIcon } from 'src/domains/layouts/shared/assets/icons/ChatIcon';
import { EmailIcon } from 'src/domains/layouts/shared/assets/icons/EmailIcon';
import { withConfig } from 'src/withConfig';

export const FooterList = styled('nav')`
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 16px;
`;

export const LinkWrapper = withConfig(theme => styled(Link)`
    align-items: center;
    align-self: flex-start;
    color: ${theme.star.footerMain.txtColorSecondary};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    text-decoration: none;
    transition: color .2s ease;
    &:hover {
        color: ${theme.star.footerMain.txtColorQuinary};
    }
    &:not(:last-of-type) {
        margin-bottom: 16px;
    }
`);

const setIcon = (): SerializedStyles => {
    return css`
        display: block;
        fill: currentcolor;
        flex: 0 0 24px;
        margin-right: 8px;
        width: 24px;
    `;
};

export const IconTwitterWrapper = styled(SocialTwitterIcon)`
    ${setIcon};
`;

export const IconEnvelopeWrapper = styled(EmailIcon)`
    ${setIcon};
`;

export const IconChatWrapper = styled(ChatIcon)`
    ${setIcon};
`;

export const IconPhoneWrapper = styled(PhoneIcon)`
    ${setIcon};
`;

export const FacebookIconWrapper = styled(SocialFacebookIcon)`
    ${setIcon};
`;

export const InstagramIconWrapper = styled(SocialInstagramIcon)`
    ${setIcon};
`;
