import React, { useEffect, useMemo, useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { formatDate } from 'src/domains/players/webview/components/Account/CreditsTab/utils/formatDate';
import { FreeBetType } from 'src/domains/sportsbook/betting/betSlipState/BetSlipSheredTypes';
import { Amount } from 'src_common/common/amount/Amount';
import { Checkbox } from '../../../../checkbox/CheckBox';
import {
    ApplyButton,
    BetAmount,
    BetDetails,
    BetExpiry,
    BetItem,
    BetList,
    BetslipGiftIconWrapper,
    CloseButton,
    CloseButtonIcon,
    Container,
    Header,
    Overlay,
    Title,
} from './FreeBetsPopup.styled';

export const FreeBetsPopup = ({ handleTogglePopup, freeBets, applyFreeBet, appliedFreeBets }: FreeBetsPopupProps) => {
    const [selectedFreeBet, setSelectedFreeBet] = useState<FreeBetType[]>([]);
    const {
        appPlayersState: { usersState },
    } = useAppStateContext();

    const toggleBetSelection = (freeBet: FreeBetType) => {
        const index = selectedFreeBet.findIndex((bet) => bet.bonusId === freeBet.bonusId);

        if (index === -1) {
            setSelectedFreeBet([...selectedFreeBet, freeBet]);
        } else {
            setSelectedFreeBet(selectedFreeBet.filter((bet) => bet.bonusId !== freeBet.bonusId));
        }
    };

    const handleApplyFreeBet = () => {
        applyFreeBet(selectedFreeBet);

        handleTogglePopup();
    };

    const orderedFreeBets = useMemo(
        () =>
            [...freeBets].sort((prev, next) => {
                return new Date(prev.expiryAt).getTime() - new Date(next.expiryAt).getTime();
            }),
        [freeBets]
    );

    useEffect(() => {
        setSelectedFreeBet(appliedFreeBets);
    }, [appliedFreeBets]);

    return (
        <Overlay>
            <Container>
                <Header>
                    <BetslipGiftIconWrapper />
                    <Title>
                        <I18n
                            langKey='betslip.free-bet.available.title'
                            defaultText='Free Bets Available'
                        />
                    </Title>
                    <CloseButton onClick={handleTogglePopup}>
                        <CloseButtonIcon />
                    </CloseButton>
                </Header>

                <BetList>
                    {orderedFreeBets.map((freeBet) => (
                        <BetItem
                            key={freeBet.bonusId}
                            htmlFor={freeBet.bonusId}
                        >
                            <Checkbox
                                id={freeBet.bonusId}
                                isChecked={selectedFreeBet.some((bet) => bet.bonusId === freeBet.bonusId)}
                                handleOnChange={() => toggleBetSelection(freeBet)}
                                isDisabled={false}
                                isReversed={true}
                                label=''
                                labelHeight='22px'
                            />

                            <BetDetails>
                                <BetAmount>
                                    {usersState.money(new Amount(`${freeBet.currentAmount}`).div(100))}{' '}
                                    <I18n
                                        langKey='betslip.free-bet.available.label'
                                        defaultText='Free Bet'
                                    />
                                </BetAmount>

                                <BetExpiry>{`Expires on ${formatDate(freeBet.expiryAt)}`}</BetExpiry>
                            </BetDetails>
                        </BetItem>
                    ))}
                </BetList>

                <ApplyButton
                    onClick={handleApplyFreeBet}
                    size='medium'
                >
                    <I18n
                        langKey='betslip.free-bet.apply'
                        defaultText='Apply'
                    />
                </ApplyButton>
            </Container>
        </Overlay>
    );
};

interface FreeBetsPopupProps {
    handleTogglePopup: () => void;
    applyFreeBet: (selectedBets: FreeBetType[]) => void;
    appliedFreeBets: FreeBetType[];
    freeBets: FreeBetType[];
}
