import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { RouletteNumberEmphasizedType } from 'src/domains/casino/utils/types';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const LastResultsWrapper = styled('div')`
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
`;

const renderNumberCss = (theme: EmotionTheme, emphasized: RouletteNumberEmphasizedType, type: 'red' | 'black' | 'green'): SerializedStyles => {
    if (emphasized === 'hot') {
        return css`
            background: ${theme.star.casino.liveCasino.number.hot.bgColor};
            color: ${theme.star.casino.liveCasino.number.hot.txtColor};
        `;
    }

    if (emphasized === 'cold') {
        return css`
            background: ${theme.star.casino.liveCasino.number.cold.bgColor};
            color: ${theme.star.casino.liveCasino.number.cold.txtColor};
        `;
    }

    switch (type) {
        case 'red': {
            return css`
                border: solid 1px ${theme.star.casino.liveCasino.number.red.borderColor};
                color: ${theme.star.casino.liveCasino.number.red.txtColor};
            `;
        }
        case 'black': {
            return css`
                border: solid 1px ${theme.star.casino.liveCasino.number.black.borderColor};
                color: ${theme.star.casino.liveCasino.number.black.txtColor};
            `;
        }
        case 'green': {
            return css`
                border: solid 1px ${theme.star.casino.liveCasino.number.green.borderColor};
                color: ${theme.star.casino.liveCasino.number.green.txtColor};
            `;
        }
    }
};

interface LastResultNumberPropsType {
    type: 'red' | 'black' | 'green';
    largeVersion: boolean;
    emphasized: RouletteNumberEmphasizedType;
}

const renderElement = (theme: EmotionTheme, largeVersion: boolean): SerializedStyles => {
    if (largeVersion) {
        return css`
            font-size: ${theme.star.fontSize.xRegular};
            height: 32px;
            line-height: 2.2857;
            margin: 0 4px;
            width: 32px;
        `;
    }

    return css`
        font-size: ${theme.star.fontSize.small};
        height: 24px;
        line-height: 2;
        margin: 0 2px;
        width: 24px;
    `;
};

export const LastResultNumber = withConfig(theme => styled('div')<LastResultNumberPropsType>`
    font-weight: ${theme.star.fontWeight.bold};
    backdrop-filter: blur(1.5px);
    text-align: center;
    background: ${theme.star.casino.liveCasino.bgColorTertiary};
    ${({ largeVersion }): SerializedStyles => renderElement(theme, largeVersion)};
    ${({ emphasized, type }): SerializedStyles => renderNumberCss(theme, emphasized, type)}
`);
