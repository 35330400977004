import styled from '@emotion/styled';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { withConfig } from 'src/withConfig';

export const Wrapper = withConfig(theme => styled('div')`
    align-items: center;
    background-color: ${theme.star.notifications.marketing.bgColor};
    display: flex;
    justify-content: center;
    padding: 10px 52px 10px 10px;
    width: 100%;
    z-index: ${theme.star.zIndexBanners.marketingNotification};
`);

export const Description = withConfig(theme => styled('div')`
    color: ${theme.star.notifications.marketing.txtColor};
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin-right: 17px;
    text-align: center;
`);

export const CloseIconWrapper = withConfig(theme => styled(CloseIcon)`
    cursor: pointer;
    fill: ${theme.star.notifications.marketing.txtColor};
    position: absolute;
    right: 30px;
    width: 12px;
`);
