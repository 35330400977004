import styled from '@emotion/styled';
import { ArrowIcon } from 'src/domains/layouts/shared/assets/icons/ArrowIcon';

export const ChevronLeftIconWrapper = styled(ArrowIcon)`
    cursor: pointer;
    fill: currentcolor;
    height: 48px;
    left: 0;
    padding: 14px;
    position: absolute;
    top: 16px;
    width: 48px;
`;
