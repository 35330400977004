import styled from '@emotion/styled';
import { Iframe } from 'src/domains/layouts/webview/components/Iframe';
import { styleConfig } from 'src/domains/casino/webview/CasinoGames/game-modal/modal-body/modal-success/bingo-content/styleConfig';

export const GameContentWrapper = styled('div')`
    background-color: ${styleConfig.color.white};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (min-width: ${styleConfig.width.tablet}) {
        height: calc(100% - 56px);
        flex-direction: row;
    }
`;

export const IframeContainer = styled('div')`
    width: 100%;
    height: 100%;
    min-height: 325px;
    position: relative;
`;

export const GameIframe = styled(Iframe)`
    width: 100%;
    height: 100%;
    border: 0;
`;
