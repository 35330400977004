import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { InputElementType } from 'src/domains/players/webview/components/Form';
import { withConfig } from 'src/withConfig';

export const VerifyWithSMSForm = styled('form')`
    padding: 0 20px;
    margin: 10px 0;
`;

const InputForwardRef = React.forwardRef<HTMLInputElement, InputElementType>(
    (props, ref) => <Input className={props.className}
        isDisabled={props.isDisabled}
        isReadOnly={props.isReadOnly}
        label={props.label}
        state={props.state}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        onInput={props.onInput}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        inputRef={ref}
        inputTextAlign='center'
        colorTheme='light'
    />
);

export const VerifyWithSMSInput = styled(InputForwardRef)`
    width: 40px;
    margin: 7px 1px;
`;

export const ErrorMessageWrapper = styled('div')`
    padding: 0 20px;
`;

export const VerifyWithSMSBtnWrapper = styled(Button)`
    margin: 0 20px !important;
    width: calc(100% - 40px);
`;

export const ConfirmBtnWrapper = styled(Button)`
    display: block;
    width: 100%;
`;

export const VerifyWithSmsWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.verifyAccountTab.bgColorSecondary} !important;
    .form {
        padding: 0 20px;
    }
    /* probably to remove */
    .form__input-field {
        font-size: ${theme.star.fontSize.xMedium};
        height: 40px;
        line-height: 0.7;
        margin: 2px;
        padding: 0 !important;
        text-align: center;
        vertical-align: middle;
        width: 40px;
    }
`);

export const SmsMsgWrapper = withConfig(theme => styled('h2')`
    color: ${theme.star.verifyAccountTab.txtColor} !important;
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.medium};
    text-align: center;
    width: 100%;
`);
