import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EventsTableHeaderStyle } from 'src/domains/sportsbook/webview/components/eventsTable/EventsMainSectionWrapper';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const EventsNFLLogoImageWrapper = withConfig(theme => styled('div')`
    background: ${theme.star.eventsTableColors.bgColorSecondary};
    display: flex;
    height: 72px;
    justify-content: center;
    padding: 16px 0;
`);

export const EventsNFLLogoImage = styled('img')`
    width: 157px;
`;

const setEventTableHeader = (theme: EmotionTheme, headerStyle: EventsTableHeaderStyle): SerializedStyles => {
    if (headerStyle === EventsTableHeaderStyle.SOLID) {
        return css`
            background-color: ${theme.star.eventsTableColors.bgColor};
            padding: 0 0 6px 0;
        `;
    }

    if (headerStyle === EventsTableHeaderStyle.DOUBLE) {
        return css`
            padding: 0 0 14px 0;
        `;
    }

    return css``;
};

interface EventsTableHeaderType {
    headerStyle: EventsTableHeaderStyle;
}

export const EventsTableHeader = withConfig(theme => styled('header')<EventsTableHeaderType>`
    ${({ headerStyle }): SerializedStyles => setEventTableHeader(theme, headerStyle)}
`);

const setFilterStyle = (theme: EmotionTheme, headerStyle: EventsTableHeaderStyle, isCompetitionPage?: boolean): SerializedStyles => {
    if (headerStyle === EventsTableHeaderStyle.SOLID) {
        if (isCompetitionPage === true) {
            return css`
                background-color: ${theme.star.eventsTableColors.bgColor};
            `;
        }
        return css`
            background-color: ${theme.star.headings.section.bgColor};
        `;
    }

    if (headerStyle === EventsTableHeaderStyle.DOUBLE) {
        return css`
            padding: 0 0 14px 0;
        `;
    }
    return css``;
};

interface FiltersStyleType {
    headerStyle: EventsTableHeaderStyle;
    isCompetitionPage?: boolean;
}

export const FiltersStyle = withConfig(theme => styled('div')<FiltersStyleType>`
    ${({ headerStyle, isCompetitionPage }): SerializedStyles => setFilterStyle(theme, headerStyle, isCompetitionPage)};
    padding: 0 0 8px 8px;
`);

const setHeading = (headerStyle: EventsTableHeaderStyle): SerializedStyles => {
    if (headerStyle === EventsTableHeaderStyle.SOLID) {
        return css`
            padding: 9px 10px 9px 10px;
            margin-bottom: 4px;
        `;
    }

    if (headerStyle === EventsTableHeaderStyle.DOUBLE) {
        return css`
            padding: 10px;
            margin-bottom: 13px;
        `;
    }
    return css``;
};

interface HeadingType {
    headerStyle: EventsTableHeaderStyle;
}

export const Heading = withConfig(theme => styled('div')<HeadingType>` 
    ${({ headerStyle }): SerializedStyles => setHeading(headerStyle)};
    background-color: ${theme.star.eventsTableColors.bgColor};
    color: ${theme.star.eventsTableColors.txtColor};
    display: flex;
    align-content: center;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.7143;
    text-transform: uppercase;
`);

export const HeadingTitle = styled('span')`
    margin-left: 8px;
`;
