import styled from '@emotion/styled';

export const ScoreBoardWrapper = styled('div')`
    text-align: center;
`;

export const IframeStandard = styled('iframe')`
    border: 0;
    display: block;
    height: 200px;
    margin: 0 auto 10px;
    width: 100%;
`;

export const IframeStandardDesktop = styled(IframeStandard)`
    height: 324px;
`;
