import { TrashIcon } from 'src/domains/layouts/shared/assets/icons/TrashIcon';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

interface DesktopWrapper {
    isFooter: boolean;
}

export const YouDecideWrapper = withConfig(theme => styled('div') <DesktopWrapper>`
    display: none;
    @media ${theme.star.mediaQuery.desktop} {
        background-color: ${theme.star.rabSelectionList.bgColor};
        bottom: 0;
        display: flex;
        flex-flow: column nowrap;
        left: 0;
        position: ${({ isFooter }):string => isFooter ? 'absolute' : 'fixed'};
        right: 280px;
        width: ${({ isFooter }):string => isFooter ? '100%' : 'initial'};
        ${theme.star.zIndexGlobal.above};
    }
    @media ${theme.star.mediaQuery.largeDesktop} {
        left: ${({ isFooter }):string => isFooter ? '0' : '200px'};
    }
`);

export const YouDecideContent = styled('div')`
    flex-grow: 1;
    justify-content: center;
    position: initial;
`;

export const ContentConfirmationWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.statuses.success.bgColor};
    display: flex;
`);

export const ContentText = withConfig(theme => styled('p')`
    background-color: ${theme.star.statuses.success.bgColor};
    color: ${theme.star.statuses.error.txtColor};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    margin: 0;
    padding: 4px;
    width: 100%;
`);

export const ErrorConflictWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.statuses.error.bgColor};
    color: ${theme.star.statuses.error.txtColor};
    line-height: 1;
    padding: 4px 4px 3px 28px;
    position: relative;
`);

export const ErrorConflictDesc = withConfig(theme => styled('p')`
    background-color: ${theme.star.statuses.error.bgColor};
    color: ${theme.star.statuses.error.txtColor};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    margin: 0 2px 0 0;
    @media ${theme.star.mediaQuery.xxMobile} {
        display: inline;
    }
`);

export const RabWarningIconWrapper = styled(WarningIcon)`
    fill: currentcolor;
    position: absolute;
    left: 4px;
    top: 4px;
    width: 16px;
`;

interface YouDecideItemProps {
    isConflicting: boolean;
}

export const YouDecideItem = withConfig(theme => styled('li')<YouDecideItemProps>`
    background-color: ${theme.star.rabSelectionList.bgColorSecondary};
    box-shadow: inset 0 0 0 1px ${({ isConflicting }):string => isConflicting ? theme.star.statuses.error.bgColor : 'transparent'};
    color: ${theme.star.rabSelectionList.txtColor};
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    min-width: 165px;
    padding: 4px 6px 6px 8px;
    &:first-of-type {
        margin-top: 4px;
        @media ${theme.star.mediaQuery.desktop} {
            margin-top: 0;
        }
    }
`);

export const YouDecideItemSelection = withConfig(theme => styled('span')`
    display: block;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
`);

export const YouDecideItemMarket = withConfig(theme => styled('span')`
    display: block;
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    opacity: 0.64;
`);

export const IconTrashWrapper = styled(TrashIcon)`
    fill: currentcolor;
    margin-left: 4px;
    width: 12px;
`;

export const DeleteItemIconTrashWrapper = styled(TrashIcon)`
    align-self: flex-start;
    cursor: pointer;
    fill: currentcolor;
    margin-left: 4px;
    opacity: 0.8;
    width: 12px;
`;

export const YouDecideAddToBetSlip = withConfig(theme => styled(Button)`
    background-color: ${theme.star.rabSelectionList.bgColorQuaternary};
    color: ${theme.star.rabSelectionList.txtColorSecondary};
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.4286;
    padding: 2px 0;
    width: 100%;
    font-weight: ${theme.star.fontWeight.regular};
`);

export const AddToBetSlipCurrency = withConfig(theme => styled('strong')`
    font-size: ${theme.star.fontSize.regular};
    line-height: 1.5;
    &:first-of-type {
        margin-right: 4px;
    }
    &:last-of-type {
        margin-left: 4px;
    }
`);

export const AddToBetSlipTxt = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.4286;
    margin: 0;
`);

export const YouDecideList = withConfig(theme => styled('ul')`
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(3, 1fr);
    max-height: 153px;
    overflow-y: auto;
    padding: 8px;
    @media ${theme.star.mediaQuery.desktop} {
        gap: 8px;
    }
    @media ${theme.star.mediaQuery.xxLargeDesktop} {
        grid-template-columns: repeat(4, 1fr);
    }
`);

export const YouDecideMore = withConfig(theme => styled('li')`
    --border-color: ${theme.star.rabSelectionList.borderColor};
    --linear-gradient: var(--border-color),
        var(--border-color) 9px,
        transparent 9px,
        transparent 18px,
        var(--border-color) 18px;

    background-image: repeating-linear-gradient(0deg, var(--linear-gradient)),
        repeating-linear-gradient(90deg, var(--linear-gradient)),
        repeating-linear-gradient(180deg, var(--linear-gradient)),
        repeating-linear-gradient(270deg, var(--linear-gradient));

    align-items: center;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 1px 100%,
        100% 1px,
        1px 100%,
        100% 1px;
    color: ${theme.star.rabSelectionList.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    justify-content: center;
    line-height: 1.3334;
    margin: 0 0 4px;
    padding: 12px;
    &:only-of-type {
        margin: 0;
    }
    @media ${theme.star.mediaQuery.desktop} {
        margin-bottom: 0;
    }
`);

export const YouDecideListHeader = withConfig(theme => styled('div')`
    align-items: center;
    background-color: ${theme.star.rabSelectionList.bgColorTertiary};
    display: flex;
    height: 24px;
    justify-content: space-between;
    padding: 0 8px;
`);

export const YouDecideListHeaderCount = withConfig(theme => styled('div')`
    color: ${theme.star.rabSelectionList.txtColor};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
`);

interface PropsTypes {
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const YouDecideListHeaderRemove = withConfig(theme => styled('div')<PropsTypes>`
    align-items: center;
    color: ${theme.star.rabSelectionList.txtColor};
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    opacity: 0.8;
`);
