import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface SwitchIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const SwitchIcon = observer(
    'SwitchIcon',
    ({ className, onClick }: SwitchIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M24 15.2v2.572a.407.407 0 01-.127.3.408.408 0 01-.302.128H5.143v2.572a.41.41 0 01-.127.3.41.41 0 01-.302.128.504.504 0 01-.321-.134L.12 16.78a.4.4 0 01-.12-.294c0-.125.04-.228.12-.308l4.286-4.286c.08-.08.183-.12.308-.12.116 0 .217.042.302.127a.414.414 0 01.127.301v2.572H23.57c.117 0 .217.042.302.127a.41.41 0 01.127.301H24zm0-7.286c0 .125-.04.228-.12.308l-4.286 4.286a.417.417 0 01-.308.12.413.413 0 01-.302-.127.413.413 0 01-.127-.301V9.629H.43a.414.414 0 01-.302-.128A.406.406 0 010 9.2V6.629c0-.116.042-.217.127-.302A.413.413 0 01.43 6.2h18.428V3.629c0-.125.04-.228.12-.308a.422.422 0 01.309-.121c.107 0 .214.045.321.134l4.273 4.272c.08.08.12.183.12.308H24z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
