import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const RunnerDesc = withConfig(theme => styled('span')`
    color: ${theme.star.quickPick.txtColorSecondary};
    display: block;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`);
