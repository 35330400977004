import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const SingleScoreWrapper = withConfig(theme => styled('div')`
    align-items: center;
    background-color: ${theme.star.eventRow.bgColorTertiary};
    color: ${theme.star.eventRow.txtColorTertiary};
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 26px;
    justify-content: center;
    padding: 0 4px;
    text-align: center;
    @media ${theme.star.mediaQuery.desktop} {
        flex-flow: row nowrap;
        flex: 0 0 65px;
        padding: 0 8px;
    }
`);

export const SingleScoreItem = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    font-weight: ${theme.star.fontWeight.medium};
    &:first-of-type {
        margin-bottom: 8px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        &:first-of-type {
            margin-bottom: 0;
            &::after {
                content: '-';
            }
        }
    }
`);
