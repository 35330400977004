import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const Badge = withConfig(
    (theme) => styled.span`
        background-color: rgba(28, 27, 31, 0.1);
        width: fit-content;
        border-radius: 18px;
        padding: 0px 8px;
        font-size: ${theme.star.fontSize.xSmall};
        text-align: center;
    `
);

export const Count = withConfig(
    (theme) => styled.span`
        background-color: rgba(28, 27, 31, 0.1);
        border-radius: 18px;
        padding: 0px 5px;
        font-size: ${theme.star.fontSize.xSmall};
        cursor: pointer;
        margin-left: 5px;
        min-width: 20px;
        position: relative;
    `
);

export const BetTypePopup = styled.div`
    position: absolute;
    top: 80%;
    right: 0;
    width: 104px;
    max-height: 250px;
    overflow-y: auto;
    min-width: min-content;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 5px;
    z-index: 1;

    span {
        width: 100%;
        white-space: nowrap;
    }
`;
