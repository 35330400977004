import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';

export const CheckMarkIconWrapper = withConfig(theme => styled(CheckSingleIcon)`
    background: ${theme.star.topUp.bgColor};
    border-radius: 50%;
    display: block;
    fill: ${theme.star.topUp.bgColorSecondary};
    height: 80px;
    margin: 80px auto 32px;
    padding: 24px;
    width: 80px;
`);
