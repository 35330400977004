import * as t from 'io-ts';
import { Amount } from 'src_common/common/amount/Amount';

export const PriceIO = t.interface({
    d: t.number,
    f: t.string,
});

export type PriceType = t.TypeOf<typeof PriceIO>;

export const SmallLegIO = t.interface({
    type: t.union([t.undefined, t.string]),
    selection: t.union([t.undefined, t.interface({ id: t.number })]),
    market: t.union([t.undefined, t.interface({ id: t.number })]),
    event: t.union([t.undefined, t.interface({ id: t.number })]),
    price: t.union([PriceIO, t.undefined, t.null]),
    priceType: t.union([t.undefined, t.string]),
});

export const ErrorIO = t.interface({
    code: t.string,
    debugDetails: t.union([t.null, t.unknown]),
    details: t.union([t.null, t.unknown]),
    field: t.union([t.null, t.unknown]),
    leg: t.union([t.undefined, SmallLegIO]),
    pointer: t.string,
    resource: t.string,
});

export type ErrorType = t.TypeOf<typeof ErrorIO>;

export const CountryIO = t.union([
    t.interface({
        readOnly: t.boolean,
        hidden: t.boolean,
        value: t.string,
    }),
    t.string,
]);

export type CountryType = t.TypeOf<typeof CountryIO>;

export const CurrencyIO = t.union([
    t.interface({
        readOnly: t.boolean,
        hidden: t.boolean,
        value: t.string,
    }),
    t.string,
]);

export type CurrencyType = t.TypeOf<typeof CurrencyIO>;

export const FreeBetIO = t.interface({
    currentAmount: t.number,
    expiryAt: t.string,
    bonusId: t.string,
});

export type FreeBetType = t.TypeOf<typeof FreeBetIO>;

export const FreeBetCreditsIO = t.interface({
    amount: t.number,
    id: t.number,
    newFreeBetId: t.string,
});

export type FreeBetCreditsType = t.TypeOf<typeof FreeBetCreditsIO>;

export const PlayableBalanceAmountsIO = t.interface({
    currentAmount: t.union([t.number, t.null, t.undefined]),
    requiredAmount: t.union([t.number, t.null, t.undefined]),
});

export type PlayableBalanceAmountsType = t.TypeOf<typeof PlayableBalanceAmountsIO>;

export interface ForTotalStakeTypes {
    eachWay: boolean | null;
    numLines: number | null;
    stakePerLine: string | null;
    potentialReturns: string | null;
    priceType?: string | null;
}

export interface BetSlipUserDataType {
    currency: string;
    country: string;
    balance: Amount | null;
    accountAuthenticated: boolean;
    userId: number | null;
    ipUser: string | null;
}
