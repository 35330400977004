import styled from '@emotion/styled';
import { AccountContent } from 'src/domains/players/webview/components/Account/accountParts/Account.style';
import { withConfig } from 'src/withConfig';

export const CaptionWrapper = withConfig(theme => styled('div')`
    background: ${theme.star.accountHistoryHeader.bgColor};
    color: ${theme.star.accountHistoryHeader.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    height: 24px;
    line-height: 1.4;
    margin-right: -7px;
    padding: 5px 16px;
`);

export const Label = styled('span')`
    display: inline-block;
    width: 25%;
    &:first-of-type {
        width: 50%;
    }
`;

export const List = styled(AccountContent)`
    padding: 16px;
`;
