import styled from '@emotion/styled';
import { FreeBetIcon } from 'src/domains/layouts/shared/assets/icons';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const Container = styled('div')`
    padding: 8px 16px;
    gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const BoxItem = styled('a')`
    border-radius: 4px;
    padding: 16px;
    width: 50%;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    border: 1px solid currentColor;
`;

export const Title = withConfig(
    (theme) => styled('span')`
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: ${theme.star.fontSize.small};
    `
);

export const AmountValue = withConfig(
    (theme) => styled('span')`
        font-weight: 700;
        font-size: ${theme.star.fontSize.xRegular};
    `
);

export const Arrow = styled(ChevronIcon)`
    width: 14px;
    height: 14px;
    margin-left: auto;
`;

export const FreeBetIconWrapped = styled(FreeBetIcon)`
    width: 14px;
    height: 14px;
`;

export const AmountWrapper = styled('span')`
    display: flex;
    align-items: center;
    gap: 6px;
`;
