import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface XTSPropsType {
    className?: string;
    onClick?: () => void;
}

export const XTSIcon = observer('XTSIcon', ({ className, onClick }: XTSPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill='#072D4F'
                d='M1.465 2h7.033l3.371 5.318L15.27 2h7.12l-6.654 9.736L23 22.372h-7.265l-3.866-5.812-3.807 5.813H1l7.207-10.376L1.465 2z'
            />
        </SvgElement>
    );
});
