import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { RacingPostIcon } from 'src/domains/layouts/shared/assets/icons/RacingPostIcon';
import { css, SerializedStyles } from '@emotion/react';
import { withConfig } from 'src/withConfig';

export const TabsSection = withConfig(theme => styled('section')`
    background-color: ${theme.star.tabsColors.bgColor};
    position: relative;
`);

export const TabsUl = withConfig(theme => styled('nav')`
    align-items: center;
    background-color: ${theme.star.raceCardColors.bgColorTabs};
    border-left: 1px solid ${theme.star.raceCardColors.borderColorHeaderDetails};
    display: flex;
    flex-flow: row nowrap;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    justify-content: flex-start;
    padding: 8px;
    overflow-x: auto;
    @media ${theme.star.mediaQuery.desktop} {
        overflow: hidden;
    }
`);

export const TabsContent = withConfig(theme => styled('div')`
    color: ${theme.star.tabsColors.txtColor};
`);

const buildButtonColors = (theme: EmotionTheme, isActive: boolean, isFinished: boolean): SerializedStyles => {
    if (isActive) {
        return css`
            background: ${theme.star.filtersColors.secondary.bgColor};
            border: 1px solid ${theme.star.filtersColors.secondary.borderColor};
            color: ${theme.star.filtersColors.secondary.txtColorSecondary};
        `;
    }

    if (isFinished) {
        return css`
            background: ${theme.star.raceCardColors.bgColorRaceLabel};
            border: 1px solid ${theme.star.raceCardColors.borderColorTab};
            color: ${theme.star.raceCardColors.txtColorFinishedRace};
        `;
    }

    return css`
        background: transparent;
        border: 1px solid ${theme.star.filtersColors.secondary.borderColor};
        color: ${theme.star.filtersColors.secondary.txtColor};
    `;
};

interface FilterItemStyledPropsType {
    isActive: boolean;
    isFinished: boolean;
}

export const FilterItem = withConfig(theme => styled('button')<FilterItemStyledPropsType>`
    ${({ isActive, isFinished }): SerializedStyles => buildButtonColors(theme, isActive, isFinished)};
    align-items: center;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 62px;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    height: 32px;
    justify-content: center;
    line-height: 1.3334;
    margin-right: 4px;
    padding: 8px 12px;
    text-transform: capitalize;
    white-space: nowrap;
    &:last-of-type {
        margin-right: 0;
    }
`);

export const RacingPostIndicator = styled(RacingPostIcon)`
    fill: currentcolor;
    flex: 0 0 16px;
    margin-right: 4px;
    width: 16px;
`;
