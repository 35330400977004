import styled from '@emotion/styled';

interface PageButtonProps {
    isActive?: boolean;
}

export const PaginationWrapper = styled.div`
    max-width: 350px;
    display: flex;
    justify-content: center;
    margin: 20px 0;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PageButton = styled.button<PageButtonProps>`
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    background-color: ${({ isActive }) => (isActive ? '#FFCC00' : '#f0f0f0')};
    color: ${({ isActive }) => (isActive ? '#111' : '#000')};
    font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 4px;

    &:hover {
        background-color: ${({ isActive }) => (isActive ? '#FFCC00' : '#ccc')};
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

export const ArrowRight = styled(PageButton)`
    margin-left: auto;
    background: none;
`;

export const ArrowLeft = styled(PageButton)`
    margin-right: auto;
    background: none;
`;
