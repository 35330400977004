import styled from '@emotion/styled';
import { Iframe } from 'src/domains/layouts/webview/components/Iframe';
import { styleConfig } from 'src/domains/casino/webview/CasinoGames/game-modal/modal-body/modal-success/bingo-content/styleConfig';

export const ModalContainer = styled('div')`
    background-color: ${styleConfig.color.gray};
    color: ${styleConfig.color.black};
    height: 27vh;
    margin: 0 10px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media (min-width: ${styleConfig.width.tablet}) {
        height: 202px;
    }
`;

export const GameIframe = styled(Iframe)`
    width: 100%;
    height: 100%;
    border: 0;
    border-bottom: solid 1px ${styleConfig.color.white};
`;
