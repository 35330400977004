import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface PropsType {
    isOpen: boolean;
}

export const MeetingsRowEvents = withConfig(theme => styled('div')<PropsType>`
    background-color: ${theme.star.meetingsRowColors.bgColor};
    display: ${({ isOpen }): string => isOpen ? 'flex' : 'none'};
    flex-flow: row nowrap;
    overflow-x: auto;
    @media ${theme.star.mediaQuery.tablet} {
        flex-wrap: wrap;
        flex: 1 1 0%;
    }
`);

