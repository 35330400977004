import { RaceSummaryRow } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

type ListPropsType = {
    isScrollbar: boolean;
}

export const StaticListWrapperStyled = withConfig(theme => styled('ul')<ListPropsType>`
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0 207px;
    &::after {
        position: absolute;
        top: 0;
        left: 100%;
        content: '';
        display: ${({ isScrollbar }): string => isScrollbar === true ? 'block' : 'none'};
        height: 100%;
        width: 14px;
        box-shadow: inset 2px 0 3px ${theme.star.raceSummary.bgColorTertiary};
        z-index: 1;
    }
`);

type ScrollbarListWrapperStyledTypeProps = {
    columnCount: number;
}

export const ScrollbarListWrapperStyled = styled('ul')<ScrollbarListWrapperStyledTypeProps>`
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;
    flex: ${({ columnCount }): string => `0 1 ${columnCount * 64}px`};
    min-width: 65px;
    align-items: start;
`;

export const TwoColumnsListWrapper = styled('div')`
    display: flex;
    flex-wrap: nowrap;
`;

export const ScrollRowWrapper = styled(RaceSummaryRow)<{isNonRunner: boolean}>`
    display: flex;
    flex-wrap: nowrap;
    height: 48px;
    border-bottom: 0;
    opacity: ${({ isNonRunner }): string => isNonRunner === true ? '0.4' : '1'};
`;
