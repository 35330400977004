import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    AmountValue,
    AmountWrapper,
    Arrow,
    BoxItem,
    Container,
    FreeBetIconWrapped,
    Title,
} from './AccountFreeBet.styled';

export const AccountFreeBet = observer(() => {
    const {
        appLayoutsState: { starRouter },
        appPlayersState: { usersState, bonusState },
    } = useAppStateContext();

    useEffect(() => {
        bonusState.refreshBalance();
    }, [bonusState]);

    if (Number(bonusState.balance.value) === 0) {
        return null;
    }

    return (
        <Container>
            <BoxItem onClick={starRouter.redirectToFreeBets}>
                <Title>
                    <I18n
                        langKey='account.summary.balance.free-bet.title'
                        defaultText='Free Bets:'
                    />

                    <Arrow position='right' />
                </Title>

                <AmountWrapper>
                    <FreeBetIconWrapped />
                    <AmountValue>{usersState.money(bonusState.balance)}</AmountValue>
                </AmountWrapper>
            </BoxItem>
        </Container>
    );
});
