import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface GameStatusWrapperPropsType {
    isFullScreen: boolean;
    isMini: boolean;
}

export const GameStatusWrapper = withConfig(theme => styled('div')<GameStatusWrapperPropsType>`
    width: 100%;
    height: 32px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    background-color: ${theme.star.casino.bgColor};
    font-size: ${theme.star.fontSize.small};
    ${({ isMini, isFullScreen }): SerializedStyles | false => isMini && css`
        font-size: ${theme.star.fontSize.xSmall};
        gap: ${isFullScreen ? '8px' : 0};
    `}
    ${({ isFullScreen }): SerializedStyles | false => isFullScreen && css`
        margin: 0;
        font-size: ${theme.star.fontSize.small};
    `}

    @media ${theme.star.mediaQuery.tablet} {
        height: 48px;
        gap: 32px;
        ${({ isMini, isFullScreen }): SerializedStyles | false => isMini && css`
            height: 32px;
            gap: ${isFullScreen ? '8px' : 0};
        `}
        ${({ isFullScreen }): SerializedStyles | false => isFullScreen && css`
            height: 48px;
            gap: 32px;
        `}
    }
`);

export const PropertyWrapper = styled('div')`
    display: flex;
    line-height: 1.3334;
    margin-left: 0;
`;

export const PropertyLabel = withConfig(theme => styled('div')`
    color: ${theme.star.casino.txtColor};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const PropertyValue = withConfig(theme => styled('div')`
    margin-left: 8px;
    color: ${theme.star.casino.txtColorSecondary};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const PropertyValueCounter = styled(PropertyValue)`
    width: 60px;
`;

