import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const TimerWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TimerItem = withConfig(theme => styled('div')`
    color: ${theme.star.popup.txtColorQuinary};
    align-items: center;
    border: 1px solid ${theme.star.popup.bgColorQuaternary};
    display: flex;
    flex: 0 0 66px;
    font-size: ${theme.star.fontSize.medium};
    font-weight: ${theme.star.fontWeight.bold};
    height: 48px;
    justify-content: center;
    line-height: 1;
    padding: 12px 8px 8px;
    position: relative;
    width: 66px;
    &:not(:last-of-type) {
        &::after {
            content: ':';
            font-size: ${theme.star.fontSize.xRegular};
            line-height: 1.4286;
            position: absolute;
            left: 100%;
            margin-left: 10px;
        }
    }
`);
