import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { streamButtonBarDesktop } from 'src/domains/sportsbook/shared/Components';
import { withConfig } from 'src/withConfig';

const setPositionMobile = (offsetTop: number, isStreamFloating: boolean): string => {
    if (offsetTop > 0) {
        if (isStreamFloating) {
            return `
                top: 0;
            `;
        }

        return `
            top: ${offsetTop + streamButtonBarDesktop}px;
        `;
    }

    return `
        top: 0;
    `;
};

const setPositionDesktop = (): string => {
    return `
        top: 0;
    `;
};

const setBannersWrapperPosition = (theme: EmotionTheme, offsetTop: number, isStreamFloating: boolean): string => {
    return `
        ${setPositionMobile(offsetTop, isStreamFloating)}

        @media ${theme.star.mediaQuery.desktop} {
            ${setPositionDesktop()}
        }
    `;
};

export const BannersWrapper = withConfig(theme => styled('div')<{offsetTop: number; isStreamFloating: boolean}>`
    position: fixed;
    left: 0;
    width: 100%;
    z-index: ${theme.star.zIndexBanners.bannersWrapper};
    ${({ offsetTop, isStreamFloating }): string => setBannersWrapperPosition(theme, offsetTop, isStreamFloating)};
`);
