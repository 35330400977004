import * as React from 'react';
import * as Sentry from '@sentry/react';

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//IOVATION CONFIG - INITIALIZE
import iovation_config from './utils/iovation/config';
import iovation from './utils/iovation/iovation';
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

//must be called even though this function does not do anything (babel issue)
iovation_config();
iovation();

import queryString from 'query-string';
import { setCookie, getCookie } from './domains/layouts/config/config';
import { autorun, configure } from 'mobx';
// import { setLogEnabled } from 'mobx-react-devtools';

import { AppState } from './appState/AppState';
import { ModernizrDetect } from './domains/layouts/state/environmentState/modernizr/ModernizrBrowser';
import { initWebsocketV1 } from './index.init.websocketV1';
import { Session } from 'src_common/sdk/session';
import { starAppStateInit } from './universes/star/AppState/starAppStateInit';
import { getEnvVariables } from './utils/getEnvVariables';
import { initParamTrading } from './index.newtrading';
import { getUniverseModuleByEnvVariables } from './domains/common/universes';
import { initParamNewDesign } from 'src/utils/new-design';
import { Common } from './domains/common/Common';
import { TrpcClient } from './appState/TrpcClient';
import { FullStoryState } from './domains/layouts/state/fullStory/FullStoryState';

export const getElement = (id: string): HTMLElement => {
    const node = document.getElementById(id);
    if (node !== null) {
        return node;
    }

    const newNode = document.createElement('div');
    newNode.setAttribute('id', id);
    document.body.appendChild(newNode);
    return newNode;
};

export const initIndex = async (): Promise<[Common, AppState]> => {
    //https://mobx.js.org/refguide/api.html -> isolateGlobalState
    configure({ isolateGlobalState: true });

    initParamTrading();
    // Temporary solution to show clients new design system implementation. Remove it once the new design is released.
    initParamNewDesign();

    // setLogEnabled(true);

    React.Component.prototype.componentDidCatch = (error: unknown, errorInfo: unknown): void => {
        console.error('Default error componentDidCatch', error, errorInfo);
    };

    const envVariables = await getEnvVariables();

    if (envVariables === null) {
        throw Error('getEnvVariables: Failed to retrieve environment variables');
    }

    const websiteBaseUrl = window.location.origin;

    // Create application instance
    const session = await Session.start({
        websocket_api_host: envVariables.websocket_api_host,
        websocketUrl: envVariables.websocket_host_v2,
        apiTimeout: 60 * 1000,
        startPing: true,
        cacheApiCall: envVariables.cacheApiCall,
        getInitJwt: (): string | null => {
            const value = getCookie('website.sid');
            return value;
        },
        setJwt: (newJwt: string): void => {
            setCookie('website.sid', newJwt, 120);
        },
        mode: 'browser',
    });
    const startingUrl = location.pathname + location.search;
    const universeModule = await getUniverseModuleByEnvVariables(envVariables);
    const faviconUrl = universeModule.favicon;

    const isBrowser = true;
    const common = new Common(
        startingUrl,
        envVariables,
        session,
        isBrowser,
        new TrpcClient(isBrowser, session),
        universeModule.themeColors,
        universeModule.features,
        faviconUrl,
        ModernizrDetect,
        websiteBaseUrl
    );

    const appState = new AppState(common);
    starAppStateInit(appState);

    //For sentry
    const { sentryUrl } = appState.config;
    const sentry = envVariables.sentry;

    window.addEventListener('click', () => {
        appState.appLayoutsState.userAttributionState.userAttributionsLocalStorage.validateDate();
    });

    if (sentry !== null && sentryUrl !== null) {
        try {
            Sentry.init({
                dsn: sentryUrl,
                attachStacktrace: true,
                sampleRate: 0.6,
                tracesSampleRate: 0.8,
                beforeSend: (event, hint) => {
                    const error = hint.originalException;
                    if (
                        error instanceof TypeError &&
                        (error.message.includes('SessionApi: Internal server error') ||
                            error.stack?.includes('Object captured as promise rejection with keys: message') === true ||
                            error.stack?.includes('wc_track') === true ||
                            error.name.includes('onMessage(widget)'))
                    ) {
                        return null;
                    }

                    return event;
                },
                ignoreErrors: [
                    'analytics',
                    'google_tag_manager',
                    'Ping Session - Response code 200 expected',
                    'Network Error',
                    'SessionApi: Internal server error',
                    '#img-arena-event-centre',
                    //Recommended list
                    'top.GLOBALS',
                    'originalCreateNotification',
                    'canvas.contentDocument',
                    'MyApp_RemoveAllHighlights',
                    'http://tt.epicplay.com',
                    "Can't find variable: ZiteReader",
                    'jigsaw is not defined',
                    'ComboSearch is not defined',
                    'http://loading.retry.widdit.com/',
                    'atomicFindClose',
                    // Facebook borked
                    'fb_xd_fragment',
                    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
                    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
                    'bmi_SafeAddOnload',
                    'EBCallBackMessageReceived',
                    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                    'conduitPage',
                    // Generic error code from errors outside the security sandbox
                    // You can delete this if using raven.js > 1.0, which ignores these automatically.
                    'Script error.',
                    "undefined is not an object (evaluating 'n.originalEvent.origin')",
                    'Object captured as promise rejection with keys: message',
                    'Non-Error promise rejection captured with value: undefined',
                ],
                denyUrls: [
                    // Google Adsense
                    /pagead\/js/i,
                    // Facebook flakiness
                    /graph\.facebook\.com/i,
                    // Facebook blocked
                    /connect\.facebook\.net\/en_US\/all\.js/i,
                    // Woopra flakiness
                    /eatdifferent\.com\.woopra-ns\.com/i,
                    /static\.woopra\.com\/js\/woopra\.js/i,
                    // Chrome extensions
                    /extensions\//i,
                    /^chrome:\/\//i,
                    // Other plugins
                    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
                ],
                // debug: true,
                replaysSessionSampleRate: 0.5,
                replaysOnErrorSampleRate: 0.8,
                integrations: [
                    new Sentry.Replay({
                        networkDetailAllowUrls: ['/api/betslip/place-bet-from-website-bets'],
                        networkRequestHeaders: ['X-Custom-Header'],
                        networkResponseHeaders: ['X-Custom-Header'],
                        maskAllText: false,
                    }),
                    new Sentry.BrowserProfilingIntegration(),
                    new Sentry.BrowserTracing(),
                ],
            });
            Sentry.setTag('pbe-enviroment', 'website');
            Sentry.setTag('pbe-universe', envVariables.universe);
        } catch (err) {
            console.error(err);
        }
    }

    const urlParams = queryString.parse(window.location.search);

    //Set cookie from url
    for (const cookieName of ['btag', 'stag', 'referrer']) {
        const valueFromUrl = urlParams[cookieName];

        if (typeof valueFromUrl === 'string') {
            setCookie(cookieName, valueFromUrl, 31, 'None');
        }

        if (Array.isArray(valueFromUrl)) {
            const firstValue = valueFromUrl[0];
            if (firstValue !== undefined) {
                setCookie(cookieName, firstValue, 31, 'None');
            }
        }
    }

    initWebsocketV1(appState);

    window.onpopstate = (): void => {
        appState.appLayoutsState.starRouter.setUrl(location.pathname + location.search);
    };

    autorun(() => {
        const { url } = appState.appLayoutsState.starRouter;
        const { pathname, search } = window.location;

        if (`${pathname}${search}` !== url) {
            window.history.pushState(null, '', url);
        }
    });

    // init fullstory in constructor
    FullStoryState.get(common).runOnStart();

    return [common, appState];
};
