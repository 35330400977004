import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface LoadingSpinnerIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const GreyhoundRacingIcon = observer(
    'GreyhoundRacingIcon',
    ({ className, onClick }: LoadingSpinnerIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                fill='#072d4f'
                clipRule='evenodd'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <defs/>
                <path
                    fill='fill'
                    fillRule='nonzero'
                    d='M5.917 20.527C2.94 18.574.962 15.124.962 11.193c0-.121.002-.243.006-.363.652-.57 1.278-1.109 1.849-1.6.945-.813 1.74-1.496 2.254-1.977.087-.916.175-1.472.468-2.16.879-.262 1.231-.589 1.524-1.047.542-.364.955-.083 1.383.208.15.102.303.206.463.283.498-.983 1.348-1.211 2.286-.983.014.074.027.151.04.231.068.415.148.901.517 1.34.336.453.882.727 1.458 1.016.882.442 1.833.919 2.206 2.127.76 1.398 2.338 2.091 3.669 2.676.414.181.803.352 1.137.531 1.096.314 1.464.537 1.906.873-.027.271-.064.54-.11.805-.17.362-.449.46-.799.583-.057.02-.116.04-.177.063-.674.818-1.816 1.341-2.491 1.407-.306.044-.842-.033-1.413-.115-.685-.098-1.421-.204-1.869-.114-1.202.229-1.553 0-1.905-.524-.27-.105-.747-.047-1.3.02-.826.101-1.819.222-2.54-.151-.278-.197-.653.617-1.119 1.63-.465 1.01-1.021 2.217-1.664 2.822 0 .785-.206 1.014-.616 1.308a14.01 14.01 0 01-.208.445z'/>
            </SvgElement>
        );
    }
);

