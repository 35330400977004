import React, { useCallback, useState } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { FreeBetType } from '../../../betSlipState/BetSlipSheredTypes';
import { BetslipGiftIconWrapper, FreeBetButton } from './BetSlipFreeBet.styled';
import { FreeBetsPopup } from './components/FreeBetsPopup';
import { observer } from 'mobx-react-lite';

export const BetSlipFreeBet = observer(({ applyFreeBet, freeBets, appliedFreeBets }: BetSlipFreeBetPropsType) => {
    const [isShown, setIsShown] = useState(false);

    const {
        appSportsBookState: {
            betSlipState: { betslipData },
        },
    } = useAppStateContext();

    const handleTogglePopup = useCallback(() => {
        setIsShown((prev) => {
            betslipData.setIsFreeBetPopupVisible(!betslipData.isFreeBetPopupVisible);

            return !prev;
        });
    }, [betslipData]);

    return (
        <>
            <FreeBetButton onClick={handleTogglePopup}>
                <BetslipGiftIconWrapper />
            </FreeBetButton>

            {isShown && (
                <FreeBetsPopup
                    handleTogglePopup={handleTogglePopup}
                    applyFreeBet={applyFreeBet}
                    freeBets={freeBets}
                    appliedFreeBets={appliedFreeBets}
                />
            )}
        </>
    );
});

type BetSlipFreeBetPropsType = {
    applyFreeBet: (selected: FreeBetType[]) => void;
    freeBets: FreeBetType[];
    appliedFreeBets: FreeBetType[];
};
