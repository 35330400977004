import styled from '@emotion/styled';
export const SliderWrapper = styled('div')`
    position: relative;
    width: 100%;
`;

export const BannersWrapper = styled('div')`
    align-items: flex-start;
    border-radius: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
`;

export const BannerWrapper = styled('div')<{offset: number; maxHeight?: number; minHeight?: number}>`
    ${({ maxHeight }): string => (maxHeight === undefined) ? '' : `max-height: ${maxHeight}px`};
    ${({ minHeight }): string => (minHeight === undefined) ? '' : `min-height: ${minHeight}px`};
    flex: 0 0 calc(100% + 16px);
    padding-right: 16px;
    position: relative;
    transform: translateX(${({ offset }): number => -offset * 100}%);
    transition: transform .5s ease;
    width: calc(100% + 16px);
`;

export const PaginationWrapper = styled('div')`
    position: relative;
    height: 8px;
    margin-top: 16px;
    & > div {
        bottom: 0;
    }
`;
