import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;
interface PropsType {
  className?: string;
  onClick?: () => void;
}

export const SignUpIcon = observer('SignUpIcon', ({ className, onClick }: PropsType): JSX.Element => (
    <SvgElement
        className={className}
        height='24'
        onClick={onClick}
        viewBox='0 0 25 24'
        width='25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path fillRule='evenodd' clipRule='evenodd' d='M21.7964 4.51822L19.6478 2.36969C19.1243 1.84615 18.2386 1.88313 17.6694 2.4523L15.6509 4.47084L19.6953 8.51515L21.7138 6.49666C22.2828 5.92748 22.3199 5.04171 21.7964 4.51822ZM8.02442 20.2128L3.9532 16.1416L15.0955 4.99931L19.1667 9.07053L8.02442 20.2128ZM2.16602 22L3.57908 16.8485L7.31753 20.5871L2.16602 22Z' fill='fill'/>
    </SvgElement>
));
