import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import Select from 'react-select';
import { CheckSingleIcon } from 'src/domains/layouts/shared/assets/icons/CheckSingleIcon';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';

export const SelectContainer = styled('div')`
    margin-bottom: 8px;
`;

export const NewSelect = withConfig(theme => styled(Select)`
    .react-select__control {
        border-radius: 0;
        border: 0;
        background: ${theme.star.form.light.bgColor};
        color: ${theme.star.form.light.txtColor};
        height: 40px;
    }

    .react-select__indicator-separator {
        display: none;
        margin: 0;
    }

    .react-select__control--is-focused {
        box-shadow: none;
    }

    .react-select__indicator, .react-select__indicator:hover  {
        color: ${theme.star.form.bgColor};
        padding: 8px 16px;
    }

    .react-select__menu {
        border-radius: 0;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.32);
    }

    .react-select__option--is-selected,
    .react-select__option--is-focused  {
        background-color: ${theme.star.form.bgColorQuaternary};
        color: ${theme.star.form.txtColorQuaternary};
    }

    .react-select__option{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;
    }

    .react-select__menu-list {
        padding: 0;
    }
`);

export const CheckIcon = styled(CheckSingleIcon)`
    fill: currentcolor;
    width: 12px;
`;

export const SelectIcon = styled(ChevronIcon)`
    width: 12px;
`;
