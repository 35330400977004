import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface CheckCircleOutlineIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const CheckCircleOutlineIcon = observer('CheckCircleOutlineIcon', ({ className, onClick }: CheckCircleOutlineIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
            data-test='check-circle-outline-icon'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 1.375c-5.316 0-9.625 4.31-9.625 9.625 0 5.316 4.31 9.625 9.625 9.625 5.316 0 9.625-4.31 9.625-9.625 0-5.316-4.31-9.625-9.625-9.625zM9.43 17.456c.199 0 .424-.13.583-.288l8.862-8.795-1.16-1.185-8.279 8.232-3.13-3.092-1.181 1.166 3.714 3.668c.166.157.392.294.59.294z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
