import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { ErrorMessageDeprecated } from 'src/domains/layouts/webview/components/Messages';
import { useAppStateContext } from 'src/appState/AppState';

/**
 * @deprecated Please try to remove this or replace it with Messages component
 */
export const OtherErrorsMessages = observer('OtherErrorsMessages', () => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const { errors } = betSlipState.basicBetSlipState;

    return (
        <>
            {errors.map((error) => {
                if (error.resource === 'Wallet' && error.code === 'minimum') {
                    return null;
                }
                return (
                    <ErrorMessageDeprecated
                        key={`${error.pointer ?? error.resource}-${error.code}`}
                        message={error}
                    />
                );
            })}
        </>
    );
});
