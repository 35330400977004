import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const SelectionNestedWrapper = withConfig(theme => styled('div')`
    border-right: 1px solid ${theme.star.selectionsGroup.borderColor};
    border-left: 1px solid ${theme.star.selectionsGroup.borderColor};
    display: flex;
    flex-flow: column nowrap;
`);

export const SelectionRabFiltersItem = withConfig(theme => styled('ul')`
    background: ${theme.star.marketGroupGoalscorer.filters.bgColor};
    border-bottom: 1px solid ${theme.star.selectionsGroup.borderColor};
    border-left: 1px solid ${theme.star.selectionsGroup.borderColor};
    border-right: 1px solid ${theme.star.selectionsGroup.borderColor};
    display: flex;
    padding: 8px;
`);

export const ThreeColumnContainer = styled('div')`
    display: flex;
    padding: 0;
`;

export const ThreeColumnItem = withConfig(theme => styled('ul')`
    width: 33.333%;
    &:last-of-type {
        li {
            border-left: 1px solid ${theme.star.selectionsGroup.borderColor};
            border-right: none;
        }
    }

    &:nth-of-type(2) {
        li {
            border-left: none;
            border-right: none;
        }
    }
`);
