/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { getBetsTranslation } from 'src/domains/layouts/shared/translations/bets';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Bonus, Status } from 'src/domains/players/state/BonusState';
import { Amount } from 'src_common/common/amount/Amount';
import { formatDate } from '../../utils/formatDate';
import { BadgeList } from '../BadgeList';
import { BetStatus } from '../BetStatus';
import {
    BonusItem,
    BonusItemBtn,
    BonusItemContent,
    BonusItemHeader,
    BonusItemHeading,
    BonusItemHeadingContainer,
    BonusItemsWrapper,
    BonusItemWidget,
    Label,
} from './BonusItems.styled';

export const BonusItems = observer(({ items }: BonusItemsProps) => {
    const appState = useAppStateContext();
    const {
        appLayoutsState: { starRouter, sportsList },
        appPlayersState: { usersState },
    } = appState;

    const sports = sportsList.translatedSports;

    const sportNamesById = useMemo(() => {
        return sports.reduce<Record<string, string>>(
            (acc, sport) => ({
                ...acc,
                [sport.id]: sport.label,
            }),
            {}
        );
    }, [sports]);

    return (
        <BonusItemsWrapper>
            {items.map((item, index) => (
                <BonusItem key={index}>
                    <BonusItemHeader>
                        <BonusItemHeadingContainer>
                            <BonusItemHeading>
                                {usersState.money(new Amount(`${item.currentAmount}`).div(100))}{' '}
                                {item.bonusType === 'free-bet' ? (
                                    <I18n
                                        langKey='account.bonus.title.free-bet'
                                        defaultText='Free Bet'
                                    />
                                ) : (
                                    <I18n
                                        langKey='account.bonus.title.bonus-balance'
                                        defaultText='Bonus Balance'
                                    />
                                )}
                            </BonusItemHeading>
                            <BetStatus status={item.status} />
                        </BonusItemHeadingContainer>
                        <strong>
                            <Label>
                                <I18n
                                    langKey='account.bonus.label.total-amount'
                                    defaultText='Total Amount:'
                                />
                            </Label>{' '}
                            {usersState.money(new Amount(`${item.totalAmount}`).div(100))}
                        </strong>
                        <Label>
                            <I18n
                                langKey='account.bonus.label.expiry-date'
                                defaultText='Expiry on:'
                            />{' '}
                            {formatDate(item.expiryAt)}
                        </Label>
                    </BonusItemHeader>

                    <BonusItemContent>
                        {item.bonusType === 'free-bet' ? (
                            <FreeBetDetails
                                item={item}
                                sports={sportNamesById}
                            />
                        ) : (
                            <BonusBalanceDetails item={item} />
                        )}
                    </BonusItemContent>

                    <BonusItemBtn
                        onClick={starRouter.redirectToHomepage}
                        disabled={item.status !== Status.Active}
                        size='small'
                    >
                        <I18n
                            langKey='account.bonus.button.bet-now'
                            defaultText='Bet Now'
                        />
                    </BonusItemBtn>
                </BonusItem>
            ))}
        </BonusItemsWrapper>
    );
});

type BonusItemsProps = {
    items: Bonus[];
};

const getUsageConditionsValue = (
    usageConditions: Bonus['usageConditions'],
    type: string
): number | string[] | undefined => {
    return usageConditions.find((usageCondition) => usageCondition.type === type)?.value;
};

const FreeBetDetails = observer(({ item }: { item: Bonus; sports: Record<string, string> }) => {
    const appState = useAppStateContext();

    const eligibleBetTypes = useMemo(() => {
        const items = getUsageConditionsValue(item.usageConditions, 'bet-type') ?? [];

        return Array.isArray(items)
            ? items.map((betTypeId) => getBetsTranslation(appState, betTypeId) ?? betTypeId)
            : [];
    }, [appState, item.usageConditions]);

    // const eligibleSports = useMemo(() => {
    //     const items = getUsageConditionsValue(item.usageConditions, 'sport') ?? [];

    //     return Array.isArray(items) ? items.map((sportId) => sports[sportId] ?? sportId) : [];
    // }, [item.usageConditions, sports]);

    const minOdds = getUsageConditionsValue(item.usageConditions, 'bet-min-odds');

    return (
        <>
            {eligibleBetTypes.length > 0 && (
                <>
                    <Label>
                        <I18n
                            langKey='account.bonus.details.label.bet-type'
                            defaultText='Bet Type:'
                        />
                    </Label>{' '}
                    <BadgeList items={eligibleBetTypes} />
                </>
            )}
            {/* {eligibleSports.length > 0 && (
                <>
                    <Label>
                        <I18n
                            langKey='account.bonus.details.label.eligible-sports'
                            defaultText='Eligible Sports:'
                        />
                    </Label>{' '}
                    <BadgeList items={eligibleSports} />
                </>
            )} */}

            {minOdds !== undefined && (
                <>
                    <Label>
                        <I18n
                            langKey='account.bonus.details.label.bet-min-odds'
                            defaultText='Bet Min Odds:'
                        />
                    </Label>{' '}
                    {minOdds}
                </>
            )}
        </>
    );
});

const BonusBalanceDetails = ({ item }: { item: any }) => (
    <>
        <Label>
            <I18n
                langKey='account.bonus.details.label.eligible-games'
                defaultText='Eligible Games:'
            />
        </Label>

        <BonusItemWidget>{item.eligibleGame}</BonusItemWidget>

        {item.playThrough && (
            <>
                <Label>
                    <I18n
                        langKey='account.bonus.details.label.play-through'
                        defaultText='Play Through:'
                    />
                </Label>

                {item.playThrough}
            </>
        )}
    </>
);
