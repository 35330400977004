import { LiveTvIcon } from 'src/domains/layouts/shared/assets/icons/LiveTvIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const WatchLiveButtonWrapper = withConfig(theme => styled('button')<{isDisabled?: boolean; streamOn?: boolean}>`
    appearance: none;
    background-color: ${({ isDisabled }): string => isDisabled === true ? theme.star.watchLiveButton.bgColorSecondary : theme.star.watchLiveButton.bgColor};
    border: 0;
    color: ${({ isDisabled }): string => isDisabled === true ? theme.star.watchLiveButton.txtColorSecondary : theme.star.watchLiveButton.txtColor};
    cursor: ${({ isDisabled, streamOn }): string => isDisabled === true || streamOn === true ? 'initial' : 'pointer'};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    padding: 5px 4px 4px 24px;
    position: relative;
    opacity: ${({ streamOn }): string => streamOn === true ? '0.4' : '1'};
`);

export const StreamIconButtonWrapper = styled(LiveTvIcon)<{isDisabled?: boolean}>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4px;
    margin: auto 0;
    width: 16px;
    fill: currentcolor;
`;
