import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string,
    size?: string
}

const SvgElement = styled('svg')<PropsType>`
    height: auto;
    width: ${({ size }): string => size === undefined ? '62px' : size};
`;

export const LogoGamstop = ({ size, className }: PropsType): JSX.Element => (
    <SvgElement
        size={size}
        className={className}
        width={83}
        height={16}
        viewBox='0 0 109 21'
    >
        <g fill='none' fillRule='evenodd'>
            <path
                fill='#35A8E0'
                d='M52.59 20.32H1.14a.95.95 0 0 1-.96-.95v-8.92A9.32 9.32 0 0 1 9.5 1.14h43.09c.52 0 .95.42.95.95v17.32a.95.95 0 0 1-.95.95v-.04z'
            />
            <path
                fill='#fff'
                fillRule='nonzero'
                d='M52.6 20.54H1.14A1.14 1.14 0 0 1 0 19.41v-8.92A9.54 9.54 0 0 1 9.55.95H52.6c.62 0 1.13.5 1.13 1.13v17.33c0 .62-.5 1.13-1.13 1.13zM9.52 1.4a9.1 9.1 0 0 0-9.09 9.1v8.91c0 .39.31.7.7.7H52.6a.7.7 0 0 0 .7-.7V2.1a.7.7 0 0 0-.7-.7L9.54 1.4z'
            />
            <path
                fill='#fff'
                d='M18.2 10.06v7.15c0 .07-.07.13-.14.13h-2.8a.13.13 0 0 1-.14-.13.13.13 0 0 0-.18-.12 6.95 6.95 0 0 1-1.92.29 6.75 6.75 0 0 1-2.5-.53 6.47 6.47 0 0 1-2.2-1.46 6.38 6.38 0 0 1-1.45-2.17 6.79 6.79 0 0 1-.47-2.5c0-.86.16-1.71.47-2.51a6.36 6.36 0 0 1 1.45-2.2 6.47 6.47 0 0 1 2.2-1.47 6.68 6.68 0 0 1 5.03 0 6.44 6.44 0 0 1 2.1 1.37.13.13 0 0 1 0 .19l-1.98 1.98a.13.13 0 0 1-.19 0c-.32-.3-.69-.54-1.1-.7a3.64 3.64 0 0 0-2.7 0 3.46 3.46 0 0 0-1.2.78 3.59 3.59 0 0 0 0 5.09c.3.3.67.56 1.06.73a3.58 3.58 0 0 0 1.21.31 3.76 3.76 0 0 0 2.32-.61.13.13 0 0 0 .06-.12v-.45a.13.13 0 0 0-.13-.14h-2.4a.13.13 0 0 1-.13-.13v-2.78c0-.07.06-.13.13-.13h5.45a.13.13 0 0 1 .14.13z'
            />
            <path
                fill='#fff'
                fillRule='nonzero'
                d='M28.64 17.27l-.46-1.38a.13.13 0 0 0-.13-.1h-4a.13.13 0 0 0-.12.1l-.48 1.43a.13.13 0 0 1-.12.1h-2.94a.13.13 0 0 1-.13-.19l4.42-12.98a.13.13 0 0 1 .13-.1h2.69a.13.13 0 0 1 .13.1l4.2 12.98a.13.13 0 0 1-.14.18h-2.92a.13.13 0 0 1-.13-.14zM25.2 12.7h1.77a.13.13 0 0 0 .14-.19l-.86-2.65a.13.13 0 0 0-.26 0l-.95 2.65a.13.13 0 0 0 .16.19z'
            />
            <path
                fill='#fff'
                d='M43.54 4.08h2.92c.07 0 .13.06.13.13l.76 13a.13.13 0 0 1-.13.14h-2.79a.13.13 0 0 1-.13-.12l-.28-4.6a.13.13 0 1 0-.26 0l-1.57 4.64a.13.13 0 0 1-.12.1H39.2a.13.13 0 0 1-.12-.1l-1.72-4.9a.13.13 0 0 0-.27 0l-.3 4.82a.13.13 0 0 1-.13.13h-2.81a.13.13 0 0 1-.14-.15l.84-12.95a.13.13 0 0 1 .14-.12h2.92c.06 0 .1.04.12.09l2.75 7.85a.13.13 0 0 0 .26 0l2.67-7.85a.13.13 0 0 1 .13-.1z'
            />
            <path
                fill='#1D1D1B'
                d='M55.63 1.17h51.47c.53 0 .95.43.95.96v8.91a9.32 9.32 0 0 1-9.31 9.32h-43.1a.95.95 0 0 1-.96-.95V2.09a.95.95 0 0 1 .95-.92z'
            />
            <path
                fill='#fff'
                fillRule='nonzero'
                d='M98.7 20.54H55.63a1.14 1.14 0 0 1-1.14-1.13V2.09c0-.63.51-1.14 1.14-1.14h51.47c.63 0 1.14.51 1.14 1.14v8.92a9.54 9.54 0 0 1-9.54 9.53zM55.63 1.4a.7.7 0 0 0-.7.7v17.3c0 .4.32.7.7.7H98.7a9.1 9.1 0 0 0 9.1-9.1V2.1a.7.7 0 0 0-.7-.7l-51.47.01z'
            />
            <path
                fill='#fff'
                d='M63.19 17.36c-.42 0-.84-.05-1.25-.17a4.25 4.25 0 0 1-1.18-.53 4.44 4.44 0 0 1-1.52-1.64 4.51 4.51 0 0 1-.55-2.05.13.13 0 0 1 .14-.14h2.83c.08 0 .14.06.14.13 0 .26.08.5.23.7.11.17.25.3.42.42.2.14.45.23.7.24.26.01.51-.04.75-.14.15-.06.3-.16.4-.27.1-.1.18-.2.25-.32a.87.87 0 0 0 .1-.3 1.53 1.53 0 0 0 0-.46.95.95 0 0 0-.1-.3 1.23 1.23 0 0 0-.24-.32 1.56 1.56 0 0 0-.41-.28l-.42-.16-.48-.16-.96-.33a5.25 5.25 0 0 1-.95-.48 3.82 3.82 0 0 1-1.07-.96 3.94 3.94 0 0 1-.75-2.7 3.73 3.73 0 0 1 1.22-2.47c.32-.3.68-.54 1.08-.72a3.93 3.93 0 0 1 1.28-.31c.46-.04.94 0 1.4.12a3.95 3.95 0 0 1 2.19 1.37 3.67 3.67 0 0 1 .78 2.2.13.13 0 0 1-.14.14h-2.76a.13.13 0 0 1-.14-.11.57.57 0 0 0-.25-.46 1.91 1.91 0 0 0-.45-.2h-.16a.88.88 0 0 0-.3 0 1.1 1.1 0 0 0-.35.12.7.7 0 0 0-.29.31.81.81 0 0 0-.06.6.86.86 0 0 0 .36.5c.18.1.37.19.57.26l.65.23.62.2c.2.06.42.15.64.24a4.25 4.25 0 0 1 2.54 3.87 4.19 4.19 0 0 1-.7 2.33 4.5 4.5 0 0 1-2.8 1.9c-.34.07-.67.1-1 .1z'
            />
            <path
                fill='#fff'
                fillRule='nonzero'
                d='M103.99 9.69a5.43 5.43 0 0 1-.42 2.14 5.58 5.58 0 0 1-1.18 1.75 5.67 5.67 0 0 1-1.74 1.2c-.63.29-1.3.44-2 .46a.13.13 0 0 0-.13.14v1.85c0 .07-.06.13-.14.13h-2.77a.13.13 0 0 1-.14-.13V4.25c0-.08.06-.14.14-.14h2.9c.73.01 1.46.17 2.13.47a5.8 5.8 0 0 1 1.74 1.21 5.58 5.58 0 0 1 1.6 3.9zm-5.48 2.39a.13.13 0 0 0 .14.13c.28-.03.55-.1.8-.22a2.51 2.51 0 0 0 1.28-1.34 2.53 2.53 0 0 0-.51-2.7 2.6 2.6 0 0 0-.77-.54 2.39 2.39 0 0 0-.8-.22.13.13 0 0 0-.14.11v4.77zM86 4.1a6.42 6.42 0 0 1 2.58.52 6.82 6.82 0 0 1 2.11 1.41 6.68 6.68 0 0 1 1.42 2.1 6.63 6.63 0 0 1 0 5.17 6.8 6.8 0 0 1-3.53 3.54 6.62 6.62 0 0 1-5.15 0 6.63 6.63 0 0 1-3.52-3.52 6.68 6.68 0 0 1 0-5.17 6.63 6.63 0 0 1 3.52-3.52A6.42 6.42 0 0 1 86 4.1zm0 10.2c.48 0 .96-.1 1.4-.28a3.6 3.6 0 0 0 1.9-1.9 3.59 3.59 0 0 0 0-2.78 3.6 3.6 0 0 0-1.9-1.91 3.59 3.59 0 0 0-2.78 0 3.6 3.6 0 0 0-1.91 1.9 3.59 3.59 0 0 0 0 2.78 3.6 3.6 0 0 0 1.91 1.91c.44.19.92.28 1.4.28H86z'
            />
            <rect
                width='3.07'
                height='10.35'
                x='72.37'
                y='7.02'
                fill='#fff'
                rx='.14'
            />
            <rect
                width='8.12'
                height='3.07'
                x='69.84'
                y='4.11'
                fill='#fff'
                rx='.14'
            />
        </g>
    </SvgElement>
);
