import styled from '@emotion/styled';
import { WatchLiveButton } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/watchLiveButton/WatchLiveButton';
import { PromotionsIcon } from 'src/domains/layouts/shared/assets/icons/PromotionsIcon';
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';

export const RaceHeaderWrapper = withConfig(theme => styled('div')`
    background: ${theme.star.raceCardColors.bgColorHeaderDetails};
    border: 1px solid ${theme.star.raceCardColors.borderColorHeaderDetails};
    border-bottom: none;
    position: relative;
`);

interface RaceHeaderPropsType {
    isFinishedRace: boolean;
}

export const RaceHeaderDetails = styled('header')`
    padding: 6px 8px 8px;
`;

export const RaceHeaderDetailsHeader = withConfig(theme => styled('h4')<RaceHeaderPropsType>`
    display: flex;
    align-items: center;
    color: ${({ isFinishedRace }): string => isFinishedRace ? theme.star.raceCardColors.txtColorFinishedRace : 'inherit'};
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 0;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.xMedium};
        line-height: 1.4;
    }
`);

export const RaceWeatherImgWrapper = styled('img')`
    height: 40px;
    margin-right: 6px;
`;

export const RaceHeaderDetailsDistance = withConfig(theme => styled('div')<RaceHeaderPropsType>`
    color: ${({ isFinishedRace }): string => isFinishedRace ? theme.star.raceCardColors.txtColorFinishedRace : theme.star.raceCardColors.txtColorMeta};
    display: flex;
    flex-flow: row wrap;
    margin-top: 3px;
`);

export const RaceHeaderListMeta = withConfig(theme => styled('ul')` 
    display: flex;
    flex-flow: row wrap;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    max-width: 100%;
    line-height: 1.3334;
    &:only-of-type {
        li:last-of-type {
            margin-right: 0;
            &::after {
                content: none;
            }
        }
    }
`);

interface RaceHeaderListItemMetaPropsType {
    isCourseType?: boolean;
}

export const RaceHeaderListItemMeta = withConfig(theme => styled('li')<RaceHeaderListItemMetaPropsType>`
    display: inline-block;
    margin: 0 16px 0 0;
    position: relative;
    &::after {
        background: currentcolor;
        bottom: 0;
        content: '';
        height: calc(12px / 1.33); /* fontSize.small / 1.33 */
        left: 100%;
        margin: auto 0 auto 7px;
        position: absolute;
        top: -1px;
        width: 1px;
    }
    ${({ isCourseType }): false | SerializedStyles => isCourseType === true && css`
        @media not ${theme.star.mediaQuery.tablet} {
            flex: 1 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 15%;
            width: 100%;
        }
    `}
`);

export const RaceHeaderListItemMetaHandicap = styled(RaceHeaderListItemMeta)`
    margin-right: 5px;
    &::after {
        content: none;
    }
`;

export const RaceHeaderListEwTerms = withConfig(theme => styled('ul')`
    display: inline-block;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
`);

export const RaceHeaderListItem = withConfig(theme => styled('li')`
    display: inline-block;
    font-weight: ${theme.star.fontWeight.regular};
    &:not(:last-of-type) {
        margin-right: 5px;
    }
`);

export const WatchLiveButtonRaceCard = styled(WatchLiveButton)`
    margin: 6px 0 4px;
`;

export const DetailsTableMessage = withConfig(theme => styled('div')`
    background-color: ${theme.star.raceCardColors.bgColorDetailsTableMessage};
    color: ${theme.star.raceCardColors.txtColorInProgress};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    padding: 16px 32px 17px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`);

export const MegaphoneIcon = styled(PromotionsIcon)`
    fill: currentcolor;
    margin-right: 5px;
    width: 18px;
`;

export const FilterContainer = withConfig(theme => styled('div')`
    align-items: center;
    background-color: ${theme.star.raceCardColors.bgColorMarketsFilters};
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 8px 0 8px 8px;
`);
