import styled from '@emotion/styled';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { withConfig } from 'src/withConfig';

interface KycWarningProps {
    isSignup: boolean;
}

export const KycWarningContainer = withConfig(theme => styled('div')<KycWarningProps>`
    border: solid 1px ${theme.star.kycWarningColors.borderColor};
    color: ${({ isSignup }): string => isSignup ? 'inherit' : theme.star.kycWarningColors.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
    margin: 10px 0 20px;
    padding: 8px;
    position: relative;
`);

export const KycWarningIconWrapper = withConfig(theme => styled(WarningIcon)<KycWarningProps>`
    fill: ${({ isSignup }): string => isSignup ? theme.star.kycWarningColors.bgColorSecondary : theme.star.kycWarningColors.bgColor};
    flex: 0 0 24px;
    width: 24px;
    margin-right: 8px;
`);
