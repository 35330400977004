import { MenuAndSearchIcon } from 'src/domains/layouts/shared/assets/icons/MenuAndSearchIcon';
import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

export const LinkHamburgerMenu = withConfig(theme => styled(Link)`
    align-items: center;
    display: flex;
    flex: 0 0 48px;
    height: 100%;
    justify-content: center;
    text-indent: -9999rem;
    @media ${theme.star.mediaQuery.desktop} {
        flex: 0 0 56px;
    }
    @media ${theme.star.mediaQuery.largeDesktop} {
        display: none;
    }
`);

export const HamburgerMenuIcon = withConfig(theme => styled(MenuAndSearchIcon)`
    fill: ${theme.star.headerMain.bgColorTertiary};
    width: 24px;
`);

export const HamburgerLabel = styled('span')`
    overflow: hidden;
    position: absolute;
    text-indent: -9999rem;
    white-space: nowrap;
`;
