import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';
import { LazyLoadingImg } from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg';

export const TCStyle = withConfig(theme => styled(Link)`
    bottom: 8px;
    color: ${theme.star.notificationPromo.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1;
    position: absolute;
    right: 8px;
    text-decoration: none;
`);

export const NotificationWrapper = styled('div')`
    background-size: cover;
    box-shadow: none;
    position: relative;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const NotificationImg = styled(LazyLoadingImg)`
    border-radius: 5px;
    display: block;
    width: 100%;
    margin: 0 auto;
`;

export const HtmlOverlayHrefMask = styled('a')`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const HtmlOverlayMask = styled('div')`
    height: 260px;
    width: 260px;
    position: absolute;
    background-color: transparent;
    opacity: 0;
    top: 0;
    left: 0;
`;

export const HtmlWrapper = styled('div')`
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 100%;
    margin: 0 auto;
`;

export const HtmlIframeWrapper = styled('iframe')`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
`;

export const VideoWrapper = styled('div')`
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 75%;
    margin: 0 auto;
`;

export const VideoIframeWrapper = styled(HtmlIframeWrapper)``;
