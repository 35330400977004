import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const MeetingsInfo = withConfig(theme => styled('div')`
    background: ${theme.star.meetingsAntePost.bgColorSecondary};
    color: ${theme.star.meetingsAntePost.txtColor};
    position: relative;
    padding: 10px 5px;
    text-align: center;
    flex: 1 1 0%;
    font-size: ${theme.star.fontSize.xRegular};
`);

export const MeetingsEventHeader = withConfig(theme => styled('header')`
    align-items: center;
    background-color: ${theme.star.meetingsAntePost.bgColorTertiary};
    border: none;
    color: ${theme.star.meetingsAntePost.txtColorTertiary};
    display: flex;
    @media ${theme.star.mediaQuery.xMobile} {
        color: inherit;
        background-color: transparent;
    }
`);

export const BtnBack = withConfig(theme => styled('div')`
    align-items: center;
    background-color: ${theme.star.meetingsAntePost.bgColorQuinary};
    color: ${theme.star.meetingsAntePost.txtColorTertiary};
    cursor: pointer;
    display: flex;
    padding: 10px;
    @media ${theme.star.mediaQuery.xMobile} {
        color: inherit;
        background-color: ${theme.star.meetingsAntePost.bgColorQuaternary};
    }
`);

export const MeetingsBackTitle = styled('span')`
    padding: 8px;
`;

export const ChevronLeftIconWrapper = styled(ChevronIcon)`
    fill: currentcolor;
    width: 20px;
`;

export const LoaderWrapper = styled(Loader)`
    justify-content: center;
`;

export const MeetingsEvent = withConfig(theme => styled('div')`
    background-color: ${theme.star.meetingsAntePost.bgColor};
`);
