import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

interface PriceLegendWrapperPropsType {
    isHomePage?: boolean;
}

export const PriceLegendWrapper = withConfig(theme => styled('div')<PriceLegendWrapperPropsType>`
    background-color: ${theme.star.priceLegendList.bgColor};
    border: 1px solid ${theme.star.priceLegendList.borderColor};
    color: ${theme.star.priceLegendList.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    margin-top: -1px;
    padding: 8px 8px 0;
    position: relative;
    z-index: ${theme.star.zIndexGlobal.above};
`);

export const PriceLegend = styled('ul')`
    line-height: 1;
`;

interface PriceLegendItemPropsType {
    itemColor: string;
}

export const PriceLegendItem = styled('li')<PriceLegendItemPropsType>`
    display: inline-block;
    line-height: 1.4;
    margin-bottom: 9px;
    padding-left: 20px;
    position: relative;
    &:not(:last-of-type) {
        margin-right: 16px;
    }
    &::before {
        background-color: ${({ itemColor }): string => itemColor};
        bottom: 0;
        content: '';
        height: 16px;
        left: 0;
        margin: auto 0;
        position: absolute;
        top: 0;
        width: 16px;
    }
`;

export const InPlayRedirect = withConfig(theme => styled(Link)`
    text-decoration: none;
    font-weight: ${theme.star.fontWeight.bold};
    margin-left: 4px;
`);

export const InPlayRedirectWrapper = styled('p')`
    margin: 0 0 10px;
    line-height: 1.4;
`;
