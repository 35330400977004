import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { HelpIcon } from 'src/domains/layouts/shared/assets/icons/HelpIcon';
import { AccountNavigationLink } from 'src/domains/layouts/webview/components/accountNavigationLink/AccountNavigationLink';
import { withConfig } from 'src/withConfig';
import { css, SerializedStyles } from '@emotion/react';

export const AccountNavigationWrapper = withConfig(theme => styled('nav')`
    background-color: ${theme.star.rhsMainNavigation.bgColor};
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 56px;
    line-height: 1;
    width: 100%;
`);

export const setLinkWrapper = (theme: EmotionTheme): SerializedStyles => css`
    position: relative;
    background: transparent;
    border-right: 1px solid ${theme.star.rhsMainNavigation.borderColor};
    color: ${theme.star.rhsMainNavigation.txtColor};
    align-items: center;
    display: flex;
    flex: 1 1 33.3333%;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: center;
    line-height: 1.3334;
    text-decoration: none;
    z-index: ${theme.star.zIndexGlobal.base};
    &::before {
        background-color: ${theme.star.rhsMainNavigation.bgColorSecondary};
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: opacity .2s ease;
        z-index: ${theme.star.zIndexGlobal.below};
    }
    &:hover {
        &::before {
            opacity: 1;
        }
    }
    &:last-child {
        border-right: none;
    }
`;

export const AccountNavLink = withConfig(theme => styled(AccountNavigationLink)`
    ${setLinkWrapper(theme)};
    flex-flow: row nowrap;
    padding: 0;
    text-transform: none;
`);

export const AccountNavText = styled('span')`
    margin-left: 4px;
`;

export const styleNavIcon = (): SerializedStyles => css`
    fill: currentcolor;
    flex: 0 0 24px;
    width: 24px;
`;

export const HelpIconWrapper = styled(HelpIcon)`
    ${styleNavIcon()};
`;
