import styled from '@emotion/styled';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';
import { withConfig } from 'src/withConfig';

export const AssigneeInfoWrapper = withConfig(theme => styled('li')`
    align-self: flex-start;
    background-color: ${theme.star.traderChat.messages.bgColorQuaternary};
    border-radius: 0 8px 8px 8px;
    color: ${theme.star.traderChat.messages.txtColorSecondary};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.4;
    margin: 0 0 38px;
    min-width: 52px;
    padding: 12px 8px 12px 24px;
    position: relative;
    &:last-of-type {
        margin-bottom: 0;
    }
`);

export const InfoIconWrapper = styled(InfoIcon)`
    fill: currentcolor;
    left: 8px;
    position: absolute;
    top: 13px;
    width: 12px;
`;
