import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const BetslipMessage = withConfig(theme => styled('div')`
    position: relative;
    background-color: ${theme.star.betSlipTooltip.bgColor};
    color: ${theme.star.betSlipTooltip.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1;
    margin: 16px -8px -5px;
    padding: 6px 10px;
    text-align: center;
    text-transform: uppercase;
    &::after {
        position: absolute;
        content: '';
        top: 100%;
        right: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 8px 0 8px;
        border-color: ${theme.star.betSlipTooltip.borderColor} transparent transparent transparent;
        margin-left: -8px;
    }
`);
