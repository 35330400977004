import { timeout } from 'src_common/common/mobx-utils/timeout';
import { StarRouter } from './router/StarRouter';
import { RightHandSideViewType } from './router/newRouter/rhsRouteType';
import { makeAutoObservable, observable, computed } from 'mobx';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';

type AccountViewType = NonNullable<RightHandSideViewType['account']>;

const tabPlace: Record<AccountViewType, 'left' | 'right' | null> = {
    'add-credit': 'left',
    'bet-history': 'right',
    credits: 'right',
    'customer-funds-protection': 'right',
    'finish-kyc': 'right',
    'forgot-password': 'right',
    'game-info': 'left',
    'personal-details': 'right',
    'reset-password-cookies': 'right',
    'reset-password-privacy': 'right',
    'reset-password-terms': 'right',
    'reset-password': 'left', // ?
    'static-resource-carousel-promo-terms': 'right',
    'static-resource-headline-promo-terms': 'right',
    'static-resource-hero-widget': 'right',
    'terms-and-conditions-casino-banner': 'left',
    'terms-and-conditions-promos-special': 'left',
    'terms-and-conditions-promos': 'left',
    'top-up': 'right',
    'trader-chat': 'left',
    'transaction-history': 'right',
    'verify-account': 'left',
    betting: 'left',
    limits: 'right',
    login: 'left',
    navigation: null, // Mobile LHS menu
    preferences: 'right',
    signup: 'right',
    static: 'right',
    summary: 'left',
    wallet: 'left',
    withdraw: 'right',
};

export const slideAnimationDuration = 300;

export class AccountDrawerNew {
    @observable.ref private activeValue: 'left' | 'right' | null = null;
    @observable.ref private tabLeftValue: RightHandSideViewType | null = null;
    @observable.ref private tabRightValue: RightHandSideViewType | null = null;

    private bettingInDesktopView(position: 'left' | 'right'): boolean {
        const innerWidth = getWindowInnerWidth() ?? 0;

        if (innerWidth < 1024) {
            return false;
        }

        const account = position === 'left' ? this.tabLeftValue?.account : this.tabRightValue?.account;
        return account === 'betting';
    }

    @computed public get active(): 'left' | 'right' | null {
        if (this.activeValue === 'left' && this.bettingInDesktopView('left')) {
            return null;
        }

        return this.activeValue;
    }

    @computed public get tabLeft(): RightHandSideViewType | null {
        if (this.bettingInDesktopView('left')) {
            return null;
        }

        return this.tabLeftValue;
    }

    @computed public get tabRight(): RightHandSideViewType | null {
        return this.tabRightValue;
    }

    public constructor(starRouter: StarRouter) {
        makeAutoObservable(this);

        starRouter.onChangeAccountView(async (_prevView, nextView) => {
            if (nextView === null) {
                this.activeValue = null;
                await timeout(slideAnimationDuration);
                this.tabLeftValue = null;
                this.tabRightValue = null;
                return;
            }

            const account = nextView.account;

            if (account === null) {
                this.activeValue = null;
                await timeout(slideAnimationDuration);
                this.tabLeftValue = null;
                this.tabRightValue = null;
                return;
            }

            const accountTarget = tabPlace[account];

            if (accountTarget === null) {
                this.activeValue = null;
                await timeout(slideAnimationDuration);
                this.tabLeftValue = null;
                this.tabRightValue = null;
                return;
            }

            if (accountTarget === 'left') {
                this.tabLeftValue = nextView;
                await timeout(100);
                this.activeValue = 'left';
            } else {
                this.tabRightValue = nextView;
                await timeout(100);
                this.activeValue = 'right';
            }
        });
    }
}
