import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { withConfig } from 'src/withConfig';

interface PropsType {
    borderBottom?: boolean;
    borderLeft?: boolean;
    borderRight?: boolean;
    borderTop?: boolean;
    isDisabled?: boolean;
    isEmpty?: boolean;
    isEventPage?: boolean;
    isOddsDown?: boolean;
    isOddsUp?: boolean;
    isSelected?: boolean;
    isShowParticipantName?: boolean;
    isSuspended?: boolean;
}

export const SelectionParticipantName = withConfig(theme => styled('span')`
    color: ${theme.star.eventRow.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    padding-right: 5px;
    transition: color 0.2s ease;
`);

const setDisabled = (theme: EmotionTheme, isDisabled: boolean): SerializedStyles | null => {
    if (isDisabled === true) {
        return css`
            background-color: ${theme.star.selection.bgColorSenary};
            color: ${theme.star.selection.txtColorSecondary};
            pointer-events: none;
            user-select: none;
        `;
    }
    return null;
};

const setSuspended = (theme: EmotionTheme, isSuspended: boolean): SerializedStyles | null => {
    if (isSuspended === true) {
        return css`
            pointer-events: none;
            user-select: none;
            &::before {
                align-items: center;
                content: 'Susp';
                display: flex;
                font-size: ${theme.star.fontSize.small};
                font-weight: ${theme.star.fontWeight.regular};
                justify-content: center;
                line-height: 1.3334;
                margin: auto;
                opacity: 1;
                text-transform: uppercase;
                z-index: ${theme.star.zIndexGlobal.base};
                @media ${theme.star.mediaQuery.tablet} {
                    font-size: ${theme.star.fontSize.xRegular};
                }
            }
        `;
    }
    return null;
};

const setEmpty = (isEmpty: boolean): SerializedStyles | null => {
    if (isEmpty === true) {
        return css`
            pointer-events: none;
            user-select: none;
        `;
    }
    return null;
};

const setBorders = (
    theme: EmotionTheme,
    borderTop: boolean,
    borderRight: boolean,
    borderBottom: boolean,
    borderLeft: boolean
): string => {
    const out: Array<string> = [];
    if (borderTop) {
        out.push(`border-top: 1px solid ${theme.star.selection.borderColor};`);
    }
    if (borderRight) {
        out.push(`border-right: 1px solid ${theme.star.selection.borderColor};`);
    }
    if (borderBottom) {
        out.push(`border-bottom: 1px solid ${theme.star.selection.borderColor};`);
    }
    if (borderLeft) {
        out.push(`border-left: 1px solid ${theme.star.selection.borderColor};`);
    }
    return out.join('\n');
};

const setTxtAndBackgroundColor = (
    theme: EmotionTheme,
    isSelected: boolean,
    isSuspended: boolean,
    isOddsDown: boolean,
    isOddsUp: boolean
): SerializedStyles => {
    if (isSelected === true) {
        return css`
            color: ${theme.star.selection.txtColorTertiary};
            &::before {
                background-color: ${theme.star.selection.bgColorQuinary};
                opacity: 1;
            }
            > span {
                color: ${theme.star.selection.txtColorTertiary};
            }
            @media screen and (hover: hover) and (pointer: fine) {
                &::after {
                    background-color: ${theme.star.selection.bgColorSecondary};
                }
                &:hover {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        `;
    }
    if (isSuspended === true) {
        return css`
            color: ${theme.star.odds.txtColorSecondary};
            &::before {
                background-color: ${theme.star.odds.bgColorTertiary};
                opacity: 1;
            }
        `;
    }
    if (isOddsDown === true) {
        return css`
            color: ${theme.star.odds.txtColor};
            &::before {
                background-color: ${theme.star.odds.bgColorSecondary};
                opacity: 1;
            }
        `;
    }
    if (isOddsUp === true) {
        return css`
            color: ${theme.star.odds.txtColor};
            &::before {
                background-color: ${theme.star.odds.bgColor};
                opacity: 1;
            }
        `;
    }

    return css`
        color: ${theme.star.selection.txtColor};
        background-color: ${theme.star.selection.bgColor};
        @media screen and (hover: hover) and (pointer: fine) {
            &:hover {
                color: ${theme.star.selection.txtColorTertiary};
                &::before {
                    background-color: ${theme.star.selection.bgColorQuinary};
                    opacity: 1;
                }
                > span {
                    color: ${theme.star.selection.txtColorTertiary};
                }
            }
        }
    `;
};

export const SelectionItemStyle = withConfig(theme => styled('a')<PropsType>`
    align-items: center;
    display: flex;
    flex: 0 0 50px;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: center;
    line-height: 1.43;
    margin-left: -1px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 0.2s ease;
    width: 50px;
    z-index: ${theme.star.zIndexGlobal.base};
    @media ${theme.star.mediaQuery.tablet} {
        flex-basis: 68px;
        font-size: ${theme.star.fontSize.xRegular};
        width: 68px;
    }
    &::after,
    &::before {
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.2s ease;
        z-index: ${theme.star.zIndexGlobal.below};
    }
    ${({ isShowParticipantName }): SerializedStyles =>
        isShowParticipantName === true
            ? css`
                justify-content: space-between;
                width: 100%;
                padding: 0 8px;
            ` : css``};

    ${({ isSelected = false, isSuspended = false, isOddsDown = false, isOddsUp = false }): SerializedStyles =>
        setTxtAndBackgroundColor(theme, isSelected, isSuspended, isOddsDown, isOddsUp)};

    ${({ borderTop = false, borderRight = false, borderBottom = false, borderLeft = false }): string =>
        setBorders(theme, borderTop, borderRight, borderBottom, borderLeft)};

    ${({ isDisabled }): SerializedStyles | null => (isDisabled === true ? setDisabled(theme, isDisabled) : null)};

    ${({ isSuspended }): SerializedStyles | null =>
        isSuspended === true ? setSuspended(theme, isSuspended) : null};

    ${({ isEmpty }): SerializedStyles | null => (isEmpty === true ? setEmpty(isEmpty) : null)};
`);
