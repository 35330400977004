import styled from '@emotion/styled';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { ChatIcon } from 'src/domains/layouts/shared/assets/icons/ChatIcon';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const HeaderWrapper = withConfig(theme => styled('header')`
    display: flex;
    flex: 0 0 48px;
    align-items: center;
    position: relative;
    background: ${theme.star.sidebar.bgColor};
    color: ${theme.star.sidebar.txtColor};
    font-size: ${theme.star.fontSize.regular};
    line-height: 1.3;
`);

export const TitleWrapper = withConfig(theme => styled('h3')<{ hasIcon: boolean; isSmallerVersion?: boolean }>`
    color: ${theme.star.sidebar.txtColor};
    font-size: ${({ isSmallerVersion }): string => isSmallerVersion === true ? theme.star.fontSize.xRegular : theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    margin: ${({ hasIcon }): string => hasIcon === true ? '0' : '0 0 0 16px'};
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`);

export const HeaderCloseIcon = styled(CloseIcon)`
    cursor: pointer;
    fill: currentcolor;
    margin-left: auto;
    padding: 14px;
    width: 48px;
`;

export const ArrowBackIcon = styled(ChevronIcon)`
    cursor: pointer;
    fill: currentcolor;
    padding: 12px 0;
    width: 48px;
    height: 44px;
`;

export const TraderChatIcon = styled(ChatIcon)`
    fill: currentcolor;
    padding: 12px;
    width: 48px;
`;
