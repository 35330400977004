import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface FlagWrapperPropsType {
    isOnList: boolean;
    isSidebar: boolean;
    isActive?: boolean;
}

export const FlagWrapper = withConfig(theme => styled('div')<FlagWrapperPropsType>`
    width: ${({ isOnList, isSidebar }): string => isOnList || isSidebar ? '16px' : '24px'};
    height: ${({ isOnList, isSidebar }): string => isOnList || isSidebar ? '16px' : '24px'};
    border-radius: 50%;
    outline: ${({ isActive }): string => `1px solid ${isActive === true ? theme.star.languageSwitcher.bgColorQuinary : theme.star.languageSwitcher.bgColorQuaternary}`};
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
`);

interface LanguageNamePropsType {
    isOnList?: boolean;
    isSidebar: boolean;
}

export const LanguageName = withConfig(theme => styled('div')<LanguageNamePropsType>`
    font-size: ${({ isOnList }): string => isOnList === true ? theme.star.fontSize.xSmall : theme.star.fontSize.xRegular};
    color: ${({ isOnList, isSidebar }): string => isOnList === true ? '' : isSidebar === true ? theme.star.languageSwitcher.sidebar.txtColor : theme.star.languageSwitcher.txtColorTertiary};
    line-height: 1.43;
`);
