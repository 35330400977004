import styled from '@emotion/styled';
// import { makeObservable } from 'mobx';
import * as React from 'react';
import { AppState } from 'src/appState/AppState';
import { EventsCollectionList } from 'src/domains/sportsbook/shared/Types';
import { observer } from 'src/utils/mobx-react';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { DateTime } from 'src_common/utils/time/time';
// import { Value } from 'src_common/common/mobx-utils/Value';

const eventTags = [
    'star-events',
    'specials',
    'telebet-top-events',
    'region',
    'country',
    'outright',
    'display-order',
    'telebet-quicklink'
];

const marketTags = [
    //dziedziczony
    'website-main',
    'website-popular',
    'display-order',
    'trap-challenge',
    'display-template',
    'group-template',
    'telebet-popular-market'
];

const selectionTags = [
    'pricewise',
    'price-boost'
];

function getSortedValues<V>(map: Map<string, V>): Array<[string, V]> {
    return Array.from(map.entries())
        .sort(([a, _av], [b, _bv]) => {
            if (a > b) {
                return 1;
            }

            if (a < b) {
                return -1;
            }

            return 0;
        });
    // .map(([_key, value]) => value);
}

class StatsValues {
    private readonly stats: Map<string, number>;

    public constructor() {
        this.stats = new Map();
    }

    public insertValue(value: string): void {
        const current = this.stats.get(value) ?? 0;
        this.stats.set(value, current + 1);
    }

    public getSorted(): Array<[string, number]> {
        return getSortedValues(this.stats);
    }
}

class Stats {
    private readonly stats: Map<string, StatsValues>;

    public constructor() {
        this.stats = new Map();
    }

    private getMapWithValues(tag: string): StatsValues {
        const values = this.stats.get(tag);

        if (values !== undefined) {
            return values;
        }

        const newValues = new StatsValues();
        this.stats.set(tag, newValues);
        return newValues;
    }

    private insert(tag: string, value: string): void {
        if (eventTags.includes(tag) || marketTags.includes(tag) || selectionTags.includes(tag)) {
            this.getMapWithValues(tag).insertValue(value);
        } else {
            //ignore this tag
        }
    }

    public insertRecord(tags: Record<string, string>): void {
        for (const [tag, value] of Object.entries(tags)) {
            this.insert(tag, value);
        }
    }

    public getSorted(): Array<[string, StatsValues]> {
        return getSortedValues(this.stats);
    }
}

class State {
    public constructor(public readonly appState: AppState) {
        // makeObservable(this);
    }

    public get dateFrom(): string {
        return DateTime.current().subtractMonths(1).startOfMonths().toISOString();
    }
    public get dateTo(): string {
        return DateTime.current().addMonths(1).endOfMonths().toISOString();
    }

    public get list(): EventsCollectionList {
        return this.appState.appSportsBookState.eventsCollection.get({
            'startTime[from]': this.dateFrom,
            'startTime[to]': this.dateTo,
        });
    }

    public get events(): Array<EventListGroupEventItemType> {
        const list = this.list.eventItems ?? [];
        return list;
    }

    public get eventStatistic(): Stats {
        const stats = new Stats();
        for (const event of this.events) {
            stats.insertRecord(event.tags);
        }
        return stats;
    }

    public get marketStatistic(): [Stats, Stats] {
        const marketStats = new Stats();
        const selectionStats = new Stats();

        for (const event of this.events) {
            const eventMarkets = this.appState.appSportsBookState.models.getEventMarkets(event.id) ?? [];

            for (const eventMarket of eventMarkets) {
                const marketModel = this.appState.appSportsBookState.models.getMarket(eventMarket.id);

                if (marketModel !== null) {
                    marketStats.insertRecord(marketModel.getData().tags);

                    for (const selection of marketModel.selections) {
                        selectionStats.insertRecord(selection.getData().tags);
                    }
                }
            }
        }

        return [marketStats, selectionStats];
    }
}

const ColumnTagValue = styled('td')`
    width: 200px;
    text-align: right;
    padding-right: 10px;
`;

interface EventsStatsValuesPropsType {
    values: StatsValues;
}

const EventsStatsValues = observer('EventsStatsValues', (props: EventsStatsValuesPropsType) => {
    const { values } = props;

    const result = [];

    for (const [value, count] of values.getSorted()) {
        result.push(
            <tr>
                <ColumnTagValue>{value}</ColumnTagValue>
                <td>{count}</td>
            </tr>
        );
    }
    return (
        <table>
            { result }
        </table>
    );
});

const WrapperBorder = styled('div')`
    border-color: black;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    padding: 5px;
`;

const StatsViewWrapper = styled('div')`
    background-color: #e0e0e0;
    border-top: 1px solid black;
    margin: 10 0;
`;

interface StatsViewPropsType {
    stats: Stats;
}

const StatsView = observer('EventStats', (props: StatsViewPropsType) => {
    const { stats } = props;

    const result = [];

    for (const [tag, values] of stats.getSorted()) {
        result.push(
            <WrapperBorder key={tag}>
                <strong>{ tag }</strong>
                <EventsStatsValues values={values} />
            </WrapperBorder>
        );
    }

    return (
        <StatsViewWrapper>
            { result }
        </StatsViewWrapper>
    );
});

interface PropsType {
    appState: AppState;
}

export const TagStatistics = observer('TagStatistics', (props: PropsType) => {
    const { appState } = props;
    const [ state ] = React.useState(() => new State(appState));

    const eventStats = state.eventStatistic;
    const [marketStatistic, selectionStatistic] = state.marketStatistic;

    return (
        <div>
            <div>events={state.events.length}</div>
            <div>From: {state.dateFrom}</div>
            <div>To: {state.dateTo}</div>
            <StatsView stats={eventStats} />
            <StatsView stats={marketStatistic} />
            <StatsView stats={selectionStatistic} />
        </div>
    );
});

