import { action, computed } from 'mobx';
import { TrpcClient } from 'src/appState/TrpcClient';
import { Amount } from 'src_common/common/amount/Amount';
import { Resource } from 'src_common/common/mobx-utils/Resource';

export class BonusState {
    private balanceData: Resource<Result<Balance> | null>;
    private bonusesData: Resource<Result<BonusResponse> | null>;

    public constructor(private readonly trpc: TrpcClient) {
        this.balanceData = new Resource(async (): Promise<Result<Balance>> => {
            return await this.trpc.client.bonus.getBalance.query();
        });

        this.bonusesData = new Resource(async (): Promise<Result<BonusResponse>> => {
            return await this.trpc.client.bonus.getBonuses.query();
        });
    }

    @computed public get balance(): Amount {
        const response = this.balanceData.getReady();

        let amount = 0;

        if (response !== null && response.data !== undefined) {
            amount = response.data['free-bet']?.availableAmount ?? 0;
        }

        return new Amount(`${amount}`).div(100);
    }

    @computed public get bonuses(): Bonus[] {
        const response = this.bonusesData.getReady();

        if (response === null) {
            return [];
        }

        const freeBets = response.data?.['free-bet'] ?? [];

        return freeBets.sort((prev, next) => {
            if (prev.status === Status.Active && next.status !== Status.Active) {
                return -1;
            } else if (prev.status !== Status.Active && next.status === Status.Active) {
                return 1;
            }

            return new Date(prev.expiryAt).getTime() - new Date(next.expiryAt).getTime();
        });
    }

    @computed public get isLoadingBonuses(): boolean {
        const response = this.bonusesData.getReady();

        return response === null;
    }

    @action public refreshBalance() {
        if (this.balanceData.get().type === 'ready') {
            void this.balanceData.refresh();
        }
    }

    @action public refreshBonuses() {
        if (this.bonusesData.get().type === 'ready') {
            void this.bonusesData.refresh();
        }
    }
}

export type Result<T> = {
    status: number;
    data?: T;
    type: string;
    error?: string;
};

export type Balance = {
    'free-bet'?: {
        universe: string;
        availableAmount: number;
    };
};

export type BonusResponse = {
    'free-bet': Bonus[];
};

export type Bonus = {
    universe: string;
    accountId: number;
    bonusId: string;
    bonusType: string;
    campaignId: string;
    totalAmount: number;
    totalAmountTime: null;
    currentAmount: number;
    currentAmountTime: null;
    createdAt: string;
    expiryAt: string;
    bonusSource: string;
    bonusPlatform: string;
    status: Status;
    usageConditions: UsageCondition[];
    rewardName: string;
};

export interface UsageCondition {
    type: string;
    op: string;
    value: string[] | number;
}

export enum Status {
    Active = 'active',
    Expired = 'expired',
    Canceled = 'canceled',
    Claimed = 'claimed',
    RolledBack = 'rolled-back',
}
