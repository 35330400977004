import { css, SerializedStyles } from '@emotion/react';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface PropsType {
    isTwoColumn?: boolean;
}

export const SelectionsGroupLiItem = withConfig(theme => styled('li')<PropsType>`
    background-color: ${theme.star.selectionsGroup.bgColor};
    color: ${theme.star.selectionsGroup.txtColor};
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    width: 100%;
`);

export const SelectionsGroupName = withConfig(theme => styled('div')<PropsType>`
    align-items: center;
    border-bottom: 1px solid ${theme.star.selectionsGroup.borderColor};
    display: flex;
    flex: 1 1 auto;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    padding: 12px 8px 11px;
    width: inherit;
`);

export const SelectionsGroup = styled('section')`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;

export const SelectionsGroupWrap = styled('div')`
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: -1px;
`;

export const SelectionsGroupList = styled('ul')`
    display: flex;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    width: 100%;
    border-right: 0;
    flex-flow: column nowrap;
`;

interface EmptyPropsType {
    selectionsCount: number;
}

const setEmptyVersion = (theme: EmotionTheme, selectionsCount: number): SerializedStyles => {
    if (selectionsCount > 5) {
        return css `
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
            @media ${theme.star.mediaQuery.xLargeDesktop} {
                grid-template-columns: repeat(3, 1fr);
            }
        `;
    } else {
        return css `
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            @media ${theme.star.mediaQuery.tablet} {
                flex-direction: row;
            }
        `;
    }
};

export const Empty = withConfig(theme => styled('div')<EmptyPropsType>`
    ${({ selectionsCount }): SerializedStyles => setEmptyVersion(theme, selectionsCount)};
`);

export const MarketLoaderWrapper = styled('div')`
    height: 40px;
    display: flex;
    align-items: center;
    margin-left: 8px;
`;

export const MarketLoader = styled(Loader)`
    fill: currentcolor;
    width: 10px;
`;

interface SelectionGroupListVersionPropsType {
    templateName?: string;
}

const setListVersion = (theme: EmotionTheme, templateName?: string): SerializedStyles => {
    if (templateName === 'one-column') {
        return css`
            li {
                margin-right: 0 !important; /* Probably to remove */
            }
        `;
    }

    if (templateName === 'two-column') {
        return css`
            flex-flow: row wrap;
            li {
                margin-right: 0 !important; /* Probably to remove */
                width: 100% !important;
                @media ${theme.star.mediaQuery.tablet} {
                    width: 50% !important;
                }
            }
        `;
    }

    if (templateName === 'three-column') {
        return css`
            flex-flow: row wrap;
            li {
                margin-right: 0 !important; /* Probably to remove */
                width: 100% !important;
                @media ${theme.star.mediaQuery.tablet} {
                    flex: 0 0 33.333% !important;
                    width: 33.333% !important;
                }
            }
        `;
    }

    return css``;
};

export const SelectionGroupListVersion = withConfig(theme => styled('ul')<SelectionGroupListVersionPropsType>`
    align-self: flex-start;
    border-left: 1px solid ${theme.star.selection.borderColor};
    display: flex;
    flex-flow: column nowrap;
    margin-left: -1px;
    width: 100%;
    ${({ templateName }): SerializedStyles => setListVersion(theme, templateName)}; /* This function overwrites default styles above. */
`);
