import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface MatchCardTypes {
    rabBetslipHeight: number | null;
    isFooterVisible: boolean;
}

export const MatchCardSection = withConfig(theme => styled('div')<MatchCardTypes>`
    background-color: ${theme.star.eventRow.bgColor};
    padding-bottom: ${({ rabBetslipHeight, isFooterVisible }):string => isFooterVisible === true ? '0' : rabBetslipHeight === null ? '0' : `${rabBetslipHeight}px`};
`);

interface RabHeaderPropsType {
    isYouDecideOpen: boolean;
}

export const RabHeader = withConfig(theme => styled('div')<RabHeaderPropsType>`
    align-items: center;
    background-color: ${({ isYouDecideOpen }):string => isYouDecideOpen ? theme.star.rabHeading.bgColorSecondary : theme.star.rabHeading.bgColor};
    color: ${theme.star.rabHeading.txtColor};
    display: flex;
    justify-content: space-between;
    padding: 8px 16px 8px 8px;
    position: relative;
    @media ${theme.star.mediaQuery.desktop} {
        padding: 6px 16px 6px 8px;
    }
`);

export const MatchCardContent = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    margin-bottom: 0;
    margin-top: -1px;
`);

export const MatchCardContentWrapper = styled('div')`
    display: flex;
    overflow: hidden;
`;

interface RabMarketsContentPropsTypes {
    isYouDecideOpen: boolean;
    widthContent: number | undefined;
    rabAvailable: boolean;
}

export const MatchCardContentMarkets = styled('div')<RabMarketsContentPropsTypes>`
    flex-shrink: 0;
    height: ${({ isYouDecideOpen, rabAvailable }):string => isYouDecideOpen && rabAvailable ? '0' : 'auto'};
    position: relative;
    right: ${({ isYouDecideOpen, widthContent, rabAvailable }):string => !isYouDecideOpen || !rabAvailable ? '0' : widthContent === undefined ? '0' : `${widthContent}px`};
    transition: right .3s ease 0s;
    width: 100%;
`;

export const MatchCardContentMarketsRAB = withConfig(theme => styled(MatchCardContentMarkets)<RabMarketsContentPropsTypes>`
    height: ${({ isYouDecideOpen }):string => isYouDecideOpen ? 'auto' : '0'};
    @media ${theme.star.mediaQuery.desktop} {
        padding-bottom: ${({ isYouDecideOpen }): string => isYouDecideOpen ? '128px' : '0'};
    }
`);

export const MatchCardInfoWrapper = styled('div')`
    padding: 30px 5px;
    position: relative;
    text-align: center;
`;
