import styled from '@emotion/styled'; 
import { BoostIcon } from 'src/domains/layouts/shared/assets/icons/BoostIcon';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { withConfig } from 'src/withConfig';

interface LifeSpanBoostButtonWrapper {
    isDisabled?: boolean;
    isBoosted?: boolean;
}

export const LifeSpanBoostButtonCloseIcon = styled(CloseIcon)`
    cursor: pointer;
    fill: currentcolor;
    margin-left: auto;
    width: 11px;
`;

export const LifeSpanBoostButtonClickedWrapper = withConfig(theme => styled('button')<LifeSpanBoostButtonWrapper>`
    align-items: center;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.12);
    border-width: 1px 0 0 0;
    border-style: solid;
    color: ${theme.star.betslipSelectionList.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: center;
    margin: 15px 0 0 0;
    padding: 8px 10px 0 20px;
    position: relative;
    width: 100%;
`);

export const BoostWrapperIcon = styled(BoostIcon)`
    margin-right: 9px;
    display: flex;
    align-items: center;
    fill: currentcolor;
`;
