import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface BackspaceIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const BackspaceIcon = observer('BackspaceIcon', ({ className, onClick }: BackspaceIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            clipRule='evenodd'
            fill='#072d4f'
            fillRule='evenodd'
            height='17'
            onClick={onClick}
            strokeLinejoin='round'
            strokeMiterlimit='2'
            viewBox='0 0 24 17'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M23.388.636A2.137 2.137 0 0021.838 0H7.875C6.736 0 5.826.441 5.17 1.333L0 8.247l5.175 6.869.008.01.009.011c.324.415.676.736 1.078.957a3.289 3.289 0 001.605.406h13.969c1.227 0 2.156-1.043 2.156-2.297v-12c0-.601-.195-1.15-.612-1.567zM22.5 14.203c0 .414-.242.797-.656.797H7.875c-.711 0-1.141-.312-1.5-.773l-4.5-5.977 4.5-6.021c.445-.609.992-.729 1.5-.729H21.82c.415 0 .68.289.68.703v12z'
                fillRule='nonzero'
            />
            <path
                d='M15.16 8.247l3.595-3.595a.683.683 0 000-.96.682.682 0 00-.961 0L14.2 7.286l-3.595-3.594a.679.679 0 00-.96.96l3.594 3.595-3.594 3.594a.68.68 0 00.96.962L14.2 9.207l3.594 3.595a.68.68 0 00.962.001.68.68 0 000-.96L15.16 8.247z'
                fillRule='nonzero'
            />
        </SvgElement>
    );
});
