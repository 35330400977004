import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { HomeIcon } from 'src/domains/layouts/shared/assets/icons/HomeIcon';
import { FootballIcon } from 'src/domains/layouts/shared/assets/icons/FootballIcon';
import { InPlayIcon } from 'src/domains/layouts/shared/assets/icons/InPlayIcon';
import { CasinoIcon } from 'src/domains/layouts/shared/assets/icons/CasinoIcon';
import { CasinoTableGamesIcon } from 'src/domains/layouts/shared/assets/icons/CasinoTableGamesIcon';
import { PromotionsIcon } from 'src/domains/layouts/shared/assets/icons/PromotionsIcon';
import { HorseRacingIcon } from 'src/domains/layouts/shared/assets/icons/HorseRacingIcon';
import { CasinoLucky6Icon } from 'src/domains/layouts/shared/assets/icons/CasinoLucky6Icon';
import { CashOutIcon } from 'src/domains/layouts/shared/assets/icons/CashOutIcon';
import { SportsListIcon } from 'src/domains/layouts/shared/assets/icons/SportsListIcon';
import { LazyLoadingImg } from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg';

export const MobileIconWrapper = styled(LazyLoadingImg)`
    height: 20px;
    width: 20px;
`;

const setMobileIcon = (): SerializedStyles => {
    return css`
        fill: currentcolor;
        width: 20px;
    `;
};

export const SportsListIconWrapper = styled(SportsListIcon)`
    ${setMobileIcon()};
`;

export const InPlayIconWrapper = styled(InPlayIcon)`
    ${setMobileIcon()};
`;

export const CasinoIconWrapper = styled(CasinoIcon)`
    ${setMobileIcon()};
`;

export const LiveCasinoIconWrapper = styled(CasinoTableGamesIcon)`
    ${setMobileIcon()};
`;

export const CasinoLucky6IconWrapper = styled(CasinoLucky6Icon)`
    ${setMobileIcon()};
`;

export const BingoIconWrapper = styled(CasinoLucky6Icon)`
    ${setMobileIcon()};
`;

export const CashOutIconWrapper = styled(CashOutIcon)`
    ${setMobileIcon()};
`;

export const LoudSpeakerIconWrapper = styled(PromotionsIcon)`
    ${setMobileIcon()};
`;

export const HomeIconWrapper = styled(HomeIcon)`
    ${setMobileIcon()};
`;

export const FootballIconWrapper = styled(FootballIcon)`
    ${setMobileIcon()};
`;

export const HorseracingIconWrapper = styled(HorseRacingIcon)`
    ${setMobileIcon()};
`;
