import React from 'react';
import { StyledCheckCircleOutlineIcon, StyledContainer, StyledText } from './FreeBetApplied.styled';

export const FreeBetApplied = () => {
    return (
        <StyledContainer>
            <StyledCheckCircleOutlineIcon />
            <StyledText>Free Bet Applied</StyledText>
        </StyledContainer>
    );
};
