import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const EventRowItem = withConfig(theme => styled('div')`
    align-items: center;
    display: flex;
    width: calc(50% - 7px);
    &:first-of-type {
        margin-right: 6px;
        flex-direction: row-reverse;
    }
    &:last-of-type {
        margin-left: 6px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        width: auto;
        &:first-of-type {
            flex-direction: row;
            margin-right: 0;
        }
        &:last-of-type {
            margin-left: 0;
        }
    }
`);

export const EventRowItemLogo = withConfig(theme => styled('img')`
    display: block;
    height: 48px;
    width: 48px;
    @media ${theme.star.mediaQuery.desktop} {
        height: 32px;
        width: 32px;
    }
`);

interface EventRowParticipantNamePropsType {
    isHomeParticipant: boolean;
}

export const EventRowParticipantName = withConfig(theme => styled('span')<EventRowParticipantNamePropsType>`
    color: ${theme.star.eventRow.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
    margin: 0;
    text-align: ${({ isHomeParticipant }): string => isHomeParticipant ? 'right' : 'left'};
    position: relative;
    &::before {
        align-items: center;
        bottom: 0;
        content: 'v';
        display: ${({ isHomeParticipant }): string => isHomeParticipant ? 'flex' : 'none'};
        font-size: ${theme.star.fontSize.xRegular};
        font-weight: ${theme.star.fontWeight.medium};
        justify-content: center;
        left: calc(100% + 48px);
        line-height: 1;
        margin: auto 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 12px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        margin: 0 8px;
        &::before {
            content: none;
        }
    }
`);
