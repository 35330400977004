
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const TwitterWidgetLink = withConfig(theme => styled('a')`
    color: ${theme.star.accountPanel.txtColor};
    display: block;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    overflow: hidden;
    text-decoration: none;
    text-indent: 101%;
    white-space: nowrap;
`);

interface TwitterWidgetWrapperProps {
    isWidgetNotVisible: boolean;
}

export const TwitterWidgetWrapper = styled('div')<TwitterWidgetWrapperProps>`
    -webkit-overflow-scrolling: touch;
    display: ${({ isWidgetNotVisible }): string => isWidgetNotVisible ? 'none' : 'block'};
    overflow: auto;
    padding: 0 8px;
    &::-webkit-scrollbar {
        background-color: rgba(0, 0, 0, .1);
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
    }
    scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.1);
    scrollbar-width: thin;
`;
