import styled from '@emotion/styled';
import { BackspaceIcon } from 'src/domains/sportsbook/betting/ui/betSlip/customKeyboard/BackspaceIcon';
import { KeyboardIcon } from 'src/domains/sportsbook/betting/ui/betSlip/customKeyboard/KeyboardIcon';
import { withConfig } from 'src/withConfig';

export const CustomNumericKeyboard = withConfig(theme => styled('div')`
    background-color: ${theme.star.customKeyboard.bgColor};
    height: 201px;
    left: 0;
    margin: 0 auto;
    max-width: 768px;
    position: fixed;
    right: 0;
    top: 100%;
    transform: translateY(-201px);
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.highLevel};
`);

export const CustomNumericQuickBetKeyboardWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.customKeyboard.bgColor};
    max-width: 768px;
    top: 100%;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.highLevel};
`);

export const IconDeleteWrapper = styled(BackspaceIcon)`
    fill: currentcolor;
    margin-top: 6px;
    width: 27px;
`;

export const KeyboardBtnIconWrapper = styled(KeyboardIcon)`
    cursor: pointer;
    margin: 14px 12px 0 0;
    width: 22px;
`;

export const KeyboardActionPanel = styled('div')`
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: flex-end;
    order: 1;
`;

export const KeyboardDigitWrapper = withConfig(theme => styled('div')`
    align-items: center;
    border-top: 1px solid ${theme.star.customKeyboard.borderColor};
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`);

export const KeyboardSingleButton = withConfig(theme => styled('a')<{isSpecial: boolean}>`
    align-items: center;
    background-color: ${({ isSpecial }): string => isSpecial ? theme.star.customKeyboard.bgColorKeySpecial : theme.star.customKeyboard.bgColorKey};
    border-bottom: 1px solid ${theme.star.customKeyboard.borderColor};
    border-right: 1px solid ${theme.star.customKeyboard.borderColor};
    color: ${theme.star.customKeyboard.txtColor};
    display: flex;
    flex-flow: row wrap;
    height: 40px;
    justify-content: center;
    text-decoration: none;
    width: 33.33%;
`);
