import { Input } from 'src/domains/players/webview/components/form/input/Input';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

interface SelectPropsType {
    isDisabled: boolean;
}

const styleDisabledSelect = (isDisabled?: boolean): string | undefined => {
    if (isDisabled !== undefined && isDisabled) {
        return `
            cursor: not-allowed;
            opacity: .4;
            user-select: none;
        `;
    }
    return;
};

export const SelectWrapper = styled('div') <SelectPropsType>`
    position: relative;
    margin: 5px 0;
    &:only-of-type {
        margin: 5px 0 0;
    }
    ${({ isDisabled }): string | undefined => styleDisabledSelect(isDisabled)};
`;

type PropsTypes = {
    hasPrefixes: boolean | undefined;
    isError: boolean;
    viewForOneOption: boolean;
}

export const SelectInput = withConfig(theme => styled(Input) <PropsTypes>`
    margin: 0;
    pointer-events: ${({ hasPrefixes }): string => hasPrefixes === true ? 'auto' : 'none'};
    caret-color: transparent;

    ${({ viewForOneOption, hasPrefixes, isError }): string => viewForOneOption === true ? '' : renderDropdownCaret(hasPrefixes, isError, theme)};
`);

const renderDropdownCaret = (hasPrefixes: boolean | undefined, isError: boolean, theme: EmotionTheme): string => {
    return `::before,
    ::after {
        position: absolute;
        content: '';
        display: ${isError === true || hasPrefixes === false ? 'none' : 'block'};
        width: 1px;
        height: 8px;
        bottom: 15px;
        right: 16px;
        background-color: ${theme.star.form.bgColor};
        pointer-events: none;
        transform: rotate(45deg);
        z-index: ${theme.star.zIndexGlobal.above};
    }
    ::after {
        margin-right: 5px;
        transform: rotate(-45deg);
    }`;
};
interface OptionsWrapperPropsType {
    isOpen: boolean;
    colorTheme?: 'light' | 'dark';
    hideLabel?: boolean;
}

export const styleBackgroundInput = (theme: EmotionTheme, colorTheme?: 'dark' | 'light'): string => {
    if (colorTheme === 'light') {
        return theme.star.form.light.bgColor;
    }

    if (colorTheme === 'dark') {
        return theme.star.form.dark.bgColor;

    }

    return 'currentcolor';

};

export const OptionsWrapper = withConfig(theme => styled('div') <OptionsWrapperPropsType>`
    display: ${({ isOpen }): string => isOpen === true ? 'block' : 'none'};
    position: absolute;
    top: ${({ hideLabel }): string => hideLabel === true ? '42px' : '60px'};
    left: 0;
    max-height: 240px;
    width: 100%;
    overflow: auto;
    background-color: ${({ colorTheme }): string => styleBackgroundInput(theme, colorTheme)};
    z-index: ${theme.star.zIndexGlobal.above};
    box-shadow: 0 7px 7px 0 rgba(0, 0, 0, .25);
`);

export interface SingleOptionPropsType {
    isSelected: boolean;
}

export const SingleOption = withConfig(theme => styled('button') <SingleOptionPropsType>`
    display: block;
    width: 100%;
    border: none;
    background: ${({ isSelected }): string => isSelected ? theme.star.form.bgColorSecondary : 'transparent'};
    padding: 10px 12px;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${({ isSelected }): string => isSelected ? theme.star.fontWeight.bold : theme.star.fontWeight.regular};
    text-align: left;
    cursor: pointer;
    &:hover {
        background: ${theme.star.form.bgColorSecondary};
    }
    &[aria-selected='true']{
        background: ${theme.star.form.bgColorSecondary};
    }
`);

export const SelectLabel = withConfig(theme => styled('label')`
    display: block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1;
    margin-bottom: 6px;
    margin-top: 16px;
`);
