import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { withConfig } from 'src/withConfig';

export const OffersSettingsHeader = withConfig(theme => styled('h3')`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 24px 0 12px !important;
    padding: 0 !important;
`);

interface SettingsListType {
    isProcessingRequest?: boolean;
}

const buildProgressingSettingsList = (isProcessingRequest?: boolean): SerializedStyles | null => {
    if (isProcessingRequest === true) {
        return css`
            pointer-events: none;
            opacity: 0.5;
            cursor: not-allowed;
        `;
    }
    return null;
};

export const SettingsList = withConfig(theme => styled('div')<SettingsListType>`

    ${({ isProcessingRequest }): SerializedStyles | null => buildProgressingSettingsList(isProcessingRequest)};

    @media ${theme.star.mediaQuery.xMobile} {
        padding-right: 0;
    }
`);

export const OptionsWrapper = styled('div')`
    display: flex;
`;

export const ErrorMessageWrapper = styled(Messages)`
    flex-basis: 100%;
`;
