import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const InfoIconWrapper = withConfig(theme => styled(InfoIcon)`
    background: ${theme.star.topUp.bgColor};
    border-radius: 50%;
    display: block;
    fill: ${theme.star.topUp.bgColorSecondary};
    height: 80px;
    margin: 80px auto 32px;
    padding: 24px;
    width: 80px;
`);

export const IssueWithdrawContentContainer = styled('div')`
    margin: 0 16px;
    text-align: center;
`;



export const IssueWithdrawTitle = withConfig(theme => styled('h4')`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 0;
`);

export const IssueWithdrawTitleSecondary = withConfig(theme => styled('h4')`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    max-width: 220px;
    margin: 0 auto;
`);

export const IssueWithdrawInfo = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.43;
    margin: 8px 0 0 0;
`);
