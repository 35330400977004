import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const setChevronPosition = (position: 'up' | 'right' | 'down' | 'left'): string => {
    if (position === 'right') {
        return 'rotate(90deg)';
    }

    if (position === 'down') {
        return 'rotate(180deg)';
    }

    if (position === 'left') {
        return 'rotate(-90deg)';
    }

    return 'rotate(0)';
};

interface SvgElementPropsType {
    position: 'up' | 'right' | 'down' | 'left';
}

const SvgElement = styled('svg')<SvgElementPropsType>`
    height: auto;
    transform: ${({ position } ): string => setChevronPosition(position)};
    transition: transform .2s ease;
`;

interface ChevronPropsType {
    className?: string;
    onClick?: (e: React.SyntheticEvent) => void;
    position: 'up' | 'right' | 'down' | 'left';
}

export const ChevronIcon = observer('ChevronIcon', ({ className, onClick, position }: ChevronPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            position={position}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M21.666 18.2L23 16.777 12 5 1 16.777 2.327 18.2 12 7.853 21.666 18.2z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
