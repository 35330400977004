import styled from '@emotion/styled';
import { HeadlineCarouselSlideImagesType } from './HeadlineCarouselSlide.state';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { LoadingWrapper } from 'src/domains/layouts/shared/loaders';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { withConfig } from 'src/withConfig';

interface PropsTypes {
    bgImagesUrl: HeadlineCarouselSlideImagesType;
    bgColor: string | null;
    hasRedirection?: boolean;
}

interface ButtonType {
    color?: string;
}

export const HeadlineCarouselWrapper = styled(LoadingWrapper)`
    margin: 16px 16px 16px 0;
    overflow: visible;
`;

export const DefaultImage = styled('img')`
    width: 100%;
    position: absolute;
    ${positionElementFull};
    height: 100%;
    object-fit: cover;
    object-position: right;
`;

export const HeadlineCarouselSlideWrapper = withConfig(theme => styled('div') <PropsTypes>`
    ${({ hasRedirection }): string => hasRedirection === true ? 'cursor: pointer' : ''};
    background: ${({ bgColor }): string => bgColor ?? theme.star.homePageCarousel.items.bgColorTertiary};
    border-radius: 10px;
    display: flex;
    flex-flow: column nowrap;
    height: 208px;
    margin-left: 8px;
    overflow: hidden;
    position: relative;
    width: 100%;
    &:first-of-type {
        margin-left: 0;
    }
    @media ${theme.star.mediaQuery.tablet} {
        height: 256px;
    }
`);

export const HeadlineCarouselSlideContent = styled('div')`
    position: relative;
    overflow: hidden;
    flex: 1 1 0%;
`;

export const HeadlineCarouselText = withConfig(theme => styled('div')`
    display: flex;
    flex: 1 0 0%;
    overflow: hidden;
    padding: 24px 16px 16px 16px;
    height: calc(100% - 32px);
    flex-flow: column nowrap;
    justify-content: space-between;
    @media ${theme.star.mediaQuery.tablet} {
        padding: 40px 24px 32px 24px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        padding: 28px 48px 20px 48px;
    }
`);

export const HeadlineCarouselTitle = withConfig(theme => styled('div')`
    color: ${theme.star.homePageCarousel.items.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.42;
    max-width: 50%;
    white-space: pre-line;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.small};
        line-height: 1.22;
    }
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.xRegular};
        line-height: 1.22;
    }
`);

export const HeadlineCarouselSubTitle = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.25;
    font-weight: ${theme.star.fontWeight.bold};
    color: ${theme.star.homePageCarousel.items.txtColor};
    white-space: pre-line;
    max-width: 50%;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.xxMedium};
        line-height: 1.2;
    }
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.medium};
        line-height: 1.2;
    }
`);

export const ButtonWrapper = withConfig(theme => styled('div')`
    z-index: ${theme.star.zIndexGlobal.above};
`);

export const ActionButton = withConfig(theme => styled(Button)<ButtonType>`
    background-color: ${({ color }): string => color === undefined ? 'transparent' : color};
    z-index: ${theme.star.zIndexGlobal.above};
`);

export const TermsWrapper = withConfig(theme => styled('div')`
    height: 32px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    padding: 10px 16px 12px 16px;
    background: ${theme.star.homePageCarousel.items.bgColorSecondary};
`);

export const TermsText = withConfig(theme => styled('div')`
    color: ${theme.star.homePageCarousel.items.txtColorSecondary};
    font-size: ${theme.star.fontSize.xxSmall};
    line-height: 1.25;
`);
