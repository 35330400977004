import * as React from 'react';
import styled from '@emotion/styled';

const SvgElement = styled('svg')`
    height: auto;
`;

interface PropsType {
  className?: string;
  onClick?: () => void;
}

export const HourGlassIcon = ({ className, onClick }: PropsType): React.ReactElement => (
    <SvgElement
        className={className}
        onClick={onClick}
        xmlns='http://www.w3.org/2000/svg'
        width='27'
        height='27'
        viewBox='0 0 27 27'
    >
        <g fill='none' fillRule='evenodd'>
            <circle cx='13.5' cy='13.5' r='13.5' fill='#A4B2BF'/>
            <path fill='#FFF' fillRule='nonzero' d='M17.517 18.448V17.17c0-1.504-1.162-2.404-2.28-3.281-.154-.121-.35-.351-.35-.702 0-.35.177-.692.35-.839 1.088-.914 2.28-1.777 2.28-3.281v-.965h.877V6.875H8.75v1.227h.877v.965c0 1.504 1.192 2.367 2.28 3.281.173.147.35.489.35.84 0 .35-.197.58-.35.7-1.118.878-2.28 1.778-2.28 3.282v1.278H8.75v1.227h9.644v-1.227h-.877zm-6.16-8.242c-.322-.365-.503-.717-.503-1.14v-.964h5.436v.965c0 .422-.18.774-.504 1.14h-4.428zm4.933 7.9l-2.065-.806c-.359-.14-.947-.14-1.306 0l-2.065.806v-.936c0-.85.677-1.425 1.78-2.293l.03-.023c.419-.328.69-.786.783-1.29.082.018.167.018.25 0 .092.504.364.962.782 1.29l.03.023c1.104.868 1.78 1.442 1.78 2.293v.936z'/>
        </g>
    </SvgElement>
);
