import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';
import { AppState } from 'src/appState/AppState';

const Wrapper = styled('div')`
    text-align: center;
    cursor: pointer;
`;

const TranslationLink = styled('span')`
    padding: 10px;
    cursor: pointer;
    &:hover {
        background-color: #e0e0e0;
        color: red;
    }
`;

const renderButtonDot = (appState: AppState): React.ReactNode => {
    const { languagesState } = appState.appLayoutsState;
    
    if (languagesState.translationsDisplayDebug === 'show-dot') {
        return (
            <TranslationLink onClick={languagesState.translationsDisplayDebugHide}>
                Hide dot
            </TranslationLink>
        );
    }
    
    return (
        <TranslationLink onClick={languagesState.translationsDisplayDebugShowDot}>
            Show dot
        </TranslationLink>
    );
};

const renderButtonId = (appState: AppState): React.ReactNode => {
    const { languagesState } = appState.appLayoutsState;

    if (languagesState.translationsDisplayDebug === 'show-id') {
        return (
            <TranslationLink onClick={languagesState.translationsDisplayDebugHide}>
                Hide id
            </TranslationLink>
        );
    }

    return (
        <TranslationLink onClick={languagesState.translationsDisplayDebugShowId}>
            Show id
        </TranslationLink>
    );
};

interface PropsTypes {
    appState: AppState;
}

export const MainViewTranslations = observer('MainViewTranslations', ({ appState }: PropsTypes) => {
    return (
        <Wrapper>
            {renderButtonDot(appState)}
            {renderButtonId(appState)}
        </Wrapper>
    );
});
