import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';

export const NetDepositText = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.3;
    margin: 16px 0;
    padding: 0 6px;
`);

export const NetDepositEmailInformation = styled('span')`
    display: block;
    margin-top: 20px;
`;

export const NetDepositEmail = withConfig(theme => styled(Link)`
    color: ${theme.star.transactionHistory.txtColorQuinary};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4286;
    text-decoration: none;
`);
