import styled from '@emotion/styled';
import { AccountNavigationLink } from 'src/domains/layouts/webview/components/accountNavigationLink/AccountNavigationLink';
import { setLinkWrapper } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation.style';
import { withConfig } from 'src/withConfig';

export const Balance = styled('span')`
    display: block;
`;

export const BalanceWrapper = withConfig(theme => styled(AccountNavigationLink)`
    ${setLinkWrapper(theme)};
    flex-flow: column nowrap;
`);

export const BalanceWrapperTitle = withConfig(theme => styled('div')`
    display: block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
`);
