import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const HighLightedTextWrapper = withConfig(theme => styled('span')`
    color: ${theme.star.searchBar.txtColorSecondary};
    display: block;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
`);

export const TextEmphasised = withConfig(theme => styled('strong')`
    color: ${theme.star.searchBar.txtColor};
`);
