import { withConfig } from 'src/withConfig';
import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';

export const PofPopupContainer = withConfig(theme => styled('div')`
    background-color: ${theme.star.pofModals.bgColor};
    color: ${theme.star.pofModals.txtColor};
    max-width: 512px;
    margin: 0 16px;
    padding: 40px 32px;
    position: fixed;
`);

export const PofPopupHeader = withConfig(theme => styled('h2')`
    font-size: ${theme.star.fontSize.big};
    color: ${theme.star.pofModals.txtColor};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0 0 12px 0;
`);

export const PofPopupSubHeader = withConfig(theme => styled('h4')`
    font-size: ${theme.star.fontSize.xRegular};
    color: ${theme.star.pofModals.txtColor};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0 0 8px 0;
    line-height: 1.43;
`);

export const PofPopupDescription = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.xRegular};
    color: ${theme.star.pofModals.txtColorSecondary};
    margin: 0 0 24px 0;
    line-height: 1.43;
`);

export const PofPopupButton = styled(Button)`
    width: 100%;
`;

export const PofPopupEmailLink = withConfig(theme => styled('a')`
    color: ${theme.star.pofModals.txtColorTertiary};
`);
