import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const EventRowWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.eventRow.bgColor};
    border-top: solid 1px ${theme.star.eventRow.borderColor};
    border-bottom: solid 1px ${theme.star.eventRow.borderColor};
    border-left: solid 1px ${theme.star.eventRow.borderColor};
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    min-height: 56px;
    position: relative;
    margin: 0 0 -1px;
    z-index: ${theme.star.zIndexGlobal.base};
    @media ${theme.star.mediaQuery.desktop} {
        min-height: 48px;
    }
`);

export const EventRowNameLink = withConfig(theme => styled(Link)`
    align-items: flex-start;
    color: ${theme.star.eventRow.txtColor};
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    overflow: hidden;
    padding: 4px 8px;
    text-decoration: none;
    text-overflow: ellipsis;
    width: 100%;
    @media ${theme.star.mediaQuery.tablet} {
        padding: 8px;
    }
`);

export const EventRowHeader = styled('div')`
    display: flex;
    flex: 1 1 0%;
    flex-flow: row nowrap;
    line-height: 1;
    min-width: 50px;
    transition: background-color .2s ease;
`;

export const EventRowMarkets = styled('div')`
    min-width: 100%;
`;

export const EventRowSelections = withConfig(theme => styled('ul')`
    display: flex;
    flex-flow: row nowrap;
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: center;
`);

export const EventRowSelectionsElement = styled('li')`
    display: flex;
    height: 100%;
`;

export const EventRowMeta = withConfig(theme => styled('div')`
    align-items: center;
    color: ${theme.star.eventRow.txtColorSecondary};
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    white-space: nowrap;
`);
