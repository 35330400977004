import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface DotsWrapperPropsType {
    displayOnMobile?: boolean;
    position: 'static' | 'absolute';
    marginTop?: number;
}
const buildDotsWrapperStyle = (displayOnMobile: boolean | undefined): string => {
    if (displayOnMobile === true) {
        return `
            display: flex;
            justify-content: center;
            align-items: center;
        `;
    }
    return `
        display: none;
    `;
};

export const DotsWrapper = withConfig(theme => styled('div')<DotsWrapperPropsType>`
    ${({ displayOnMobile }): string => buildDotsWrapperStyle(displayOnMobile)};

    @media ${theme.star.mediaQuery.tablet} {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: ${({ marginTop }): string => marginTop === undefined ? 'auto' : `${marginTop}px`};
        position: ${({ position }): string => position};
        bottom: 12px;
        left: 0;
        right: 0;
    }
`);

interface DotPropsTypes {
    isActive: boolean,
    isSmall: boolean,
    isSmallest: boolean,
    isHomePage: boolean,
}

export const Dot = withConfig(theme => styled('span') <DotPropsTypes>`
    background-color: ${({ isHomePage, isActive }): string => isHomePage ? isActive ? theme.star.homePageCarousel.homePage.bgColorSecondary : theme.star.homePageCarousel.homePage.bgColor : isActive ? theme.star.homePageCarousel.casino.bgColorSecondary : theme.star.homePageCarousel.casino.bgColor};
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    width: ${({ isSmall, isSmallest }): string => isSmallest ? '2px' : isSmall ? '4px' : '8px'};
    height: ${({ isSmall, isSmallest }): string => isSmallest ? '2px' : isSmall ? '4px' : '8px'};
    margin-left: 5px;
    &:first-of-type {
        margin-left: 0;
    }
`);
