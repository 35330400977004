import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const setArrowPosition = (position: 'up' | 'right' | 'down' | 'left'): string => {
    if (position === 'right') {
        return 'rotate(90deg)';
    }

    if (position === 'down') {
        return 'rotate(180deg)';
    }

    if (position === 'left') {
        return 'rotate(-90deg)';
    }

    return 'rotate(0)';
};

interface SvgElementPropsType {
    position: 'up' | 'right' | 'down' | 'left';
}

const SvgElement = styled('svg')<SvgElementPropsType>`
    height: auto;
    transform: ${({ position } ): string => setArrowPosition(position)};
`;

interface ChevronPropsType {
    className?: string;
    onClick?: () => void;
    position: 'up' | 'right' | 'down' | 'left';
}

export const ChevronIconFull = observer('ChevronIconFull', ({ className, onClick, position }: ChevronPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#fff'
            height='9'
            onClick={onClick}
            position={position}
            viewBox='0 0 8 9'
            width='8'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path fill='fill' fillRule='evenodd' d='M8 6.334H0l4-4 4 4Z' clipRule='evenodd'/>
        </SvgElement>
    );
});
