import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const StyledSeeMoreButton = withConfig(theme => styled('div')`
    background-color: ${theme.star.casino.casinoMini.bgColorSecondary};
    cursor: pointer;
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.small};
    margin: 4px 10px 0;
    text-align: center;
    width: calc(100% - 20px);
`);
