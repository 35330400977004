import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { NotRunningPriceView } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { withConfig } from 'src/withConfig';

interface PropsType {
    isRaceInProgress?: boolean;
}

export const RaceHeaderListMeta = styled('ul')``;

export const RaceHeaderListItemMeta = withConfig(theme => styled('li')`
    display: inline-block;
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1.3334;
    margin: 0 5px 0 0;
    &:last-of-type {
        margin-right: 0;
    }
`);

export const RaceHeaderListItemSeparatorMeta = styled(RaceHeaderListItemMeta)`
    position: relative;
    width: 1px;
    &:last-of-type {
        display: none;
    }
    &::after {
        background-color: currentcolor;
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
    }
`;

export const RaceCardFeed = withConfig(theme => styled('div')`
    align-items: center;
    display: flex;
    font-weight: ${theme.star.fontWeight.medium};
`);


export const RaceSummary = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3;
    margin-top: -1px;
    &:first-of-type {
        margin-top: -1px;
    }
`);

interface RaceSummaryNoHistoryPropsType {
    isPriceHistoryHidden: boolean;
}

export const RaceSummaryNoHistory = styled(RaceSummary)<RaceSummaryNoHistoryPropsType>`
    .race-summary__selection__price-history { /* Please remove after component refactor */
        ${({ isPriceHistoryHidden }): string => isPriceHistoryHidden ? 'display: none;' : ''}
    }
`;

export const RaceSummaryMeta = styled('div')`
    text-align: right;
    padding: 0 10px;
    /* To refactor */
    li {
        vertical-align: middle;
        display: inline-block;
        margin-right: 5px;
        &:last-of-type {
            margin-right: 0;
        }
    }
`;

/* Table caption and cells */
export const RaceSummaryCaption = withConfig(theme => styled('li')`
    background: ${theme.star.raceSummary.bgColorSecondary};
    border: 1px solid ${theme.star.raceSummary.borderColor};
    color: ${theme.star.raceSummary.txtColorTertiary};
    display: flex;
    flex-flow: row nowrap;
    font-size: ${theme.star.fontSize.xSmall};
    justify-content: space-between;
    line-height: 1.4;
`);

export const setRaceCell = (theme: EmotionTheme): SerializedStyles => css`
    align-items: center;
    border-right: 1px solid ${theme.star.raceSummary.borderColor};
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    justify-content: center;
`;

export const PlaceCaption = withConfig(theme => styled('span')`
    ${setRaceCell(theme)};
    flex: 0 0 24px;
    text-align: center;
    @media ${theme.star.mediaQuery.tablet} {
        flex: 0 0 32px;
    }
`);

export const PlaceCell = withConfig(theme => styled(PlaceCaption)`
    border-right: 1px solid ${theme.star.raceSummary.borderColor};
    flex-flow: column nowrap;
`);

export const SilkCaption = withConfig(theme => styled('span')`
    ${setRaceCell(theme)};
    flex: 0 0 40px;
    @media ${theme.star.mediaQuery.tablet} {
        flex: 0 0 65px;
    }
`);

export const SilkCell = withConfig(theme => styled(SilkCaption)`
    border-right: 1px solid ${theme.star.raceSummary.borderColor};
    img {
        width: 24px;
    }
    @media ${theme.star.mediaQuery.tablet} {
        padding: 17px 20px !important;
    }
`);

export const RunnerCaption = withConfig(theme => styled('span')`
    ${setRaceCell(theme)};
    flex: 1 1 0%;
    padding: 1px 8px !important;
    justify-content: flex-start;
`);

interface RunnerCellPropsType {
    isHideRunnerInfo?: boolean;
}

export const RunnerCell = withConfig(theme => styled(RunnerCaption)<RunnerCellPropsType>`
    border-right: 1px solid ${theme.star.raceSummary.borderColor};
    align-items: flex-start;
    flex-flow: column nowrap;
    justify-content: center;
    min-width: 111px;
    i {
        ${({ isHideRunnerInfo }): string => isHideRunnerInfo === true ? 'display: none': ''};
    }
`);

export const RaceRunnerName = withConfig(theme => styled('span')`
    color: ${theme.star.raceSummary.txtColor};
    display: block;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.333;
`);

export const WeightCaption = withConfig(theme => styled('span')`
    display: none !important;
    @media ${theme.star.mediaQuery.tablet} {
        ${setRaceCell(theme)};
        display: flex !important;
        flex: 0 0 64px;
        padding: 1px 2px !important;
        text-align: center;
    }
`);

export const WeightCell = withConfig(theme => styled(WeightCaption)`
    @media ${theme.star.mediaQuery.tablet} {
        border-right: 1px solid ${theme.star.raceSummary.borderColor};
    }
`);

export const SelectionCaption = withConfig(theme => styled('span')<PropsType>`
    ${setRaceCell(theme)};
    color: ${({ isRaceInProgress }): string => isRaceInProgress === true ? theme.star.raceCardColors.txtColorInProgressRace : 'inherit'};
    flex: 0 0 48px;
    padding: 1px 4px !important;
    text-align: center;
    margin-left: -1px;
    &:last-of-type {
        border-right: 0;
    }
    @media ${theme.star.mediaQuery.tablet} {
        flex: 0 0 64px;
    }
`);


export const PriceHistoryCaption = withConfig(theme => styled('span')<PropsType>`
    ${setRaceCell(theme)};
    flex: 0 0 76px;
    padding: 0 !important;
    text-align: center;
    @media ${theme.star.mediaQuery.tablet} {
        flex: 0 0 160px;
    }
`);

export const PriceHistoryCell = withConfig(theme => styled(PriceHistoryCaption)`
    border-right: 1px solid ${theme.star.raceSummary.borderColor};
    overflow: hidden;
    padding: 4px !important;
`);

export const NonRunnerCell = withConfig(theme => styled('span')<NotRunningPriceView>`
    ${setRaceCell(theme)};
    border-right: 1px solid ${theme.star.raceSummary.borderColor};
    flex: ${({ eachWayExtra }): string => eachWayExtra === true ? '0 0 64px' : '0 0 48px'};
    width: ${({ eachWayExtra }): string => eachWayExtra === true ? '64px' : '48px'};
    font-size: ${theme.star.fontSize.small};
    margin-left: -1px;
    @media ${theme.star.mediaQuery.tablet} {
        flex: 0 0 64px;
        width: 64px;
        font-size: ${theme.star.fontSize.xRegular};
    }
`);

export const NonRunnerCellMatchBet = withConfig(theme => styled(NonRunnerCell)`
    border-left: 1px solid ${theme.star.raceSummary.borderColor};
`);

interface RaceSummaryRowPropsType {
    isNonRunner?: boolean;
    tags?: React.ReactNode;
    isAlternativeTag?: boolean;
}

const setTags = (isAlternativeTag?: boolean, tags?: React.ReactNode): SerializedStyles | null => {
    if (tags === undefined || tags === null) {
        return null;
    }

    if (isAlternativeTag === true) {
        return css`
            margin-bottom: 18px;
        `;
    }

    return css`
        margin-bottom: 28px;
    `;
};

export const RaceSummaryRow = withConfig(theme => styled('li')<RaceSummaryRowPropsType>`
    ${({ isAlternativeTag, tags }): SerializedStyles | null => setTags(isAlternativeTag, tags)};
    background-color: ${theme.star.raceSummary.bgColor};
    color: ${theme.star.raceSummary.txtColor};
    border-left: 1px solid  ${theme.star.raceSummary.borderColor};
    border-right: 1px solid  ${theme.star.raceSummary.borderColor};
    border-bottom: 1px solid  ${theme.star.raceSummary.borderColor};
    display: flex;
    flex-flow: row nowrap;
    height: 48px;
    justify-content: space-between;
    opacity: ${({ isNonRunner }): number => isNonRunner === true ? 0.4 : 1};
    position: relative;
    &:last-of-type {
        border-bottom: 0;
    }
    @media ${theme.star.mediaQuery.tablet} {
        height: 56px;
    }
`);
