import styled from '@emotion/styled';
import { Selection } from 'src/domains/sportsbook/webview/components/Selection';
import { withConfig } from 'src/withConfig';

export const EventRowSelections = styled('div')`
    flex: 1 0 33.3333%;
`;

export const EventRowSelectionsName = withConfig(theme => styled('div')`
    align-items: center;
    background-color: ${theme.star.eventRow.bgColorSecondary};
    color: ${theme.star.eventRow.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.medium};
    height: 16px;
    justify-content: center;
    line-height: 1;
    text-align: center;
    width: 100%;
    @media ${theme.star.mediaQuery.desktop} {
        display: none;
    }
`);

export const EventRowSelectionsElement = withConfig(theme => styled('div')`
    display: flex;
    height: 44px;
    @media ${theme.star.mediaQuery.desktop} {
        width: 100%;
        margin-left: 1px;
    }
`);

export const EventRowSingleSelection = styled(Selection)`
    flex: 1 1 100%;
    height: 100%;
    width: 100%;
`;
