import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const EventRow = withConfig(theme => styled('section')`
    background-color: ${theme.star.eventRow.bgColor};
    border-left: 1px solid ${theme.star.eventRow.borderColor};
    border-right: 1px solid ${theme.star.eventRow.borderColor};
    display: flex;
    flex-direction: column;
    transition: background-color .2s ease;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        border-top: 1px solid ${theme.star.eventRow.borderColor};
        width: 100%;
        top: -1px;
    }
    &:hover {
        background-color: ${theme.star.eventRow6PackColors.bgColor};
    }
    @media ${theme.star.mediaQuery.desktop} {
        flex-direction: row;
    }
`);

export const EventRowName = withConfig(theme => styled(Link)`
    color: inherit;
    display: flex;
    flex-flow: column nowrap;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    justify-content: center;
    line-height: 1;
    min-height: 80px;
    overflow: hidden;
    padding: 12px 28px 6px 28px;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    width: 100%;
    @media ${theme.star.mediaQuery.desktop} {
        justify-content: flex-start;
        min-height: 88px;
        padding: 4px 5px;
    }
`);

export const EventRowNameContainer = withConfig(theme => styled('div')`
    color: ${theme.star.eventRow.txtColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    @media ${theme.star.mediaQuery.desktop} {
        display: block;
    }
`);

export const EventRowAdditions = withConfig(theme => styled('div')`
    align-items: center;
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    height: 14px;
    justify-content: center;
    text-align: center;
    @media ${theme.star.mediaQuery.desktop} {
        justify-content: flex-start;
        padding-left: 40px;
    }
`);

export const EventRowMoreMarketsLink = withConfig(theme => styled('span')`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    color: ${theme.star.eventRow6PackColors.txtColor};
    margin-left: 8px;
`);

export const MoreMarketChevron = styled(ChevronIcon)`
    width: 12px;
    margin: 0 0 0 4px;
    fill: currentcolor;
`;

export const EventRowMobileChevron = withConfig(theme => styled(ChevronIcon)`
    fill: ${theme.star.eventRow6PackColors.bgColorSecondary};
    margin-top: -10px;
    position: absolute;
    right: 8px;
    top: 50%;
    width: 12px;
`);

export const EventRowSelectionsContainer = withConfig(theme => styled('div')`
    display: flex;
    width: 100%;
    @media ${theme.star.mediaQuery.desktop} {
        flex: 0 0 460px;
    }
    @media ${theme.star.mediaQuery.xLargeDesktop} {
        flex: 0 0 480px;
    }
`);
