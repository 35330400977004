import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const SelfExclusionParagraph = withConfig(theme => styled('p')`
    line-height: 1.4285;
    margin: 0 0 8px;
    font-size: ${theme.star.fontSize.xRegular};
`);

export const SelfExclusionExpiryDate = styled('strong')`
    float: right;
`;

export const SectionHeader = withConfig(theme => styled('h4')`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0 0 4px 0;
`);

export const SelfExclusionProcedureButton = styled(Button)`
    width: 100%;
`;
