import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { Iframe } from 'src/domains/layouts/webview/components/Iframe';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface PromoSidebarWrapperType {
    topOffset: number;
}

export const PromoSidebarWrapper = withConfig(theme => styled('aside')<PromoSidebarWrapperType>`
    &::-webkit-scrollbar {
        background-color: rgba(2, 13, 22, 0.2);
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
    }
    background-color: ${theme.star.promoSidebarSection.bgColor};
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
    max-width: 415px;
    overflow-y: auto;
    padding-bottom: 16px;
    position: fixed;
    right: 0;
    scrollbar-color: rgba(2, 13, 22, 0.2) rgba(255, 255, 255, 0.2);
    scrollbar-width: thin;
    top: ${({ topOffset }): string => `${topOffset}px`};
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.above};
    @media ${theme.star.mediaQuery.xMobile} {
        max-width: 320px;
    }
    @media ${theme.star.mediaQuery.desktop} {
        right: 280px;
    }
`);

export const IframeStyle = styled(Iframe)`
    width: 100%;
    border: none;
    height: 520px;
`;

export const BannerImage = styled('img')`
    display: block;
    width: 100%;
`;

export const LoginInfo = withConfig(theme => styled('h2')`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4286;
    margin: 0;
`);

export const TermsApplyMobile = withConfig(theme => styled('div')`
    color: ${theme.star.promoSidebarSection.txtColorSecondary};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    text-align: center;
`);

export const MoreInfoWrapper = withConfig(theme => styled('span')`
    color: ${theme.star.promoSidebarSection.txtColor};
    cursor: pointer;
    font-weight: ${theme.star.fontWeight.bold};
    margin-left: 4px;
    transition: color .2s ease;
    &:hover {
        color: ${theme.star.promoSidebarSection.txtColorSecondary};
    }
`);

export const LoginLinkWrapper = withConfig(theme => styled('span')`
    cursor: pointer;
    color: ${theme.star.promoSidebarSection.txtColorQuaternary};
    margin-left: 5px;
`);

export const CloseButton = styled(CloseIcon)`
    cursor: pointer;
    fill: currentcolor;
    height: 48px;
    padding: 14px;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
`;

export const IframeContent = withConfig(theme => styled('div')`
    margin-top: -220px;
    position: relative;
    @media ${theme.star.mediaQuery.xMobile} {
        margin-top: -320px;
    }
`);

