import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Iframe } from 'src/domains/layouts/webview/components/Iframe';

export const PraxisDepositButton = styled(Button)`
    margin: 32px 0 0;
    width: 100%;
`;

interface PraxisIframeStyleProps {
    isSignup: boolean;
}

export const PraxisIframeStyle = styled(Iframe)<PraxisIframeStyleProps>`
    width: 100%;
    border: none;
    height: 100%;
    padding: ${({ isSignup }):string => isSignup === true ? '16px' : '0px'};
`;
