import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const SelectionNestedWrapper = withConfig(theme => styled('div')`
    border-left: 1px solid ${theme.star.selectionsGroup.borderColor};
    display: flex;
    flex-flow: column nowrap;
`);

export const SelectionRabItemMenu = withConfig(theme => styled('ul')`
    background: ${theme.star.marketGroupGoalscorer.filters.bgColor};
    border-color: ${theme.star.selectionsGroup.borderColor};
    border-style: solid;
    border-width: 0 1px 1px 1px;
    display: flex;
    padding: 8px;
`);
