import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const CounterWrapper = styled('div')`
    display: flex;
    justify-content: center;
`;

export const Time = styled('div')`
    display: flex;
    align-items: baseline;
`;

interface TimerType {
    isSidebar?: boolean;
}

export const Number = withConfig(theme => styled('div')<TimerType>`
    color: ${theme.star.heroEvents.txtColor};
    font-size: 26px;
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1;
    text-align: right;
    width: 34px;
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${({ isSidebar }): string => isSidebar === true ? '22px' : '40px'};
        width: ${({ isSidebar }): string => isSidebar === true ? '30px' : '53px'};
    }
`);

export const Format = withConfig(theme => styled('div')<TimerType>`
    color: ${theme.star.heroEvents.txtColor};
    font-size: 13px;
    line-height: 1;
    margin: 0 10px 0 6px;
    opacity: 0.5;
    font-weight: ${theme.star.fontWeight.bold};
    text-transform: uppercase;
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${({ isSidebar }): string => isSidebar === true ? '12px' : '20px'};
    }
`);
