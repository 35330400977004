/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, autorun, computed, makeObservable, observable } from 'mobx';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { ErrorType, FreeBetType } from 'src/domains/sportsbook/betting/betSlipState/BetSlipSheredTypes';
import {
    BetslipAllSinglesId,
    BetslipCombinationId,
    BetslipRabId,
    BetslipSingleId,
} from 'src/domains/sportsbook/betting/models/BetslipIdModels';
import { BetslipIdsFactory } from 'src/domains/sportsbook/betting/models/BetslipIdsFactory';
import { CombinationInputType } from 'src/domains/sportsbook/betting/state/betSlipState/CombinationsStateType';
import { BetslipStateLegsType } from 'src/domains/sportsbook/betting/state/betSlipState/LegStateType';
import { Amount } from 'src_common/common/amount/Amount';
import { assertNever } from 'src_common/common/assertNever';
import { AutoMap, AutoMapSerialized } from 'src_common/common/mobx-utils/AutoMap';
import { EventEmmiter } from 'src_common/common/mobx-utils/EventEmmiter';
import { Value } from 'src_common/common/mobx-utils/Value';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { PriceType } from 'src_common/common/websocket2/modelsApi/Market';
import { QuickBetState } from './quickBetState/QuickBetState';
import { ReferralState } from './ReferralState';
import { StakeInputState2 } from './stakeInputState/StakeInputState2';

interface BetRefferalLegUpdateType {
    stakePerLine: string | undefined | null;
    price:
        | undefined
        | null
        | {
              d: number;
              f: string;
          };
}

interface BetRefferalCombinationsUpdateType {
    stakePerLine: string | null;
}

class RefItem {
    @observable public refSelection: HTMLElement | null = null;

    public constructor() {
        makeObservable(this);
    }
    @action public setRef = (ref: HTMLElement | null): void => {
        this.refSelection = ref;
    };
}
// refSelection

type BetslipIdType = BetslipSingleId | BetslipCombinationId | BetslipRabId | BetslipAllSinglesId;

const betslipIdSerialize = (id: BetslipIdType): string => {
    switch (id.type) {
        case 'all':
            return 'all';

        case 'single':
            return ['single', id.reactKey].join('_');

        case 'combination':
            return ['combination', id.reactKey].join('_');

        case 'rab':
            return ['rab', id.reactKey].join('_');

        default:
            return assertNever('', id);
    }
};

const newInputsCombination = (
    decimalLength: number,
    onChangeCombinationStake: (id: string, newValue: string) => void
): Value<AutoMap<string, StakeInputState2>> => {
    return new Value(
        new AutoMap(
            (id) =>
                new StakeInputState2(decimalLength, (newValue: Amount) => {
                    onChangeCombinationStake(id, newValue.value);
                })
        )
    );
};

export class BetslipData {
    private mapCorePrepareLegsValue: Value<Map<BetslipSingleId, BetslipStateLegsType>>;
    private mapCorePrepareCombinations: Value<Map<string, CombinationInputType>>; //TODO - string -> BetslipCombinationId
    private readonly refs: AutoMapSerialized<BetslipIdType, RefItem>;
    private inputsCombination: Value<AutoMap<string, StakeInputState2>>; //TODO - string -> BetslipCombinationId

    @observable private isFreeBetPopupShown = false;

    public readonly onGoogleTagManagerBetAddedTag: EventEmmiter<{
        selectionPrice: PriceType | undefined;
        marketId: number;
        marketName: string;
        selectionId: number;
        selectionName: string;
        betEventId: number;
        betEventName: string;
        type: string;
        sportId: string;
        sportName: string;
    }>;
    @observable private appliedFreeBetsById: Map<string, FreeBetType[]> = new Map();

    public getMapCorePrepareLegsValue(): Map<BetslipSingleId, BetslipStateLegsType> {
        return this.mapCorePrepareLegsValue.getValue();
    }

    public constructor(
        private readonly ids: BetslipIdsFactory,
        private readonly quickBetState: QuickBetState,
        private readonly referralState: () => ReferralState,
        private readonly whenBetDelete: (betId: BetslipSingleId) => void,
        private readonly lifeSpanState: () => LifeSpanState,
        private readonly decimalLength: number
    ) {
        makeObservable(this);

        this.mapCorePrepareLegsValue = new Value(new Map());
        this.onGoogleTagManagerBetAddedTag = new EventEmmiter();
        this.mapCorePrepareCombinations = new Value(new Map());
        this.refs = new AutoMapSerialized(betslipIdSerialize, () => new RefItem());

        // this.inputsSingle = newInputsSingle(this.decimalLength, this.onChangeStake);
        this.inputsCombination = newInputsCombination(this.decimalLength, this.onChangeCombinationStake);
    }

    /**
     * @deprecated - this method should be removed in the next version
     * @param selectionId
     * @returns
     */
    public convertOldSelectionIdToNewId = (
        oldSelectionId: number,
        refferalIndex?: number
    ): Promise<BetslipSingleId> => {
        return new Promise((resolve) => {
            autorun((dispose) => {
                const selectionModel = $appState.appSportsBookState.models.getSelection(oldSelectionId);

                if (selectionModel === null) {
                    return;
                }

                dispose.dispose();

                const id = this.ids.getSingleId(selectionModel.id2, refferalIndex ?? 0);
                resolve(id);
            });
        });
    };

    private onResetCombinations(): void {
        this.mapCorePrepareCombinations = new Value(new Map());
        this.inputsCombination = newInputsCombination(this.decimalLength, this.onChangeCombinationStake);
    }

    public getSelectedFreeBets(betId: string): FreeBetType[] {
        return this.appliedFreeBetsById.get(betId) ?? [];
    }

    public hasSelectedFreeBets(): boolean {
        return this.appliedFreeBetsById.size > 0;
    }

    public setSelectedFreeBets = (betId: BetslipSingleId | string, selectedFreeBets: FreeBetType[]): void => {
        const isCombination = typeof betId === 'string';

        const betIdString = isCombination ? betId : betId.id.rawId;
        this.appliedFreeBetsById.set(betIdString, selectedFreeBets);

        // Don't trigger post possible bets request if we don't have any freebets selected
        if (selectedFreeBets.length === 0) {
            return;
        }

        if (isCombination) {
            this.mutateCombinationsItem(betId, (legItem) => ({
                ...legItem,
                freeBets: selectedFreeBets,
            }));

            return;
        }

        this.mutateLegsItemIfExist(betId, (legItem) => ({
            ...legItem,
            freeBets: selectedFreeBets,
        }));
    };

    @computed public get isFreeBetPopupVisible(): boolean {
        return this.isFreeBetPopupShown;
    }

    @action public setIsFreeBetPopupVisible(value: boolean): void {
        this.isFreeBetPopupShown = value;
    }

    public getInputSingle(selectionId: BetslipSingleId): StakeInputState2 | null {
        const item = this.mapCorePrepareLegsValue.getValue().get(selectionId);
        if (item === undefined) {
            return null;
        }

        return item.stakePerLine;
    }

    public getInputCombination(combinationId: string): StakeInputState2 {
        return this.inputsCombination.getValue().get(combinationId);
    }

    private mutateLegs(callback: (map: Map<BetslipSingleId, BetslipStateLegsType>) => void): void {
        const map = this.mapCorePrepareLegsValue.getValue();
        callback(map);
        this.mapCorePrepareLegsValue.setValue(map);
        if (map.size <= 1) {
            this.ids.getAll().getModel().stakeInput.reset();
        }
    }

    private mutateLegsItemIfExist(
        betId: BetslipSingleId,
        callback: (item: BetslipStateLegsType) => BetslipStateLegsType
    ): void {
        const map = this.mapCorePrepareLegsValue.getValue();
        const item = map.get(betId);
        if (item === undefined) {
            console.error(`mutateLegsItemIfExist -> not exist betId=${betId.reactKey}`);
        } else {
            const newItem = callback(item);
            map.set(betId, newItem);
            this.mapCorePrepareLegsValue.setValue(map);
        }
    }

    private mutateCombinationsItem(
        combinationsId: string,
        callback: (item: CombinationInputType) => CombinationInputType
    ): void {
        const map = this.mapCorePrepareCombinations.getValue();
        const item: CombinationInputType = map.get(combinationsId) ?? { stakePerLine: '0', eachWay: false };

        const newItem = callback(item);
        map.set(combinationsId, newItem);
        this.mapCorePrepareCombinations.setValue(map);
    }

    @action public onChangePriceType = (betId: BetslipSingleId, price: 'fp' | 'sp'): void => {
        this.mutateLegsItemIfExist(betId, (legItem): BetslipStateLegsType => {
            return {
                ...legItem,
                priceType: price,
            };
        });
    };

    @action public onUpdateError = async (betId: number, errors: Array<ErrorType>): Promise<void> => {
        const id = await this.convertOldSelectionIdToNewId(betId);
        this.mutateLegsItemIfExist(id, (legItem): BetslipStateLegsType => {
            return {
                ...legItem,
                errors: errors,
            };
        });
    };

    private async triggerGoogle(legModel: BetslipStateLegsType): Promise<void> {
        const selection = await legModel.selectionId.getModelAsync();
        const market = await legModel.selectionId.getMarketId().getModelAsync();
        const event = await legModel.selectionId.getEventId().getEventModelAsync();

        this.onGoogleTagManagerBetAddedTag.trigger({
            selectionPrice: selection.price,
            marketId: market.id2.toOldId(),
            marketName: market.name,
            selectionId: selection.id2.toOldId(),
            selectionName: selection.name,
            betEventId: event.id2.toOldId(),
            betEventName: event.name,
            type: selection.isSP ? 'sp' : 'fp',
            sportId: event.sportOriginal,
            sportName: event.sport,
        });
    }

    @action private justAddBet = (selectionId: SelectionId, sp: boolean, index: number | null): void => {
        const betId = this.ids.getSingleId(selectionId, 0);
        const selectionModel = selectionId.getModel();

        if (selectionModel === null) {
            console.error('BetslipData.justAddBet -> no selectionModel loaded');
            return;
        }

        const selectionSuspended = selectionModel.forView(sp)?.suspended ?? false;
        const { isReferred } = this.referralState();
        const isEachWayExtra = selectionModel.templateMarketId === 'each-way-extra';

        if (selectionSuspended || isReferred) {
            console.warn('BetslipData.justAddBet ignore user event', { selectionSuspended, isReferred });
            return;
        }

        const coreLegsPossibleBetsRequestItem: BetslipStateLegsType = {
            selectionId,
            priceType: selectionModel.spOnly === true || sp === true ? 'sp' : 'fp',
            stakePerLine: new StakeInputState2(this.decimalLength, () => {
                const selectionBust = this.lifeSpanState().selectionsWithAvailableBoosts(betId.toString());
                if (selectionBust !== null && selectionBust.selectionInfo !== null) {
                    this.lifeSpanState().deactivateSelectionBoost(selectionBust.selectionInfo);
                }
            }),
            eachWay: isEachWayExtra,
            index,
            selectionPrice: selectionModel.price ?? null,
            errors: [],
            freeBets: [],
        };

        this.mutateLegs((map) => {
            map.set(betId, coreLegsPossibleBetsRequestItem);

            if (map.size === 1) {
                this.quickBetState.onUpdateQuickBet(true);
            } else {
                this.quickBetState.onUpdateQuickBet(false);
            }
        });

        this.onResetCombinations();

        this.triggerGoogle(coreLegsPossibleBetsRequestItem).catch((error) => {
            console.error(error);
        });
    };

    @action public onAddSimpleBet = (betIdNew: SelectionId): void => {
        const betId = this.ids.getSingleId(betIdNew, 0);

        const betItem = this.mapCorePrepareLegsValue.getValue().get(betId);
        if (betItem === undefined) {
            this.justAddBet(betIdNew, false, null);
        } else {
            if (betItem.priceType === 'sp') {
                this.onChangePriceType(betId, 'fp');
            } else {
                this.onRemoveLeg(betId);
            }
        }
    };

    @action public onRemoveLeg = (betId: BetslipSingleId): void => {
        this.onResetCombinations();

        this.mutateLegs((map) => {
            map.delete(betId);
            // this.inputsSingle.getValue().get(betId).changeStake(new Amount(''));
            this.appliedFreeBetsById.delete(`${betId.id.rawId}`);

            this.whenBetDelete(betId);
        });
    };

    @action public onAddSPBet = (betIdNew: SelectionId): void => {
        const betId = this.ids.getSingleId(betIdNew, 0);

        const betItem = this.mapCorePrepareLegsValue.getValue().get(betId);
        if (betItem === undefined) {
            this.justAddBet(betIdNew, true, null);
        } else {
            if (betItem.priceType === 'fp') {
                this.onChangePriceType(betId, 'sp');
            } else {
                this.onRemoveLeg(betId);
            }
        }
    };

    @action public onAddCastBet = (betIdNew: SelectionId, index: number, sp: boolean): void => {
        const betId = this.ids.getSingleId(betIdNew, 0);

        const betItem = this.mapCorePrepareLegsValue.getValue().get(betId);
        if (betItem === undefined) {
            this.justAddBet(betIdNew, sp, index);
        } else {
            this.onRemoveLeg(betId);
        }
    };

    // @action public onChangeStake = (betId: BetslipSingleId, stakeValue: Amount): void => {
    //     this.mutateLegsItemIfExist(betId, (legItem): BetslipStateLegsType => {
    //         const selectionBust = this.lifeSpanState().selectionsWithAvailableBoosts(betId.toString());
    //         if (selectionBust !== null && selectionBust.selectionInfo !== null) {
    //             this.lifeSpanState().deactivateSelectionBoost(selectionBust.selectionInfo);
    //         }

    //         console.log('stakeValue', stakeValue);
    //         return {
    //             ...legItem,
    //             stakePerLine: stakeValue,
    //         };
    //     });
    // };

    @action public onChangeEachWay = (betId: BetslipSingleId, checked: boolean): void => {
        this.mutateLegsItemIfExist(betId, (legItem): BetslipStateLegsType => {
            return {
                ...legItem,
                eachWay: checked,
            };
        });
    };

    @action public deleteMapCorePrepareLegs = (): void => {
        this.mapCorePrepareLegsValue.setValue(new Map());
        // this.inputsSingle = newInputsSingle(this.decimalLength, this.onChangeStake);
        this.onResetCombinations();
        this.appliedFreeBetsById.clear();
    };

    @action public updateOrCreateLegFromBetRefferal = (
        betId: BetslipSingleId,
        priceType: 'fp' | 'sp',
        stakePerLine: Amount,
        eachWay: boolean,
        selectionPrice: PriceType | null
    ): void => {
        this.mutateLegs((map) => {
            const betItem = map.get(betId);

            if (betItem === undefined) {
                const item: BetslipStateLegsType = {
                    selectionId: betId.id,
                    priceType,
                    stakePerLine: new StakeInputState2(this.decimalLength, () => {
                        const selectionBust = this.lifeSpanState().selectionsWithAvailableBoosts(betId.toString());
                        if (selectionBust !== null && selectionBust.selectionInfo !== null) {
                            this.lifeSpanState().deactivateSelectionBoost(selectionBust.selectionInfo);
                        }
                    }),
                    eachWay,
                    index: null,
                    selectionPrice,
                    errors: [],
                    freeBets: [],
                };

                item.stakePerLine.changeStake(stakePerLine);
                map.set(betId, item);
            } else {
                const newItem = {
                    ...betItem,
                    priceType,
                    eachWay,
                    selectionPrice,
                };

                betItem.stakePerLine.changeStake(stakePerLine);

                map.set(betId, newItem);
            }
        });
    };

    // @action public setMapCorePrepareLegs = async (betIdOld: number, legModel: BetslipStateLegsType): Promise<void> => {
    //     const betId = await this.convertOldSelectionIdToNewId(betIdOld);

    //     this.mutateLegs((map) => {
    //         map.set(betId, legModel);
    //     });
    // };

    @action public setSelectionPrice = (betId: BetslipSingleId, selectionPrice: PriceType | null): void => {
        this.mutateLegsItemIfExist(betId, (legItem): BetslipStateLegsType => {
            return {
                ...legItem,
                selectionPrice,
            };
        });
    };

    public getSelectionPrice = (betId: BetslipSingleId): PriceType | null => {
        return this.mapCorePrepareLegsValue.getValue().get(betId)?.selectionPrice ?? null;
    };

    public getAllLegsIds = (): Array<BetslipSingleId> => {
        return [...this.mapCorePrepareLegsValue.getValue().keys()];
    };

    //-------------------------------------------------------------------------------------
    //combinations
    //-------------------------------------------------------------------------------------

    @action public onChangeCombinationStake = (combinationType: string, stakeValue: string): void => {
        this.mutateCombinationsItem(combinationType, (combinationItem): CombinationInputType => {
            return {
                ...combinationItem,
                stakePerLine: stakeValue,
            };
        });
    };

    @action public onChangeEachWayCombination = (combinationType: string, checked: boolean): void => {
        this.mutateCombinationsItem(combinationType, (combinationItem): CombinationInputType => {
            return {
                ...combinationItem,
                eachWay: checked,
            };
        });
    };

    @action public deleteMapCorePrepareCombinations = (): void => {
        this.mapCorePrepareCombinations.setValue(new Map());
    };

    public getCoreCombinationsPossibleBetsRequest = (): Record<string, CombinationInputType> => {
        if (this.referralState().referralData !== null) {
            return this.referralState().coreCombinationsPossibleBetsRequest;
        }

        const tempObj: Record<string, CombinationInputType> = {};

        for (const [key, elem] of this.mapCorePrepareCombinations.getValue().entries()) {
            // const combinationItem = this.possibleBetsRequestState.mapCorePrepareCombinationsFromResponse.get(key);
            // if (combinationItem !== undefined) {
            tempObj[key] = {
                // legs: combinationItem.legs,
                // potentialReturns: combinationItem.potentialReturns ?? '0',
                // price: combinationItem.price,
                // ewOffered: combinationItem.ewOffered ?? false,
                // name: combinationItem.name ?? '',
                // maxStake: combinationItem.maxStake,
                stakePerLine: elem.stakePerLine,
                // potentialReturnsEw: elem.potentialReturns,
                // potentialReturnsAt: elem.stakePerLine,
                // numLines: combinationItem.numLines ?? 1,
                // type: combinationItem.type,
                eachWay: elem.eachWay,
                // freebetCredits: combinationItem.freebetCredits,
                // freebetRemarks: combinationItem.freebetRemarks,
            };
            // }
        }
        return tempObj;
    };

    //-------------------------------------------------------------------------------------
    //refs
    //-------------------------------------------------------------------------------------

    @action public setRef = (
        id: BetslipSingleId | BetslipCombinationId | BetslipRabId | BetslipAllSinglesId
    ): ((ref: HTMLElement | null) => void) => {
        const box = this.refs.get(id);
        return box.setRef;
    };

    public getRef = (
        id: BetslipSingleId | BetslipCombinationId | BetslipRabId | BetslipAllSinglesId
    ): HTMLElement | null => {
        return this.refs.get(id).refSelection;
    };

    //-------------------------------------------------------------------------------------
    //Updates from betRefferal
    //-------------------------------------------------------------------------------------

    @action public betReferralUpdateLeg = (selectionId: BetslipSingleId, leg: BetRefferalLegUpdateType): void => {
        if (
            leg.stakePerLine !== undefined &&
            leg.stakePerLine !== null &&
            !new Amount(leg.stakePerLine).isEqualWith(new Amount('0'))
        ) {
            const input = this.getInputSingle(selectionId);
            if (input === null) {
                console.error('betReferralUpdateLeg ignore input update');
            } else {
                input.changeStake(new Amount(leg.stakePerLine));
            }
        }

        if (this.getSelectionPrice(selectionId) === null) {
            this.setSelectionPrice(selectionId, leg.price ?? null);
        }
    };

    @action public betReferralUpdateCombination = (
        combinationId: string,
        combination: BetRefferalCombinationsUpdateType
    ): void => {
        if (combination.stakePerLine !== null && !new Amount(combination.stakePerLine).isEqualWith(new Amount('0'))) {
            this.getInputCombination(combinationId).changeStake(new Amount(combination.stakePerLine));
        }
    };
}
