import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface FootballIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const FootballIcon = observer(
    'FootballIcon',
    ({ className, onClick }: FootballIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='none'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12 2C6.48587 2 2 6.4858 2 12.0003C2 17.5145 6.4858 22 12 22C17.5144 22 21.9999 17.5145 21.9999 12.0003C21.9999 6.4858 17.5144 2 12 2ZM5.57856 18.7124C3.80905 17.0191 2.70362 14.6368 2.70362 12.0003C2.70362 11.9447 2.70674 11.8904 2.70774 11.8358L5.29711 12.7274L7.10888 17.3978L5.57856 18.7124ZM7.69845 4.99496L12.4 3.30341L15.7657 6.44321L13.0001 10.4029L7.8551 9.42509L7.69845 4.99496ZM18.2091 18.9114C18.0147 19.0866 17.8126 19.2532 17.6045 19.4116L13.7395 21.0628L11.2077 18.4242L14.4939 14.7258L18.8839 15.2659L18.2091 18.9114ZM19.4473 6.44148C20.6085 7.99303 21.297 9.91766 21.297 12.0003C21.297 12.0559 21.2936 12.1113 21.2926 12.1665L20.7687 12.034L19.1876 7.57504L19.4473 6.44148Z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
