import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

interface MarketGroupHeaderType {
    isCollapsed: boolean;
}

export const MarketGroup = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.small};
`);

export const MarketGroupHeader = withConfig(theme => styled('h3')<MarketGroupHeaderType>`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    margin: -1px 0 0;
    overflow: hidden;
    padding-right: 64px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`);

interface MarketDisplayColumnType {
    columns?: string;
}

export const SelectionRabList = styled('ul')<MarketDisplayColumnType>`
    display: ${({ columns }): string => columns === 'Column' ? 'block' : 'flex'};
    flex: 1 1 0;
    width: 100%;
`;

export const SelectionGroupWrapper = withConfig(theme => styled('div')`
    border-left: 1px solid ${theme.star.eventRow.borderColor};
    display: flex;
    flex-flow: column nowrap;
`);

export const SelectionsGroup = styled('div')`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
`;

export const ChevronIconWrapper = styled(ChevronIcon)`
    margin: 0 8px;
    width: 12px;
    flex: 0 0 12px;
    fill: currentcolor;
`;

export const ChevronIconMoreWrapper = styled(ChevronIconWrapper)`
    margin: 0 0 0 4px;
`;

export const RabHeaderWrapper = withConfig(theme => styled('div')`
    background: ${theme.star.headings.market.bgColor};
    border: 1px solid ${theme.star.headings.market.borderColor};
    color: ${theme.star.headings.market.txtColor};
    cursor: pointer;
    display: flex;
    margin-top: -1px;
    padding: 3px 0 2px;
`);
