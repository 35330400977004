import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface BetslipPropsType {
    className?: string;
    onClick?: () => void;
}

export const BetslipIcon = observer('BetslipIcon', ({ className, onClick }: BetslipPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#072d4f'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill='fill'
                fillRule='evenodd'
                d='M2 1h20.625v22H2V1zm4.125 6H18.5v1H6.125V7zM18.5 10H6.125v1H18.5v-1zM6.125 13H18.5v1H6.125v-1zm6.188 3H6.125v1h6.188v-1z'
                clipRule='evenodd'
            />
        </SvgElement>
    );
});
