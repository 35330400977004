import styled from '@emotion/styled';
import { AccountContent } from 'src/domains/players/webview/components/Account/accountParts/Account.style';
import { withConfig } from 'src/withConfig';

export const Wrapper = styled(AccountContent)`
    padding: 0 16px;
`;

export const SectionWrapper = styled('div')`
    padding: 16px 0;
`;

export const MarketingSectionWrapper = styled('div')`
    padding-top: 28px;
`;

export const Header = withConfig(theme => styled('h2')`
    font-size: ${theme.star.fontSize.regular};
    line-height: 1.5;
    margin: 0;
    text-transform: uppercase;
`);
