import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { withConfig } from 'src/withConfig';

export const ErrorModalWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.errorModalLayoutColors.bgColor};
    position: fixed;
    opacity: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in;
    z-index: ${theme.star.zIndexCasino.errorWrapper};
`);

export const ErrorModalInner = withConfig(theme => styled('div')`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${theme.star.zIndexCasino.errorInner};
    width: 300px;
    border-radius: 5px;
    display: flex;
    flex-flow: column;
`);

export const ErrorModalHeader = withConfig(theme => styled('div')`
    background-color: ${theme.star.errorModalLayoutColors.bgColorSecondary};
    border-radius: 5px 5px 0 0;
    height: 120px;
    position: relative;
    width: 100%;
`);

export const ErrorModalIconWrapper = withConfig(theme => styled(WarningIcon)`
    ${positionElementFull};
    fill: ${theme.star.errorModalLayoutColors.bgColorTertiary};
    width: 40px;
    position: absolute;
    margin: auto;
`);

export const ErrorModalContent = withConfig(theme => styled('div')`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-items: center;
    justify-content: space-around;
    width: 100%;
    height: 214px;
    background-color: ${theme.star.errorModalLayoutColors.bgColorTertiary};
    font-weight: ${theme.star.fontWeight.bold};
    padding: 10px 10px 30px 10px;
    border-radius: 0 0 5px 5px;
`);

export const ErrorModalContentHeader = withConfig(theme => styled('h3')`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.regular};
    text-align: center;
    color: ${theme.star.errorModalLayoutColors.txtColor};
`);

export const ErrorModalContentText = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.medium};
    text-align: center;
    color: ${theme.star.errorModalLayoutColors.txtColorSecondary};
`);

export const ErrorModalBtnWrapper = styled(Button)``;
