import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface CasinoIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const CasinoIcon = observer(
    'CasinoIcon',
    ({ className, onClick }: CasinoIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M17.902 13.723c-1.362-2.934-.872-6.845.304-9.654.552-.197-.528-.542-.93-.468-4.231.796-8.08 3.601-9.554 7.702C4.914 8.505.615 11.261.753 15.345c.114 3.366 2.09 5.875 5.225 6.444 4.548.823 7.685-3.515 7.829-6.522.136-2.83-2.159-4.47-4.696-3.671 1.162-3.54 4.223-6.144 7.753-7.178-1.038 2.753-1.413 6.237-.282 9.026-.603-.607-1.275-.961-1.96-1.096a5.43 5.43 0 01.686 2.677c0 .087 0 .185-.006.315-.15 2.34-1.536 5.09-3.936 6.695.878.942 2.037 1.606 3.447 1.863 4.547.823 7.685-3.515 7.83-6.522.137-2.843-2.185-4.485-4.741-3.653zm-7.012 2.35l.003.004a.553.553 0 01.651-.455.563.563 0 01.455.651c-.365 1.967-1.93 3.51-3.806 3.525 0 0-.022-.003-.02 0-.019.003-.037.003-.056.003a.562.562 0 01-.069-1.123c.055-.006.096-.006.118-.006h.029c1.2.016 2.424-1.088 2.695-2.6v.001zm5.95 5.833s-.023-.003-.023 0c-.02.003-.042.003-.063.003a.563.563 0 01-.06-1.121c.09-.01.135-.007.146-.007 1.198.016 2.425-1.088 2.697-2.6v.003a.561.561 0 111.107.198c-.362 1.965-1.932 3.507-3.804 3.524zM15.63 1.907S13.644-.896 10.07.295C8.249.904 6.717 2.147 5.795 3.202c1.635 1.095 3.38 1.501 5.361 1.374 1.572-.108 3.237-1.107 4.475-2.669h-.001z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
