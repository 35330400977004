import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface JackpotBarType {
    isBigTile?: boolean;
}

export const JackpotBarWrapper = withConfig(theme => styled('span') <JackpotBarType>`
    background-color: ${theme.star.casino.game.bgColorSecondary};
    bottom: 0;
    color: ${theme.star.casino.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    left: 0;
    padding: 9px 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%;
    @media ${theme.star.mediaQuery.tablet} {
        padding: ${({ isBigTile }): string => isBigTile === true ? '10px' : '7px'} 0;
    }
`);
