import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface PropsType {
    isMobileGridOpen: boolean;
}

export const DesktopWrapper = styled('div')`
    margin-bottom: 10px;
`;

export const MobileWrapper = withConfig(theme => styled('div') <PropsType>`
    @supports not (height: 100vh) {
        top: 100%;
    }
    background-color: ${({ isMobileGridOpen }): string => isMobileGridOpen ? theme.star.casino.casinoMini.bgColor : 'transparent'};
    border-radius: 4px 4px 0 0;
    height: 66vh;
    margin-top: ${({ isMobileGridOpen }): string => isMobileGridOpen ? '0' : '-88px'};
    position: fixed;
    right: 0;
    top: 100dvh;
    transform: ${({ isMobileGridOpen }): string => isMobileGridOpen ? 'translateY(-100%)' : 'translateY(0)'};
    transition: transform .5s ease-out;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.miniGamesGridMobile};
    overflow: hidden;
    @media ${theme.star.mediaQuery.xxMobile} {
        max-width: 375px;
    }
`);
