import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { WarningIcon } from 'src/domains/layouts/shared/assets/icons/WarningIcon';
import { withConfig } from 'src/withConfig';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';

export const PayButton = styled(Button)`
    margin-top: 32px;
    text-transform: capitalize;
    width: 100%;
`;

export const InfoContainer = withConfig(theme => styled('div')`
    border: solid 1px ${theme.star.kycWarningColors.borderColor};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
    margin: 10px 0 20px;
    padding: 8px;
    position: relative;
`);

export const InfoWarningIconWrapper = withConfig(theme => styled(WarningIcon)`
    fill: ${theme.star.kycWarningColors.bgColor};
    flex: 0 0 24px;
    width: 24px;
    margin-right: 8px;
`);

export const ErrorLink = styled(Link)`
    color: currentcolor;
`;

