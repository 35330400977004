import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

export const FooterCopyrightWrapper = styled('div')`
    display: flex;
    flex-flow: row nowrap;
`;

export const FooterCopyrightText = withConfig(theme => styled('p')`
    color: ${theme.star.footerMain.txtColorTertiary};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin: 0;
`);

export const LinkWrapper = withConfig(theme => styled(Link)`
    color: ${theme.star.footerMain.txtColorSecondary};
    cursor: pointer;
    text-decoration: none;
    transition: color 0.2s ease;
    &:hover {
        color: ${theme.star.footerMain.txtColorQuinary};
    }
`);

export const EmptyLinkWrapper = withConfig(theme => styled('span')`
    color: ${theme.star.footerMain.txtColorSecondary};
`);

export const MobileAppVersion = withConfig(theme => styled('p')`
    color: ${theme.star.footerMain.txtColorTertiary};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin: 8px 0;
`);

export const ValidationScriptIconWrapper = withConfig(theme => styled('div')`
    background: ${theme.star.footerMain.bgColorSecondary};
    border-radius: 4px;
    flex: 0 0 42px;
    height: 42px;
    margin-right: 16px;
    overflow: hidden;
    padding: 5px;
    width: 42px;
`);

export const ValidationScriptImgWrapper = styled('img')`
    height: 42px;
    margin-right: 15px;
`;
