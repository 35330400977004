import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { WatchLiveButtonWrapper, StreamIconButtonWrapper } from './WatchLiveButton.style';

interface PropsType {
    children: string | JSX.Element;
    className?: string;
    onClick?: () => void;
    isDisabled?: boolean;
    streamOn?: boolean;
}

export const WatchLiveButton = observer('WatchLiveButton', ({ children, className, onClick, isDisabled, streamOn }: PropsType): JSX.Element => {
    return (
        <WatchLiveButtonWrapper className={className} onClick={onClick} isDisabled={isDisabled} streamOn={streamOn}>
            <StreamIconButtonWrapper isDisabled={isDisabled}/>
            { children }
        </WatchLiveButtonWrapper>
    );
});
