import * as React from 'react';
import styled from '@emotion/styled';

const SvgElement = styled('svg')`
    height: auto;
`;

interface PropsType {
    className?: string;
}

export const SilkIcon = ({ className }: PropsType): JSX.Element => (

    <SvgElement
        className={className}
        width='24'
        height='25'
        fill='#364778'
        viewBox='0 0 24 25'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.508 1.336v1.77L3.085 6.192 0 23.14h3.363l2.362-8.082 1.235 7.594h10.094l1.234-7.594 2.35 8.082H24v-.003L20.891 6.173l-5.423-3.085V1.336h-6.96Z'
            fill='fill'
        />
    </SvgElement>
);


