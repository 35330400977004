import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const InfoWrapper = withConfig(theme => styled('div')`
    position: relative;
    padding: 10px 5px;
    text-align: center;
    font-size: ${theme.star.fontSize.small};
`);

export const LoaderWrapper = styled(Loader)`
    color: currentcolor;
    justify-content: center;
`;
