import styled from '@emotion/styled';
import { RadioButtonsStateLess } from 'src/domains/layouts/webview/components/form/radioButtons/RadioButtons';
import { withConfig } from 'src/withConfig';

export const SectionWrapper = styled('div')`
    margin-top: 12px;
`;

export const SectionWrapperSecondary = styled('div')`
    margin-top: 16px;
`;

export const Header = withConfig(theme => styled('h3')`
    line-height: 1.3334;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    margin: 14px 0 0 0;
`);

export const ButtonTitle = withConfig(theme => styled('span')`
    font-size: ${theme.star.fontSize.xRegular};
    min-width: 120px;
    width: 100%;
`);

export const ButtonTitleSecondary = withConfig(theme => styled('span')`
    display: flex;
    align-items: center;
    font-size: ${theme.star.fontSize.small};
    margin-right: 12px;
    gap: 4px;
`);

export const ButtonsWrapper = styled('div')`
    display: flex;
    margin-top: 12px;
`;

export const RadioButtonWrapper = styled(RadioButtonsStateLess)`
    flex: 0 0 105px;
`;
