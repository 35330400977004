import styled from '@emotion/styled';
import { FreeBetIcon } from 'src/domains/layouts/shared/assets/icons';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`;

export const Container = withConfig(
    (theme) => styled.div`
        position: fixed;
        right: 0;
        background: ${theme.star.headerMain.bgColorTertiary};
        max-height: min(435px, 80vh);
        padding: 16px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        width: 100%;

        @media ${theme.star.mediaQuery.desktop} {
            top: 20%;
            width: 280px;
        }
    `
);

export const Header = withConfig(
    (theme) => styled.header`
        display: flex;
        align-items: center;
        background: ${theme.star.headerMain.bgColorTertiary};
        margin-bottom: 24px;
        flex-shrink: 0;
        gap: 6px;
    `
);

export const BetslipGiftIconWrapper = withConfig(
    (theme) => styled(FreeBetIcon)`
        fill: ${theme.star.form.txtColor};
    `
);

export const Title = withConfig(
    (theme) => styled.h3`
        margin: 0;
        font-size: ${theme.star.fontSize.regular};
        font-weight: ${theme.star.fontWeight.bold};
        color: ${theme.star.form.txtColor};
    `
);

export const CloseButtonIcon = withConfig(
    (theme) => styled(CloseIcon)`
        width: 14px;
        fill: ${theme.star.form.txtColorSecondary};
    `
);

export const CloseButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    z-index: 1;
    width: 20px;
    height: 20px;
    line-height: 24px;
    margin-left: auto;
`;

export const BetList = styled.section`
    margin-bottom: 14px;
    overflow-y: auto;
    flex-shrink: 1;
    min-height: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const BetItem = styled.label`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
    cursor: pointer;

    &:not(:last-of-type) {
        margin-bottom: 12px;
    }
`;

export const BetDetails = styled.div`
    display: flex;
    flex-direction: column;
    color: black;
`;

export const BetAmount = withConfig(
    (theme) => styled.div`
        font-size: ${theme.star.fontSize.small};
        font-weight: ${theme.star.fontWeight.bold};
    `
);

export const BetExpiry = styled.div`
    font-size: 10px;
    color: #666;
`;

export const ApplyButton = styled(Button)`
    width: 100%;
`;
