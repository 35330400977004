import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';
import { LogoZenetPay } from 'src/domains/common/paymentMethods/logos/LogoZenetPay';

export const DepositContainer = styled('div')`
    margin-top: 16px;
`;

export const PayButton = styled(Button)`
    margin-top: 32px;
    text-transform: capitalize;
    width: 100%;
    box-shadow: none;
`;

export const QrCodeContainer = styled('div')`
    text-align: center;
`;

export const QrCodeText = withConfig(theme => styled('p')`
    text-align: center;
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.xMedium};
    margin: 0;
`);

export const PaymentProviderImagesContainer = styled('div')`
    margin-top: 24px;
    display: flex;
    gap: 24px;
    justify-content: center;
`;

export const LogoZenetPayWrapper = styled(LogoZenetPay)`
    fill: #000;
    width: 122px;
    flex: 0 0 122px;
`;

export const ImageQrCode = styled('img')`
    height: 200px;
`;
