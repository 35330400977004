import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

export const CookiesPolicyWrapper = withConfig(theme => styled('div')`
    align-items: center;
    background-color: ${theme.star.cookieMessage.bgColor};
    display: flex;
    justify-content: center;
    padding: 8px;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.cookies};
    @media ${theme.star.mediaQuery.desktop} {
        padding: 4px;
        position: relative;
    }
`);

export const CookieMessageText = withConfig(theme => styled('p')`
    color: ${theme.star.cookieMessage.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin: 0;
    text-align: left;
`);

export const CookiesPolicyButton = withConfig(theme => styled(Button)`
    align-self: flex-start;
    margin-left: 8px !important;
    position: relative;
    text-transform: capitalize;
    @media ${theme.star.mediaQuery.tablet} {
        align-self: center;
    }
    @media ${theme.star.mediaQuery.desktop} {
        padding: 10px 12px;
    }
`);

export const CookiesPolicyLink = withConfig(theme => styled(Link)`
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.bold};
    color: ${theme.star.cookieMessage.txtColorSecondary};
    margin-left: 2px;
    text-decoration: none;
    &::after {
        content: '.';
    }
`);
