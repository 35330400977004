import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FullScreenInIcon } from 'src/domains/layouts/shared/assets/icons/FullScreenInIcon';
import { FullScreenOutIcon } from 'src/domains/layouts/shared/assets/icons/FullScreenOutIcon';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { withConfig } from 'src/withConfig';

export const HeaderWrapper = withConfig(theme => styled('header')`
    align-items: center;
    background: ${theme.star.casino.bgColorTertiary};
    display: flex;
    height: 56px;
    padding: 0 16px;
    width: 100%;
    flex-shrink: 0;
`);

export const ButtonsWrapper = withConfig(theme => styled('div')`
    color: ${theme.star.casino.txtColorTertiary};
    display: flex;
    margin-left: auto;
    margin-right: 4px;
    gap: 14px;
    @media ${theme.star.mediaQuery.tablet} {
        gap: 24px;
    }
`);

export const ResizeButton = withConfig(theme => styled('button')`
    color: ${theme.star.casino.txtColorTertiary};
    background-color: transparent;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-left: 4px;
    text-align: left;
    border: 0;
    cursor: pointer;
`);

const headerIconCss = css`
    margin: 6px 0 0 6px;
    fill: currentcolor;
    width: 20px;
`;

export const InFullScreenIcon = styled(FullScreenInIcon)`
    ${headerIconCss};
`;

export const OutFullScreenOutIcon = styled(FullScreenOutIcon)`
    ${headerIconCss};
`;

export const CloseGameIcon = styled(CloseIcon)`
    fill: currentcolor;
    flex: 0 0 21px;
    padding: 6px 0 6px 6px;
    cursor: pointer;
`;
