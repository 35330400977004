import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface RacingPostIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const RacingPostIcon = observer('RacingPostIcon', ({ className, onClick }: RacingPostIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='#000000'
            height='24'
            onClick={onClick}
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
            clipRule='evenodd'
            fillRule='evenodd'
            strokeLinejoin='round'
            strokeMiterlimit={2}
        >
            <path
                d='M12.477 13.275a.419.419 0 00-.419-.418h-.417a.419.419 0 00-.418.418v10.301c0 .231.187.419.418.419h.417a.42.42 0 00.419-.419v-10.3z'
                fill='fill'
            />
            <path
                d='M19.683 7.692a7.693 7.693 0 11-15.383 0 7.693 7.693 0 0115.383 0zm-4.524.034a3.202 3.202 0 11-6.404 0 3.202 3.202 0 016.404 0z'
                fill='#fa5858'
            />
        </SvgElement>
    );
});


