import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const TrapChallengeContainer = styled('div')`
    overflow-x: auto;
    margin-bottom: 20px;
`;

export const TrapHeaderList = withConfig(theme => styled('ul')`
    border-left: 1px solid ${theme.star.raceSummary.borderColor};
    display: grid;
    grid-template-columns: repeat(6, 70px);
    margin-left: -1px;
    min-width: max-content;
    line-height: 1;
    text-align: center;
    @media ${theme.star.mediaQuery.tablet} {
        grid-template-columns: repeat(6, 67px);
        margin-left: 0;
        padding-left: 264px;
    }
`);

export const TrapNumberSilk = withConfig(theme => styled('li')`
    border-left: 1px solid ${theme.star.raceSummary.borderColor};
    margin-left: -1px;
    padding: 0 16px;
    &:last-of-type {
        border-right: 1px solid ${theme.star.raceSummary.borderColor};
    }
    img {
        margin: 5px 0 3px 0;
        width: 29px;
    }
`);

export const CompetitionRow = withConfig(theme => styled('div')`
    border: 1px solid ${theme.star.raceSummary.borderColor};
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    font-size: ${theme.star.fontSize.xRegular};
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    min-width: fit-content;
    @media ${theme.star.mediaQuery.tablet} {
        flex-direction: row;
    }
`);

export const CompetitionRowName = withConfig(theme => styled('div')`
    align-items: center;
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
    color: ${theme.star.raceSummary.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    height: 24px;
    line-height: 1;
    overflow: hidden;
    padding: 5px 8px;
    text-overflow: ellipsis;
    width: 100%;
    @media ${theme.star.mediaQuery.tablet} {
        background-color: inherit;
        border-bottom: none;
        flex: 0 0 264px;
        font-weight: ${theme.star.fontWeight.regular};
        height: 40px;
        line-height: 1.3334;
        padding: 12px 8px;
    }
    @media ${theme.star.mediaQuery.largeDesktop} {
        font-size: ${theme.star.fontSize.small};
    }
`);

export const CompetitionRowTrapList = withConfig(theme => styled('ul')`
    background-color: ${theme.star.eventRow.bgColorQuaternary};
    display: grid;
    grid-template-columns: repeat(6, 70px);
    @media ${theme.star.mediaQuery.tablet} {
        grid-template-columns: repeat(6, 67px);
        overflow-x: initial;
    }
`);
