import styled from '@emotion/styled';
import { Status } from 'src/domains/players/state/BonusState';
import { withConfig } from 'src/withConfig';

export const BonusStatusWidget = withConfig((theme) => {
    const statusColors: Record<string, string> = {
        [Status.Active]: theme.star.statuses.success.bgColor,
        [Status.Expired]: theme.star.statuses.error.bgColor,
        [Status.Canceled]: theme.star.statuses.cancel.bgColor,
        [Status.Claimed]: 'rgba(0, 0, 0, 0.8)',
        [Status.RolledBack]: 'rgba(28, 27, 31, 0.1)',
    };

    return styled.span<{ status: string }>`
        background-color: ${({ status }) => statusColors[status]};
        border-radius: 18px;
        padding: 0 8px;
        font-size: ${theme.star.fontSize.xSmall};
        color: ${theme.star.statuses.success.txtColor};
    `;
});
