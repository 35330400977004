import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';


export const DescriptionsWrapper = withConfig(theme => styled('div')`
    border: solid 1px ${theme.star.topUp.borderColor};
    margin: 16px 0 0;
    padding: 16px;
`);

export const Description = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    margin: 0;
`);

export const AdditionalDescription = styled(Description)`
    margin-top: 8px;
`;

export const MarkedTxt = withConfig(theme => styled('a')`
    color: ${theme.star.topUp.txtColorSecondary};
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;
`);

export const ShowMoreWrapper = withConfig(theme => styled('div')`
    color: ${theme.star.topUp.txtColorSecondary};
    margin: 12px 0;
    display: flex;
    align-items: center;
    height: 16px;
`);

export const Arrow = styled(ChevronIcon)`
    fill: currentcolor;
    width: 12px;
`;

export const ShowMoreOrLess = withConfig(theme => styled('span')`
    display: inline-block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    margin-right: 4px;
`);
