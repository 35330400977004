import styled from '@emotion/styled';
import { BogIndicator } from 'src/domains/sportsbook/webview/components/bog/BogIdicator.style';
import { withConfig } from 'src/withConfig';

export const BogMessageWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.bogBanner.bgColor};
    color: ${theme.star.bogBanner.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    line-height: 1.3334;
    margin-top: 8px;
    padding: 8px 8px 8px 40px;
    position: relative;
`);

export const BogMessageIndicator = withConfig(theme => styled(BogIndicator)`
    background-color: ${theme.star.bog.bgColorSecondary};
    color: ${theme.star.bog.txtColorSecondary};
    left: 8px;
    position: absolute;
    top: 8px;
`);
