import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface HomeIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const HomeIcon = observer(
    'HomeIcon',
    ({ className, onClick }: HomeIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='none'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M21.7993 11.4945L20.3701 10.0666L12.5058 2.20916C12.2267 1.93028 11.7741 1.93028 11.4949 2.20916L3.63057 10.0666L2.2007 11.4953C1.9264 11.7791 1.93423 12.2313 2.21826 12.5053C2.49532 12.7727 2.93455 12.7727 3.21162 12.5053L3.42036 12.2953V21.2857C3.42036 21.6802 3.74045 22 4.13531 22H19.864C20.2588 22 20.5789 21.6802 20.5789 21.2857V12.2953L20.7884 12.5046C21.0724 12.7787 21.525 12.7708 21.7993 12.4871C22.0669 12.2102 22.0669 11.7714 21.7993 11.4945ZM14.1445 20.5714H9.85485V14.8568H14.1445V20.5714ZM19.149 20.5714H15.5743V14.1425C15.5743 13.748 15.2543 13.4282 14.8594 13.4282H9.1399C8.74503 13.4282 8.42494 13.748 8.42494 14.1425V20.5714H4.85026V10.8667L11.9996 3.72349L19.149 10.8667V20.5714Z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
