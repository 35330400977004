import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';
interface SportsEventHeaderGroupType {
    lightTheme?: boolean;
    isOutrights?: boolean;
}

const pickBgColor = (isOutrights: boolean | undefined, lightTheme: boolean | undefined, theme: EmotionTheme): string => {
    if (isOutrights === true) {
        return theme.star.headings.outright.bgColor;
    }
    if (lightTheme === true) {
        return theme.star.headings.market.bgColor;
    }
    return theme.star.headings.competition.bgColor;
};

export const SportsEventHeaderGroup = withConfig(theme => styled('header')<SportsEventHeaderGroupType>`
    align-items: center;
    background-color: ${({ isOutrights, lightTheme }): string => pickBgColor(isOutrights, lightTheme, theme)};
    border-top: 1px solid ${theme.star.headings.competition.borderColor};
    color: ${theme.star.headings.competition.txtColor};
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    margin-top: -1px;
    min-height: 24px;
    overflow: hidden;
    position: relative;
    &:last-of-type {
        border-bottom: none;
    }
    @media ${theme.star.mediaQuery.tablet} {
        height: 28px;
    }
`);

export const SportsEventHeaderGroupName = withConfig(theme => styled('h4')`
    color: ${theme.star.headings.competition.txtColor};
    flex: 1 1 100%;
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.2;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`);

export const SportsEventHeaderGroupSelection = withConfig(theme => styled('div')`
    align-items: center;
    color: ${theme.star.headings.competition.txtColorSecondary};
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    flex: 0 0 47px;
    height: 100%;
    justify-content: center;
    text-align: center;
    @media ${theme.star.mediaQuery.tablet} {
        flex-basis: 67px;
    }
`);

export const SportsEventHeaderGroupSelectionName = withConfig(theme => styled('span')`
    color: currentcolor;
    &:last-of-type {
        display: none;
    }
    @media ${theme.star.mediaQuery.tablet} {
        &:first-of-type {
            display: none;
        }
        &:last-of-type {
            display: block;
        }
    }
`);

export const ChevronIconWrapper = styled(ChevronIcon)`
    margin: 0 8px;
    width: 12px;
    flex: 0 0 12px;
    fill: currentcolor;
`;
