import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { UserIcon } from 'src/domains/layouts/shared/assets/icons/UserIcon';
import { ChatIcon } from 'src/domains/layouts/shared/assets/icons/ChatIcon';
import { BetslipIcon } from 'src/domains/layouts/shared/assets/icons/BetslipIcon';
import { BetslipIconNew } from 'src/domains/layouts/shared/assets/icons/BetslipIconNew';
import { withConfig } from 'src/withConfig';
import { css } from '@emotion/react';

const renderButton = (isActive: boolean, theme: EmotionTheme): string => {
    if (isActive === true) {
        return `
            background-color: ${theme.star.quickHeaderMain.bgColor};
            color: ${theme.star.quickHeaderMain.txtColorSecondary};
        `;
    }
    return `
        background-color: transparent;
        color: ${theme.star.quickHeaderMain.txtColor};
    `;
};

interface QuickBetButtonPropsType {
    isActive: boolean;
}

export const QuickHeaderButton = withConfig(theme => styled('button') <QuickBetButtonPropsType>`
    ${({ isActive }): string => renderButton(isActive, theme)};
    align-items: center;
    border: 0;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin: 0;
    min-width: 48px;
    padding: 6px 0;
    position: relative;
`);

export const QuickHeaderWrapper = withConfig(theme => styled('nav')`
    color: ${theme.star.quickHeaderMain.txtColor};
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    text-align: center;
`);

const Counter = withConfig(theme => styled('span')`
    align-items: center;
    background-color: ${theme.star.counter.bets.bgColor};
    border-radius: 50%;
    color: ${theme.star.counter.bets.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.bold};
    height: 18px;
    width: 18px;
    justify-content: center;
    position: absolute;
    right: 7px;
    top: 2px;
`);

export const CounterQuickHeader = styled(Counter)`
    width: 16px;
    height: 16px;
`;

export const UnreadCount = styled(Counter)`
    right: 4px;
`;

const iconFill = css`
    --icon-size: 20px;
    fill: currentcolor;
    flex: 0 0 var(--icon-size);
    width: var(--icon-size);
`;

export const UserIconWrapper = styled(UserIcon)`
    ${iconFill};
`;

export const ChatIconWrapper = styled(ChatIcon)`
    ${iconFill};
`;

export const BetslipIconWrapper = styled(BetslipIcon)`
    ${iconFill};
`;

export const BetslipNewIconWrapper = styled(BetslipIconNew)`
    ${iconFill};
`;

export const LinkName = withConfig(theme => styled('span')`
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin-top: 2px;
    white-space: nowrap;
`);
