import styled from '@emotion/styled';
import { CheckCircleOutlineIcon } from 'src/domains/layouts/shared/assets/icons';
import { withConfig } from 'src/withConfig';

export const StyledContainer = withConfig(
    (theme) => styled.div`
        color: ${theme.star.form.txtColor};
        background-color: ${theme.star.buttons.primary.bgColor};
        margin-top: 5px;
        display: flex;
        align-items: center;
    `
);

export const StyledCheckCircleOutlineIcon = withConfig(
    (theme) => styled(CheckCircleOutlineIcon)`
        width: 17px;
        height: 17px;
        fill: ${theme.star.form.txtColor};
        margin-left: 7px;
    `
);

export const StyledText = withConfig(
    (theme) => styled.span`
        font-size: ${theme.star.fontSize.small};
        font-weight: ${theme.star.fontWeight.regular};
        color: ${theme.star.form.txtColor};
        padding-left: 10px;
    `
);
