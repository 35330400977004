import styled from '@emotion/styled';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';
import { SerializedStyles, css } from '@emotion/react';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

export const SrgQuestionnaireContainer = withConfig(theme => styled('div')`
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar,
    ::-webkit-scrollbar-track-piece {
        background-color: transparent;
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
    }
    background-color: ${theme.star.srgQuestionnaire.bgColor};
    color: ${theme.star.srgQuestionnaire.txtColor};
    max-height: calc(100vh - 132px);
    max-width: 512px;
    margin: 0 16px;
    overflow: hidden auto;
    overscroll-behavior-y: contain;
    padding: 32px;
    position: fixed;
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
    scrollbar-width: thin;
`);

export const SrgQuestionnaireOverlay = withConfig(theme => styled('div')`
    ${positionElementFull};
    background-color: ${theme.star.sidebar.bgColorSecondary};
    position: fixed;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    z-index: ${theme.star.zIndexGlobal.lhsMenu};
`);

export const SrgQuestionnaireTitle = withConfig(theme => styled('h2')`
    margin: 0;
    font-size: ${theme.star.fontSize.xMedium};
    line-height: 1.4;
`);

export const SrgQuestionnaireDescription = withConfig(theme => styled('div')`
    color: ${theme.star.srgQuestionnaire.txtColorSecondary};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    margin: 8px 0 0 0;
`);

export const SrgQuestionnaireButton = styled(Button)`
    margin: 8px 0;
    width: 100%;
`;

export const SrgQuestionnaireDescriptionMore = withConfig(theme => styled('footer')`
    color: ${theme.star.srgQuestionnaire.txtColorTertiary};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin-top: 16px;
`);

interface SrgQuestionnaireDescriptionEmailInfoContainerPropsType {
    isHighlited?: boolean;
}

export const buildSrgQuestionnaireDescriptionEmailInfoContainerHighlited = (theme: EmotionTheme,isHighlited?: boolean): SerializedStyles | null => {
    if (isHighlited === true) {
        return css`
            color: ${theme.star.srgQuestionnaire.txtColorSecondary};
            font-weight: ${theme.star.fontWeight.bold};
        `;
    }
    return null;
};

export const SrgQuestionnaireDescriptionEmailInfoContainer = withConfig(theme => styled('p')<SrgQuestionnaireDescriptionEmailInfoContainerPropsType>`
    color: ${theme.star.srgQuestionnaire.txtColorTertiary };
    font-weight: ${ theme.star.fontWeight.regular};

    ${({ isHighlited }): SerializedStyles | null => buildSrgQuestionnaireDescriptionEmailInfoContainerHighlited(theme, isHighlited)};
`);

interface SrgQuestionnaireEmailOrPhoneLinkPropsType {
    isHighlited?: boolean;
}

export const buildSrgQuestionnaireEmailOrPhoneLinkHighlited = (theme: EmotionTheme,isHighlited?: boolean): SerializedStyles | null => {
    if (isHighlited === true) {
        return css`
            color: ${theme.star.srgQuestionnaire.txtColor};
            font-weight: ${theme.star.fontWeight.bold};
        `;
    }
    return null;
};

export const SrgQuestionnaireEmailOrPhoneLink = withConfig(theme => styled('a')<SrgQuestionnaireEmailOrPhoneLinkPropsType>`
    color: ${theme.star.srgQuestionnaire.txtColorSecondary };
    text-decoration: none;
    transition: opacity .2s ease;
    font-weight: ${theme.star.fontWeight.regular};
    &:hover {
        opacity: 0.64;
    }

    ${({ isHighlited }): SerializedStyles | null => buildSrgQuestionnaireEmailOrPhoneLinkHighlited(theme, isHighlited)};
`);

export const SrgQuestionnaireCloseIcon = styled(CloseIcon)`
    cursor: pointer;
    fill: currentcolor;
    height: 44px;
    padding: 16px 8px 16px 16px;
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
`;

export const SrgQuestionnaireSteps = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4286;
    margin-bottom: 24px;
    &:empty {
        margin-bottom: 0;
    }
`);

export const SrgQuestionnaireMoreLess = withConfig(theme => styled('button')`
    background: transparent;
    border: 0;
    color: ${theme.star.srgQuestionnaire.txtColorQuaternary};
    cursor: pointer;
    display: block;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4;
    margin: 0;
    padding: 0;
`);

interface SrgQuestionnaireQuestionContainerPropsType {
    isDisabled?: boolean;
}

export const SrgQuestionnaireQuestionContainer = withConfig(theme => styled('div')<SrgQuestionnaireQuestionContainerPropsType>`
    margin: 24px 0 16px;
    background-color: ${({ isDisabled }): string => isDisabled === true ? theme.star.srgQuestionnaire.bgColorSecondary : theme.star.srgQuestionnaire.bgColorTertiary};
    padding: 16px 24px;
`);

export const SrgQuestionnaireQuestionText = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.small};
    margin: 0 0 12px 0;
    line-height: 1.3334;
`);

export const SrgQuestionnaireQuestionsOptionsWrapper = styled('div')`
    display: flex;
    flex: 1 1 50%;
`;

export const SrgQuestionnaireBackButton = withConfig(theme => styled('button')`
    background: transparent;
    border: 0;
    color: ${theme.star.srgQuestionnaire.txtColorSecondary};
    cursor: pointer;
    display: block;
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 0 0 8px;
    padding: 12px;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%;
`);

export const SrgGreetingParagraph = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    margin: 8px 0 16px;
`);

export const SrgParagraph = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    margin: 16px 0;
`);

export const SrgParagraphSecondary = styled('p')`
    margin-bottom: 32px;
`;

export const SrgParagraphSmall = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin: 16px 0;
    &:last-of-type {
        margin-bottom: 0;
    }
`);

export const SrgVerdictParagraph = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    margin: 24px 0 0 0;
`);

interface SrgQuestionnaireBackButton {
    isUnderlined?: boolean;
    isHighlited?: boolean;
}

export const StaticLinkWrapper = withConfig(theme => styled(Link)<SrgQuestionnaireBackButton>`
    color: ${({ isHighlited }): string => isHighlited === true ? theme.star.srgQuestionnaire.txtColorSecondary : 'inherit'};
    transition: all .2s ease;
    text-decoration: ${({ isUnderlined }): string => isUnderlined === true ? 'underline' : 'none'};
    &:hover {
        opacity: 0.64;
        text-decoration: ${({ isUnderlined }): string => isUnderlined === true ? 'underline' : 'none'};
    }
`);
