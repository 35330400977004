import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const ChatMessagesListWrapper = withConfig(theme => styled('ul')`
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        background-color: ${theme.star.customScrollBar.dark.bgColor};
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${theme.star.customScrollBar.dark.bgColorSecondary};
        border-radius: 4px;
    }
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 10px 20px;
    position: relative;
    scrollbar-color: ${theme.star.customScrollBar.dark.bgColorSecondary} ${theme.star.customScrollBar.dark.bgColor};
    scrollbar-width: thin;
`);
