import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const ContainerStepOne = styled('div')`
    margin: 0;
    padding: 5px 16px 16px;
    width: 100%;
    > div {
        margin: 8px 0;
    }
`;

export const BtnWrapper = styled(Button)`
    display: block;
    width: 100%;
`;

export const PromoCodeContainer = withConfig(theme => styled('div')`
    background-color: ${theme.star.form.light.bgColor};
    border: 1px solid ${theme.star.form.light.borderColor};
    color: ${theme.star.form.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    height: 38px;
    line-height: 1;
    padding: 12px 47px 10px 12px;
    width: 100%;
`);
