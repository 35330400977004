import styled from '@emotion/styled';
import { Select } from 'src/domains/layouts/webview/components/form/select/Select';
import { Bullets } from 'src/domains/layouts/webview/components/popups/MarketingNotificationsPopup/Bullets';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { withConfig } from 'src/withConfig';

export const PopupContent = styled('div')`
    margin: auto 0;
`;

export const Header = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.xMedium};
    font-weight: ${theme.star.fontWeight.bold};
    text-align: center;
    margin-bottom: 0;
`);

export const Description = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    text-align: center;
    margin-top: 8px;
`);

export const QuestionLabel = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    margin-top: 40px;
    margin-bottom: 9px;
`);

export const SkipButton = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    text-transform: uppercase;
    cursor: pointer;
    position: absolute;
    top: 32px;
    right: 32px;
`);

export const QuestionsSelect = styled(Select)`
    margin: 0 0 40px 0;
`;

export const ButtonWrapper = styled(Button)`
    display: block;
    width: 100%;
    max-height: 45px;
`;

export const Label = withConfig(theme => styled('div')`
    font-size: ${theme.star.fontSize.xRegular};
`);

export const BulletsNav = styled(Bullets)`
    position: relative;
    bottom: 0;
    margin: 35px 0 0 0;
`;
