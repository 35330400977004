import styled from '@emotion/styled';
import { ClosePreviewIcon } from 'src/domains/casino/webview/CasinoGames/mini-games/mini-game-modal/MiniGameModal.style';
import { withConfig } from 'src/withConfig';

export const PlayNowButton = withConfig(theme => styled('button')`
    background-color: ${theme.star.casino.casinoMini.bgColorSecondary};
    border: none;
    color: ${theme.star.casino.txtColor};
    cursor: pointer;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    padding: 4px 8px;
`);

export const CloseWidgetIcon = styled(ClosePreviewIcon)`
    right: 16px;
`;
