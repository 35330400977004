import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface EyeOpenIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const EyeOpenIcon = observer(
    'EyeOpenIcon',
    ({ className, onClick }: EyeOpenIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12 8.652c.885 0 1.651.324 2.298.97.647.647.97 1.413.97 2.298s-.323 1.651-.97 2.298-1.413.97-2.298.97-1.651-.323-2.298-.97-.97-1.413-.97-2.298.323-1.65.97-2.298c.647-.646 1.413-.97 2.298-.97zm0 8.732c1.498 0 2.783-.536 3.855-1.608 1.073-1.073 1.609-2.358 1.609-3.856 0-1.498-.536-2.783-1.609-3.855C14.783 6.993 13.498 6.456 12 6.456c-1.498 0-2.783.537-3.855 1.609-1.073 1.072-1.609 2.357-1.609 3.855 0 1.498.536 2.783 1.609 3.856 1.072 1.072 2.357 1.608 3.855 1.608zM12 3.75c2.69 0 5.123.749 7.302 2.247C21.481 7.495 23.047 9.469 24 11.92c-.953 2.451-2.52 4.426-4.698 5.924C17.124 19.342 14.69 20.09 12 20.09c-2.69 0-5.123-.748-7.302-2.246C2.519 16.346.953 14.37 0 11.92c.953-2.45 2.52-4.425 4.698-5.923C6.877 4.499 9.31 3.75 12 3.75z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
