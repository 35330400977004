import styled from '@emotion/styled';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

export const HeaderWrapper = styled('header')`
    align-items: center;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 8px 0;
`;

export const Headline = withConfig(theme => styled('h3')`
    color: ${theme.star.casino.txtColor};
    flex: 1 1 0%;
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4;
    margin: 0;
    @media ${theme.star.mediaQuery.desktop} {
        font-size: ${theme.star.fontSize.xMedium};
        line-height: 1.5;
    }
`);

export const ViewAll = withConfig(theme => styled('div')`
    color: ${theme.star.casino.casinoSlider.txtColor};
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const ViewAllTxt = styled('span')`
    margin-right: 8px;
`;

export const ChevronRight = styled(ChevronIcon)`
    fill: currentcolor;
    width: 14px;
`;
