import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface NewsIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const NewsIcon = observer(
    'NewsIcon',
    ({ className, onClick }: NewsIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M22 1H11.5v4.5H22V1zm0 7.5H11.5v3H22v-3zM8.5 1H1v10.5h7.5V1zm.75 21H22v-3H9.25v3zM22 13.75H1v3h21v-3zM6.25 19H1v3h5.25v-3z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
