import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const EventRowTitle = withConfig(theme => styled('span')`
    display: inline-block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3334;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media ${theme.star.mediaQuery.tablet} {
        display: block;
        margin-bottom: 2px;
    }
`);

export const MobileParticipant = withConfig(theme => styled('span')`
    display: block;
    line-height: 1.3334;
    color: ${theme.star.eventRow.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    &:not(:last-of-type) {
        margin-bottom: 2px;
    }
`);
