import styled from '@emotion/styled';
import { BoostIcon } from 'src/domains/layouts/shared/assets/icons/BoostIcon';
import { withConfig } from 'src/withConfig';

export const BoostIconWrapper = withConfig(theme => styled(BoostIcon)`
    fill: ${theme.star.lifeSpanBoostButton.txtColorTertiary};
    width: 14px;
    height: 14px;
    animation: blinker 1.2s linear infinite;

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
`);

const renderSpaceWrapper = (type: string): string | undefined => {
    if (type === 'market') { // type: 'sport' | 'event' | 'competition' | 'market' | 'selection'
        return `
            position: relative;
            width: 14px;
            height: 14px;
            margin: 0 4px 0 -4px;
        `;
    }
};

interface PropsTypes {
    type: string,
}

export const SpaceWrapper = styled('div')<PropsTypes>`
    ${({ type }): string | undefined => renderSpaceWrapper(type)};
`;
