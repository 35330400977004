import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { withConfig } from 'src/withConfig';

export const BalanceWrapper = styled('div')`
    align-items: center;
    display: flex;
    justify-content: space-between;
    line-height: 1.3334;
`;

export const BalanceValue = withConfig(theme => styled('span')`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4286;
`);

export const BalanceWrapperSecondary = styled('div')`
    display: flex;
    justify-content: space-between;
`;

export const WithdrawForm = styled('form')`
    margin: 0;
`;

export const WithdrawProcedureBtnWrapper = styled(Button)`
    display: block;
    margin-top: 24px;
    width: 100%;
`;

export const BalanceRowWrapper = styled('div')`
    display: flex;
`;

export const LabelInput = withConfig(theme => styled('span')`
    font-weight: ${theme.star.fontWeight.medium};
`);

export const WithdrawInput = styled(Input)`
    width: 100%;
`;
