import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg')`
    height: auto;
`;

interface GridIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const GridIcon = observer(
    'GridIcon',
    ({ className, onClick }: GridIconPropsType): JSX.Element => {
        return (
            <SvgElement
                className={className}
                clipRule='evenodd'
                fill='#072d4f'
                fillRule='evenodd'
                height='24'
                onClick={onClick}
                strokeLinejoin='round'
                strokeMiterlimit='2'
                viewBox='0 0 24 24'
                width='24'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M5.438 5.025a.412.412 0 01-.413.412H1.913a.412.412 0 01-.413-.412V1.913c0-.228.185-.413.413-.413h3.112c.228 0 .412.185.412.413v3.112h.001zm8.53 0a.411.411 0 01-.412.412h-3.112a.412.412 0 01-.413-.412V1.913c0-.228.185-.413.413-.413h3.112c.228 0 .413.185.413.413v3.112h-.001zm8.12.412a.412.412 0 00.412-.412V1.913a.413.413 0 00-.413-.413h-3.112a.413.413 0 00-.413.413v3.112c0 .228.185.412.413.412h3.113zm-16.65 8.119a.413.413 0 01-.413.413H1.913a.413.413 0 01-.413-.413v-3.112c0-.228.185-.413.413-.413h3.112c.228 0 .412.185.412.413v3.112h.001zm8.118.413a.413.413 0 00.413-.413v-3.112a.413.413 0 00-.413-.413h-3.112a.413.413 0 00-.413.413v3.112c0 .228.185.413.413.413h3.112zm8.944-.413a.413.413 0 01-.413.413h-3.112a.413.413 0 01-.413-.413v-3.112c0-.228.185-.413.413-.413h3.112c.228 0 .413.185.413.413v3.112zM5.025 22.5a.412.412 0 00.412-.413v-3.112a.412.412 0 00-.412-.413H1.913a.413.413 0 00-.413.413v3.112c0 .228.185.413.413.413h3.112zm8.944-.413a.413.413 0 01-.413.413h-3.112a.413.413 0 01-.413-.413v-3.112c0-.228.185-.413.413-.413h3.112c.228 0 .413.185.413.413v3.112zm8.118.413a.413.413 0 00.413-.413v-3.112a.413.413 0 00-.413-.413h-3.112a.413.413 0 00-.413.413v3.112c0 .228.185.413.413.413h3.112z'
                    fill='fill'
                />
            </SvgElement>
        );
    }
);
