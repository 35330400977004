import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const EventsTableInfo = withConfig(theme => styled('div')`
    border-top: 1px solid ${theme.star.eventsTableColors.borderColor};
    padding: 15px 5px 10px 5px;
    position: relative;
    text-align: center;
`);

export const FootballFiltersWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.filtersColors.wrapper.bgColorTertiary};
    border: 1px solid ${theme.star.filtersColors.wrapper.borderColor};
    padding: 8px 0 8px 8px;
`);
