import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const GameStatisticWrapper = withConfig(theme => styled('div')`
    flex-grow: 1;
    position: relative;
    &::before {
        background-color: ${theme.star.casino.gameInfo.bgColorTertiary};
        bottom: 20%;
        content: '';
        margin: auto;
        position: absolute;
        right: 0;
        top: 20%;
        width: 1px;
    }
    &:last-child::before {
        content: none;
    }
`);

export const GameStatisticLabel = withConfig(theme => styled('div')`
    text-align: center;
    width: 100%;
    color: ${theme.star.casino.gameInfo.txtColorQuinary};
`);

export const GameStatisticValue = withConfig(theme => styled('div')`
    color: ${theme.star.casino.gameInfo.txtColorSecondary};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    text-align: center;
    width: 100%;
`);
