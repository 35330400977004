import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

interface PropsType {
    className?: string
}

const SvgElement = styled('svg')`
    height: auto;
`;

// Temp icon - Please remove after betslip visual refactor.
export const CalendarEndIcon = observer('CalendarEndIcon', ({ className }: PropsType) => (
    <SvgElement
        className={className}
        clipRule='evenodd'
        fill='#fff'
        fillRule='evenodd'
        height='32px'
        strokeLinejoin='round'
        strokeMiterlimit='2'
        viewBox='0 0 32 32'
        width='32px'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path fill='none' d='M2 5h28v25H2z' />
        <path d='M32 5a2 2 0 00-2-2H2a2 2 0 00-2 2v25a2 2 0 002 2h28a2 2 0 002-2V5zm-2 0H2v25h28V5z'/>
        <path d='M4.5 0h1.958v7H4.5zM11.5 0h1.958v7H11.5zM25.5-.047h1.958v7H25.5zM18.5-.047h1.958v7H18.5zM10.385 11L9 12.385l12.728 12.728 1.385-1.385L10.385 11z'/>
        <path d='M23.284 12.385L21.899 11 9.172 23.728l1.384 1.385 12.728-12.728z'/>
    </SvgElement>
));
