import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { RewardsIcon } from 'src/domains/layouts/shared/assets/icons/RewardsIcon';
import { UnreadCount } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { AccountNavLink } from 'src/domains/players/webview/components/Account/accountPanel/accountNavigation/AccountNavigation.style';
import { SerializedStyles, css } from '@emotion/react';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

export const RewardsCounterWrapper = styled('div')`
    position: relative;
`;

export const RewardsIconWrapper = styled(RewardsIcon)`
    fill: currentcolor;
`;
interface RewardsCounterPropsType {
    isActiveLifespan?: boolean;
}

const renderLifeSpanCounter = (theme: EmotionTheme, isActiveLifespan?: boolean):SerializedStyles => {
    if (isActiveLifespan === true) {
        return css`
            background: ${theme.star.counter.rewards.bgColorSecondary};
            color: ${theme.star.counter.rewards.txtColorSecondary};
        `;
    }

    return css`
        background: ${theme.star.counter.rewards.bgColor};
        color: ${theme.star.counter.rewards.txtColor};
    `;
};

export const RewardsCounter = withConfig(theme => styled(UnreadCount)<RewardsCounterPropsType>`
    ${({ isActiveLifespan }): SerializedStyles => renderLifeSpanCounter(theme, isActiveLifespan)};
    height: 16px;
    left: 14px;
    padding-top: 1px;
    position: absolute;
    top: -9px;
    width: 16px;
`);

const renderLifeSpanButton = (theme: EmotionTheme, isActiveLifespan?: boolean):SerializedStyles => {
    if (isActiveLifespan === true) {
        return css`
            background: ${theme.star.lifeSpan.bgColorSecondary};
            color: ${theme.star.lifeSpan.txtColor};
            &:hover {
                &::before {
                    content: none;
                }
            }
        `;
    }

    return css``;
};

interface RewardNavLinkPropsType {
    isActiveLifespan?: boolean;
}

export const RewardNavLink = withConfig(theme => styled(AccountNavLink)<RewardNavLinkPropsType>`
    ${({ isActiveLifespan }): SerializedStyles => renderLifeSpanButton(theme, isActiveLifespan)};
`);
