import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

interface ExpandButtonType {
    isHomepage?: boolean;
}
/* 1 - You can delete this if z-index will be deleted in Selection component */
export const MoreLessGroup = withConfig(theme => styled('div')<ExpandButtonType>`
    background-color: ${theme.star.moreLessButton.bgColor};
    border-top: solid 1px ${theme.star.moreLessButton.borderColor};
    ${({ isHomepage }): string => isHomepage === true ? `border-bottom: solid 1px ${theme.star.moreLessButton.borderColor};` : ''}
    text-align: center;
    position: relative; /* 1 */
    margin-top: -1px; /* 1 */
`);

export const ChevronIconWrapper = styled(ChevronIcon)`
    fill: currentcolor;
    margin-left: 4px;
    width: 12px;
`;

export const LinkMoreLessWrapper = withConfig(theme => styled('button')`
    align-items: center;
    background: transparent;
    border: 0;
    color: ${theme.star.moreLessButton.txtColor};
    cursor: pointer;
    display: inline-flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: center;
    line-height: 1.3334;
    padding: 12px 10px;
    position: relative;
    text-decoration: none;
    transition: background-color .35s ease, color .35s ease !important;
    width: 100%;
    &:hover {
        background: ${theme.star.moreLessButton.bgColorSecondary};
        color: ${theme.star.moreLessButton.txtColorSecondary};
    }
`);
