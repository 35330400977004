import { css, SerializedStyles } from '@emotion/react';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import styled from '@emotion/styled';
import { fadeIn } from 'src/domains/layouts/shared/Animation.style';
import { withConfig } from 'src/withConfig';


const setChatMessagesListItem = (theme: EmotionTheme, senderType: 'customer' | 'staff' | 'system' | Error): SerializedStyles | null => {
    if (senderType === 'customer') {
        return css`
            align-self: flex-end;
            background-color: ${theme.star.traderChat.messages.bgColor};
            border-radius: 8px 8px 0 8px;
            border: solid 1px ${theme.star.traderChat.messages.borderColor};
            color: ${theme.star.traderChat.messages.txtColor};
            padding: 11px 12px;
        `;
    }

    if (senderType === 'staff') {
        return css`
            align-self: flex-start;
            background-color: ${theme.star.traderChat.messages.bgColorSecondary};
            border-radius: 0 8px 8px 8px;
            color: ${theme.star.traderChat.messages.txtColorSecondary};
            padding: 12px;
        `;
    }

    if (senderType === 'system') {
        return css`
            align-self: flex-start;
            background-color: ${theme.star.traderChat.messages.bgColorTertiary};
            border-radius: 0 8px 8px 8px;
            color: ${theme.star.traderChat.messages.txtColorSecondary};
            padding: 12px;
        `;
    }

    return null;
};

interface MessageItemPropsType {
    senderType: 'customer' | 'staff' | 'system' | Error;
}

export const MessageItem = withConfig(theme => styled('li')<MessageItemPropsType>`
    animation: ${fadeIn} .3s ease forwards;
    font-size: ${theme.star.fontSize.small};
    hyphens: auto;
    line-height: 1.334;
    margin: 0 0 38px;
    min-width: 52px;
    position: relative;
    word-break: break-word;
    ${({ senderType }): SerializedStyles | null => setChatMessagesListItem(theme, senderType)}
`);

interface MessageAuthorPropsType {
    senderType: 'customer' | 'staff' | 'system' | Error;
}

const setAuthorDesc = (senderType: 'customer' | 'staff' | 'system' | Error): SerializedStyles | null => {
    if (senderType === 'customer') {
        return css`
            text-align: right;
            right: 0;
        `;
    }

    if (senderType === 'staff' || senderType === 'system') {
        return css`
            text-align: left;
            left: 0;
        `;
    }

    return null;
};

export const MessageAuthor = withConfig(theme => styled('div')<MessageAuthorPropsType>`
    color: ${theme.star.traderChat.messages.txtColorTertiary};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    margin-top: 8px;
    position: absolute;
    top: 100%;
    width: 280px;
    ${({ senderType }): SerializedStyles | null => setAuthorDesc(senderType)};
`);

export const Author = styled('i')<MessageAuthorPropsType>`
    display: inline-block;
    font-style: normal;
    margin-right: 9px;
    position: relative;
    text-transform: capitalize;
    &::before {
        ${({ senderType }): string => (senderType === 'customer' || senderType === 'staff') ? 'content: ""' : 'content: none'};
        border-left: 1px solid currentcolor;
        bottom: 3px;
        left: 100%;
        margin-left: 4px;
        position: absolute;
        top: 2px;
        width: 1px;
    }
`;
