import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const ForgotPasswordWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.accountDrawer.bgColor};
    display: flex;
    flex-direction: column;
    height: 100%;
`);

export const ForgotPasswordForm = styled('div')`
    margin: 16px;
`;

export const ForgotPasswordBtnWrapper = styled(Button)`
    display: block;
    margin-top: 16px;
    width: 100%;
`;

export const ForgotPasswordDescription = withConfig(theme => styled('p')`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.regular};
    margin: 0 0 16px 0;
    line-height: 1.3334;
`);

export const AlignBottomWrapper = styled('div')`
    margin: auto 0 0 0;
`;
