import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const SportMeetingsListWrapper = withConfig(theme => styled('div')`
    background-color: ${theme.star.meetingsListColors.bgColor};
`);

export const SportMeetingsFiltersWrapper = withConfig(theme => styled('div')`
    border: 1px solid ${theme.star.filtersColors.wrapper.borderColor};
    padding: 8px 0 8px 8px;
`);

export const MeetingsAzSubheader = withConfig(theme => styled('div')`
    align-items: center;
    background: ${theme.star.headings.competition.bgColor};
    color: ${theme.star.headings.competition.txtColor};
    cursor: pointer;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: flex-start;
    line-height: 1.333;
    padding: 4px 0;
    user-select: none;
`);
